import * as React from "react";
import { FormattedMessage } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";

import { Autocomplete, TextField } from "@mui/material";

import { Cafs, CafsItem } from "../../../api/types/cafs";
import { List } from "../../../components/icons/List";

export type UpdateProps = {
  data: Cafs;
};

export function ChildrenInfo({ data }: UpdateProps) {
  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = (value: CafsItem | null) => {
    if (!value) {
      return;
    }
    const pathSegments = location.pathname.split("/");
    pathSegments[pathSegments.length - 1] = String(value.Id);
    const newPathname = pathSegments.join("/");
    navigate(newPathname);
  };

  return (
    <div className="flex flex-col text-darkGray justify-between">
      <div className="flex flex-row gap-2 mb-2 items-start w-[400px] flex-1">
        <List className="text-2xl text-primary" />
        <span className="font-semibold">
          <FormattedMessage
            id="CHILDREN_IFNO.TIER"
            defaultMessage="Children"
            description="Children info label text"
          />
        </span>
      </div>
      <div className="flex flex-row gap-4 w-full items-center flex-1">
        {!!data.Descendants?.length ? (
          <Autocomplete
            key={data.Id}
            size="small"
            className="w-full"
            onChange={(e, value) => handleChange(value)}
            options={data.Descendants}
            getOptionLabel={option => option.FullName ?? ""}
            renderInput={params => (
              <TextField
                key={data.Id}
                {...params}
                placeholder="Navigate to child"
              />
            )}
            isOptionEqualToValue={(option, value) => option.Id === value.Id}
          />
        ) : (
          "-----"
        )}
      </div>
    </div>
  );
}
