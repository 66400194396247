import React, { useContext } from "react";

import { CafsTreeNodeType } from "../../../types/cafs-tree-node-type";
import { DashboardContext } from "../DashboardContext";
import { treeNodeStyles } from "../utils/tree-node-styles";

import { CafsTreeItem } from "./CafsTreeItem";
import { CafsTreeItemLabel } from "./CafsTreeItemLabel";

export type TreeNodeProps = {
  treeNodes: CafsTreeNodeType[];
  searchTerm: string;
};

export function CafsTreeNode({ treeNodes, searchTerm }: TreeNodeProps) {
  const { selectedInputs, selectedPlants } = useContext(DashboardContext);

  const selectedNodes = [...selectedInputs, ...selectedPlants];

  return (
    <>
      {treeNodes.map(treeNode => {
        const isSelected = selectedNodes.some(
          selectedNode => selectedNode.nodeId === treeNode.nodeId,
        );
        return (
          <CafsTreeItem
            key={treeNode.nodeId}
            itemId={treeNode.nodeId}
            label={
              <CafsTreeItemLabel node={treeNode} isSelected={isSelected} />
            }
            sx={treeNodeStyles(treeNode, searchTerm)}>
            {!!treeNode?.childNodes?.length && (
              <CafsTreeNode
                treeNodes={treeNode.childNodes}
                searchTerm={searchTerm}
              />
            )}
          </CafsTreeItem>
        );
      })}
    </>
  );
}
