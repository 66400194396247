import { QueryKey } from "../../../consts/query-keys";

export const CafsViewQueryKeys = [
  [QueryKey.CafsDetails],
  [QueryKey.DefinitionSection],
  [QueryKey.GrowthSection],
  [QueryKey.InputCostSection],
  [QueryKey.InputLabourSection],
  [QueryKey.LifeCycleSection],
  [QueryKey.WoodDensitySection],
  [QueryKey.PlantAudit],
  [QueryKey.BiomassAllocationSection],
  [QueryKey.RootsSection],
  [QueryKey.ConstraintsSection],
  [QueryKey.GrowthConditionsSection],
  [QueryKey.LabourSection],
  [QueryKey.CostsSection],
  [QueryKey.Biodiversity],
  [QueryKey.PlantProducts],
  [QueryKey.PlantGuests],
  [QueryKey.GuestResponseToPressure],
  [QueryKey.GuestRtp],
  [QueryKey.PlantRtp],
];
