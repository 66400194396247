import * as React from "react";
import { useEffect, useRef } from "react";
import {
  Control,
  Controller,
  FieldValues,
  Path,
  useFormContext,
} from "react-hook-form";

import { TextFieldProps } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

type OptionType = {
  id: string | number | null | undefined;
  label: string /* other properties */;
};

type RHFAutocompleteFieldProps<
  O extends { id: string | number | null | undefined; label: string },
  TField extends FieldValues,
> = TextFieldProps & {
  control: Control<TField>;
  name: Path<TField>;
  options: O[];
  placeholder?: string;
  multiple?: boolean;
  disabled?: boolean;
  focusOnError?: boolean;
};

export const RHFAutocompleteField = <
  O extends { id: string | number | null | undefined; label: string },
  TField extends FieldValues,
>(
  props: RHFAutocompleteFieldProps<O, TField>,
) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { control, options, name, multiple, disabled } = props;
  const { getFieldState } = useFormContext();

  useEffect(() => {
    if (getFieldState(name).invalid && inputRef.current && props.focusOnError) {
      inputRef.current.focus();
    }
  }, [getFieldState(name).invalid]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const { onChange, value } = field;
        return (
          <div id={getFieldState(name).invalid ? `${name}-error` : name}>
            <Autocomplete
              disabled={disabled}
              multiple={multiple}
              value={
                value
                  ? multiple
                    ? Array.isArray(value)
                      ? options.filter(option => value.includes(option.id)) ??
                        []
                      : []
                    : options.find(option => option.id === value) ?? null
                  : multiple
                    ? []
                    : null
              }
              getOptionLabel={option => {
                return Array.isArray(option)
                  ? option.map(o => o?.label || "").join(", ")
                  : option?.label || "";
              }}
              onBlur={field.onBlur}
              onChange={(
                event: React.SyntheticEvent,
                //eslint-disable-next-line
                newValue: any,
                reason: string,
              ) => {
                const value = newValue as OptionType | OptionType[] | null;
                const valueToSet = multiple
                  ? Array.isArray(value)
                    ? value.filter(Boolean).map(option => option?.id)
                    : []
                  : Array.isArray(value)
                    ? value[0]?.id || null
                    : value?.id || null;
                field.onChange(newValue);
                onChange(valueToSet);
              }}
              id="controllable-states-demo"
              options={options}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={params => (
                <TextField
                  {...params}
                  size="small"
                  error={getFieldState(name).invalid}
                  label={props.placeholder}
                  inputRef={inputRef}
                  inputProps={{ ...params.inputProps, ...props.inputProps }}
                />
              )}
            />
          </div>
        );
      }}
    />
  );
};
