import React, { useContext } from "react";

import { Checkbox } from "@mui/material";

import { Access } from "../../../consts/access";
import { CafsTreeNodeType } from "../../../types/cafs-tree-node-type";
import { DashboardContext } from "../DashboardContext";

type CafsSelectProps = {
  node: CafsTreeNodeType;
  isSelected: boolean;
};

export function CafsSelect({ node, isSelected }: CafsSelectProps) {
  const { handleCafsSelect } = useContext(DashboardContext);
  const shouldRender =
    node.access !== Access.None && !node.isRoot && node.isAssigned;

  if (node.isRoot) {
    return null;
  }

  return shouldRender ? (
    <Checkbox
      sx={{ marginLeft: 0, marginRight: 1, width: "5px" }}
      checked={isSelected}
      onChange={(e, checked) => {
        e.stopPropagation();
        handleCafsSelect(node);
      }}
    />
  ) : null;
}
