import { useEffect } from "react";
import { UseFormReturn } from "react-hook-form";

import { InputDefinition } from "../../../../../api/types/inputs";
import { DataFormat } from "../../../../../consts/data-format";
import { createOptions } from "../../../../../utils/create-options";
import { useInputDefinitionData } from "../../../api/useInputDefinitionData";
import { CafsVariableType } from "../../../const/cafs-variable-type";
import { CafsVariablesCollection } from "../../cafs-table/CafsTable";

import { rusleFactorFn } from "./functions/rusleFactor";
import { renderForIrrigation } from "./render-for-irrigation";
import { renderForSeasonal } from "./render-for-seasonal";
import { renderForSeasonalWithSplits } from "./render-for-seasonal-with-splits";

export function useInputDefinitionVariables(
  methods: UseFormReturn,
): CafsVariablesCollection<InputDefinition> {
  const { data } = useInputDefinitionData(true);

  const baseCategory = methods.watch("Type.Base");

  useEffect(() => {
    if (baseCategory && baseCategory !== "InputSeasonalWithSplits") {
      methods.setValue("FreeNitrogenContent.Base", null);
      methods.setValue("BiomDMN.Base", null);
      methods.setValue("BiomDMP.Base", null);
      methods.setValue("BiomDMK.Base", null);
    }

    if (baseCategory && baseCategory === "InputSeasonalWithSplits") {
      methods.resetField("FreeNitrogenContent.Base");
      methods.resetField("BiomDMN.Base");
      methods.resetField("BiomDMP.Base");
      methods.resetField("BiomDMK.Base");
    }

    if (
      (baseCategory && baseCategory !== "InputSeasonalWithSplits") ||
      baseCategory !== "InputSeasonalNoSplits"
    ) {
      methods.setValue("BiomDMTonnePHaPY.Base", null);
      methods.setValue("BiomDecompPY.Base", null);
      methods.setValue("BiomDMTonnePHaPY.Base", null);
    }

    if (
      (baseCategory && baseCategory === "InputSeasonalWithSplits") ||
      baseCategory === "InputSeasonalNoSplits"
    ) {
      methods.resetField("BiomDMTonnePHaPY.Base");
      methods.resetField("BiomDecompPY.Base");
      methods.resetField("BiomDMTonnePHaPY.Base");
    }

    if (
      (baseCategory && baseCategory !== "IrrigationSprinkler") ||
      baseCategory !== "IrrigationDrip"
    ) {
      methods.setValue("IrrigationPerMonthMm.Base", null);
    }

    if (
      (baseCategory && baseCategory === "IrrigationSprinkler") ||
      baseCategory === "IrrigationDrip"
    ) {
      methods.resetField("IrrigationPerMonthMm.Base");
    }
  }, [baseCategory]);

  return [
    {
      name: "DefinitionRemarks",
      type: CafsVariableType.Remarks,
    },
    {
      name: "Type",
      type: CafsVariableType.Select,
      options: createOptions(data?.Types),
    },
    {
      name: "Scope",
      type: CafsVariableType.Select,
      options: createOptions(data?.Scopes),
    },
    {
      name: "TriggerEvent",
      type: CafsVariableType.Select,
      options: createOptions(data?.TriggerEvents),
    },
    {
      name: "TriggerInitialMonth",
      type: CafsVariableType.Input,
    },
    {
      name: "EconomicLifeCycleMonths",
      type: CafsVariableType.Input,
      format: DataFormat.Month,
    },
    {
      name: "RuslePFactor",
      type: CafsVariableType.Input,
    },
    {
      name: "RusleCFactor",
      type: CafsVariableType.Input,
    },
    {
      name: "RusleFactor",
      type: CafsVariableType.Calculated,
      dependOn: ["RusleCFactor", "RusleCFactor"],
      calculationFn: rusleFactorFn,
    },
    {
      name: "BiomDMTonnePHaPY",
      type: CafsVariableType.Input,
      renderCondition: renderForSeasonal,
    },
    {
      name: "BiomDecompPY",
      type: CafsVariableType.Input,
      renderCondition: renderForSeasonal,
    },
    {
      name: "BiomDMN",
      type: CafsVariableType.Input,
      renderCondition: renderForSeasonalWithSplits,
    },
    {
      name: "BiomDMP",
      type: CafsVariableType.Input,
      renderCondition: renderForSeasonalWithSplits,
    },
    {
      name: "BiomDMK",
      type: CafsVariableType.Input,
      renderCondition: renderForSeasonalWithSplits,
    },
    {
      name: "FreeNitrogenContent",
      type: CafsVariableType.Input,
      renderCondition: renderForSeasonalWithSplits,
    },
    {
      name: "SeasonalInputDMContent",
      type: CafsVariableType.Input,
      renderCondition: renderForSeasonal,
    },
    {
      name: "IrrigationPerMonthMm",
      type: CafsVariableType.Input,
      renderCondition: renderForIrrigation,
    },
  ];
}
