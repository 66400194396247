import React from "react";

import { CafsTreeNodeType } from "../../../types/cafs-tree-node-type";

import { CafsFolder } from "./CafsFolder";
import { CafsSelect } from "./CafsSelect";

type LabelIconProps = {
  node: CafsTreeNodeType;
  isSelected: boolean;
};
export function LabelIcon({ node, isSelected }: LabelIconProps) {
  return (
    <div className="flex items-center">
      <CafsSelect node={node} isSelected={isSelected} />
      <CafsFolder node={node} />
    </div>
  );
}
