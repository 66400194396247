import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { ConfirmationModal } from "../../../components/ConfirmationModal";
import { Check } from "../../../components/icons/Check";
import { useDraftApprove } from "../api/useDraftApprove";

export type RejectDraftModalProps = {
  open: boolean;
  onClose: () => void;
};

export function ApproveDraftModal({ open, onClose }: RejectDraftModalProps) {
  const { approveDraft, isPending: approvePending } = useDraftApprove({
    onSuccess: onClose,
  });

  const { formatMessage } = useIntl();

  return (
    <ConfirmationModal
      open={open}
      onConfirm={approveDraft}
      onCancel={onClose}
      icon={<Check />}
      header={formatMessage({
        id: "MODAl.APPROVE_DRAFT_HEADER",
        defaultMessage: "Approve draft",
        description: "Header of approve draft modal",
      })}
      okText={formatMessage({
        id: "MODAl.APPROVE_DRAFT_BTN",
        defaultMessage: "Approve draft",
        description: "Text of approve draft modal confirm button",
      })}
      cancelText={formatMessage({
        id: "MODAl.CANCEL_BNT",
        defaultMessage: "cancel",
        description: "Modal cancel button text",
      })}
      disabled={approvePending}
      text={
        <FormattedMessage
          id="MODAL.APPROVE_DRAFT_TEXT"
          defaultMessage="Are you sure you want to <span>approve</span> draft?"
          description="Draft approve confirmation message"
          values={{
            span: chunks => (
              <span className="font-semibold text-primary">{chunks}</span>
            ),
          }}
        />
      }
    />
  );
}
