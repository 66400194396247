import * as React from "react";
import { useContext } from "react";

import { Help } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { twMerge } from "tailwind-merge";

import { StringCafsValue } from "../../../../api/types/cafs";
import { TooltipItem } from "../../../../api/types/tooltips";
import { RhfInput } from "../../../../components/rhf/RhfInput";
import { TextWithEllipsis } from "../../../../components/TextWithEllipsis";
import { Status } from "../../../../consts/status";
import { getBaseValue } from "../../../../utils/variable-helper";
import { CafsContext } from "../../CafsContext";

export type SectionRemarksProps<T> = {
  name: keyof T;
  isReadMode?: boolean;
  tooltip?: TooltipItem;
  data?: T | undefined;
};

export function SectionRemarks<T>({
  isReadMode,
  name,
  tooltip,
  data,
}: SectionRemarksProps<T>) {
  const { cafs } = useContext(CafsContext);

  const variableValue = (data || {})[
    name as keyof typeof data
  ] as StringCafsValue;

  const displayEditLabel =
    getBaseValue(variableValue) !== null &&
    getBaseValue(variableValue) !== undefined &&
    !!String(getBaseValue(variableValue)).length &&
    cafs?.Status === Status.DraftEdit;

  return (
    <div
      className="mb-12 text-sm flex flex-row items-start gap-12"
      data-testid={`VariableRow.${name as string}`}>
      <div className="w-full">
        <RhfInput
          placeholder={`${name as string}`}
          multiline
          rows={3}
          label={`${name as string}`}
          name={`${name as string}.Base`}
          disabled={isReadMode}
          className={twMerge(displayEditLabel && "edited")}
          inputProps={{ "data-testid": `${name as string}.Remarks` }}
        />
        {displayEditLabel && (
          <small
            className="text-orange-400 italic"
            data-testid="row-edited-label">
            Edited
          </small>
        )}
        {getBaseValue(variableValue) && cafs?.Status === Status.DraftEdit && (
          <div className="flex flex-col gap-2  mb-4 mt-4 items-start">
            <p className="font-bold text-nowrap text-left">Current value: </p>
            <TextWithEllipsis text={variableValue?.Current ?? ""} limit={200} />
          </div>
        )}
      </div>
      {tooltip && tooltip.Description && (
        <Tooltip title={tooltip.Description}>
          <IconButton className="hover:text-primary">
            <Help />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
}
