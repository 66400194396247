import React, { useContext } from "react";

import { Tooltip } from "@mui/material";

import { Copy } from "../../../components/icons/Copy";
import { CafsTreeNodeType } from "../../../types/cafs-tree-node-type";
import { useCafsCopy } from "../api/useCafsCopy";
import { DashboardContext } from "../DashboardContext";

type CafsCopyProps = {
  node: CafsTreeNodeType;
};

export function CafsCopy({ node }: CafsCopyProps) {
  const shouldRender = node.canCopy;
  const { selectedDataset } = useContext(DashboardContext);

  const { copyCafs } = useCafsCopy(selectedDataset!);

  const handleCafsCopy = (node: CafsTreeNodeType) => {
    copyCafs(node);
  };

  return shouldRender ? (
    <Tooltip title="Copy and edit in EditNew mode">
      <div>
        <Copy
          className="text-primary hover:text-orange-400 text-xl"
          onClick={() => handleCafsCopy(node)}
        />
      </div>
    </Tooltip>
  ) : null;
}
