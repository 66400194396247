import { ReactNode } from "react";
import * as React from "react";

import { Help } from "@mui/icons-material";
import { IconButton, TableCell, TableRow, Tooltip } from "@mui/material";
import clsx from "clsx";

import { StringCafsValue } from "../../../../api/types/cafs";
import { Input } from "../../../../api/types/inputs";
import { TooltipItem } from "../../../../api/types/tooltips";
import { RhfFormField } from "../../../../components/rhf/RhfFormField";
import { RhfInput } from "../../../../components/rhf/RhfInput";

export type InputDetailsFormRowProps = {
  data: Input;
  name: keyof Input;
  isReadMode: boolean | undefined;
  tooltip?: TooltipItem;
  rows?: number;
};

export function InputFormRow({
  data,
  name,
  isReadMode,
  tooltip,
  rows,
}: InputDetailsFormRowProps) {
  return (
    <TableRow
      className={clsx({
        draftRow: !!(data[name] as StringCafsValue)?.Base,
      })}>
      {/* name */}
      <TableCell sx={{ width: "30%" }}>
        <div className="flex flex-row items-center">
          <p className="text-mono">{name as ReactNode}</p>
          {!!(data[name] as StringCafsValue)?.Base && (
            <small className="text-orange-500">Edited</small>
          )}
          {tooltip && tooltip.Description && (
            <Tooltip title={tooltip.Description}>
              <IconButton className="hover:text-primary">
                <Help />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </TableCell>

      <TableCell sx={{ width: "70%" }}>
        <RhfFormField name={`${name}`}>
          <RhfInput
            label={name}
            name={`${name}.Base`}
            multiline={!!rows}
            rows={rows ? rows : 1}
            type="text"
            disabled={isReadMode}></RhfInput>
        </RhfFormField>
        {(data[name] as StringCafsValue)?.Base && (
          <p className="mt-2">
            <span className="font-semibold text-shark">Current value:</span>{" "}
            {(data[name] as StringCafsValue)?.Current}
          </p>
        )}
      </TableCell>
    </TableRow>
  );
}
