import React from "react";

import { FormControl, TextField, TextFieldProps } from "@mui/material";

export const MuiInput = React.forwardRef<HTMLInputElement, TextFieldProps>(
  (props, ref) => {
    return (
      <FormControl className="w-full">
        <TextField
          inputRef={ref}
          color="primary"
          size="small"
          {...props}
          inputProps={{ ...props.inputProps, step: "any" }}
        />
      </FormControl>
    );
  },
);
