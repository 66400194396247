import { ReactNode } from "react";

import { Button } from "@mui/material";

import { MuiModal } from "./mui/MuiModal";

export type ConfirmationModalProps = {
  onConfirm: () => void;
  onCancel: () => void;
  text: ReactNode;
  open: boolean;
  variant?: "primary" | "error" | "warning";
  okText?: string;
  cancelText?: string;
  disabled?: boolean;
  icon?: ReactNode;
  header?: ReactNode;
};

export function ConfirmationModal({
  onConfirm,
  onCancel,
  text,
  open,
  variant = "primary",
  okText = "ok",
  cancelText = "cancel",
  disabled,
  header = "",
  icon,
}: ConfirmationModalProps) {
  return (
    <MuiModal open={open} header={header} onClose={onCancel} variant={variant}>
      <div className="flex flex-col gap-8 w-full" data-testid="something">
        <p>{text}</p>
        <div className="flex justify-end items-end gap-4">
          <Button
            color={variant}
            variant="contained"
            disabled={disabled}
            onClick={onConfirm}
            startIcon={icon}>
            {okText}
          </Button>
          <Button color={variant} variant="outlined" onClick={onCancel}>
            {cancelText}
          </Button>
        </div>
      </div>
    </MuiModal>
  );
}
