import { FormattedMessage } from "react-intl";

import { Status } from "../../../components/icons/Status";

import { StatusChip } from "./StatusChip";

export type StatusProps = {
  status: string | null | undefined;
};

export function CafsStatus({ status }: StatusProps) {
  return (
    <div className="flex flex-col text-darkGray h-[70px]">
      <div className="flex flex-row gap-2 mb-2 items-start flex-1">
        <Status className="text-2xl text-primary" />
        <span className="font-semibold">
          <FormattedMessage
            id="PLANT_VIEW.STATUS_LABEL"
            defaultMessage="Status"
            description="Status label on plant view page"
          />
        </span>
      </div>
      <div className="flex-1">
        <StatusChip status={status} />
      </div>
    </div>
  );
}
