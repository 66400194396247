import React, { useContext, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import { Autocomplete, TextField } from "@mui/material";

import { Dataset } from "../../../../api/types/datasets";
import { useModal } from "../../../../hooks/useModal";
import { useDataSets } from "../../../dashboard-view/api/useDatasets";
import { useAssignToDatasetDetails } from "../../api/useDatasetAssign";
import { CafsContext } from "../../CafsContext";

import { UnassignDatasetModal } from "./UnassignDatasetModal";

type DatasetAssignProps = {
  cafsDatasets: number[] | null | undefined;
};
export function CafsDatasetAssign({ cafsDatasets }: DatasetAssignProps) {
  const { datasetId, id } = useParams();
  const [changedItem, setChangedItem] = useState<Dataset | undefined>(
    undefined,
  );
  const { isReadMode } = useContext(CafsContext);
  const { open, handleOpen, handleClose } = useModal();

  const { datasets } = useDataSets();

  const { formatMessage } = useIntl();

  const { assignDataset } = useAssignToDatasetDetails(datasetId!, id!);

  const selectedDataSets = useMemo(
    () =>
      (cafsDatasets || []).map(datasetId => {
        const foundDataset = (datasets?.Datasets || []).find(
          d => d.Id === datasetId,
        );
        return { Id: datasetId, Name: foundDataset?.Name ?? null };
      }),
    [cafsDatasets, datasets?.Datasets],
  );

  const handleDatasetChange = (
    e: React.SyntheticEvent<Element, Event>,
    newValue: { Id?: number | undefined; Name?: string | undefined | null }[],
  ) => {
    let changedItem;
    let prevValue = selectedDataSets;

    if (newValue.length > prevValue.length) {
      changedItem = newValue.find(
        item => !prevValue.some(prevItem => prevItem.Id === item.Id),
      );
      assignDataset(changedItem?.Id!);
    } else {
      changedItem = prevValue.find(
        prevItem => !newValue.some(item => item.Id === prevItem.Id),
      );
      setChangedItem(changedItem);
      handleOpen();
    }
  };

  return (
    <div className=" bg-gray-50 p-6 mt-6 rounded-md border border-red-grey-100">
      <h3 className="text-xl text-shark">
        <FormattedMessage
          id="CAFS.DATASET_ASSIGN_HEADER"
          description="Assign dataset title"
          defaultMessage="Assign dataset"
        />
      </h3>
      <div className="mt-4">
        <Autocomplete
          size="small"
          disableClearable
          multiple
          disabled={isReadMode}
          value={selectedDataSets || []}
          onChange={handleDatasetChange}
          options={datasets?.Datasets || []}
          isOptionEqualToValue={(option, value) => option.Id === value.Id}
          getOptionLabel={option => option?.Name ?? ""}
          renderInput={params => (
            <TextField
              {...params}
              label={formatMessage({
                id: "CAFS.DATASET_ASSIGN_LABEL",
                description: "Assigned datasets label",
                defaultMessage: "Assigned datasets",
              })}
            />
          )}
        />
      </div>
      <UnassignDatasetModal
        open={open}
        onClose={handleClose}
        dataset={changedItem}
      />
    </div>
  );
}
