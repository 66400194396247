import * as yup from "yup";

import { InputCost } from "../../../../../api/types/inputs";
import {
  CafsValueBooleanValidation,
  CafsValueNumberValidation,
  CafsValueRemarksValidation,
} from "../../../../../consts/validation";
import { CafsForm } from "../../../../../types/cafs-form";

export const inputCostSchema = yup.object<CafsForm<keyof InputCost>>({
  CostsRemarks: CafsValueRemarksValidation(),
  ProductId: CafsValueNumberValidation(),
  CostInitialUnitPerHa: CafsValueNumberValidation(),
  InitialCostPerUnit: CafsValueNumberValidation(),
  InitialCostTotalHa: CafsValueNumberValidation(),
  RunningCostPHaPY: CafsValueNumberValidation(),
  IrrigationCostsPerMMPerHa: CafsValueNumberValidation(),
  CostsStartYear: CafsValueNumberValidation(),
  CostsEndYear: CafsValueNumberValidation(),
  InitialCostTotalIsInvestment: CafsValueBooleanValidation(),
});
