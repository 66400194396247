import { useContext, useEffect } from "react";

import { AppContext, SnackbarType } from "../AppContext";
import { ApiError } from "../types/api-error";

export const useFetchError = (error: ApiError | null) => {
  const { showSnackbar } = useContext(AppContext);

  useEffect(() => {
    if (error) {
      const { Body } = error;

      if (Body?.Errors && Object.keys(Body?.Errors).length > 0) {
        Object.entries(Body?.Errors).forEach(([field, messages]) => {
          messages.forEach(message => {
            showSnackbar({
              type: SnackbarType.Error,
              message: message,
            });
          });
        });
      }

      if (Body?.GenericErrors && Body?.GenericErrors.length > 0) {
        Body?.GenericErrors.forEach(genericError => {
          showSnackbar({
            type: SnackbarType.Error,
            message: genericError,
          });
        });
      }

      if (Body?.title) {
        showSnackbar({
          type: SnackbarType.Error,
          message: Body?.title,
        });
      }
    }
  }, [error]);
};
