import { IconProps } from "../../types/icon-props";

export function Plant(props: IconProps) {
  return (
    <svg
      height="1em"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fill="currentColor"
        d="M23.2152 3.73968C23.2045 3.5562 23.1268 3.38304 22.9968 3.25308C22.8669 3.12312 22.6937 3.0454 22.5102 3.03468C17.6577 2.75343 13.7615 4.22906 12.0871 6.99281C10.9808 8.82 10.9827 11.0391 12.0721 13.1559C11.452 13.8941 10.9989 14.7576 10.7437 15.6872L9.21834 14.1562C9.95147 12.6253 9.92334 11.0297 9.12459 9.70406C7.88709 7.66125 5.03053 6.56625 1.48397 6.77437C1.30049 6.78509 1.12733 6.8628 0.997363 6.99277C0.8674 7.12273 0.789683 7.29589 0.778968 7.47937C0.569906 11.0259 1.66584 13.8825 3.70866 15.12C4.38278 15.5319 5.15742 15.7498 5.94741 15.75C6.71419 15.7406 7.46904 15.5589 8.15616 15.2184L10.4999 17.5622V20.25C10.4999 20.4489 10.5789 20.6397 10.7196 20.7803C10.8602 20.921 11.051 21 11.2499 21C11.4488 21 11.6396 20.921 11.7802 20.7803C11.9209 20.6397 11.9999 20.4489 11.9999 20.25V17.4853C11.9966 16.2922 12.4026 15.134 13.1502 14.2041C14.1149 14.7082 15.1847 14.978 16.273 14.9916C17.3253 14.995 18.358 14.7075 19.2571 14.1609C22.0208 12.4884 23.5002 8.59218 23.2152 3.73968ZM4.48209 13.8375C3.04397 12.9666 2.23022 10.905 2.24991 8.25C4.90491 8.2275 6.96647 9.04406 7.83741 10.4822C8.29209 11.2322 8.36616 12.1069 8.06803 13.0078L5.77959 10.7194C5.6378 10.5847 5.44898 10.5107 5.25341 10.5132C5.05784 10.5157 4.87099 10.5945 4.73269 10.7328C4.59438 10.8711 4.51558 11.0579 4.51308 11.2535C4.51057 11.4491 4.58457 11.6379 4.71928 11.7797L7.00772 14.0681C6.10678 14.3662 5.23303 14.2922 4.48209 13.8375ZM18.4799 12.8794C17.2237 13.6397 15.7471 13.6978 14.2471 13.0669L19.2805 8.0325C19.4152 7.8907 19.4892 7.70189 19.4867 7.50632C19.4842 7.31074 19.4054 7.12389 19.2671 6.98559C19.1288 6.84729 18.942 6.76848 18.7464 6.76598C18.5508 6.76348 18.362 6.83747 18.2202 6.97218L13.1858 12C12.5521 10.5 12.6093 9.0225 13.3733 7.76718C14.6802 5.61093 17.7796 4.40906 21.748 4.50187C21.838 8.46937 20.638 11.5725 18.4799 12.8794Z"
      />
    </svg>
  );
}
