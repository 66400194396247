import { Reference } from "../../api/types/references";
import { ModalSize, MuiModal } from "../../components/mui/MuiModal";

import { useReferenceUpdate } from "./api/useUpdateReference";
import { ReferencesForm } from "./ReferencesForm";

export type CreateProductModalProps = {
  open: boolean;
  onClose: () => void;
  item: Reference | undefined | null;
};

export function EditReferenceModal({
  open,
  onClose,
  item,
}: CreateProductModalProps) {
  const handleClose = () => {
    clearApiErrors();
    onClose();
  };

  const { apiErrors, clearApiErrors, updateReference } = useReferenceUpdate({
    onSuccess: handleClose,
  });

  return (
    <MuiModal
      open={open}
      onClose={handleClose}
      size={ModalSize.Big}
      header="Edit reference">
      <ReferencesForm
        data={item}
        onSubmit={updateReference}
        apiErrors={apiErrors}
      />
    </MuiModal>
  );
}
