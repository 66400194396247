import { useQuery } from "@tanstack/react-query";

import { Presets } from "../../../api/presets";
import { QueryKey } from "../../../consts/query-keys";

export function useTreeHabitData() {
  const { data: treeHabit } = useQuery({
    queryKey: [QueryKey.TreeHabit],
    queryFn: () => Presets.treeHabit(),
    staleTime: Infinity,
  });

  return { treeHabit };
}
