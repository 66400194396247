import { CafsTreeNodeType } from "../../../types/cafs-tree-node-type";

export const findNodes = (
  nodes: CafsTreeNodeType[],
  searchTerm: string,
  path: string[] = [],
): string[][] => {
  let paths: string[][] = [];
  for (let node of nodes) {
    const newPath = [...path, node.nodeId.toString()];
    if (
      (node?.name as string).toLowerCase().includes(searchTerm.toLowerCase()) ||
      (node?.scientificName as string)
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase())
    ) {
      paths.push(node.childNodes ? path.map(String) : newPath);
    }
    if (node.childNodes) {
      paths = [...paths, ...findNodes(node.childNodes, searchTerm, newPath)];
    }
  }
  return paths;
};
