export enum LocalStorageKey {
  Token = "Token",
  RefreshToken = "RefreshToken",
  TokenValidTo = "TokenValidTo",
  User = "User",
  RefreshTokenValidTo = "RefreshTokenValidTo",
  SelectedDataset = "SelectedDataset",
  SelectedPlants = "selected_plants",
  SelectedInputs = "selected_inputs",
  ExpandedNodes = "expanded_nodes",
  SearchTerm = "search_term",
  ActiveFilter = "active_filter",
}
