import { FormattedMessage } from "react-intl";
import {
  NavLink,
  useMatch,
  useNavigate,
  useResolvedPath,
} from "react-router-dom";

import { menuMessages } from "../../messages/menu";
import { MenuItem } from "../../types/menu";

export type SidebarItemProps = {
  navItem: MenuItem;
  onNavigate: () => void;
};

export function NavItem({ navItem, onNavigate }: SidebarItemProps) {
  const resolved = useResolvedPath(navItem.link);
  const navigate = useNavigate();
  const match = useMatch({ path: resolved.pathname, end: false });
  const navItemMessage = menuMessages[navItem.messageKey];

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    navigate(navItem.link);
    onNavigate();
  };

  return (
    <NavLink
      to={navItem.link}
      key={navItem.key}
      onClick={handleClick}
      className={({ isActive }) =>
        `text-base pl-4 py-3 flex gap-2 items-center rounded ${
          match
            ? "bg-primary text-white"
            : "text-gray-500 hover:bg-primary hover:bg-opacity-10 hover:text-primary"
        }`
      }>
      {navItem.icon && <span>{navItem.icon}</span>}
      <span>
        <FormattedMessage {...navItemMessage}></FormattedMessage>
      </span>
    </NavLink>
  );
}
