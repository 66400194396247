import { MuiDrawer } from "../../../../components/mui/MuiDrawer";

import { CafsHistoryDrawerContent } from "./CafsHistoryDrawerContent";

export type HistoryDrawerProps = {
  open: boolean;
  onClose: () => void;
};

export function CafsHistoryDrawer({ open, onClose }: HistoryDrawerProps) {
  return (
    <MuiDrawer anchor="right" title="History" open={open} onClose={onClose}>
      <CafsHistoryDrawerContent />
    </MuiDrawer>
  );
}
