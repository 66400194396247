import React, { useContext } from "react";
import { FlagIcon } from "react-flag-kit";
import { FormattedMessage, useIntl } from "react-intl";

import { MenuItem, Select } from "@mui/material";
import { AppIntlContext } from "IntlContext";

export function LangBtn() {
  const { locale } = useIntl();
  const { switchLanguage } = useContext(AppIntlContext);
  const handleChange = (value: string) => {
    switchLanguage(value);
  };

  return (
    <>
      <Select
        className="w-full"
        id="language-select-button"
        size="small"
        value={locale}
        onChange={e => handleChange(e.target.value)}>
        <MenuItem value={"en"}>
          <div className="flex flex-row w-full items-center">
            <div className="mr-2">
              <FlagIcon code="GB" size={24} />
            </div>
            <FormattedMessage
              id="LANG.EN"
              defaultMessage="English"
              description="English language label"
            />
          </div>
        </MenuItem>
        <MenuItem value={"de"}>
          <div className="flex flex-row w-full items-center">
            <div className="mr-2">
              <FlagIcon code="DE" size={24} />
            </div>
            <FormattedMessage
              id="LANG.DE"
              defaultMessage="German"
              description="German language label"
            />
          </div>
        </MenuItem>
        <MenuItem value={"nl"}>
          <div className="flex flex-row w-full items-center">
            <div className="mr-2">
              <FlagIcon code="NL" size={24} />
            </div>
            <FormattedMessage
              id="LANG.NL"
              defaultMessage="Dutch"
              description="Dutch language label"
            />
          </div>
        </MenuItem>
      </Select>
    </>
  );
}
