import { twMerge } from "tailwind-merge";

import { Region } from "../../../api/types/regions";
import { ApiValidationErrors } from "../../../types/api-error";
import { PriceItem } from "../../../types/price-item";
import { PriceValue } from "../../../types/price-value";
import { PriceUpdatePayload } from "../api/useUpdatePrice";

import { PriceForm } from "./PriceForm";
import { PriceCell } from "./ProductsWithPrices";

export type ProductTableRowProps = {
  item: PriceItem;
  onCellEdit: (data: PriceCell) => void;
  editedCell: PriceCell;
  onRegionSave: (data: Partial<PriceUpdatePayload>) => void;
  onWorldSave: (data: Partial<PriceUpdatePayload>) => void;
  selectedRegion: Region | undefined | null;
  apiErrors: ApiValidationErrors;
};

export function PricesTableRow({
  item,
  onCellEdit,
  editedCell,
  onRegionSave,
  onWorldSave,
  selectedRegion,
  apiErrors,
}: ProductTableRowProps) {
  const handleWorldUpdate = (data: PriceValue) => {
    const dataToSave: Partial<PriceUpdatePayload> = {
      data: { ...data },
      productId: item.productId,
    };
    onWorldSave(dataToSave);
  };

  const handleRegionUpdate = (data: PriceValue) => {
    const dataToSave: Partial<PriceUpdatePayload> = {
      data: { ...data },
      productId: item.productId,
    };
    onRegionSave(dataToSave);
  };

  return (
    <div
      className={twMerge(
        "grid grid-cols-3 gap-0 items-center min-h-14 px-4 odd:bg-white even:bg-gray-100",
        selectedRegion && "grid-cols-5",
      )}>
      <div className="col-span-1">{item.name}</div>
      <div className="col-span-2">
        <PriceForm
          type="world"
          editedItem={editedCell}
          item={item}
          onSubmit={handleWorldUpdate}
          data={item.world}
          apiErrors={apiErrors}
          onCancel={() => onCellEdit(null)}
          onEdit={value =>
            onCellEdit({ column: value, row: item.productId!, type: "world" })
          }
        />
      </div>

      {selectedRegion && (
        <div className="col-span-2">
          <PriceForm
            type="region"
            onCancel={() => onCellEdit(null)}
            item={item}
            editedItem={editedCell}
            data={item.region}
            apiErrors={apiErrors}
            onSubmit={handleRegionUpdate}
            onEdit={value =>
              onCellEdit({
                column: value,
                row: item.productId!,
                type: "region",
              })
            }
          />
        </div>
      )}
    </div>
  );
}
