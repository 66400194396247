import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { PageInfo } from "types/page-info";
import { plainSearchParams } from "utils/plain-search-params";

export type UsePaginationProps = Partial<PageInfo & { prefix?: string }>;

export function usePagination({
  page: defaultPage,
  pageSize: defaultPerPage,
  prefix,
}: UsePaginationProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  return useMemo(() => {
    const page = getPrefixedParameter(
      prefix ?? null,
      searchParams,
      "page",
      defaultPage as number,
    );
    const pageSize = getPrefixedParameter(
      prefix ?? null,
      searchParams,
      "pageSize",
      defaultPerPage as number,
    );

    function handlePageChange({ page, pageSize }: PageInfo) {
      setSearchParams({
        ...plainSearchParams(searchParams),
        ...createPrefixedParameters(prefix ?? null, page, pageSize),
      });
    }

    return { page, pageSize, handlePageChange };
  }, [searchParams, setSearchParams, defaultPage, defaultPerPage, prefix]);
}

export function getPrefixedParameter(
  prefix: string | null,
  searchParams: URLSearchParams,
  parameter: "page" | "pageSize",
  defaultValue: number,
): number {
  if (!prefix) {
    return +(searchParams.get(parameter) ?? defaultValue ?? 0);
  }
  return +(searchParams.get(prefix + "_" + parameter) ?? defaultValue ?? 0);
}

function createPrefixedParameters(
  prefix: string | null,
  page: number,
  pageSize: number,
): Record<string, string> {
  return prefix
    ? {
        [`${prefix}_page`]: (page + 1).toString(),
        [`${prefix}_pageSize`]: pageSize.toString(),
      }
    : { page: (page + 1).toString(), pageSize: pageSize.toString() };
}
