import { FileResponse } from "../http-client";

export function saveFile({ blob, filename }: FileResponse) {
  const decodedFilename = decodeURIComponent(filename);

  const url = window.URL.createObjectURL(
    new Blob([blob], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    }),
  );
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", decodedFilename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
}
