import styled from "@emotion/styled";
import Drawer from "@mui/material/Drawer";

export const Root = styled(Drawer)`
  & .MuiDrawer-paper {
    min-width: 550px;
    max-width: 50%;
  }

  ${({ theme }) => theme.breakpoints.down("md")} {
    & .MuiDrawer-paper {
      min-width: 100%;
      max-width: 100%;
    }
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing(3)};
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(3)};
`;
