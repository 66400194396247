import { paths } from "../api-client/api-schema";
import { createApiOperation } from "../api-client/create-api-operation";
import { FileResponse } from "../http-client";

import { ReferenceTo as ReferenceToType } from "./types/references";

export type GetReferencesResponseContent =
  paths["/Api/ReferencesTo"]["get"]["responses"]["200"]["content"]["application/json"];

export const ReferencesTo = {
  list: createApiOperation<
    GetReferencesResponseContent,
    "/Api/ReferencesTo",
    "get"
  >("/Api/ReferencesTo", "get"),
  assign: createApiOperation<ReferenceToType, "/Api/ReferencesTo", "post">(
    "/Api/ReferencesTo",
    "post",
  ),
  unassign: createApiOperation<
    ReferenceToType,
    "/Api/ReferencesTo/{id}",
    "delete"
  >("/Api/ReferencesTo/{id}", "delete"),
  export: createApiOperation<FileResponse, "/Api/ReferencesTo/Export", "get">(
    "/Api/ReferencesTo/Export",
    "get",
    { file: true },
  ),
};
