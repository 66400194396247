import { useCallback, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { omit } from "ramda";

import { cleanNilProperties } from "utils/clean-nil-properties";
import { plainSearchParams } from "utils/plain-search-params";

export function useDebouncedSearch(
  paramName: string,
  debounceMs: number,
): [string | null, (searchPhrase: string) => void] {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchInput, setSearchInput] = useState(searchParams.get(paramName));
  const ref = useRef<number>();

  const setter = useCallback(
    (value: string) => {
      setSearchInput(value);
      clearTimeout(ref.current);
      ref.current = setTimeout(
        () => {
          setSearchParams(currentSearchParams => {
            const cleanCurrentSearchParams = cleanNilProperties(
              plainSearchParams(currentSearchParams),
            );
            const cleanNewSearchParam = cleanNilProperties({
              [paramName]: value,
            });
            return {
              ...omit([paramName], cleanCurrentSearchParams),
              ...cleanNewSearchParam,
            };
          });
        },
        debounceMs,
        value,
      );
    },
    [debounceMs, paramName, setSearchParams],
  );

  return [searchInput, setter];
}
