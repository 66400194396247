import * as yup from "yup";

import { PlantRoot } from "../../../../../api/types/plants";
import {
  CafsValueNumberValidation,
  CafsValueRemarksValidation,
} from "../../../../../consts/validation";
import { CafsForm } from "../../../../../types/cafs-form";

export const rootsSchema = yup.object<CafsForm<keyof PlantRoot>>({
  RootDepthM: CafsValueNumberValidation(),
  RootNPKUptakeEffectivityFactor: CafsValueNumberValidation(true),
  RootTypeId: CafsValueNumberValidation(),
  RootLayerDistribution: CafsValueNumberValidation(),
  RootsRemarks: CafsValueRemarksValidation(),
});
