import { Input } from "../../../../../api/types/inputs";

export const defaultInputFormValues = (data: Input) => ({
  EnglishName: { Base: data.EnglishName?.Base ?? data.EnglishName?.Current },
  FormerRecName: {
    Base: data.FormerRecName?.Base ?? data.FormerRecName?.Current,
  },
  NameSuffix: { Base: data.NameSuffix?.Base ?? data.NameSuffix?.Current },
  GeneralRemarks: {
    Base: data.GeneralRemarks?.Base ?? data.GeneralRemarks?.Current,
  },
});
