import { useMutation } from "@tanstack/react-query";

import { References } from "../../../api/references";
import { Reference } from "../../../api/types/references";
import { MutationKey } from "../../../consts/mutation-keys";
import { QueryKey } from "../../../consts/query-keys";
import { useApiError } from "../../../hooks/useApiError";
import { useApiSuccess } from "../../../hooks/useApiSuccess";
import { ApiHookConfig } from "../../../types/api-hook-config";

export function useReferenceUpdate({ onSuccess }: ApiHookConfig) {
  const { handleSuccess } = useApiSuccess({
    snackbarText: "Reference updated.",
    invalidate: [
      [QueryKey.References, QueryKey.AllReferencesList],
      [QueryKey.AllReferencesList],
    ],
    onSuccessFn: onSuccess,
  });

  const { handleError, apiErrors, clearApiErrors } = useApiError();

  const mutationFn = (data: Partial<Reference>) =>
    References.update({ path: { referenceId: data.Id! } }, data);

  const { mutate: updateReference, isPending } = useMutation({
    mutationKey: [MutationKey.UpdateReference],
    mutationFn: mutationFn,
    onSuccess: handleSuccess,
    onError: handleError,
    retry: 0,
  });

  return { updateReference, isPending, apiErrors, clearApiErrors };
}
