import { ReactNode } from "react";

import { IconButton } from "@mui/material";
import { DrawerProps } from "@mui/material/Drawer";

import { Xmark } from "../icons/Xmark";

import { ContentWrapper, Root } from "./MuiDrawer.styles";

export type FormDrawerProps = DrawerProps & {
  title: ReactNode;
  onClose?: () => void;
};

export function MuiDrawer({
  children,
  title,
  onClose,
  ...props
}: FormDrawerProps) {
  const handleClose = (
    _: unknown,
    reason: "backdropClick" | "escapeKeyDown",
  ) => {
    if (reason === "escapeKeyDown") {
      onClose && onClose();
    }
  };

  const handleCloseButtonClick = () => onClose && onClose();

  return (
    <Root {...props} onClose={handleClose}>
      <ContentWrapper>
        <div className="flex flex-row justify-between items-center mb-8">
          <h4 className="text-primary text-2xl font-bold">{title}</h4>
          <IconButton
            color="primary"
            onClick={handleCloseButtonClick}
            aria-label="Close From">
            <Xmark className="text-2xl" />
          </IconButton>
        </div>
        <div className="w-full flex-grow overflow-auto scroll-auto">
          {children}
        </div>
      </ContentWrapper>
    </Root>
  );
}
