import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { RemoveCircle } from "@mui/icons-material";

import { Cafs } from "../../../api/types/cafs";
import { ConfirmationModal } from "../../../components/ConfirmationModal";
import { useDraftReject } from "../api/useDraftReject";

export type RejectDraftModalProps = {
  open: boolean;
  onClose: () => void;
  cafs: Cafs | undefined;
};

export function RejectDraftModal({
  open,
  onClose,
  cafs,
}: RejectDraftModalProps) {
  const { rejectDraft, isPending: rejectPending } = useDraftReject({
    cafs: cafs,
    onSuccess: onClose,
  });

  const { formatMessage } = useIntl();

  return (
    <ConfirmationModal
      open={open}
      onConfirm={rejectDraft}
      onCancel={onClose}
      variant="error"
      header={formatMessage({
        id: "MODAl.REJECT_DRAFT_HEADER",
        defaultMessage: "Reject draft",
        description: "Header of reject draft modal",
      })}
      okText={formatMessage({
        id: "MODAl.REJECT_DRAFT_BTN",
        defaultMessage: "Reject draft",
        description: "Text of reject draft modal confirm button",
      })}
      icon={<RemoveCircle />}
      disabled={rejectPending}
      cancelText={formatMessage({
        id: "MODAl.CANCEL_BNT",
        defaultMessage: "cancel",
        description: "Modal cancel button text",
      })}
      text={
        <FormattedMessage
          id="MODAL.REJECT_DRAFT_TEXT"
          defaultMessage="Are you sure you want to <span>reject</span> draft?"
          description="Draft reject confirmation message"
          values={{
            span: chunks => (
              <span className="font-semibold text-red-500">{chunks}</span>
            ),
          }}
        />
      }
    />
  );
}
