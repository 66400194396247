import * as yup from "yup";

import { PlantResponseToPressure } from "../../../../../api/types/plants";
import {
  CafsValueNumberValidation,
  CafsValueRemarksValidation,
  CafsValueStringValidation,
} from "../../../../../consts/validation";
import { CafsForm } from "../../../../../types/cafs-form";

export const validationSchema = yup.object<
  Partial<CafsForm<keyof PlantResponseToPressure>>
>({
  TargetSuitability: CafsValueStringValidation(),
  Category: CafsValueStringValidation(),
  MaxEffect: CafsValueNumberValidation(),
  GeneralRemarks: CafsValueRemarksValidation(),
});
