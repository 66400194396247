import * as yup from "yup";

export const FractionValidation = () => {
  return yup
    .number()
    .min(0)
    .max(1)
    .nullable()
    .transform(value =>
      value === "" || Number.isNaN(value) || value === undefined ? null : value,
    );
};

export const emptyToNullTransform = (value: unknown) =>
  value === "" || Number.isNaN(value) || value === undefined ? null : value;

export const CafsValueNumberValidation = (fraction: boolean = false) =>
  yup.object({
    Parent: yup
      .number()
      .nullable()
      .transform(value =>
        value === "" || Number.isNaN(value) || value === undefined
          ? null
          : value,
      ),
    Current: yup
      .number()
      .nullable()
      .transform(value =>
        value === "" || Number.isNaN(value) || value === undefined
          ? null
          : value,
      ),
    Base: fraction
      ? FractionValidation()
      : yup
          .number()
          .nullable()
          .transform(value =>
            value === "" || Number.isNaN(value) || value === undefined
              ? null
              : value,
          ),
    Calculated: fraction
      ? FractionValidation()
      : yup
          .number()
          .nullable()
          .transform(value =>
            value === "" || Number.isNaN(value) || value === undefined
              ? null
              : value,
          ),
  });

export const CafsValueBooleanValidation = () =>
  yup.object({
    Base: yup.boolean().nullable(),
    Current: yup.boolean().nullable(),
    Calculated: yup.boolean().nullable(),
    Parent: yup.boolean().nullable(),
  });

export const CafsValueStringValidation = (required = false) =>
  yup.object({
    Base: yup
      .string()
      .nullable()
      .transform(value =>
        value === "" || Number.isNaN(value) || value === undefined
          ? null
          : value,
      )
      .concat(
        required
          ? yup.string().required("This field is required")
          : yup
              .string()
              .nullable()
              .transform(value =>
                value === "" || Number.isNaN(value) || value === undefined
                  ? null
                  : value,
              ),
      ),
    Calculated: yup
      .number()
      .nullable()
      .transform(value =>
        value === "" || Number.isNaN(value) || value === undefined
          ? null
          : value,
      ),
    Parent: yup.string().nullable(),
    Current: yup.string().nullable(),
  });

export const CafsValueRemarksValidation = (transformToNull = false) =>
  yup.object({
    Base: transformToNull ? yup.string().nullable() : yup.string().nullable(),
    Calculated: yup.number().nullable(),
    Parent: yup.string().nullable(),
    Current: yup.string().nullable(),
  });
