import * as yup from "yup";

import { PlantConstraint } from "../../../../../api/types/plants";
import {
  CafsValueNumberValidation,
  CafsValueRemarksValidation,
} from "../../../../../consts/validation";
import { CafsForm } from "../../../../../types/cafs-form";

export const constraintsSchema = yup.object<CafsForm<keyof PlantConstraint>>({
  TolToDrought: CafsValueNumberValidation(true),
  TolToPoorSoil: CafsValueNumberValidation(true),
  TolToPoorPH: CafsValueNumberValidation(true),
  TolToPoorMgmt: CafsValueNumberValidation(true),
  TolToPests: CafsValueNumberValidation(true),
  TolToSalinity: CafsValueNumberValidation(true),
  TolToDiseases: CafsValueNumberValidation(true),
  TolToHerbicide: CafsValueNumberValidation(true),
  TolToPoorPollination: CafsValueNumberValidation(true),
  TolToPoorTemp: CafsValueNumberValidation(true),
  MinSuitabilityToDamage: CafsValueNumberValidation(true),
  ConstraintsRemarks: CafsValueRemarksValidation(),
});
