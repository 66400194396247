import { ReactNode } from "react";
import { FieldValues, Path, useFormContext } from "react-hook-form";

import { StringCafsValue } from "../../../../api/types/cafs";
import { Formula } from "../../../../components/formula/Formula";
import { DataFormat } from "../../../../consts/data-format";

export type CafsCalculatedFieldProps<T extends Path<FieldValues>> = {
  dependencies: T[];
  field: T;
  fn: (values: Record<T, StringCafsValue>, data?: unknown) => ReactNode;
  data?: unknown;
  format?: DataFormat;
};

export function CalculatedField<T extends Path<FieldValues>>({
  dependencies,
  field,
  fn,
  data,
  format,
}: CafsCalculatedFieldProps<T>) {
  const {
    watch,
    formState: { dirtyFields },
    getValues,
  } = useFormContext<FieldValues>();

  const values = watch() as Record<T, StringCafsValue>;

  if (dependencies.some(f => dirtyFields[f])) {
    return <Formula format={format} value={fn(values, data) as string} />;
  }

  return (
    <Formula format={format} value={getValues()[field]?.Calculated ?? ""} />
  );
}
