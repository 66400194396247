import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import { useMutation } from "@tanstack/react-query";

import { Inputs } from "../../../api/inputs";
import { InputCost } from "../../../api/types/inputs";
import { MutationKey } from "../../../consts/mutation-keys";
import { QueryKey } from "../../../consts/query-keys";
import { useApiError } from "../../../hooks/useApiError";
import { useApiSuccess } from "../../../hooks/useApiSuccess";
import { ApiHookConfig } from "../../../types/api-hook-config";
import { CafsForm } from "../../../types/cafs-form";

export const useInputCostUpdate = ({ onSuccess }: ApiHookConfig) => {
  const { datasetId, id } = useParams();
  const { formatMessage } = useIntl();
  const { handleSuccess } = useApiSuccess({
    invalidate: [[QueryKey.InputCostSection], [QueryKey.CafsDetails]],
    snackbarText: formatMessage({
      id: "ACTIONS.INPUT_COST_UPDATE_SUCCESS",
      defaultMessage: "Input costs updated successfully.",
      description: "Input costs update success message",
    }),
    onSuccessFn: onSuccess,
  });

  const { handleError, apiErrors } = useApiError();
  const mutationFn = (data: Partial<CafsForm<InputCost>>) => {
    return Inputs.costUpdate(
      { path: { datasetId: +datasetId!, inputId: +id! } },
      data,
    );
  };

  const { mutate: updateCost } = useMutation({
    mutationKey: [MutationKey.InputCostUpdate, datasetId, id],
    mutationFn: mutationFn,
    onSuccess: handleSuccess,
    onError: handleError,
  });

  return { updateCost, apiErrors };
};
