import { useState } from "react";
import { FormattedMessage } from "react-intl";

import { History as HistoryIcon } from "../../../../components/icons/History";

import { CafsHistoryDrawer } from "./CafsHistoryDrawer";

export function CafsHistory() {
  // const { open, handleOpen, handleClose } = useModal();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="flex flex-col text-darkGray">
      <div
        className="flex flex-row gap-1 cursor-pointer text-primary items-center"
        onClick={handleOpen}>
        <HistoryIcon className="text-primary" />

        <span className="underline">
          <FormattedMessage
            id="PLANT_VIEW.HISTORY_LABEL"
            defaultMessage="History"
            description="History label on plant page view"
          />
        </span>
      </div>

      <CafsHistoryDrawer open={open} onClose={handleClose} />
    </div>
  );
}
