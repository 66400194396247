import * as yup from "yup";

import { GuestResponseToPressure } from "../../../../api/types/guests";
import {
  CafsValueNumberValidation,
  CafsValueStringValidation,
} from "../../../../consts/validation";
import { CafsForm } from "../../../../types/cafs-form";

export const guestPressureEditSchema = yup.object<
  Partial<CafsForm<keyof GuestResponseToPressure>>
>({
  Category: CafsValueStringValidation(),
  REffect: CafsValueNumberValidation(),
});
