type FormatCurrencyOptions = Omit<Intl.NumberFormatOptions, "style"> & {
  locale?: string;
};
export const formatNumber = (
  value: number,
  {
    locale = "en-US",
    minimumFractionDigits = 0,
    ...options
  }: FormatCurrencyOptions = {},
): string => {
  return new Intl.NumberFormat(locale, {
    ...options,
    minimumFractionDigits,
  }).format(value);
};
