import { useMutation } from "@tanstack/react-query";

import { Products } from "../../../api/products";
import { MutationKey } from "../../../consts/mutation-keys";
import { QueryKey } from "../../../consts/query-keys";
import { useApiError } from "../../../hooks/useApiError";
import { useApiSuccess } from "../../../hooks/useApiSuccess";

export type PriceUpdatePayload = {
  productId: number | undefined | null;
  regionId: number | undefined | null;
  data: {
    HighPricePKg?: number | null | undefined;
    PriceLowOfHigh?: number | null | undefined;
  };
};

export function useUpdatePrice(successFn: () => void) {
  const { handleSuccess } = useApiSuccess({
    snackbarText: "Price updated.",
    invalidate: [[QueryKey.Products], [QueryKey.RegionPrices]],
    onSuccessFn: successFn,
  });

  const { handleError, apiErrors, clearApiErrors } = useApiError();

  const mutationFn = ({ productId, regionId, data }: PriceUpdatePayload) => {
    return Products.updatePrice(
      { path: { productId: productId!, regionId: regionId! } },
      { ProductId: productId, RegionId: regionId, ...data },
    );
  };

  const { mutate: updatePrice, isPending } = useMutation({
    mutationKey: [MutationKey.UpdatePrice],
    mutationFn: mutationFn,
    onSuccess: handleSuccess,
    onError: handleError,
    retry: 0,
  });

  return { updatePrice, isPending, apiErrors, clearApiErrors };
}
