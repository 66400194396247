import * as yup from "yup";

export const loginSchema = yup.object({
  Email: yup
    .string()
    .transform(value => (!value.length ? null : value))
    .email()
    .required("Email is required"),
  Password: yup
    .string()
    .transform(value => (!value.length ? null : value))
    .required("Password is required"),
});
