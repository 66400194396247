import { Controller, useFormContext } from "react-hook-form";

import { Switch, SwitchProps, TextFieldProps } from "@mui/material";

export type RhfSwitchProps = SwitchProps &
  TextFieldProps & {
    name: string;
    readonly?: boolean;
    shouldDirty?: boolean;
    shouldTouch?: boolean;
    dataTestId?: string;
  };

export function RhfSwitch({
  name,
  shouldDirty = true,
  shouldTouch = true,
  dataTestId,
  color,
  ...rest
}: RhfSwitchProps) {
  const { control, setValue } = useFormContext();

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(name, event.target.checked, {
      shouldDirty: shouldDirty,
      shouldTouch: shouldTouch,
    });
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, name, ref, value } }) => (
        <Switch
          color={color}
          checked={value || false}
          onChange={handleOnChange}
          name={name}
          inputRef={ref}
          {...rest}
        />
      )}
    />
  );
}
