import { FormattedMessage } from "react-intl";

import { Button } from "@mui/material";

import { Export } from "../../../components/icons/Export";
import { useReferencesExport } from "../api/useReferencesExport";

export const ReferencesExport = () => {
  const { exportReferences } = useReferencesExport();
  return (
    <div style={{ position: "absolute", right: "25px", bottom: "25px" }}>
      <Button
        color="primary"
        variant="contained"
        startIcon={<Export />}
        onClick={() => exportReferences()}>
        <FormattedMessage
          id="REFERENCES.EXPORT"
          defaultMessage="Export references"
          description="Export references btn"
        />
      </Button>
    </div>
  );
};
