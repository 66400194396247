import { useIntl } from "react-intl";
import { useMatch, useParams } from "react-router-dom";

import { useMutation } from "@tanstack/react-query";

import { Inputs } from "../../../api/inputs";
import { Plants } from "../../../api/plants";
import { MutationKey } from "../../../consts/mutation-keys";
import { QueryKey } from "../../../consts/query-keys";
import { useApiError } from "../../../hooks/useApiError";
import { useApiSuccess } from "../../../hooks/useApiSuccess";
import { ApiHookConfig } from "../../../types/api-hook-config";

export const useDatasetUnassign = ({ onSuccess }: ApiHookConfig) => {
  const matchInput = useMatch("/dashboard/input/:datasetId/:id");
  const { id } = useParams();
  const { formatMessage } = useIntl();

  const { handleSuccess } = useApiSuccess({
    invalidate: [[QueryKey.CafsDetails]],
    snackbarText: formatMessage({
      id: "ACTIONS.DATASET_UNASSING_SUCCESS",
      defaultMessage: "Dataset unassigned successfully.",
      description: "Dataset unassign success message",
    }),
    onSuccessFn: onSuccess,
  });
  const { handleError } = useApiError();

  const mutationFn = (newDataset: number) => {
    return matchInput
      ? Inputs.datasetUnassign({
          path: { datasetId: newDataset, inputId: +id! },
        })
      : Plants.datasetUnassign({
          path: { datasetId: newDataset, plantId: +id! },
        });
  };

  const { mutate: unassignDataset } = useMutation({
    mutationKey: [MutationKey.DatasetAssign],
    mutationFn: mutationFn,
    onSuccess: handleSuccess,
    onError: handleError,
  });

  return { unassignDataset };
};
