import React from "react";

type PageSectionHeaderProps = {
  title: string;
  description?: string;
};

export function PageSectionHeader({
  title,
  description,
}: PageSectionHeaderProps) {
  return (
    <div>
      <h3 className="text-2xl font-semibold">{title}</h3>
      {description && <p className="mt-2">{description}</p>}
    </div>
  );
}
