import { IconProps } from "../../types/icon-props";

export function Rubber(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="1em"
      {...props}
      viewBox="0 0 512 512">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
        <path
          fill="currentColor"
          d="M290.7 57.4L57.4 290.7c-25 25-25 65.5 0 90.5l80 80c12 12 28.3 18.7 45.3 18.7H288h9.4H512c17.7 0 32-14.3 32-32s-14.3-32-32-32H387.9L518.6 285.3c25-25 25-65.5 0-90.5L381.3 57.4c-25-25-65.5-25-90.5 0zM297.4 416H288l-105.4 0-80-80L227.3 211.3 364.7 348.7 297.4 416z"
        />
      </svg>
    </svg>
  );
}
