import { useContext, useEffect } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";

import { PlantProduct } from "../../../../api/types/plants";
import { useApiValidation } from "../../../../hooks/useApiValidation";
import { useUpdatePlantProductForm } from "../../api/usePlantProductUpdate";
import { CafsContext } from "../../CafsContext";
import { CafsSection } from "../../const/cafs-section";
import { SectionRemarks } from "../cafs-sections/SectionRemarks";
import { CafsTable } from "../cafs-table/CafsTable";
import { renderVariableRow } from "../cafs-table/render-variable-row";
import { mapInitialValues } from "../cafs-table/utils/map-initial-values";
import { mapSubmitValues } from "../cafs-table/utils/map-submit-values";

import { editProductSchema } from "./edit-product-schema";
import { useProductsVariables } from "./useProductsVariables";

export type PlantProductEditProps = {
  product: PlantProduct;
  productId: number | undefined;
  expanded: boolean;
};

export function ProductEdit({
  product,
  productId,
  expanded,
}: PlantProductEditProps) {
  const { isReadMode } = useContext(CafsContext);

  const { updateProduct, apiErrors: updateApiErrors } =
    useUpdatePlantProductForm({ onSuccess: () => {}, productId: productId });

  const methods = useForm<FieldValues>({
    resolver: yupResolver(editProductSchema),
    shouldFocusError: true,
    mode: "onChange",
  });

  const variables = useProductsVariables(product, methods);

  useEffect(() => {
    if (product) {
      methods.reset(mapInitialValues(product, variables));
    }
  }, [product, expanded]);

  const handleSubmit = (data: FieldValues) => {
    updateProduct(mapSubmitValues(data));
  };

  const disabled =
    !methods.formState.isValid || isReadMode || !methods.formState.isDirty;

  useApiValidation(updateApiErrors, methods);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <div className="text-xl font-mono mb-8">
          Biomass allocation: <b>{product.BiomassAllocation}</b>
        </div>
        <SectionRemarks<PlantProduct> name="GeneralRemarks" data={product} />
        <CafsTable<PlantProduct>
          name={CafsSection.PlantProduct}
          variables={variables}
          render={renderVariableRow}
          data={product ?? {}}
          productCode={product.Code?.Current ?? product.Code?.Base}
          disabled={disabled}
        />
      </form>
    </FormProvider>
  );
}
