import { UserRole } from "../../api/types/user";
import { MenuItem } from "../../types/menu";

import { NavItem } from "./NavItem";

export const renderNavItem = (
  item: MenuItem,
  userRole: UserRole | undefined,
  onNavigate: () => void,
) => {
  if (!userRole) return null;
  return item.roles.includes(userRole) ? (
    <NavItem key={item.key} navItem={item} onNavigate={onNavigate} />
  ) : null;
};
