import React from "react";

import { TableCell, TableRow } from "@mui/material";
import { twMerge } from "tailwind-merge";

import { Reference } from "../../api/types/references";
import { Edit } from "../../components/icons/Edit";
import { Trash } from "../../components/icons/trash";

export type ReferencesTableRowProps = {
  item: Reference;
  onEdit: (reference: Reference) => void;
  onDelete: (reference: Reference) => void;
};

export function ReferencesTableRow({
  item,
  onEdit,
  onDelete,
}: ReferencesTableRowProps) {
  return (
    <TableRow>
      <TableCell>{item?.Id}</TableCell>
      <TableCell>{item?.Author}</TableCell>
      <TableCell>{item?.Year}</TableCell>
      <TableCell>{item?.Title}</TableCell>
      <TableCell>{item?.Subtitle}</TableCell>
      <TableCell>{item?.Edition}</TableCell>
      <TableCell>{item?.DOI}</TableCell>
      <TableCell>
        {item?.Link && (
          <a
            href={item.Link}
            target="_blank"
            rel="noopener noreferrer"
            className="text-primary">
            {item.Link}
          </a>
        )}
      </TableCell>
      <TableCell>
        <div className="flex flex-row gap-2">
          <Edit
            className="text-xl text-darkGray hover:text-primary cursor-pointer"
            onClick={() => onEdit(item)}
          />
          <Trash
            className={twMerge(
              "text-xl text-darkGray hover:text-primary cursor-pointer",
              !item.CanDelete && "text-grey pointer-events-none",
            )}
            onClick={() => onDelete(item)}
          />
        </div>
      </TableCell>
    </TableRow>
  );
}
