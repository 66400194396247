import { CafsType } from "../../../consts/cafsType";
import { CafsTreeNodeType } from "../../../types/cafs-tree-node-type";

export const expandTreeNodes = (
  nodes: CafsTreeNodeType[],
  type: CafsType,
  path: string[] = [],
): string[][] => {
  let paths: string[][] = [];
  for (let node of nodes) {
    const newPath = [...path, node.nodeId.toString()];
    if (node.type === type) {
      paths.push(newPath);
    }
    if (node.childNodes) {
      paths = [...paths, ...expandTreeNodes(node.childNodes, type, newPath)];
    }
  }
  return paths;
};
