import { PropsWithChildren, SyntheticEvent } from "react";

import { AccordionDetails } from "@mui/material";

import { StyledAccordion } from "../../../components/StyledAccordion";
import { StyledAccordionSummary } from "../../../components/StyledAccordionSummary";

export type PlantVariableItemProps = PropsWithChildren & {
  onChange: (event: SyntheticEvent<Element, Event>, expanded: boolean) => void;
  expanded: boolean;
  name: string;
};

export function VariableSection({
  onChange,
  expanded,
  children,
  name,
  ...props
}: PlantVariableItemProps) {
  return (
    <StyledAccordion onChange={onChange} expanded={expanded} {...props}>
      <StyledAccordionSummary expanded={expanded}>
        {expanded ? (
          <h2 className="text-3xl font-semibold">{name}</h2>
        ) : (
          <h2>{name}</h2>
        )}
      </StyledAccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </StyledAccordion>
  );
}
