import { ReactNode, useContext } from "react";
import { useFormContext, UseFormReturn } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";

import {
  CafsVariableCollection,
  CafsVariableTooltips,
} from "../../../../api/types/cafs";
import { PlantType } from "../../../../api/types/plants";
import { DataFormat } from "../../../../consts/data-format";
import { CalculationFn } from "../../../../types/calculation-fn";
import { SelectOption } from "../../../../types/select-option";
import { VariableRenderProps } from "../../../../types/variable-render-props";
import { useTooltips } from "../../api/useTooltips";
import { CafsContext } from "../../CafsContext";
import { CafsSection } from "../../const/cafs-section";
import { CafsVariableType } from "../../const/cafs-variable-type";

import { SaveBtn } from "./SaveBtn";

export type CafsVariable<SectionName> = {
  name: keyof SectionName;
  type: CafsVariableType;
  options?: SelectOption[] | undefined;
  calculationFn?: CalculationFn<keyof SectionName>;
  dependOn?: Array<keyof SectionName>;
  format?: DataFormat;
  data?: unknown;
  text?: boolean;
  required?: boolean;
  renderCondition?: (data: VariableRenderProps) => boolean;
};

export type CafsVariablesCollection<SectionName> = CafsVariable<SectionName>[];

type CafsTableProps<SectionName> = {
  variables: CafsVariablesCollection<SectionName>;
  data: SectionName;
  render: (
    variable: CafsVariable<SectionName>,
    data: unknown,
    section: CafsSection,
    tooltips: CafsVariableTooltips<SectionName> | undefined,
    plantType: PlantType | undefined,
    isReadMode?: boolean,
    productCode?: string,
    guestName?: string | null,
    methods?: UseFormReturn,
  ) => ReactNode;
  name: CafsSection;
  disabled: boolean;
  productCode?: string;
  guestName?: string | null;
};

export function CafsTable<SectionName>({
  variables,
  render,
  data,
  name,
  disabled,
  productCode,
  guestName,
}: CafsTableProps<SectionName>) {
  const { cafs, isReadMode } = useContext(CafsContext);
  const methods = useFormContext();
  const { tooltips } = useTooltips(
    data as CafsVariableCollection<SectionName> | undefined,
    name,
  );

  const { formatMessage } = useIntl();

  return (
    <>
      <TableContainer>
        <Table data-testid={`${name}_table`}>
          <TableHead>
            <TableRow sx={{ backgroundColor: "005F3B" }}>
              <TableCell>
                <FormattedMessage
                  id="CAFS_TABLE.NAME"
                  description="Name column in cafs table"
                  defaultMessage="Name"
                />
              </TableCell>
              <TableCell></TableCell>
              <TableCell>
                <Tooltip
                  title={formatMessage({
                    id: "TOOLTIP.DESCRIPTION_COLUMN",
                    defaultMessage:
                      "The meaning of the variable, contains the calculation if it is a calculated variable",
                    description: "Description column tooltip in cafs table",
                  })}>
                  <div>
                    <FormattedMessage
                      id="CAFS_TABLE.DESCRIPTION"
                      defaultMessage="Description"
                      description="Description column title in cafs table"
                    />
                  </div>
                </Tooltip>
              </TableCell>
              <TableCell>
                <Tooltip
                  title={formatMessage({
                    id: "TOOLTIP.UNIT_COLUMN",
                    defaultMessage: "The unit in which the value is stored",
                    description: "Unit column tooltip in cafs table",
                  })}>
                  <div>
                    <FormattedMessage
                      id="CAFS_TABLE.UNIT"
                      defaultMessage="Unit"
                      description="Unit column title in cafs table"
                    />
                  </div>
                </Tooltip>
              </TableCell>
              <TableCell>
                <Tooltip
                  title={formatMessage({
                    id: "TOOLTIP.PARENT_COLUMN",
                    defaultMessage: "The value of the parent of this CAFS",
                    description: "Parent column tooltip in cafs table",
                  })}>
                  <div>
                    <FormattedMessage
                      id="CAFS_TABLE.PARENT"
                      defaultMessage="Parent"
                      description="Parent column title in cafs table"
                    />
                  </div>
                </Tooltip>
              </TableCell>
              <TableCell
                sx={{
                  width: "200px",
                  minWidth: "200px",
                  maxWidth: "200px",
                }}>
                <div className="text-center">
                  <Tooltip
                    title={formatMessage({
                      id: "TOOLTIP.NEW_BASE_COLUMN",
                      defaultMessage:
                        "The new value you want this variable to be updated to (this will break inheritance from the parent)",
                      description: "New base column tooltip in cafs table",
                    })}>
                    <div>
                      <FormattedMessage
                        id="CAFS_TABLE.NEW_BASE"
                        defaultMessage="New base"
                        description="New base column title in cafs table"
                      />
                    </div>
                  </Tooltip>
                </div>
              </TableCell>
              <TableCell>
                <div className="text-right">
                  <Tooltip
                    title={formatMessage({
                      id: "TOOLTIP.CALCULATED_COLUMN",
                      defaultMessage:
                        "If a value is calculated, this shows the result of the calculation from the new base values",
                      description: "Calculated column tooltip in cafs table",
                    })}>
                    <div>
                      <FormattedMessage
                        id="CAFS_TABLE.CALCULATED"
                        defaultMessage="Calculated"
                        description="Calculated column title in cafs table"
                      />
                    </div>
                  </Tooltip>
                </div>
              </TableCell>
              <TableCell>
                <div className="text-right">
                  <Tooltip
                    title={formatMessage({
                      id: "TOOLTIP.CURRENT_COLUMN",
                      defaultMessage:
                        "The value currently stored for the approved CAFS",
                      description: "Current column tooltip in cafs table",
                    })}>
                    <div>
                      <FormattedMessage
                        id="CAFS_TABLE.CURRENT"
                        defaultMessage="Current"
                        description="Current column title in cafs table"
                      />
                    </div>
                  </Tooltip>
                </div>
              </TableCell>
              <TableCell
                sx={{
                  width: "30px",
                  minWidth: "300px",
                  maxWidth: "300px",
                }}>
                <div className="text-right">
                  <Tooltip
                    title={formatMessage({
                      id: "TOOLTIP.CITATION_COLUMN",
                      defaultMessage:
                        "Enter the ID numbers of the references here",
                      description: "Citation column tooltip in cafs table",
                    })}>
                    <div>
                      <FormattedMessage
                        id="CAFS_TABLE.CITATION"
                        defaultMessage="Citation"
                        description="Citation column title in cafs table"
                      />
                    </div>
                  </Tooltip>
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(variables || []).map(variable =>
              render(
                variable,
                data,
                name,
                tooltips,
                cafs?.Type,
                isReadMode,
                productCode,
                guestName,
                methods,
              ),
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="mt-8">
        <SaveBtn data-testid="save_section_btn" disabled={disabled} />
      </div>
    </>
  );
}
