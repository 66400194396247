import React from "react";

import { Reference } from "../../api/types/references";
import { ConfirmationModal } from "../../components/ConfirmationModal";
import { Trash } from "../../components/icons/trash";

import { useReferenceDelete } from "./api/useDeleteReference";

export type DeleteReferenceModalProps = {
  open: boolean;
  onClose: () => void;
  reference: Reference | undefined | null;
};

export function DeleteReferenceModal({
  open,
  onClose,
  reference,
}: DeleteReferenceModalProps) {
  const { deleteReference, deleteReferencePending } = useReferenceDelete({
    onSuccess: onClose,
    reference,
  });

  return (
    <ConfirmationModal
      onConfirm={() => deleteReference()}
      onCancel={onClose}
      header="Reference Delete"
      variant="error"
      okText="delete"
      icon={<Trash />}
      disabled={deleteReferencePending}
      text={
        <span className="text-shark">
          Are you sure you want to{" "}
          <span className="font-semibold text-red-500">delete</span> reference
          with id <span className="font-semibold">{reference?.Id}</span>?
        </span>
      }
      open={open}
    />
  );
}
