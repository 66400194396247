import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import { useMutation } from "@tanstack/react-query";

import { Inputs } from "../../../api/inputs";
import { Plants } from "../../../api/plants";
import { Cafs } from "../../../api/types/cafs";
import { Dataset } from "../../../api/types/datasets";
import { CafsMap } from "../../../consts/cafsMap";
import { CafsType } from "../../../consts/cafsType";
import { MutationKey } from "../../../consts/mutation-keys";
import { useApiSuccess } from "../../../hooks/useApiSuccess";
import { useFetchError } from "../../../hooks/useFetchError";
import { ApiError } from "../../../types/api-error";
import { CafsTreeNodeType } from "../../../types/cafs-tree-node-type";

export const useCafsCopy = (selectedDataSet: Dataset | undefined) => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const { handleSuccess: handleApiSuccess } = useApiSuccess({
    snackbarText: formatMessage({
      id: "ACTION.CAFS_COPY_SUCCESS",
      defaultMessage: "CAFS has been copied.",
      description: "Success msg of CAFS copy",
    }),
    invalidate: [],
  });

  const mutationFn = (data: CafsTreeNodeType) => {
    return data.type === CafsType.Input
      ? Inputs.copy(
          {
            path: {
              datasetId: +selectedDataSet?.Id!,
              inputId: +data.id!,
            },
          },
          {},
        )
      : Plants.copy(
          {
            path: {
              datasetId: +selectedDataSet?.Id!,
              plantId: +data.id!,
            },
          },
          {},
        );
  };

  const handleSuccess = (data: Cafs, node: CafsTreeNodeType) => {
    handleApiSuccess();
    navigate(`${CafsMap.get(node.type)}/${selectedDataSet?.Id}/${data.Id}`);
  };

  const { mutate: copyCafs, error } = useMutation({
    mutationKey: [MutationKey.CopyCafs, selectedDataSet],
    mutationFn: mutationFn,
    onSuccess: handleSuccess,
  });

  useFetchError(error as unknown as ApiError);

  return { copyCafs };
};
