import { FileResponse } from "../index";

export const uploadDataFile = async (
  url: string,
  requestInit: RequestInit,
  onProgress: (progress: number) => void,
  onUploadComplete: () => void,
): Promise<FileResponse> => {
  const xhr = new XMLHttpRequest();
  xhr.open(requestInit.method || "POST", url);

  if (requestInit.headers) {
    for (const [key, value] of Object.entries(requestInit.headers)) {
      xhr.setRequestHeader(key, value as string);
    }
  }

  return new Promise((resolve, reject) => {
    xhr.upload.onprogress = event => {
      if (event.lengthComputable) {
        const progress = (event.loaded / event.total) * 100;
        onProgress(progress);
      }
    };

    xhr.upload.onload = () => {
      onUploadComplete();
    };

    xhr.onload = () => {
      if (xhr.status === 200) {
        const contentDisposition = xhr.getResponseHeader("Content-Disposition");
        const match =
          contentDisposition && contentDisposition.match(/filename="?(.+)"?/);
        const filename = match ? match[1].replace(/['"]/g, "") : "download";
        resolve({ blob: new Blob([xhr.response]), filename });
      } else {
        try {
          const errorBody = JSON.parse(xhr.responseText);
          reject({ Status: xhr.status, Body: errorBody });
        } catch (e) {
          reject({ Status: xhr.status, Body: xhr.statusText });
        }
      }
    };

    xhr.onerror = () =>
      reject(new Error("Network error occurred during upload."));
    xhr.ontimeout = () => reject(new Error("Upload timed out."));

    if (requestInit.body instanceof FormData) {
      xhr.send(requestInit.body);
    } else {
      reject(new Error("The request body must be a FormData instance."));
    }
  });
};
