import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import { useMutation } from "@tanstack/react-query";

import { Guests } from "../../../api/guests";
import { GuestResponseToPressure } from "../../../api/types/guests";
import { MutationKey } from "../../../consts/mutation-keys";
import { QueryKey } from "../../../consts/query-keys";
import { useApiError } from "../../../hooks/useApiError";
import { useApiSuccess } from "../../../hooks/useApiSuccess";
import { ApiHookConfig } from "../../../types/api-hook-config";
import { CafsForm } from "../../../types/cafs-form";

export type CreateGuestRtpMutation = {
  data: Partial<CafsForm<keyof GuestResponseToPressure>>;
  guestId: number | undefined;
};

export const useGuestPressureCreate = ({ onSuccess }: ApiHookConfig) => {
  const { datasetId, id } = useParams();
  const { handleError, apiErrors, clearApiErrors } = useApiError();
  const { formatMessage } = useIntl();

  const { handleSuccess } = useApiSuccess({
    onSuccessFn: onSuccess,
    snackbarText: formatMessage({
      id: "ACTIONS.CREATE_PRESSURE_SUCCESS",
      defaultMessage: "Response to pressure created successfully.",
    }),
    invalidate: [[QueryKey.GuestRtp, datasetId!, id!], [QueryKey.CafsDetails]],
  });

  const mutationFn = ({ data, guestId }: CreateGuestRtpMutation) =>
    Guests.createResponsesToPressure(
      {
        path: { datasetId: +datasetId!, plantId: +id!, guestId: +guestId! },
      },
      data,
    );

  const { mutate: createRtp, isPending } = useMutation({
    mutationKey: [MutationKey.CreateGuestRtp],
    mutationFn: mutationFn,
    onSuccess: handleSuccess,
    onError: handleError,
  });

  return { createRtp, apiErrors, isPending, clearApiErrors };
};
