import { twMerge } from "tailwind-merge";

import { Xmark } from "../../../components/icons/Xmark";

export type FilterProps = {
  text: string;
  id: number;
  active: boolean;
  onClick: () => void;
};

export function Filter({ text, id, onClick, active }: FilterProps) {
  return (
    <div
      className={twMerge(
        "flex gap-2 items-center rounded-xl p-2 pl-4 pr-4 bg-gray-100 w-auto cursor-pointer hover:bg-primary hover:text-white",
        active && "bg-gray-300",
      )}
      onClick={onClick}>
      <small>{text}</small>
      {active && <Xmark />}
    </div>
  );
}
