import { FieldValues } from "react-hook-form";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import { useMutation } from "@tanstack/react-query";

import { Plants } from "../../../api/plants";
import { MutationKey } from "../../../consts/mutation-keys";
import { QueryKey } from "../../../consts/query-keys";
import { useApiError } from "../../../hooks/useApiError";
import { useApiSuccess } from "../../../hooks/useApiSuccess";
import { ApiHookConfig } from "../../../types/api-hook-config";

export const useBiodiversityUpdate = ({ onSuccess }: ApiHookConfig) => {
  const { datasetId, id } = useParams();
  const { formatMessage } = useIntl();
  const { handleSuccess } = useApiSuccess({
    invalidate: [
      [QueryKey.Biodiversity],
      [QueryKey.CafsDetails],
      [QueryKey.PlantAudit],
    ],
    snackbarText: formatMessage({
      id: "ACTIONS.BIODIVERISTY_UPDATE",
      defaultMessage: "Plant biodiversity updated successfully.",
      description: "Plant biodiversity update success message",
    }),
    onSuccessFn: onSuccess,
  });

  const { handleError, apiErrors } = useApiError();

  const mutationFn = (data: FieldValues) =>
    Plants.updateBiodiversity(
      {
        path: { datasetId: +datasetId! as number, plantId: +id! as number },
      },
      data,
    );

  const { mutate: updateBiodiversity, isPending } = useMutation({
    mutationKey: [MutationKey.SaveBiodiversity, datasetId, id],
    mutationFn: mutationFn,
    onSuccess: handleSuccess,
    onError: handleError,
  });

  return { updateBiodiversity, apiErrors, isPending };
};
