import React, { useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

import { Dataset } from "../../../api/types/datasets";
import { Region } from "../../../api/types/regions";
import { SearchBar } from "../../../components/SearchBar";
import { useDataSets } from "../../dashboard-view/api/useDatasets";
import { NoResult } from "../../dashboard-view/components/NoResult";
import { useFetchRegions } from "../../price-view/api/useFetchRegions";
import { useAssignRegion } from "../api/useAssignRegion";
import { useDatasetUpdate } from "../api/useDatasetUpdate";
import { useUnassignRegion } from "../api/useUnassignRegion";

import { DatasetsTableRow } from "./DatasetsTableRow";

const cellStyles = {
  fontWeight: "bold",
  backgroundColor: "#A6B1B7",
  color: "#ffffff!important",
  height: "60px",
  width: "33%",
};

const tableStyles = {
  minWidth: 650,
};

export type EditedField = {
  row: number;
  column: string;
};

export function DatasetsTable({ ...props }) {
  const { datasets } = useDataSets();
  const { regions } = useFetchRegions();

  const [searchPhase, setSearchPhase] = useState("");
  const [editedData, setEditedData] = useState<null | EditedField>();

  const closeEditMode = () => {
    setEditedData(null);
  };

  const filteredDatasets = useMemo(
    () =>
      (datasets?.Datasets || []).filter(d =>
        d?.Name?.toLowerCase().includes(searchPhase.toLowerCase()),
      ),
    [searchPhase, datasets],
  );

  const { assignRegion, isPending: isAssignPending } = useAssignRegion({
    onSuccess: closeEditMode,
  });
  const { unassignRegion, isPending: isUnnassignPending } = useUnassignRegion({
    onSuccess: closeEditMode,
  });
  const { updateDataset } = useDatasetUpdate({ onSuccess: closeEditMode });

  const handleRegionAssign = (data: {
    dataset: Dataset;
    region: Region | null | undefined;
  }) => {
    if (!data.region) {
      unassignRegion({
        datasetId: data.dataset.Id!,
        regionId: data.dataset.RegionId!,
      });
    } else {
      assignRegion({ datasetId: data.dataset?.Id!, regionId: data.region.Id! });
    }
  };

  const handleDataUpdate = (data: {
    id?: number | undefined | null;
    name?: string | undefined | null;
    region?: Region | null;
    owner?: string | null;
  }) => {
    updateDataset(data);
  };

  return (
    <div {...props}>
      <div className="p-4 border-[1px] border-solid border-gray-200 rounded mt-4">
        <div className="mt-4">
          <SearchBar
            placeholder="Search by dataset name"
            searchIcon
            onChange={value => {
              setSearchPhase(value);
            }}
          />
        </div>

        <div className="mt-6">
          <Table sx={tableStyles} aria-label="simple base-table">
            <TableHead>
              <TableRow>
                <TableCell sx={cellStyles}>
                  <FormattedMessage
                    id="DATASET_TABLE.DATASET"
                    description="Dataset column in dataset table"
                    defaultMessage="Dataset"
                  />
                </TableCell>
                <TableCell sx={cellStyles}>
                  <FormattedMessage
                    id="DATASET_TABLE.REGION"
                    description="Region column in dataset table"
                    defaultMessage="Region"
                  />
                </TableCell>
                <TableCell sx={cellStyles}>
                  <FormattedMessage
                    id="DATASET_TABLE.OWNER"
                    description="Owner column in dataset table"
                    defaultMessage="Owner"
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredDatasets.length ? (
                filteredDatasets.map(dataset => (
                  <DatasetsTableRow
                    data={dataset}
                    onEdit={(data, column) =>
                      setEditedData({ row: data.Id!, column: column })
                    }
                    onRegionAssign={handleRegionAssign}
                    onSave={handleDataUpdate}
                    regions={regions?.Regions}
                    editedField={editedData}
                    disabled={isUnnassignPending || isAssignPending}
                    onCancel={() => setEditedData(null)}></DatasetsTableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4}>
                    <NoResult />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
}
