import { useContext } from "react";

import { useMediaQuery, useTheme } from "@mui/material";

import { AppContext } from "../../AppContext";
import { Menu } from "../../types/menu";

import { DesktopMenu } from "./DesktopMenu";
import { MobileMenu } from "./MobileMenu";
import { Root } from "./Root";

export type SidebarProps = {
  open: boolean;
  menu: Menu;
  onNavigate: () => void;
};

export function SideNav({ menu, open, onNavigate }: SidebarProps) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const { user } = useContext(AppContext);
  return (
    <Root open={open}>
      {matches ? (
        <DesktopMenu menu={menu} userRole={user?.Role} />
      ) : (
        <MobileMenu menu={menu} userRole={user?.Role} onNavigate={onNavigate} />
      )}
    </Root>
  );
}
