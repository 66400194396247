import * as yup from "yup";

import { Input } from "../../../../api/types/inputs";

export const inputFormSchema = yup.object<Input>({
  EnglishName: yup.object().shape({
    Base: yup
      .string()
      .transform(value => (!value?.length ? null : value))
      .required("EnglishName field is required"),
  }),
  NameSuffix: yup.object().shape({
    Base: yup.string().nullable(),
  }),
  GeneralRemarks: yup.object().shape({
    Base: yup.string().nullable(),
  }),
});
