import React from "react";

import { twMerge } from "tailwind-merge";

import { Seedling } from "../../../components/icons/Seedling";
import { Towel } from "../../../components/icons/Towel";
import { Tree } from "../../../components/icons/Tree";
import { Access } from "../../../consts/access";
import { CafsType } from "../../../consts/cafsType";
import { CafsTreeNodeType } from "../../../types/cafs-tree-node-type";

export type CafsFolderProps = {
  node: CafsTreeNodeType;
};

export function CafsFolder({ node }: CafsFolderProps) {
  switch (node.type) {
    case CafsType.Tree:
      return (
        <Tree
          className={twMerge(
            "text-secondary text-xl",
            node.access === Access.None && "text-grey",
          )}
        />
      );
    case CafsType.Crop:
      return (
        <Seedling
          className={twMerge(
            "text-secondary text-xl",
            node.access === Access.None && "text-grey",
          )}
        />
      );
    case CafsType.Input:
      return (
        <Towel
          className={twMerge(
            "text-secondary text-xl",
            node.access === Access.None && "text-grey",
          )}
        />
      );
  }
}
