import { useContext } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";

import { yupResolver } from "@hookform/resolvers/yup";
import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";

import { GuestResponseToPressure } from "../../../../api/types/guests";
import { PlantResponseToPressure } from "../../../../api/types/plants";
import { useApiValidation } from "../../../../hooks/useApiValidation";
import { ApiError } from "../../../../types/api-error";
import { CafsForm } from "../../../../types/cafs-form";
import { CafsContext } from "../../CafsContext";
import { CafsBaseTable } from "../cafs-base-table/CafsBaseTable";
import { renderBaseRow } from "../cafs-base-table/render-base-row";
import { SectionRemarks } from "../cafs-sections/SectionRemarks";

import { guestPressureCreateSchema } from "./guest-pressure-create-schema";
import { useGuestPressureVariables } from "./useGuestPressureVariables";

export type GuestResponseToPressureProps = {
  categories: { id: string; label: string }[];
  customErrors?: ApiError["Body"]["Errors"];
  onSubmit: (data: Partial<CafsForm<keyof GuestResponseToPressure>>) => void;
  onCancel: () => void;
  disabled?: boolean;
  readonly?: boolean;
};

export function GuestPressureCreate({
  categories,
  onSubmit,
  onCancel,
  disabled,
  customErrors,
}: GuestResponseToPressureProps) {
  const variables = useGuestPressureVariables({ categories: categories });
  const { isReadMode } = useContext(CafsContext);
  const methods = useForm({
    resolver: yupResolver(guestPressureCreateSchema),
  });

  useApiValidation(customErrors, methods);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <CafsBaseTable variables={variables} render={renderBaseRow} />
        <SectionRemarks<PlantResponseToPressure> name="Remarks" />
        <div className="mt-12 flex flex-row gap-4">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            startIcon={<Add />}
            disabled={!methods.formState.isValid || disabled || isReadMode}
            onClick={methods.handleSubmit(onSubmit)}>
            <FormattedMessage
              id="BTN.CREATE_GUEST"
              defaultMessage="Create"
              description="Create guest button"
            />
          </Button>
          <Button type="button" variant="outlined" onClick={onCancel}>
            <FormattedMessage id="APP.CANCEL" defaultMessage="Cancel" />
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}
