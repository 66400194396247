import { useContext } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";

import { yupResolver } from "@hookform/resolvers/yup";
import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";

import { PlantResponseToPressure } from "../../../../../api/types/plants";
import { useApiValidation } from "../../../../../hooks/useApiValidation";
import { ApiValidationErrors } from "../../../../../types/api-error";
import { CafsForm } from "../../../../../types/cafs-form";
import { usePlantPressureData } from "../../../api/usePlantPressureData";
import { CafsContext } from "../../../CafsContext";
import { CafsBaseTable } from "../../cafs-base-table/CafsBaseTable";
import { renderBaseRow } from "../../cafs-base-table/render-base-row";
import { SectionRemarks } from "../SectionRemarks";

import { createPressureSchema } from "./create-pressure-schema";
import { usePressureVariables } from "./usePressureVariables";

export type PlantResponseToPressureProps = {
  customErrors?: ApiValidationErrors;
  onSubmit: (data: Partial<CafsForm<keyof PlantResponseToPressure>>) => void;
  onCancel: () => void;
  readonly?: boolean;
  disabled?: boolean;
};

export function CreateRtpForm({
  onSubmit,
  onCancel,
  customErrors,
  disabled,
}: PlantResponseToPressureProps) {
  const methods = useForm({ resolver: yupResolver(createPressureSchema) });
  const { isReadMode } = useContext(CafsContext);
  const { plantPressureData } = usePlantPressureData(true);
  const variables = usePressureVariables({
    categories: plantPressureData?.Categories,
    targets: plantPressureData?.Targets,
  });

  useApiValidation(customErrors, methods);

  return (
    <FormProvider {...methods}>
      <CafsBaseTable variables={variables} render={renderBaseRow} />
      <SectionRemarks<PlantResponseToPressure> name="Remarks" />
      <div className="mt-12 flex flex-row gap-4">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          startIcon={<Add />}
          disabled={!methods.formState.isValid || isReadMode || disabled}
          onClick={methods.handleSubmit(onSubmit)}>
          <FormattedMessage
            id="BTN.CREATE_GUEST"
            defaultMessage="Create"
            description="Create guest button"
          />
        </Button>
        <Button type="button" variant="outlined" onClick={onCancel}>
          <FormattedMessage id="APP.CANCEL" defaultMessage="Cancel" />
        </Button>
      </div>
    </FormProvider>
  );
}
