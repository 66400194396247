import { StringCafsValue } from "../../../../../../api/types/cafs";
import { PlantRoot } from "../../../../../../api/types/plants";
import { RootType } from "../../../../../../api/types/presets";

export const rootLayerDistributionFn = (
  values: Partial<Record<keyof PlantRoot, StringCafsValue>>,
  data: RootType[],
): number | undefined => {
  const rootTypeId = parseInt(values.RootTypeId?.Base ?? "0", 10);
  const tree = (data || []).find(d => d.Id === rootTypeId);
  return tree?.RootLayerDistribution;
};
