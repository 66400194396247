import { Tab, TabProps } from "@mui/material";
import { grey } from "@mui/material/colors";
import { styled } from "@mui/system";

type StyledTabProps = {
  index: number;
  selected?: boolean;
} & TabProps;

export const StyledTabItem = styled(
  ({ index, selected, ...props }: StyledTabProps) => {
    const a11yProps = (index: number) => {
      return {
        id: `tab-${index}`,
        "aria-controls": `tabpanel-${index}`,
      };
    };

    return <Tab {...props} {...a11yProps(index)} />;
  },
)(({ selected }) => ({
  flex: 1,
  maxWidth: "none",
  background: selected ? "#F2F6EF" : grey[100],
  borderRight: "1px solid #CED4DA",
}));
