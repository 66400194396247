import * as yup from "yup";
import { number, string } from "yup";

import { GuestResponseToPressure } from "../../../../api/types/guests";
import { CafsForm } from "../../../../types/cafs-form";

export const guestPressureCreateSchema = yup.object<
  Partial<CafsForm<keyof GuestResponseToPressure>>
>({
  Category: yup.object({
    Base: string().required(),
  }),
  REffect: yup.object({
    Base: number().required(),
  }),
});
