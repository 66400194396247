import { useMemo } from "react";

import { PlantProduct } from "../../../api/types/plants";
import { Products } from "../../../api/types/products";

export function useProductName(
  product: PlantProduct,
  products: Products | undefined,
) {
  const name = useMemo(() => {
    return (products?.Products || []).find(
      p => p.Id === (product.ProductId?.Current ?? product.ProductId?.Base),
    )?.Name;
  }, [products, product]);

  return { name };
}
