import { useMutation } from "@tanstack/react-query";

import { Auth } from "../../../api/auth";
import { MutationKey } from "../../../consts/mutation-keys";
import { useApiSuccess } from "../../../hooks/useApiSuccess";
import { ApiHookConfig } from "../../../types/api-hook-config";
import { LoginFormShape } from "../components/LoginForm";

export const useLogin = ({ onSuccess }: ApiHookConfig) => {
  const { handleSuccess } = useApiSuccess({ onSuccessFn: onSuccess });
  const mutationFn = (data: LoginFormShape) => Auth.login(undefined, data);

  const {
    mutate: login,
    isError,
    isPending,
  } = useMutation({
    mutationKey: [MutationKey.Login],
    mutationFn: mutationFn,
    onSuccess: handleSuccess,
  });

  return { login, isPending, isError };
};
