import { useContext, useEffect } from "react";
import { FormattedMessage } from "react-intl";

import { Add } from "@mui/icons-material";
import { Alert, Button } from "@mui/material";

import { LoadingPlaceholder } from "../../../../components/LoadingPlaceholder";
import { useAccordion } from "../../../../hooks/useAccordion";
import { useModal } from "../../../../hooks/useModal";
import { useFetchProducts } from "../../../products-view/api/useFetchProducts";
import { usePlantProductsData } from "../../api/usePlantProductsData";
import { CafsContext } from "../../CafsContext";
import { PlantProductsContextProvider } from "../../ProductsContext";

import { CreateProductModal } from "./createProductModal";
import { ProductEdit } from "./ProductEdit";
import { ProductsListItem } from "./ProductsListItem";

export function ProductsList() {
  const { isReadMode } = useContext(CafsContext);
  const { products, isPending: productsPending } = useFetchProducts();
  const { plantProducts, isPending: plantProductsPending } =
    usePlantProductsData();

  const loading = productsPending || plantProductsPending;

  const {
    expanded,
    handleChange: toggleAccordion,
    collapseAll,
  } = useAccordion();

  useEffect(() => {
    collapseAll();
  }, [plantProducts?.PlantProducts?.length]);

  const { open, handleOpen, handleClose } = useModal();

  return (
    <PlantProductsContextProvider
      data={{
        plantProducts: plantProducts,
        allProducts: products,
      }}>
      {loading && <LoadingPlaceholder />}

      {!plantProducts?.PlantProducts?.length && !loading && (
        <Alert severity="info">There is no products for this plant.</Alert>
      )}

      {!loading &&
        plantProducts?.PlantProducts?.map((item, index) => (
          <ProductsListItem
            key={item.Id}
            onChange={toggleAccordion(index)}
            expanded={expanded === index}
            product={item}>
            <ProductEdit
              product={item}
              expanded={expanded === index}
              productId={item.Id}
            />
          </ProductsListItem>
        ))}

      <div className="mt-6 border-t-[1px] border-grey pt-6">
        <Button
          type="submit"
          variant="outlined"
          color="primary"
          disabled={isReadMode}
          startIcon={<Add />}
          onClick={handleOpen}>
          <FormattedMessage
            id="BTN.ADD_NEW_PRODUCT"
            defaultMessage="Add new product"
            description="Add new product btn"
          />
        </Button>
      </div>

      <CreateProductModal open={open} onClose={handleClose} />
    </PlantProductsContextProvider>
  );
}
