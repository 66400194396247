import * as yup from "yup";

import { PlantBiodiversity } from "../../../../../api/types/plants";
import {
  CafsValueBooleanValidation,
  CafsValueNumberValidation,
  CafsValueRemarksValidation,
} from "../../../../../consts/validation";
import { CafsForm } from "../../../../../types/cafs-form";

export const biodiversitySchema = yup.object<CafsForm<keyof PlantBiodiversity>>(
  {
    ShannonBiodiverstitySpeciesEquivalent: CafsValueNumberValidation(),
    IsIndigenous: CafsValueBooleanValidation(),
    BiodiversityRemarks: CafsValueRemarksValidation(),
  },
);
