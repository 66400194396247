import { useIntl } from "react-intl";
import { useMatch, useParams } from "react-router-dom";

import { useMutation } from "@tanstack/react-query";

import { Inputs } from "../../../api/inputs";
import { Plants } from "../../../api/plants";
import { MutationKey } from "../../../consts/mutation-keys";
import { QueryKey } from "../../../consts/query-keys";
import { useApiError } from "../../../hooks/useApiError";
import { useApiSuccess } from "../../../hooks/useApiSuccess";
import { CafsForm } from "../../../types/cafs-form";

export const useCafsDetailsUpdate = () => {
  const matchInput = useMatch("/dashboard/input/:datasetId/:id");
  const { datasetId, id } = useParams();
  const { formatMessage } = useIntl();

  const { handleError, apiErrors } = useApiError();
  const { handleSuccess } = useApiSuccess({
    snackbarText: formatMessage({
      id: "ACTIONS.CAFS_DETAILS_UPDATE",
      defaultMessage: "CAFS details updated successfully.",
      description: "CAFS details update success message",
    }),
    invalidate: [[QueryKey.CafsDetails]],
  });

  const mutationFn = (data: CafsForm<unknown>) => {
    return matchInput
      ? Inputs.updateDetails(
          { path: { datasetId: +datasetId!, inputId: +id! } },
          data,
        )
      : Plants.updateDetails(
          { path: { datasetId: +datasetId!, plantId: +id! } },
          data,
        );
  };

  const { mutate: updateCafs } = useMutation({
    mutationFn: mutationFn,
    onSuccess: handleSuccess,
    onError: handleError,
    mutationKey: matchInput
      ? [MutationKey.UpdateInputDetails, datasetId!, id!]
      : [MutationKey.UpdatePlantDetails, datasetId!, id!],
  });

  return { updateCafs, apiErrors };
};
