import { createContext, PropsWithChildren } from "react";

import { PlantProducts } from "../../api/types/plants";
import { Products } from "../../api/types/products";

export type PlantProductsProps = {
  plantProducts: PlantProducts | undefined;
  allProducts: Products | undefined;
};

export const ProductsContext = createContext<PlantProductsProps>({
  plantProducts: undefined,
  allProducts: undefined,
});

export type PlantProductsContextProviderProps = PropsWithChildren & {
  data: {
    plantProducts: PlantProducts | undefined;
    allProducts: Products | undefined;
  };
};

export function PlantProductsContextProvider({
  data,
  children,
}: PlantProductsContextProviderProps) {
  return (
    <ProductsContext.Provider
      value={{
        plantProducts: data.plantProducts,
        allProducts: data.allProducts,
      }}>
      {children}
    </ProductsContext.Provider>
  );
}
