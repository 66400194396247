import { useQuery } from "@tanstack/react-query";

import { Presets } from "../../../api/presets";
import { QueryKey } from "../../../consts/query-keys";

export function useVegetationLayer() {
  const { data: vegetationLayers } = useQuery({
    queryKey: [QueryKey.VegetationLayers],
    queryFn: () => Presets.vegetationLayer(),
    staleTime: Infinity,
  });

  return { vegetationLayers };
}
