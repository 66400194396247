import React from "react";

import { Check } from "../../../components/icons/Check";
import { Access } from "../../../consts/access";
import { CafsTreeNodeType } from "../../../types/cafs-tree-node-type";

import { ActionIcons } from "./ActionIcons";
import { LabelIcon } from "./LabelIcon";

export type TreeItemLabelProps = {
  node: CafsTreeNodeType;
  isSelected: boolean;
};

export function CafsTreeItemLabel({ isSelected, node }: TreeItemLabelProps) {
  return (
    <div className="flex flex-row">
      <div className="w-full flex flex-row justify-between items-center">
        <div className="flex flex-row items-center">
          <div className="flex flex-row justify-end items-center pr-1 w-auto">
            <LabelIcon node={node} isSelected={isSelected} />
          </div>
          <div
            className={`text-shark ${node.access === Access.None ? "text-darkGray" : ""}`}>
            {node.isAssigned ? (
              <div className="flex flex-row items-center">
                {node.name || ""}
                <Check className="text-green text-xl ml-2" />
              </div>
            ) : (
              <>
                <span>{node.name || ""}</span>
              </>
            )}
          </div>
        </div>
        <ActionIcons node={node} />
      </div>
    </div>
  );
}
