import { useQuery } from "@tanstack/react-query";

import { Prices } from "../../../api/prices";
import { QueryKey } from "../../../consts/query-keys";

export const useFetchRegionPrices = (regionId: number | null | undefined) => {
  const queryFn = () => Prices.getRegion({ path: { regionId: +regionId! } });

  const { data: regionPrices, isPending } = useQuery({
    queryKey: [QueryKey.RegionPrices, regionId],
    queryFn: queryFn,
    enabled: !!regionId,
  });

  return { regionPrices, isPending };
};
