export enum Routes {
  Login = "login",
  Dashboard = "dashboard",
  Perennial = "perennial/:datasetId/:id",
  Annual = "annual/:datasetId/:id",
  Input = "input/:datasetId/:id",
  Price = "price",
  Dataset = "dataset",
  Product = "products",
  TooltipsSettings = "tooltips-settings",
  References = "references",
}
