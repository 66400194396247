import { PlantCosts } from "../../../../../api/types/plants";
import { SelectOption } from "../../../../../types/select-option";
import { useFetchProducts } from "../../../../products-view/api/useFetchProducts";
import { CafsVariableType } from "../../../const/cafs-variable-type";
import { CafsVariablesCollection } from "../../cafs-table/CafsTable";

export function useCostsVariables(): CafsVariablesCollection<PlantCosts> {
  const { products } = useFetchProducts(true);
  return [
    {
      name: "CostsRemarks",
      type: CafsVariableType.Remarks,
    },
    {
      name: "InitialProductId",
      type: CafsVariableType.Select,
      options: products?.Products?.map(th => {
        return { label: th.Name, id: th.Id } as SelectOption;
      }),
    },
    {
      name: "InitialCostPerUnit",
      type: CafsVariableType.Info,
    },
    {
      name: "OtherInitialCostPHa",
      type: CafsVariableType.Input,
    },
    {
      name: "RunningCostPHaPY",
      type: CafsVariableType.Input,
    },
    {
      name: "InitialCostPerUnitIsInvestment",
      type: CafsVariableType.Boolean,
    },
    {
      name: "OtherInitialCostIsInvestment",
      type: CafsVariableType.Boolean,
    },
  ];
}
