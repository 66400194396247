import { StringCafsValue } from "../../../../../../api/types/cafs";
import { PlantWoodDensity } from "../../../../../../api/types/plants";
import { TreeShape } from "../../../../../../api/types/presets";

export const boleFractionOfTotalHeightMaxFn = (
  values: Partial<Record<keyof PlantWoodDensity, StringCafsValue>>,
  data: TreeShape[],
): number | undefined => {
  const treeShapeValue = parseInt(values.TreeShapeId?.Base ?? "0", 10);
  const tree = (data || []).find(d => d.Id === treeShapeValue);
  return tree?.BoleFractionOfTotalHeightMax;
};
