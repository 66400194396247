import { useIntl } from "react-intl";
import { useMatch, useParams } from "react-router-dom";

import { useMutation } from "@tanstack/react-query";

import { ReferencesTo } from "../../../api/references-to";
import { MutationKey } from "../../../consts/mutation-keys";
import { useApiSuccess } from "../../../hooks/useApiSuccess";
import { useFetchError } from "../../../hooks/useFetchError";
import { FileResponse } from "../../../http-client";
import { ApiError } from "../../../types/api-error";
import { saveFile } from "../../../utils/save-file";

export const useReferencesExport = () => {
  const { formatMessage } = useIntl();
  const { id } = useParams();
  const matchInput = useMatch("/dashboard/input/:datasetId/:id");

  const { handleSuccess } = useApiSuccess({
    snackbarText: formatMessage({
      id: "ACTION.REFERENCES_EXPORT_SUCCESS",
      defaultMessage: "References exported successfully.",
      description: "References export success message",
    }),
  });

  const mutationFn = () => {
    const params = matchInput
      ? { query: { inputId: +id! } }
      : { query: { plantId: +id! } };
    return ReferencesTo.export(params);
  };

  const { mutate: exportReferences, error } = useMutation<
    FileResponse,
    ApiError
  >({
    mutationKey: [MutationKey.ExportReferencesTo],
    mutationFn: mutationFn,
    onSuccess: response => {
      handleSuccess();
      saveFile(response);
    },
  });

  useFetchError(error);

  return { exportReferences };
};
