import { Theme } from "@emotion/react";
import styled from "@emotion/styled";

export const Root = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: ${({ theme }) => theme.spacing(1)};
  width: 348px;
  height: 100vh;
  background-color: ${({ theme }) => theme.palette.grey[100]};
  will-change: transform;
  overflow-y: auto;
  z-index: 2;

  transition: transform ${({ theme }) => theme.transitions.easing.easeInOut}
    200ms;

  box-shadow: ${({ theme }) => theme.shadows[1]};

  ${({ theme }) => theme.breakpoints.down(theme.breakpoints.values.lg)} {
    width: 100vw;
    transition: ${({ theme, open }: { theme?: Theme; open: boolean }) =>
        open
          ? theme?.transitions.easing.easeIn
          : theme?.transitions.easing.easeOut}
      200ms;
    transform: ${({ open }: { theme?: Theme; open: boolean }) =>
      open ? "translateX(0)" : "translateX(-110vw)"};
    box-shadow: ${({ theme }) => theme.shadows[2]};
  }
`;
