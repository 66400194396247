import { paths } from "../api-client/api-schema";
import { createApiOperation } from "../api-client/create-api-operation";

import { Reference as ReferenceType } from "./types/references";

export type GetReferencesResponseContent =
  paths["/Api/References"]["get"]["responses"]["200"]["content"]["application/json"];
export type GetReferencesAllResponseContent =
  paths["/Api/References/List"]["get"]["responses"]["200"]["content"]["application/json"];

export const defaultReferencesListQueryParams: Record<string, string | number> =
  {
    orderBy: "Author",
    order: "Descending",
    pageSize: 10,
    page: 1,
  };

export const allowedReferencesListParams = [
  "order",
  "orderBy",
  "query",
  "page",
  "pageSize",
];

export const References = {
  list: createApiOperation<
    GetReferencesResponseContent,
    "/Api/References",
    "get"
  >("/Api/References", "get"),
  listAll: createApiOperation<
    GetReferencesAllResponseContent,
    "/Api/References/List",
    "get"
  >("/Api/References/List", "get"),
  update: createApiOperation<
    ReferenceType,
    "/Api/References/{referenceId}",
    "put"
  >("/Api/References/{referenceId}", "put"),
  create: createApiOperation<ReferenceType, "/Api/References", "post">(
    "/Api/References",
    "post",
  ),
  delete: createApiOperation<
    ReferenceType,
    "/Api/References/{referenceId}",
    "delete"
  >("/Api/References/{referenceId}", "delete"),
};
