import { HttpMethod } from "../types";

export function requestInitBuilder(
  method: HttpMethod,
  data: unknown,
): RequestInit {
  const body = hasBody(method)
    ? { body: data instanceof FormData ? data : JSON.stringify(data ?? {}) }
    : {};
  const token = localStorage.getItem("Token");
  return {
    method,
    ...body,
    headers: {
      ...(data instanceof FormData
        ? {}
        : { "Content-Type": "application/json" }),
      Authorization: token ? `Bearer ${token}` : "",
    },
  };
}

function hasBody(method: HttpMethod): boolean {
  return ["post", "put", "patch"].includes(method);
}
