import { useParams } from "react-router-dom";

import { useQuery } from "@tanstack/react-query";

import { Plants } from "../../../api/plants";
import { QueryKey } from "../../../consts/query-keys";

export const usePlantPressureData = (enabled: boolean) => {
  const { datasetId, id } = useParams();
  const queryFn = () =>
    Plants.getResponsesToPressure({
      path: { datasetId: +datasetId!, plantId: +id! },
    });

  const { data: plantPressureData, isPending } = useQuery({
    queryKey: [QueryKey.PlantRtp, datasetId, id],
    queryFn: queryFn,
    enabled: enabled,
    staleTime: Infinity,
  });

  return { plantPressureData, isPending };
};
