import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";

import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  RadioProps,
  TextFieldProps,
  Tooltip,
  Typography,
} from "@mui/material";

export type RhfSwitchProps = Omit<RadioProps, "defaultValue"> &
  TextFieldProps & {
    name: string;
    dataTestId: string;
    defaultValue: string | boolean | number | readonly string[] | undefined;
  };

export function RhfYesNoRadioGroup({
  name,
  color,
  children,
  dataTestId,
  ...rest
}: RhfSwitchProps) {
  const { control, setValue, resetField, watch } = useFormContext();

  const fieldValue = watch(name);

  const handleReset = () => {
    resetField(name);
    setValue(name, null, { shouldDirty: true });
  };

  return (
    <Controller
      name={name}
      defaultValue={rest.defaultValue}
      control={control}
      render={({ field }) => (
        <RadioGroup
          {...field}
          aria-labelledby="demo-radio-buttons-group-label"
          onChange={(event, value) => {
            field.onChange(value === "true");
          }}
          data-testid={dataTestId}>
          <div className="flex flex-row items-center">
            <FormControlLabel
              label={
                <Typography variant="body2" color="textSecondary">
                  <FormattedMessage id="APP.YES" defaultMessage="Yes" />
                </Typography>
              }
              value={true}
              control={<Radio data-testid={`${name}.yes_input`} />}
            />
            <FormControlLabel
              label={
                <Typography variant="body2" color="textSecondary">
                  <FormattedMessage id="APP.NO" defaultMessage="No" />
                </Typography>
              }
              value={false}
              control={<Radio data-testid={`${name}.no_input`} />}
            />
            <Tooltip title="Set field value to null">
              <div>
                <Button
                  sx={{
                    textTransform: "none",
                    color: "grey.600",
                    borderColor: "grey.400",
                    "&:hover": {
                      backgroundColor: "primary.main",
                      color: "white",
                      borderColor: "transparent",
                    },
                  }}
                  variant="outlined"
                  color="primary"
                  disabled={fieldValue === null}
                  onClick={handleReset}>
                  <FormattedMessage id="APP.RESET" defaultMessage="reset" />
                </Button>
              </div>
            </Tooltip>
          </div>
        </RadioGroup>
      )}
    />
  );
}
