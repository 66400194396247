import { CafsValue, CafsVariableTooltips } from "../api/types/cafs";
import { TooltipItem, Tooltips as TooltipsType } from "../api/types/tooltips";
import { CafsSection } from "../views/cafs-view/const/cafs-section";

export function mapTooltips<T>(
  variables: Partial<Record<keyof T, CafsValue | undefined>> | undefined,
  tooltips: TooltipsType | undefined,
  section: CafsSection,
): CafsVariableTooltips<T> | undefined {
  if (!variables) return undefined;

  return Object.keys(variables).reduce((acc, key) => {
    const tooltip = (tooltips?.Tooltips || []).find(
      t => t.Name === (`${section}.${key}` as keyof typeof tooltips),
    );

    acc[key as keyof T] = {
      ...tooltip,
      Description:
        tooltip?.Description?.replace(/\n/g, "<br>") || tooltip?.Description,
    } as TooltipItem;

    return acc;
  }, {} as CafsVariableTooltips<T>);
}
