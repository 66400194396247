import { PlantGrowth } from "../../../../../api/types/plants";
import { SelectOption } from "../../../../../types/select-option";
import { useTreeHabitData } from "../../../api/useTreeHabitData";
import { useVegetationLayer } from "../../../api/useVegetationLayer";
import { CafsVariableType } from "../../../const/cafs-variable-type";
import { CafsVariablesCollection } from "../../cafs-table/CafsTable";
import { renderForTree } from "../../cafs-table/utils/render-for-tree";

import { heightMaxGrowPMonthFn } from "./functions/heightMaxGrowPMonthFn";
import { offSeasonCanopyCoverReductionFn } from "./functions/offSeasonCanopyCoverReductionFn";
import { rusleFactorFn } from "./functions/RusleFactorFn";

export function useGrowthVariables(): CafsVariablesCollection<PlantGrowth> {
  const { treeHabit } = useTreeHabitData();
  const { vegetationLayers } = useVegetationLayer();

  return [
    {
      name: "GrowthRemarks",
      type: CafsVariableType.Remarks,
    },
    {
      name: "VegetationLayerId",
      type: CafsVariableType.Select,
      options: vegetationLayers?.map(vl => {
        return { label: vl.Name, id: vl.Id } as SelectOption;
      }),
    },
    {
      name: "MaxHeightM",
      type: CafsVariableType.Input,
      renderCondition: renderForTree,
    },
    {
      name: "MinYearsToMaxHeight",
      type: CafsVariableType.Input,
      renderCondition: renderForTree,
    },
    {
      name: "HeightMaxGrowthPMonth",
      type: CafsVariableType.Calculated,
      calculationFn: heightMaxGrowPMonthFn,
      dependOn: ["MaxHeightM", "MinYearsToMaxHeight"],
      renderCondition: renderForTree,
    },
    {
      name: "EffectSuitabilityOnGrowth",
      type: CafsVariableType.Input,
      renderCondition: renderForTree,
    },
    {
      name: "EffectAgeOnGrowth",
      type: CafsVariableType.Input,
      renderCondition: renderForTree,
    },
    {
      name: "CanopyMaxGrowthPYearM",
      type: CafsVariableType.Input,
      renderCondition: renderForTree,
    },
    {
      name: "TreeCrownDiameterNaturalM",
      type: CafsVariableType.Input,
      renderCondition: renderForTree,
    },
    {
      name: "TreePlantingDistanceM",
      type: CafsVariableType.Input,
      renderCondition: renderForTree,
    },
    {
      name: "TreeRowWidthM",
      type: CafsVariableType.Input,
      renderCondition: renderForTree,
    },
    {
      name: "TreeToppingHeightM",
      type: CafsVariableType.Input,
      renderCondition: renderForTree,
    },
    {
      name: "CanopyRadiationInterception",
      type: CafsVariableType.Input,
    },
    {
      name: "MeanAnnualIncrementMaxM3PY",
      type: CafsVariableType.Input,
      renderCondition: renderForTree,
    },
    {
      name: "TreeHabitId",
      type: CafsVariableType.Select,
      options: treeHabit?.map(vl => {
        return { label: vl.Name, id: vl.Id } as SelectOption;
      }),
      renderCondition: renderForTree,
    },
    {
      name: "OffSeasonCanopyCoverReduction",
      type: CafsVariableType.Calculated,
      dependOn: ["TreeHabitId"],
      calculationFn: offSeasonCanopyCoverReductionFn,
      data: treeHabit,
      renderCondition: renderForTree,
    },
    {
      name: "CanopyFractionOfTotalHeight",
      type: CafsVariableType.Input,
    },
    {
      name: "PruningAffectsCanopyCover",
      type: CafsVariableType.Boolean,
    },
    {
      name: "RusleCFactor",
      type: CafsVariableType.Input,
    },
    {
      name: "RuslePFactor",
      type: CafsVariableType.Input,
    },
    {
      name: "RusleFactor",
      type: CafsVariableType.Calculated,
      calculationFn: rusleFactorFn,
      dependOn: ["RusleCFactor", "RuslePFactor"],
    },
  ];
}
