import { createApiOperation } from "../api-client/create-api-operation";

import { Tooltips as TooltipsType } from "./types/tooltips";
import { TooltipItem } from "./types/tooltips";

export const Tooltips = {
  list: createApiOperation<TooltipsType, "/Api/Tooltips", "get">(
    "/Api/Tooltips",
    "get",
  ),
  update: createApiOperation<TooltipItem, "/Api/Tooltips", "post">(
    "/Api/Tooltips",
    "post",
  ),
};
