import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import { useMutation } from "@tanstack/react-query";

import { Inputs } from "../../../api/inputs";
import { InputLabour } from "../../../api/types/inputs";
import { MutationKey } from "../../../consts/mutation-keys";
import { QueryKey } from "../../../consts/query-keys";
import { useApiError } from "../../../hooks/useApiError";
import { useApiSuccess } from "../../../hooks/useApiSuccess";
import { ApiHookConfig } from "../../../types/api-hook-config";
import { CafsForm } from "../../../types/cafs-form";

export const useInputLabourUpdate = ({ onSuccess }: ApiHookConfig) => {
  const { datasetId, id } = useParams();
  const { formatMessage } = useIntl();

  const { handleSuccess } = useApiSuccess({
    invalidate: [[QueryKey.InputLabourSection], [QueryKey.CafsDetails]],
    snackbarText: formatMessage({
      id: "ACTIONS.INPUT_LABOUR_UPDATE_SUCCESS",
      defaultMessage: "Input labour updated successfully.",
      description: "Input labour update success message",
    }),
    onSuccessFn: onSuccess,
  });

  const { handleError, apiErrors } = useApiError();

  const mutationFn = (data: Partial<CafsForm<InputLabour>>) => {
    return Inputs.updateLabour(
      { path: { datasetId: +datasetId!, inputId: +id! } },
      data,
    );
  };

  const { mutate: updateLabour } = useMutation({
    mutationKey: [MutationKey.InputLabourUpdate, datasetId, id],
    mutationFn: mutationFn,
    onSuccess: handleSuccess,
    onError: handleError,
  });

  return { updateLabour, apiErrors };
};
