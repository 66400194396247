import { useFormContext } from "react-hook-form";

import { RHFAutocompleteField } from "../../../../components/rhf/RhfAutocomplete";
import { RhfFormField } from "../../../../components/rhf/RhfFormField";

export type FormSelectRowProps<T extends Record<string, unknown>> = {
  name: keyof T;
  options: { id: number | null | string | undefined; label: string }[];
  required?: boolean;
  readonly?: boolean;
  focusOnError?: boolean;
};

export function BaseAutocompleteRow<T extends Record<string, unknown>>({
  name,
  readonly,
  options,
  focusOnError,
}: FormSelectRowProps<T>) {
  const { control } = useFormContext();
  return (
    <div data-testid={name}>
      <RhfFormField name={`${name as string}`}>
        <RHFAutocompleteField
          disabled={readonly}
          control={control}
          name={`${name as string}.Base`}
          options={options || []}
          placeholder={name as string}
          focusOnError={focusOnError}
        />
      </RhfFormField>
    </div>
  );
}
