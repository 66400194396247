import { useParams } from "react-router-dom";

import { useQuery } from "@tanstack/react-query";

import { Inputs } from "../../../api/inputs";
import { QueryKey } from "../../../consts/query-keys";

export const useInputDefinitionData = (shouldFetchData: boolean) => {
  const { datasetId, id } = useParams();
  const { data, isPending } = useQuery({
    queryKey: [QueryKey.DefinitionSection, datasetId, id],
    queryFn: () =>
      Inputs.definition({
        path: { datasetId: +datasetId! as number, inputId: +id! as number },
      }).then(response => {
        return response;
      }),
    enabled: shouldFetchData,
    throwOnError: true,
  });

  return { data, isPending };
};
