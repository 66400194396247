import React from "react";
import { useIntl } from "react-intl";

import { PageHeader } from "../../components/PageHeader";
import { PageSection } from "../../components/PageSection";

import { CafsTree } from "./components/CafsTree";
import { DatasetSelector } from "./components/DatasetSelector";
import { DashboardContextProvider } from "./DashboardContext";

/**
 * Dashboard view component
 *
 * @remarks
 * This component is the main entry point for the dashboard view.
 * It provides the page header and sections for the dataset selector and the CAfS tree.
 *
 * @returns The JSX element for the dashboard page
 */
export function DashboardView() {
  const { formatMessage } = useIntl();

  const pageTitle = formatMessage({
    id: "DASHBOARD.TITLE",
    description: "Dashboard page title",
    defaultMessage: "Dashboard",
  });

  const datasetSelectorTitle = formatMessage({
    id: "DASHBOARD.SELECT_DATASET",
    description: "Dataset selection section title on dashboard page",
    defaultMessage: "Select dataset",
  });

  return (
    <DashboardContextProvider>
      <PageHeader text={pageTitle} />

      <PageSection title={datasetSelectorTitle}>
        <DatasetSelector />
      </PageSection>

      <PageSection>
        <CafsTree />
      </PageSection>
    </DashboardContextProvider>
  );
}
