import React, { useCallback, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";

import { Button } from "@mui/material";

import FileUpload from "../../../components/FileUpload";
import { Download } from "../../../components/icons/Download";
import { useDatasetImport } from "../api/useDatasetImport";
import { useJsonDownload } from "../api/useJsonDownload";
import { useJsonUpload } from "../api/useJsonUpload";

export function JsonUpload({ ...props }) {
  const [uploadStatus, setUploadStatus] = useState<string>("");
  const [progress, setProgress] = useState<number>(0);
  const { fetchJSON, isPending: isDownloadPending } = useJsonDownload();

  const fileUploadRef = useRef<HTMLInputElement>(null);

  const updateProgress = useCallback((newProgress: number) => {
    setProgress(newProgress);
  }, []);

  const resetUploadState = useCallback(() => {
    setUploadStatus("Processing...");
    if (fileUploadRef.current) {
      fileUploadRef.current.value = "";
    }
  }, []);

  const { upload, isPending: isUploadPending } = useJsonUpload({
    onProgress: updateProgress,
    onUploadComplete: resetUploadState,
  });

  const handleUpload = useCallback(
    (file: File) => {
      const formData = new FormData();
      formData.append("File", file);
      upload(formData);
    },
    [upload],
  );

  return (
    <div className="mt-4">
      <div className="flex flex-row items-center gap-4">
        <div className="flex flex-row items-center gap-4">
          <FileUpload
            ref={fileUploadRef}
            disabled={isUploadPending}
            onUpload={handleUpload}
            progress={progress}
            isPending={isUploadPending}
            status={uploadStatus}
            accept=".json"
            color="primary"
            text="Upload JSON"
          />
        </div>
        <Button
          variant="outlined"
          color="primary"
          disabled={isDownloadPending}
          startIcon={<Download />}
          onClick={() => fetchJSON()}>
          Download JSON
        </Button>
      </div>
    </div>
  );
}
