import { ReactNode, useContext } from "react";
import { useFormContext, UseFormReturn } from "react-hook-form";

import { PlantType } from "../../../../api/types/plants";
import { CafsContext } from "../../CafsContext";
import { CafsVariable } from "../cafs-table/CafsTable";

import { renderBaseRow } from "./render-base-row";

type CafsBaseTableProps<T> = {
  variables: CafsVariable<T>[];
  render: (
    variable: CafsVariable<T>,
    plantType: PlantType | undefined,
    methods: UseFormReturn,
  ) => ReactNode;
};

export function CafsBaseTable<T>({ variables, render }: CafsBaseTableProps<T>) {
  const { cafs } = useContext(CafsContext);
  const methods = useFormContext();
  return (variables || []).map((variable, index) => (
    <div key={variable.name as string}>
      {renderBaseRow(variable, cafs?.Type, methods)}
    </div>
  ));
}
