import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import { useMutation } from "@tanstack/react-query";

import { Datasets } from "../../../api/datasets";
import { Dataset } from "../../../api/types/datasets";
import { Region } from "../../../api/types/regions";
import { MutationKey } from "../../../consts/mutation-keys";
import { QueryKey } from "../../../consts/query-keys";
import { useApiError } from "../../../hooks/useApiError";
import { useApiSuccess } from "../../../hooks/useApiSuccess";
import { ApiHookConfig } from "../../../types/api-hook-config";

export function useDatasetUpdate({ onSuccess }: ApiHookConfig) {
  const { datasetId, id } = useParams();
  const { formatMessage } = useIntl();

  const { apiErrors, clearApiErrors, handleError } = useApiError();
  const { handleSuccess } = useApiSuccess({
    invalidate: [
      [QueryKey.DataSetsCollection, datasetId!, id!],
      [QueryKey.Regions],
    ],
    snackbarText: formatMessage({
      id: "ACTION.DATASET_UPDATE_SUCCESS",
      defaultMessage: "Dataset updated successfully.",
      description: "Success msg dataset update",
    }),
    onSuccessFn: onSuccess,
  });

  const mutationFn = (data: {
    id?: number | undefined | null;
    name?: string | undefined | null;
    dataset?: Dataset;
    region?: Region | null;
    owner?: string | null;
  }) => {
    return Datasets.update(
      { path: { datasetId: data.id! } },
      { name: data.name, owner: data.owner },
    );
  };

  const { mutate: updateDataset } = useMutation({
    mutationKey: [MutationKey.UpdateDataset, datasetId, id],
    mutationFn: mutationFn,
    onSuccess: handleSuccess,
    onError: handleError,
  });

  return { updateDataset, apiErrors, clearApiErrors };
}
