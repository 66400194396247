import React, { useContext, useMemo, useState } from "react";
import { useIntl } from "react-intl";

import { Autocomplete, TextField } from "@mui/material";

import { Region } from "../../api/types/regions";
import { AppContext } from "../../AppContext";
import { PageHeader } from "../../components/PageHeader";
import { PageSection } from "../../components/PageSection";
import { UserRole } from "../../consts/user-role";

import { useFetchRegions } from "./api/useFetchRegions";
import { PricesImport } from "./components/PricesImport";
import { ProductsWithPrices } from "./components/ProductsWithPrices";

export type PriceValue = {
  HighPricePKg?: number | null | undefined;
  PriceLowOfHigh?: number | null | undefined;
};

export type PriceItem = {
  name: string;
  productId: number | undefined | null;
  world: PriceValue;
  region: PriceValue;
};

export type PricePayload = {
  productId: number | undefined | null;
  data: {
    highPricePKg: number | null | undefined;
    priceLowOfHighFraction: number | null | undefined;
  };
};

export function PriceView() {
  const { user } = useContext(AppContext);
  const { regions } = useFetchRegions();
  const [selectedRegion, setSelectedRegion] = useState<Region | null>();
  const { formatMessage } = useIntl();

  const regionsOptions = useMemo(
    () => (regions?.Regions || []).filter(r => !r.IsDefault),
    [regions],
  );

  return (
    <div>
      <PageHeader
        text={formatMessage({
          id: "PRICE.TITLE",
          defaultMessage: "Price",
          description: "Price page title",
        })}
      />

      {user?.Role === UserRole.FarmTreeDeveloper && (
        <PageSection
          title={formatMessage({
            id: "DASHBOARD.TITLE",
            description: "Dashboard page title",
            defaultMessage: "Upload CSV file",
          })}
          description="Choose file to which you want to import.">
          <PricesImport />
        </PageSection>
      )}

      <PageSection title="Select region">
        <Autocomplete
          size="small"
          value={selectedRegion}
          onChange={(e, value) => setSelectedRegion(value)}
          options={regionsOptions || []}
          getOptionLabel={option => option.Name ?? ""}
          renderInput={params => (
            <TextField {...params} label="Choose a region" />
          )}
        />
      </PageSection>

      <PageSection title="Products with prices">
        <ProductsWithPrices selectedRegion={selectedRegion} />
      </PageSection>
    </div>
  );
}
