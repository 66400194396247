import { UnknownObject } from "../types/unknown-obbject";

/**
 * Clear all undefined and null properties
 * @param obj
 * @returns obj
 */
export function cleanNilProperties(obj: UnknownObject): UnknownObject {
  return Object.fromEntries(
    Object.entries(obj).filter(([, value]) => value ?? true),
  );
}
