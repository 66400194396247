import React from "react";

import { CafsTreeNodeType } from "../../../types/cafs-tree-node-type";

import { CafsCopy } from "./CafsCopy";
import { CafsCreate } from "./CafsCreate";
import { CafsDelete } from "./CafsDelete";
import { CafsEdit } from "./CafsEdit";
import { CafsStatus } from "./CafsStatus";

export type RenderActionIconsProps = {
  node: CafsTreeNodeType;
};

export function ActionIcons({ node }: RenderActionIconsProps) {
  return (
    <div className="flex flex-row gap-2 items-center">
      <CafsStatus node={node} />
      <CafsDelete node={node} />
      <CafsEdit node={node} />
      <CafsCopy node={node} />
      <CafsCreate node={node} />
    </div>
  );
}
