import { useIntl } from "react-intl";

import { useMutation } from "@tanstack/react-query";

import { Audits } from "../../../api/audits";
import { Synchronization } from "../../../api/synchronization";
import { MutationKey } from "../../../consts/mutation-keys";
import { useApiSuccess } from "../../../hooks/useApiSuccess";
import { useFetchError } from "../../../hooks/useFetchError";
import { FileResponse } from "../../../http-client";
import { ApiError } from "../../../types/api-error";
import { saveFile } from "../../../utils/save-file";

export const useJsonDownload = () => {
  const { formatMessage } = useIntl();

  const { handleSuccess } = useApiSuccess({
    snackbarText: formatMessage({
      id: "ACTION.FILE_DOWNLOAD_SUCCESS",
      defaultMessage: "File downloaded successfully.",
      description: "File download success message",
    }),
  });

  const mutationFn = () => {
    return Synchronization.downloadJSON();
  };

  const {
    mutate: fetchJSON,
    isPending,
    error,
  } = useMutation<FileResponse, ApiError>({
    mutationKey: [MutationKey.DownloadJson],
    mutationFn: mutationFn,
    onSuccess: response => {
      handleSuccess();
      saveFile(response);
    },
  });

  useFetchError(error);

  return { fetchJSON, isPending };
};
