import { useSearchParams } from "react-router-dom";

import { useQuery } from "@tanstack/react-query";

import {
  defaultReferencesListQueryParams,
  References,
} from "../../../api/references";
import { QueryKey } from "../../../consts/query-keys";
import { Order } from "../../../types/order";

export function useReferences() {
  const [urlSearchParams] = useSearchParams();

  const queryFn = () =>
    References.list({
      query: {
        page: +(
          urlSearchParams.get("page") ?? defaultReferencesListQueryParams.page
        ),
        pageSize: +(
          urlSearchParams.get("pageSize") ??
          defaultReferencesListQueryParams.pageSize
        ),
        order: (urlSearchParams.get("order") ??
          defaultReferencesListQueryParams.order) as Order,
        orderBy: String(
          urlSearchParams.get("orderBy") ??
            defaultReferencesListQueryParams.orderBy,
        ),
        search: urlSearchParams.get("search")
          ? String(urlSearchParams.get("search"))
          : undefined,
      },
    });

  const {
    data: references,
    refetch,
    isPending,
  } = useQuery({
    queryKey: [QueryKey.References],
    queryFn: queryFn,
    retry: 0,
  });

  return { references, isPending, refetch };
}
