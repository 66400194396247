import { useQuery } from "@tanstack/react-query";

import { Presets } from "../../../api/presets";
import { QueryKey } from "../../../consts/query-keys";

export const useRootTypes = () => {
  const { data: rootTypes, isPending } = useQuery({
    queryKey: [QueryKey.RootTypes],
    queryFn: () => Presets.rootTypes(),
    throwOnError: true,
    staleTime: Infinity,
  });

  return { rootTypes, isPending };
};
