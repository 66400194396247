import { useIntl } from "react-intl";
import { useMatch, useParams } from "react-router-dom";

import { useMutation } from "@tanstack/react-query";

import { Inputs } from "../../../api/inputs";
import { Plants } from "../../../api/plants";
import { MutationKey } from "../../../consts/mutation-keys";
import { QueryKey } from "../../../consts/query-keys";
import { useApiError } from "../../../hooks/useApiError";
import { useApiSuccess } from "../../../hooks/useApiSuccess";
import { ApiHookConfig } from "../../../types/api-hook-config";

export const useGuestDelete = ({ onSuccess }: ApiHookConfig) => {
  const { datasetId, id } = useParams();
  const matchInput = useMatch("/dashboard/input/:datasetId/:id");
  const { formatMessage } = useIntl();

  const { handleError } = useApiError();
  const { handleSuccess } = useApiSuccess({
    snackbarText: formatMessage({
      id: "ACTIONS.GUEST_DELETE_SUCCESS",
      defaultMessage: "Guest deleted successfully.",
      description: "Guest delete success message",
    }),
    onSuccessFn: onSuccess,
    invalidate: [
      [QueryKey.PlantGuests, datasetId!, id!],
      [QueryKey.InputGuests, datasetId!, id!],
    ],
  });

  const mutationFn = (plantGuestId: number) => {
    return matchInput
      ? Inputs.deleteGuest({
          path: {
            guestId: plantGuestId,
            datasetId: +datasetId!,
            inputId: +id!,
          },
        })
      : Plants.deletePlantGuest({
          path: {
            guestId: plantGuestId,
            datasetId: +datasetId!,
            plantId: +id!,
          },
        });
  };

  const { mutate: deletePlantGuest } = useMutation({
    mutationKey: [MutationKey.DeletePlantGuest, datasetId, id],
    mutationFn: mutationFn,
    onSuccess: handleSuccess,
    onError: handleError,
  });

  return { deletePlantGuest };
};
