import React, { useCallback, useRef, useState } from "react";

import FileUpload from "../../../components/FileUpload";
import { usePriceImport } from "../api/usePriceImport";

export function PricesImport() {
  const [uploadStatus, setUploadStatus] = useState<string>("");
  const [progress, setProgress] = useState<number>(0);
  const fileUploadRef = useRef<HTMLInputElement>(null);

  const updateProgress = useCallback((newProgress: number) => {
    setProgress(newProgress);
  }, []);

  const resetUploadState = useCallback(() => {
    setUploadStatus("Processing...");
    if (fileUploadRef.current) {
      fileUploadRef.current.value = "";
    }
  }, []);

  const { upload, isPending } = usePriceImport({
    onProgress: updateProgress,
    onUploadComplete: resetUploadState,
  });

  const handleUpload = (file: File) => {
    const data = new FormData();
    data.append("File", file);
    upload(data);
  };
  return (
    <FileUpload
      ref={fileUploadRef}
      text="Upload CSV file"
      disabled={isPending}
      onUpload={handleUpload}
      progress={progress}
      isPending={isPending}
      status={uploadStatus}
    />
  );
}
