import { RhfFormField } from "../../../../components/rhf/RhfFormField";
import { RhfInput } from "../../../../components/rhf/RhfInput";

export type FormTextAreaRowProps<T extends Record<string, unknown>> = {
  name: keyof T;
  disabled?: boolean;
  focusOnError?: boolean;
};

export function BaseTextAreaRow<T extends Record<string, unknown>>({
  name,
  disabled,
  focusOnError,
}: FormTextAreaRowProps<T>) {
  return (
    <>
      <div>
        <RhfFormField name={`${name as string}`}>
          <div className="text-sm">
            <RhfInput
              disabled={disabled}
              placeholder={name as string}
              multiline
              rows={3}
              label={name as string}
              name={`${name as string}.Base`}
              focusOnError={focusOnError}
            />
          </div>
        </RhfFormField>
      </div>
    </>
  );
}
