import { useIntl } from "react-intl";
import { useMatch, useParams } from "react-router-dom";

import { useMutation } from "@tanstack/react-query";

import { Inputs } from "../../../api/inputs";
import { Plants } from "../../../api/plants";
import { PlantGuest } from "../../../api/types/plants";
import { MutationKey } from "../../../consts/mutation-keys";
import { QueryKey } from "../../../consts/query-keys";
import { useApiError } from "../../../hooks/useApiError";
import { useApiSuccess } from "../../../hooks/useApiSuccess";
import { ApiHookConfig } from "../../../types/api-hook-config";
import { CafsForm } from "../../../types/cafs-form";

export const useGuestCreate = ({ onSuccess }: ApiHookConfig) => {
  const { datasetId, id } = useParams();
  const matchInput = useMatch("/dashboard/input/:datasetId/:id");
  const { formatMessage } = useIntl();
  const { apiErrors, clearApiErrors, handleError } = useApiError();
  const { handleSuccess } = useApiSuccess({
    invalidate: [
      [QueryKey.PlantGuests],
      [QueryKey.InputGuests],
      [QueryKey.CafsDetails],
    ],
    snackbarText: formatMessage({
      id: "ACTIONS.GUEST_CREATE_SUCCESS",
      defaultMessage: "Guest created successfully.",
      description: "Guest create success message",
    }),
    onSuccessFn: onSuccess,
  });

  const mutationFn = (newData: Partial<CafsForm<PlantGuest>>) => {
    return matchInput
      ? Inputs.createGuest(
          { path: { datasetId: +datasetId!, inputId: +id! } },
          newData,
        )
      : Plants.createGuests(
          { path: { datasetId: +datasetId!, plantId: +id! } },
          newData,
        );
  };

  const { mutate: createGuest } = useMutation({
    mutationKey: [MutationKey.CreateCafsGuest, datasetId, id],
    mutationFn: mutationFn,
    onSuccess: handleSuccess,
    onError: handleError,
  });

  return { apiErrors, clearApiErrors, createGuest };
};
