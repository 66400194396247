import { Reference } from "../../api/types/references";
import { BaseTableColumn } from "../../types/base-table-column";

export const useReferencesTableColumns = (): {
  columns: BaseTableColumn<Reference>[];
} => {
  return {
    columns: [
      { label: "Id", key: "Id", isSortable: true },
      { label: "Author", key: "Author", isSortable: true },
      { label: "Year", key: "Year", isSortable: true },
      { label: "Title", key: "Title", isSortable: true },
      { label: "Subtitle", key: "Subtitle", isSortable: true },
      { label: "Edition", key: "Edition", isSortable: true },
      { label: "DOI", key: "DOI", isSortable: true },
      { label: "Link", key: "Link", isSortable: false },
      { label: "Actions", key: "Actions", isSortable: false },
    ],
  };
};
