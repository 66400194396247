import { twMerge } from "tailwind-merge";

import { GuestResponseToPressure } from "../../../../api/types/guests";
import { Trash } from "../../../../components/icons/trash";

export function renderGuestTrash(
  guest: GuestResponseToPressure,
  deleteFn: (data: GuestResponseToPressure) => void,
  isReadMode: boolean | undefined,
) {
  return guest.CanDelete && !isReadMode ? (
    <Trash
      className={twMerge(
        "text-xl text-darkGray hover:text-primary",
        isReadMode && "pointer-events-none text-grey",
      )}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        deleteFn(guest);
      }}
    />
  ) : null;
}
