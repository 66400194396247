import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import { useMutation } from "@tanstack/react-query";

import { Plants } from "../../../api/plants";
import { MutationKey } from "../../../consts/mutation-keys";
import { QueryKey } from "../../../consts/query-keys";
import { useApiError } from "../../../hooks/useApiError";
import { useApiSuccess } from "../../../hooks/useApiSuccess";
import { ApiHookConfig } from "../../../types/api-hook-config";

export const usePlantPressureDelete = ({ onSuccess }: ApiHookConfig) => {
  const { datasetId, id } = useParams();
  const { formatMessage } = useIntl();
  const { handleError } = useApiError();
  const { handleSuccess } = useApiSuccess({
    onSuccessFn: onSuccess,
    invalidate: [[QueryKey.PlantRtp], [QueryKey.CafsDetails]],
    snackbarText: formatMessage({
      id: "ACTIONS.PLANT_PRESSURE_DELETE_SUCCESS",
      defaultMessage: "Response to pressure deleted successfully..",
      description: "Response to pressure delete success message",
    }),
  });

  const mutationFn = (plantToDeleteId: number) =>
    Plants.deleteResponsesToPressure({
      path: {
        datasetId: +datasetId!,
        plantId: +id!,
        plantResponseToPressureId: plantToDeleteId!,
      },
    });

  const { mutate: deleteRtp, isPending } = useMutation({
    mutationKey: [MutationKey.DeletePlantRtp],
    mutationFn: mutationFn,
    onSuccess: handleSuccess,
    onError: handleError,
  });

  return { deleteRtp, isPending };
};
