import { RhfFormField } from "../../../../components/rhf/RhfFormField";
import { RhfSwitch } from "../../../../components/rhf/RhfSwitch";

export type FormSwitchRowProps<T extends Record<string, unknown>> = {
  name: keyof T;
  readonly?: boolean;
};

export function BaseSwitchRow<T extends Record<string, unknown>>({
  name,
  readonly,
}: FormSwitchRowProps<T>) {
  return (
    <>
      <div className="mb-2 text-sm">{String(name)}</div>
      <div>
        <RhfFormField name={`${name as string}.Base`}>
          <RhfSwitch disabled={readonly} name={`${name as string}.Base`} />
        </RhfFormField>
      </div>
    </>
  );
}
