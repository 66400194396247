import { useParams } from "react-router-dom";

import { useQuery } from "@tanstack/react-query";

import { Plants } from "../../../api/plants";
import { QueryKey } from "../../../consts/query-keys";

export const useLifecycleData = (shouldFetchData: boolean) => {
  const { datasetId, id } = useParams();
  const {
    data: lifeCycleData,
    isPending,
    dataUpdatedAt,
  } = useQuery({
    queryKey: [QueryKey.LifeCycleSection, datasetId, id],
    queryFn: () =>
      Plants.getLifeCycle({
        path: { datasetId: +datasetId! as number, plantId: +id! as number },
      }),
    enabled: shouldFetchData,
    throwOnError: true,
  });

  return { lifeCycleData, isPending, dataUpdatedAt };
};
