import * as yup from "yup";

import { PlantLabour } from "../../../../../api/types/plants";
import {
  CafsValueNumberValidation,
  CafsValueRemarksValidation,
} from "../../../../../consts/validation";
import { CafsForm } from "../../../../../types/cafs-form";

export const labourSchema = yup.object<CafsForm<keyof PlantLabour>>({
  LabourEstDayPHa: CafsValueNumberValidation(),
  LabourEstFemale: CafsValueNumberValidation(true),
  LabourMaintDayPHaPY: CafsValueNumberValidation(),
  LabourMaintFemale: CafsValueNumberValidation(true),
  LabourRemarks: CafsValueRemarksValidation(),
});
