import { useQuery } from "@tanstack/react-query";

import { Presets } from "../../../api/presets";
import { QueryKey } from "../../../consts/query-keys";

export const useTemperaturesData = () => {
  const { data: agroEcologicalZoneTemperatures, isPending } = useQuery({
    queryKey: [QueryKey.AgroEcologicalZoneTemperatures],
    queryFn: () =>
      Presets.AgroEcologicalZoneTemperatures().then(response => {
        return response;
      }),
    throwOnError: true,
    staleTime: Infinity,
  });

  return { agroEcologicalZoneTemperatures, isPending };
};
