import { LocalStorageKey } from "../consts/local-storage-key";

export const validateToken = () => {
  const token = localStorage.getItem(LocalStorageKey.Token);
  const expiryTime = localStorage.getItem(LocalStorageKey.TokenValidTo);
  const refreshToken = localStorage.getItem(LocalStorageKey.RefreshToken);
  const refreshTokenExpiryTime = localStorage.getItem(
    LocalStorageKey.RefreshTokenValidTo,
  );

  if (!token || !expiryTime || !refreshToken || !refreshTokenExpiryTime) {
    return false;
  }

  const currentTime = new Date();
  const tokenExpiryDate = new Date(expiryTime);
  const refreshTokenExpiryDate = new Date(refreshTokenExpiryTime);

  return currentTime < tokenExpiryDate || currentTime < refreshTokenExpiryDate;
};
