import { useContext } from "react";
import { useIntl } from "react-intl";

import { useMutation } from "@tanstack/react-query";

import { Cafs } from "../../../api/export";
import { MutationKey } from "../../../consts/mutation-keys";
import { useApiSuccess } from "../../../hooks/useApiSuccess";
import { useFetchError } from "../../../hooks/useFetchError";
import { FileResponse } from "../../../http-client";
import { ApiError } from "../../../types/api-error";
import { saveFile } from "../../../utils/save-file";
import { DashboardContext } from "../DashboardContext";

export const useExportAllCafs = () => {
  const { selectedDataset } = useContext(DashboardContext);

  const { formatMessage } = useIntl();

  const { handleSuccess } = useApiSuccess({
    snackbarText: formatMessage({
      id: "ACTION.CAFS_EXPORT_SUCCESS",
      defaultMessage: "Cafs exported successfully.",
      description: "Cafs export success message",
    }),
  });

  const mutationFn = () => {
    return Cafs.exportAll({ path: { datasetId: selectedDataset?.Id! } });
  };

  const { mutate: exportAllCafs, error } = useMutation<FileResponse, ApiError>({
    mutationKey: [MutationKey.ExportCafs],
    mutationFn: mutationFn,
    onSuccess: response => {
      handleSuccess();
      saveFile(response);
    },
  });

  useFetchError(error);

  return { exportAllCafs };
};
