import { VariableRenderProps } from "../../../../../types/variable-render-props";

export function renderForSeasonalWithSplits({
  methods,
}: VariableRenderProps): boolean {
  if (methods) {
    const baseType = methods.watch("Type.Base");
    const currentType = methods.watch("Type.Current");
    return (
      baseType === "InputSeasonalWithSplits" ||
      (currentType === "InputSeasonalWithSplits" && !baseType)
    );
  }
  return true;
}
