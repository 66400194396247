import { useContext } from "react";

import { Token } from "../api/types/token";
import { AppContext } from "../AppContext";
import { FarmTreeRoles } from "../consts/farm-tree-roles";
import { UserRole } from "../consts/user-role";
import { removeLocalStorageAuthData } from "../utils/remove-local-storage-auth-data";
import { saveLocalStorageAuthData } from "../utils/save-local-storage-auth-data";
import { validateToken } from "../utils/validate-token";

export const useAuth = () => {
  const { user, setUserData } = useContext(AppContext);
  const isAuthenticated = validateToken();
  const isFarmTreeRole = FarmTreeRoles.includes(user?.Role as UserRole);

  const setAuthData = (data: Token) => {
    setUserData(data?.User);
    saveLocalStorageAuthData(data);
  };

  const clearAuthData = () => {
    setUserData(undefined);
    removeLocalStorageAuthData();
  };

  return {
    user,
    isAuthenticated,
    isFarmTreeRole,
    setAuthData,
    clearAuthData,
  };
};
