import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import { Button, TableCell, TableRow } from "@mui/material";

import { Product } from "../../../api/types/products";
import { Edit } from "../../../components/icons/Edit";
import { MuiInput } from "../../../components/mui/MuiInput";

export type ProductTableRowProps = {
  item: Product;
  onEdit: (data: Product) => void;
  onSave: (value: string | null) => void;
  onCancel: (data: Product) => void;
  editMode: boolean;
};

export function ProductTableRow({
  item,
  editMode,
  onEdit,
  onSave,
  onCancel,
}: ProductTableRowProps) {
  const [value, setValue] = useState(item.Name);

  useEffect(() => {
    setValue(item.Name);
  }, [editMode]);

  return (
    <TableRow>
      <TableCell width="90%">
        {editMode ? (
          <MuiInput
            name="name"
            value={value}
            onChange={e => setValue(e.target.value)}
          />
        ) : (
          item.Name
        )}
      </TableCell>
      <TableCell>
        <div className="flex flex-row gap-2 justify-end">
          {editMode ? (
            <>
              <Button
                color="primary"
                variant="contained"
                onClick={() => onSave(value)}>
                <FormattedMessage
                  id="APP.SAVE"
                  defaultMessage="Save"
                  description="General sace text"
                />
              </Button>
              <Button
                color="warning"
                variant="outlined"
                onClick={() => onCancel(item)}>
                <FormattedMessage
                  id="APP.CANCEL"
                  defaultMessage="Cancel"
                  description="General cancel text"
                />
              </Button>
            </>
          ) : (
            <>
              <Edit
                className="text-xl text-darkGray hover:text-primary cursor-pointer"
                onClick={() => onEdit(item)}
              />
            </>
          )}
        </div>
      </TableCell>
    </TableRow>
  );
}
