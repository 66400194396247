import { FormattedMessage } from "react-intl";
import { Navigate, useNavigate } from "react-router-dom";

import Logo from "assets/logo.svg";

import { Token } from "../../api/types/token";
import { LangBtn } from "../../components/LangBtn";
import { Routes } from "../../consts/routes";
import { useAuth } from "../../hooks/useAuth";

import { useLogin } from "./api/useLogin";
import { LoginForm, LoginFormShape } from "./components/LoginForm";

export function LoginView() {
  const { setAuthData } = useAuth();
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();

  const handleSuccess = (data: unknown) => {
    setAuthData(data as Token);
    navigate(`/${Routes.Dashboard}`);
  };
  const { login, isError, isPending } = useLogin({ onSuccess: handleSuccess });

  function handleLogin(data: LoginFormShape) {
    login(data);
  }

  return !isAuthenticated ? (
    <div className="xs:grid-cols-1 lg:grid grid-cols-2 max-h-full h-screen">
      <div className="max-h-full h-screen pt-16 sm:pt-16 lg:pt-16 xl:pt-16 2xl:pt-32 flex flex-col items-center ">
        <img
          src={Logo}
          alt="Logo"
          className="w-[240px] mb-10 sm:mb-10 lg:mb-10 xl:mb-10 2xl:mb-20"
        />
        <h1 className="font-poppins text-5xl font-bold mb-5">
          <FormattedMessage
            id="LOGIN.TITLE"
            defaultMessage="Hello!"
            description="Welcome message on login page"
          />
        </h1>
        <p className="text-base mb-16">
          <FormattedMessage
            id="LOGIN.SUBTITLE"
            defaultMessage="Please <b>log in</b> to the expert application"
            description="Login page text under hello text"
            values={{
              b: chunks => <b>{chunks}</b>,
            }}
          />
        </p>
        <LoginForm
          onSubmit={handleLogin}
          isError={isError}
          isPending={isPending}
        />
        <div className="flex-1 flex flex-col justify-end w-full">
          <div className="flex flex-row w-full justify-between items-center px-8 py-4">
            <div className="w-[200px] hidden">
              <LangBtn />
            </div>
            <span className="block text-gray-500">
              <FormattedMessage
                id="SIDEBAR.COPYRIGHT"
                defaultMessage="Copyright © FarmTree® B.V."
              />
            </span>
          </div>
        </div>
      </div>
      <div
        className="hidden lg:block lg:max-h-screen lg:overflow-hidden bg-cover"
        style={{
          backgroundImage: `url(${require("assets/river.jpeg")})`,
        }}></div>
    </div>
  ) : (
    <Navigate to="/dashboard" />
  );
}
