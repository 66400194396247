import { useMatch } from "react-router-dom";

import { useQuery } from "@tanstack/react-query";

import { Inputs } from "../../../api/inputs";
import { Plants } from "../../../api/plants";
import { InputGuests } from "../../../api/types/inputs";
import { QueryKey } from "../../../consts/query-keys";
import { useFetchError } from "../../../hooks/useFetchError";
import { ApiError } from "../../../types/api-error";

export const useGuestsData = (
  datasetId: string | undefined,
  id: string | undefined,
) => {
  const matchInput = useMatch("/dashboard/dashboard/input/:datasetId/:id");

  const queryFn = () => {
    return matchInput
      ? Inputs.guests({ path: { datasetId: +datasetId!, inputId: +id! } })
      : Plants.getGuests({
          path: { datasetId: +datasetId!, plantId: +id! },
        });
  };

  const {
    data: guests,
    isPending,
    error,
  } = useQuery<InputGuests, ApiError>({
    queryKey: matchInput
      ? [QueryKey.InputGuests, datasetId, id]
      : [QueryKey.PlantGuests, datasetId, id],
    queryFn: queryFn,
    throwOnError: true,
    retry: 0,
  });

  useFetchError(error);

  return { guests, isPending, error };
};
