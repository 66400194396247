import { useMutation } from "@tanstack/react-query";

import { Products } from "../../../api/products";
import { MutationKey } from "../../../consts/mutation-keys";
import { QueryKey } from "../../../consts/query-keys";
import { useApiError } from "../../../hooks/useApiError";
import { useApiSuccess } from "../../../hooks/useApiSuccess";

export type ProductUpdatePayload = {
  productId: number;
  name: string;
};
export function useUpdateProduct(successFn: () => void) {
  const { handleSuccess } = useApiSuccess({
    snackbarText: "Product deleted.",
    invalidate: [[QueryKey.Products]],
    onSuccessFn: successFn,
  });

  const { handleError } = useApiError();

  const mutationFn = ({ productId, name }: ProductUpdatePayload) =>
    Products.update({ path: { productId: productId } }, { Name: name });

  const { mutate: updateProduct, isPending } = useMutation({
    mutationKey: [MutationKey.DeleteProduct],
    mutationFn: mutationFn,
    onSuccess: handleSuccess,
    onError: handleError,
    retry: 0,
  });

  return { updateProduct, isPending };
}
