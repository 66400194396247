import { useContext, useState } from "react";

import { AppContext, SnackbarType } from "../AppContext";
import { ApiError, ApiValidationErrors } from "../types/api-error";
import { mapApiErrorToMessage } from "../utils/map-error-to-message";
import { mapErrors } from "../utils/map-errors";

export const useApiError = () => {
  const [apiErrors, setApiErrors] = useState<ApiValidationErrors | undefined>(
    undefined,
  );

  const { showSnackbar } = useContext(AppContext);

  const clearApiErrors = () => {
    setApiErrors(undefined);
  };
  const handleError = (res: ApiError) => {
    if (res.Status && res?.Status.toString().startsWith("5")) {
      showSnackbar({
        type: SnackbarType.Error,
        message: res.Body.GenericErrors
          ? res.Body.GenericErrors[0]
          : "Internal server error",
      });
    }

    const errors = res.Body.Errors ?? res.Body.errors;

    if (
      res.Status &&
      res?.Status.toString().startsWith("4") &&
      Object.keys(errors ?? {}).length !== 0
    ) {
      showSnackbar({
        type: SnackbarType.Error,
        message: "One or more validation errors occurred.",
      });
      setApiErrors(
        errors ? (mapErrors(errors) as ApiError["Body"]["Errors"]) : undefined,
      );
    } else
      showSnackbar({
        type: SnackbarType.Error,
        message: mapApiErrorToMessage(res),
      });
  };

  return { handleError, apiErrors, clearApiErrors };
};
