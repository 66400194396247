import { FormattedMessage } from "react-intl";

import { Cafs } from "../../../api/types/cafs";
import { TierLevel } from "../../../components/icons/TierLevel";

export type UpdateProps = {
  data: Cafs;
};

export function TierInfo({ data }: UpdateProps) {
  return (
    <div className="flex flex-col text-darkGray items-center h-[70px]">
      <div className="flex flex-row gap-2 mb-2 items-start flex-1">
        <TierLevel className="text-2xl text-primary" />
        <span className="font-semibold">
          <FormattedMessage
            id="CAFS_IFNO.TIER"
            defaultMessage="Tier"
            description="Tier info label text"
          />
        </span>
      </div>
      <div className="flex flex-row gap-4 flex-1 items-center">
        <div>{data.Tier}</div>
      </div>
    </div>
  );
}
