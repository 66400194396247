import { createApiOperation } from "../api-client/create-api-operation";

export const Cafs = {
  exportAll: createApiOperation<never, "/Api/Export/{datasetId}", "post">(
    "/Api/Export/{datasetId}",
    "post",
    { file: true },
  ),
  exportSelected: createApiOperation<never, "/Api/Export/{datasetId}", "post">(
    "/Api/Export/{datasetId}",
    "post",
    { file: true },
  ),
};
