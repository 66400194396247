import { useState } from "react";

export const useTabs = (initialValue = 0) => {
  const [tabIndex, setTabIndex] = useState(initialValue);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  return { tabIndex, handleTabChange };
};
