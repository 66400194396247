import { useIntl } from "react-intl";
import { useMatch, useParams } from "react-router-dom";

import { useMutation } from "@tanstack/react-query";

import { Inputs } from "../../../api/inputs";
import { Plants } from "../../../api/plants";
import { MutationKey } from "../../../consts/mutation-keys";
import { useApiError } from "../../../hooks/useApiError";
import { useApiSuccess } from "../../../hooks/useApiSuccess";
import { ApiHookConfig } from "../../../types/api-hook-config";
import { CafsViewQueryKeys } from "../const/cafs-view-query-keys";

export function useDraftApprove({ onSuccess }: ApiHookConfig) {
  const { datasetId, id } = useParams();
  const matchInput = useMatch("/dashboard/input/:datasetId/:id");
  const { formatMessage } = useIntl();

  const { handleError } = useApiError();
  const { handleSuccess } = useApiSuccess({
    invalidate: CafsViewQueryKeys,
    snackbarText: formatMessage({
      id: "ACTIONS.DRAFT_ACCEPT_SUCCESS",
      defaultMessage: "Draft approved",
      description: "Approve draft success message",
    }),
    onSuccessFn: onSuccess,
  });

  const MutationFn = () => {
    return matchInput
      ? Inputs.approve({ path: { datasetId: +datasetId!, inputId: +id! } })
      : Plants.approve({
          path: { datasetId: +datasetId!, plantId: +id! },
        });
  };

  const { mutate: approveDraft, isPending } = useMutation({
    mutationFn: MutationFn,
    onSuccess: handleSuccess,
    onError: handleError,
    mutationKey: [MutationKey.ApproveDraft, datasetId!, id!],
  });

  return { approveDraft, isPending };
}
