import { FormProvider, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Button, CircularProgress } from "@mui/material";

import { components } from "../../../api-client/api-schema";
import { RhfFormField } from "../../../components/rhf/RhfFormField";
import { RhfInput } from "../../../components/rhf/RhfInput";

import { loginSchema } from "./login-schema";

export type LoginFormProps = {
  onSubmit: (data: LoginFormShape) => void;
  isError: boolean;
  isPending: boolean;
};

export type LoginFormShape = components["schemas"]["LoginModelDto"];

export function LoginForm({ onSubmit, isError, isPending }: LoginFormProps) {
  const methods = useForm<LoginFormShape>({
    resolver: yupResolver(loginSchema),
    mode: "onBlur",
  });

  const { formatMessage } = useIntl();

  return (
    <div className="w-full">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="grid grid-cols-4 gap-4">
            <div className="col-start-2 col-span-2">
              <div className="flex flex-col w-full">
                <div className="mb-4">
                  <RhfFormField name="Email">
                    <RhfInput
                      className="w-full"
                      name="Email"
                      label={formatMessage({
                        id: "LOGIN_FORM.LOGIN",
                        defaultMessage: "Email",
                        description: "Login form login label",
                      })}
                    />
                  </RhfFormField>
                </div>
                <div className="mb-8">
                  <RhfFormField name="Password">
                    <RhfInput
                      className="w-full"
                      name="Password"
                      label={formatMessage({
                        id: "LOGIN_FORM.PASSWORD",
                        defaultMessage: "Password",
                        description: "Login form password label",
                      })}
                      type="password"
                    />
                  </RhfFormField>
                </div>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={!methods.formState.isValid || isPending}
                  startIcon={isPending ? <CircularProgress size={20} /> : null}>
                  <FormattedMessage
                    id="LOGIN.LOGIN_BTN"
                    defaultMessage="Log in"
                    description="Login button text on login page"
                  />
                </Button>

                {isError && (
                  <div className="mt-8 w-full">
                    <Alert severity="error">Invalid credentials</Alert>
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
