import { useMemo } from "react";

import { PlantProducts } from "../../../api/types/plants";
import { createOptions } from "../../../utils/create-options";

export function useCategoriesOptions(plantProducts: PlantProducts | undefined) {
  const options = useMemo(
    () => createOptions(plantProducts?.ProductCategories),
    [plantProducts],
  );
  return { options };
}
