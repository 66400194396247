import React, { useCallback, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";

import FileUpload from "../../../components/FileUpload";
import { useDatasetImport } from "../api/useDatasetImport";

export function DatasetImport({ ...props }) {
  const [uploadStatus, setUploadStatus] = useState<string>("");
  const [progress, setProgress] = useState<number>(0);
  const fileUploadRef = useRef<HTMLInputElement>(null);

  const updateProgress = useCallback((newProgress: number) => {
    setProgress(newProgress);
  }, []);

  const resetUploadState = useCallback(() => {
    setUploadStatus("Processing...");
    if (fileUploadRef.current) {
      fileUploadRef.current.value = "";
    }
  }, []);

  const { upload, isPending } = useDatasetImport({
    onProgress: updateProgress,
    onUploadComplete: resetUploadState,
  });

  const handleUpload = useCallback(
    (file: File) => {
      const formData = new FormData();
      formData.append("File", file);
      upload(formData);
    },
    [upload],
  );

  return (
    <div {...props}>
      <p className="mt-2">
        <FormattedMessage
          id="DASHBOARD.INSTRUCTIONS"
          defaultMessage="Choose file to which you want to import."
        />
      </p>
      <div className="mt-4">
        <div className="flex flex-row items-center gap-4">
          <FileUpload
            ref={fileUploadRef}
            disabled={isPending}
            onUpload={handleUpload}
            progress={progress}
            isPending={isPending}
            status={uploadStatus}
            text="Upload CSV"
          />
        </div>
      </div>
    </div>
  );
}
