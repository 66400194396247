import { useParams } from "react-router-dom";

import { useQuery } from "@tanstack/react-query";

import { Plants } from "../../../api/plants";
import { QueryKey } from "../../../consts/query-keys";

export const useGrowthConditionsData = (shouldFetchData: boolean) => {
  const { datasetId, id } = useParams();
  const { data: growthConditionsData, isPending } = useQuery({
    queryKey: [QueryKey.GrowthConditionsSection, datasetId, id],
    queryFn: () =>
      Plants.getGrowthConditions({
        path: { datasetId: +datasetId! as number, plantId: +id! as number },
      }).then(response => {
        return response;
      }),
    throwOnError: true,
    enabled: shouldFetchData,
  });

  return { growthConditionsData, isPending };
};
