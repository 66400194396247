import { useContext } from "react";
import { UseFormReturn } from "react-hook-form";

import { CafsContext } from "../CafsContext";

export function useSectionSaveDisabled(
  methods: UseFormReturn,
  isPending: boolean,
) {
  const { isReadMode } = useContext(CafsContext);

  return {
    disabled:
      !methods.formState.isValid ||
      isPending ||
      isReadMode ||
      !methods.formState.isDirty,
  };
}
