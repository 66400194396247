import { PropsWithChildren, SyntheticEvent, useContext } from "react";

import { AccordionDetails } from "@mui/material";
import { twMerge } from "tailwind-merge";

import { PlantProduct } from "../../../../api/types/plants";
import { StyledAccordion } from "../../../../components/StyledAccordion";
import { StyledAccordionSummary } from "../../../../components/StyledAccordionSummary";
import { useModal } from "../../../../hooks/useModal";
import { CafsContext } from "../../CafsContext";
import { useProductName } from "../../hooks/useProductName";
import { ProductsContext } from "../../ProductsContext";

import { DeleteProductModal } from "./deleteProductModal";
import { renderTrash } from "./render-product-trash";

export type PlantProductItemProps = PropsWithChildren & {
  product: PlantProduct;
  onChange: (event: SyntheticEvent<Element, Event>, expanded: boolean) => void;
  expanded: boolean;
};

export function ProductsListItem({
  children,
  product,
  onChange,
  expanded,
  ...rest
}: PlantProductItemProps) {
  const { isReadMode } = useContext(CafsContext);
  const { allProducts } = useContext(ProductsContext);

  const { name } = useProductName(product, allProducts);
  const { open, handleOpen, handleClose } = useModal();

  const accordionSummaryClass = twMerge(
    "w-full flex justify-between items-center",
    expanded && "text-2xl font-semibold mb-4 ",
  );

  return (
    <>
      <StyledAccordion onChange={onChange} expanded={expanded}>
        <StyledAccordionSummary expanded={expanded}>
          <div className={accordionSummaryClass}>
            <div>
              <span className="text-primary">
                {product.Code?.Current ?? product.Code?.Base}
              </span>
              {" - "}
              {name}
            </div>
            {renderTrash(product, handleOpen, isReadMode)}
          </div>
        </StyledAccordionSummary>
        <AccordionDetails>
          <div className="grid grid-cols-1">{children}</div>
        </AccordionDetails>
      </StyledAccordion>

      <DeleteProductModal open={open} onClose={handleClose} product={product} />
    </>
  );
}
