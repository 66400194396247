import { createApiOperation } from "../api-client/create-api-operation";

import {
  GuestResponseToPressure,
  GuestResponseToPressures,
} from "./types/guests";

export const Guests = {
  getResponsesToPressure: createApiOperation<
    GuestResponseToPressures,
    "/Api/Plants/{datasetId}/{plantId}/Guest/{guestId}/ResponseToPressures",
    "get"
  >(
    "/Api/Plants/{datasetId}/{plantId}/Guest/{guestId}/ResponseToPressures",
    "get",
  ),

  createResponsesToPressure: createApiOperation<
    GuestResponseToPressure,
    "/Api/Plants/{datasetId}/{plantId}/Guest/{guestId}/ResponseToPressure",
    "post"
  >(
    "/Api/Plants/{datasetId}/{plantId}/Guest/{guestId}/ResponseToPressure",
    "post",
  ),

  updateResponsesToPressure: createApiOperation<
    never,
    "/Api/Plants/{datasetId}/{plantId}/Guest/{guestId}/ResponseToPressure/{guestResponseToPressureId}",
    "put"
  >(
    "/Api/Plants/{datasetId}/{plantId}/Guest/{guestId}/ResponseToPressure/{guestResponseToPressureId}",
    "put",
  ),

  deleteResponsesToPressure: createApiOperation<
    never,
    "/Api/Plants/{datasetId}/{plantId}/Guest/{guestId}/ResponseToPressure/{guestResponseToPressureId}",
    "delete"
  >(
    "/Api/Plants/{datasetId}/{plantId}/Guest/{guestId}/ResponseToPressure/{guestResponseToPressureId}",
    "delete",
  ),
};
