import { useIntl } from "react-intl";

import { useMutation } from "@tanstack/react-query";

import { ReferencesTo } from "../../../api/references-to";
import { MutationKey } from "../../../consts/mutation-keys";
import { QueryKey } from "../../../consts/query-keys";
import { useApiSuccess } from "../../../hooks/useApiSuccess";

type mutationFnProps = {
  id: number;
};

export const useReferencesUnassign = () => {
  const { formatMessage } = useIntl();
  const { handleSuccess } = useApiSuccess({
    invalidate: [[QueryKey.AssignedReferencesList]],
    snackbarText: formatMessage({
      id: "ACTIONS.REFERENCE_UNASSIGN",
      defaultMessage: "Reference unassigned successfully.",
      description: "Variable reference unassign success message",
    }),
  });

  const mutationFn = ({ id }: mutationFnProps) =>
    ReferencesTo.unassign({ path: { id: +id! } });

  const { mutate: unassignReference, isPending } = useMutation({
    mutationKey: [MutationKey.UnassignReferencesTo],
    mutationFn: mutationFn,
    onSuccess: handleSuccess,
  });

  return { unassignReference, isPending };
};
