import { useMemo } from "react";

import { PlantProducts } from "../../../api/types/plants";
import { SelectOption } from "../../../types/select-option";
import { createOptions } from "../../../utils/create-options";

export function usePlantPartsOptions(
  plantProducts: PlantProducts | undefined,
): {
  options: SelectOption[];
} {
  const options = useMemo(
    () => createOptions(plantProducts?.PlantParts),
    [plantProducts],
  );

  return { options };
}
