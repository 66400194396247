import { ReactNode } from "react";

import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";

export const renderBreadcrumb = (ancestors: ReactJSXElement[]): ReactNode => {
  return ancestors.reduce((previous, current, index) => {
    return index === 0
      ? [current]
      : [
          ...previous,
          <span className="text-primary" key={index}>
            {" > "}
          </span>,
          current,
        ];
  }, [] as ReactJSXElement[]);
};
