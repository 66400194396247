import { useState } from "react";

export type UseModalProps = {
  closeAction?: () => void;
};

export function useModal({ closeAction = () => {} }: UseModalProps = {}) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    closeAction();
  };

  return { open, handleOpen, handleClose };
}
