import { Token } from "../api/types/token";
import { LocalStorageKey } from "../consts/local-storage-key";

export function saveLocalStorageAuthData(data: Token) {
  localStorage.setItem(LocalStorageKey.Token, data.Token ?? "");
  localStorage.setItem(LocalStorageKey.RefreshToken, data.RefreshToken ?? "");
  localStorage.setItem(LocalStorageKey.TokenValidTo, data.TokenValidTo ?? "");
  localStorage.setItem(LocalStorageKey.User, JSON.stringify(data.User) ?? "");
  localStorage.setItem(
    LocalStorageKey.RefreshTokenValidTo,
    data.RefreshTokenValidTo ?? "",
  );
}
