import React, { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { Autocomplete, Chip, TextField, Tooltip } from "@mui/material";

import { Reference } from "../../../api/types/references"; // Import the Reference type
import { useAssignedReferences } from "../../../hooks/useAssignedReferences";
import { useAuth } from "../../../hooks/useAuth";
import { useAllReferencesList } from "../api/useAllReferencesList";
import { useReferencesAssign } from "../api/useReferencesAssign";
import { useReferencesUnassign } from "../api/useReferencesUnassign";
import { CafsContext } from "../CafsContext";
import { CafsSection } from "../const/cafs-section";

export type ReferenceAssignProps = {
  fieldName: string;
  sectionName: CafsSection;
  productCode: string | undefined;
  guestName: string | undefined | null;
};

export function ReferenceAssign({
  fieldName,
  sectionName,
  productCode,
  guestName,
}: ReferenceAssignProps) {
  const { isFarmTreeRole } = useAuth();
  const [selectedReferences, setSelectedReferences] = useState<Reference[]>([]);

  const { isReadMode } = useContext(CafsContext);
  const { assignReference } = useReferencesAssign(
    sectionName,
    productCode,
    guestName,
  );
  const { unassignReference } = useReferencesUnassign();
  const { allReferencesList, isFetching } = useAllReferencesList();
  const { assignedReferences } = useAssignedReferences(fieldName, sectionName);

  useEffect(() => {
    if (allReferencesList && assignedReferences) {
      const assignedRefs = allReferencesList.filter(ref =>
        assignedReferences.some(
          assignedRef => assignedRef.ReferenceId === ref.Id,
        ),
      );
      setSelectedReferences(assignedRefs);
    }
  }, [assignedReferences, allReferencesList]);

  const handleReferenceUnassign = (itemId: number | undefined | null) => {
    const connectionId = assignedReferences?.find(
      r => r.ReferenceId === itemId,
    );
    unassignReference({ id: connectionId?.Id! });
  };

  const handleReferenceChange = (
    e: React.SyntheticEvent<Element, Event>,
    newValue: Reference[],
  ) => {
    let changedItem;
    let prevValue = selectedReferences;

    if (newValue.length > prevValue.length) {
      changedItem = newValue.find(
        item => !prevValue.some(prevItem => prevItem.Id === item.Id),
      );
      assignReference({
        referenceId: changedItem?.Id!,
        name: fieldName,
      });
    } else {
      changedItem = prevValue.find(
        prevItem => !newValue.some(item => item.Id === prevItem.Id),
      );
      handleReferenceUnassign(changedItem?.Id);
    }
  };

  const { formatMessage } = useIntl();
  return (
    <Autocomplete
      size="small"
      disableClearable
      multiple
      disabled={isReadMode || isFetching || !isFarmTreeRole}
      value={selectedReferences}
      onChange={handleReferenceChange}
      options={allReferencesList || []}
      getOptionLabel={option => option?.FullName ?? ""}
      renderTags={(value, getTagProps) =>
        [
          ...value.slice(0, 2).map((option, index) => (
            <div key={option.Id}>
              <Tooltip title={option.FullName}>
                <Chip
                  {...getTagProps({ index })}
                  key={option.Id}
                  label={option.Id}
                  style={{ maxWidth: "100%" }}
                />
              </Tooltip>
            </div>
          )),
          value.length > 2 && (
            <Tooltip
              title={value.slice(2, value.length).map(item => {
                return `${item.Id}. ${item.FullName}`;
              })}>
              <small key="ellipsis" style={{ marginLeft: 8 }}>
                +{value.length - 2} more...
              </small>
            </Tooltip>
          ),
        ].filter(Boolean)
      }
      renderInput={params => (
        <TextField
          {...params}
          label={formatMessage({
            id: "CAFS.REFERENCE_ASSIGN_LABEL",
            defaultMessage: "Assigned references",
          })}
        />
      )}
    />
  );
}
