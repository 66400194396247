import { useMutation } from "@tanstack/react-query";

import { Tooltips } from "../../../api/tooltips";
import { MutationKey } from "../../../consts/mutation-keys";
import { QueryKey } from "../../../consts/query-keys";
import { useApiSuccess } from "../../../hooks/useApiSuccess";
import { ApiHookConfig } from "../../../types/api-hook-config";

export type TooltipItem = {
  Name: string;
  Description: string | null;
  Unit: string | null;
};

export type CategoryMap = {
  [category: string]: CategoryValue;
};

export type CategoryValue = {
  [property: string]: Omit<TooltipItem, "Name">;
};

export function useUpdateTooltips({ onSuccess }: ApiHookConfig) {
  const mutationFn = (data: TooltipItem) => Tooltips.update(undefined, data);
  const { handleSuccess } = useApiSuccess({
    invalidate: [[QueryKey.Tooltips], [QueryKey.PlantTooltips]],
    onSuccessFn: onSuccess,
    snackbarText: "Tooltip updated.",
  });

  const { mutate: updateTooltip, isPending } = useMutation({
    mutationKey: [MutationKey.Tooltips],
    onSuccess: handleSuccess,
    mutationFn: mutationFn,
  });

  return { updateTooltip, isPending };
}
