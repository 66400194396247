import { useParams } from "react-router-dom";

import { PlantGuest } from "../../../../api/types/plants";
import { createOptions } from "../../../../utils/create-options";
import { useGuestsData } from "../../api/useGuestsData";
import { CafsVariableType } from "../../const/cafs-variable-type";
import { CafsVariablesCollection } from "../cafs-table/CafsTable";

export function useGuestVariables(): CafsVariablesCollection<PlantGuest> {
  const { datasetId, id } = useParams();
  const { guests } = useGuestsData(datasetId, id);

  return [
    { name: "GeneralRemarks", type: CafsVariableType.Remarks },
    {
      type: CafsVariableType.Input,
      name: "Name",
      text: true,
    },
    {
      type: CafsVariableType.Select,
      name: "Category",
      options: createOptions(guests?.Categories),
    },
    {
      type: CafsVariableType.Input,
      name: "PressureMaxScale",
    },
    {
      type: CafsVariableType.Input,
      name: "RpMonth",
    },
    {
      type: CafsVariableType.Input,
      name: "InitialMonth",
    },
    {
      type: CafsVariableType.Input,
      name: "DurationMonths",
    },
    {
      type: CafsVariableType.Input,
      name: "RhythmYears",
    },
    {
      type: CafsVariableType.Input,
      name: "ContrToBiodiversity",
    },
    {
      type: CafsVariableType.Input,
      name: "SOMSensitivity",
    },
    {
      type: CafsVariableType.Input,
      name: "OptSOMtpHa",
    },
  ];
}
