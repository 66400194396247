import { useIntl } from "react-intl";

import { useMutation } from "@tanstack/react-query";

import { Guests } from "../../../api/guests";
import { GuestResponseToPressure } from "../../../api/types/guests";
import { MutationKey } from "../../../consts/mutation-keys";
import { QueryKey } from "../../../consts/query-keys";
import { useApiError } from "../../../hooks/useApiError";
import { useApiSuccess } from "../../../hooks/useApiSuccess";
import { CafsForm } from "../../../types/cafs-form";

export type UpdateGuestRtpMutation = {
  datasetId: number;
  plantId: number;
  guestId: number;
  guestResponseToPressureId: number;
  data: Partial<CafsForm<GuestResponseToPressure>>;
};

export const useGuestPressureUpdate = () => {
  const { formatMessage } = useIntl();

  const { handleError, apiErrors, clearApiErrors } = useApiError();
  const { handleSuccess } = useApiSuccess({
    invalidate: [[QueryKey.GuestRtp], [QueryKey.CafsDetails]],
    snackbarText: formatMessage({
      id: "ACTIONS.GUEST_PRESSURE_UPDATE_SUCCESS",
      defaultMessage: "Guest response to pressure updated successfully.",
      description: "Guest response to pressure update success message",
    }),
  });

  const mutationFn = ({
    datasetId,
    plantId,
    guestId,
    guestResponseToPressureId,
    data,
  }: UpdateGuestRtpMutation) =>
    Guests.updateResponsesToPressure(
      {
        path: {
          datasetId,
          plantId,
          guestId,
          guestResponseToPressureId,
        },
      },
      data,
    );

  const { mutate: updateRtp, isPending } = useMutation({
    mutationKey: [MutationKey.UpdatePlantRtp],
    mutationFn: mutationFn,
    onSuccess: handleSuccess,
    onError: handleError,
  });

  return { updateRtp, isPending, apiErrors, clearApiErrors };
};
