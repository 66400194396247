import { CafsSection } from "./cafs-section";

export const CafsSectionsMap: Map<CafsSection, string> = new Map([
  [CafsSection.PlantGrowth, "Growth"],
  [CafsSection.PlantDefinition, "Definition"],
  [CafsSection.PlantLifeCycle, "Life cycle"],
  [CafsSection.PlantWoodDensity, "Wood density"],
  [CafsSection.PlantBiomassAllocation, "Biomass allocation"],
  [CafsSection.PlantRoot, "Roots"],
  [CafsSection.PlantConstraint, "Constraints"],
  [CafsSection.PlantGrowthCondition, "Growth conditions"],
  [CafsSection.PlantCost, "Costs"],
  [CafsSection.PlantLabour, "Labour"],
  [CafsSection.PlantBiodiversity, "Biodiversity"],
  [CafsSection.PlantResponseToPressure, "Response to pressure"],
]);
