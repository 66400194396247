import { ModalSize, MuiModal } from "../../../../../components/mui/MuiModal";
import { usePlantPressureCreate } from "../../../api/usePlantPressureCreate";

import { CreateRtpForm } from "./PressureCreate";

export type CreateProductModalProps = {
  open: boolean;
  onClose: () => void;
};

export function CreatePressureModal({
  open,
  onClose,
}: CreateProductModalProps) {
  const handleClose = () => {
    clearApiErrors();
    onClose();
  };

  const { apiErrors, clearApiErrors, createRtp, isPending } =
    usePlantPressureCreate({
      onSuccess: handleClose,
    });

  return (
    <MuiModal
      open={open}
      onClose={handleClose}
      size={ModalSize.Medium}
      header="Create response to pressure">
      <CreateRtpForm
        customErrors={apiErrors}
        onSubmit={createRtp}
        onCancel={handleClose}
        disabled={isPending}
      />
    </MuiModal>
  );
}
