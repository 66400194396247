import { useContext } from "react";
import { ErrorBoundary } from "react-error-boundary";

import { Alert } from "@mui/material";

import { CafsType } from "../../../../consts/cafsType";
import { useAccordion } from "../../../../hooks/useAccordion";
import { CafsContext } from "../../CafsContext";
import { CafsSection } from "../../const/cafs-section";
import { CafsSectionsMap } from "../../const/cafs-sections-map";
import { BiodiversitySection } from "../cafs-sections/biodiversity/BiodiversitySection";
import { BiomassAllocationSection } from "../cafs-sections/biomass-allocation/BiomassAllocationSection";
import { ConstraintsSection } from "../cafs-sections/constraints/ConstraintsSection";
import { CostsSection } from "../cafs-sections/costs/CostsSection";
import { GrowthSection } from "../cafs-sections/growth/GrowthSection";
import { GrowthConditionsSection } from "../cafs-sections/growth-conditions/GrowthConitionsSection";
import { LabourSection } from "../cafs-sections/labour/LabourSection";
import { LifeCycleSection } from "../cafs-sections/life-cycle/LifeCycleSection";
import { PressureSection } from "../cafs-sections/pressures/PressureSection";
import { RootsSection } from "../cafs-sections/roots/rootsSection";
import { WoodDensitySection } from "../cafs-sections/wood-density/WoodDentitySection";
import { VariableSection } from "../VariableSection";

export function PlantVariables() {
  const { cafs } = useContext(CafsContext);
  const { expanded, handleChange } = useAccordion();

  return (
    <>
      <VariableSection
        name={CafsSectionsMap.get(CafsSection.PlantGrowth) ?? ""}
        onChange={handleChange(CafsSection.PlantGrowth)}
        expanded={expanded === CafsSection.PlantGrowth}
        data-testid={`Section.${CafsSection.PlantGrowth}`}>
        <ErrorBoundary
          fallback={<Alert severity="error">Something went wrong...</Alert>}>
          <GrowthSection expanded={expanded === CafsSection.PlantGrowth} />
        </ErrorBoundary>
      </VariableSection>

      <VariableSection
        name={CafsSectionsMap.get(CafsSection.PlantLifeCycle) ?? ""}
        onChange={handleChange(CafsSection.PlantLifeCycle)}
        expanded={expanded === CafsSection.PlantLifeCycle}
        data-testid={`Section.${CafsSection.PlantLifeCycle}`}>
        <ErrorBoundary
          fallback={<Alert severity="error">Something went wrong...</Alert>}>
          <LifeCycleSection
            expanded={expanded === CafsSection.PlantLifeCycle}
          />
        </ErrorBoundary>
      </VariableSection>

      {cafs?.Type === CafsType.Tree && (
        <VariableSection
          name={CafsSectionsMap.get(CafsSection.PlantWoodDensity) ?? ""}
          onChange={handleChange(CafsSection.PlantWoodDensity)}
          expanded={expanded === CafsSection.PlantWoodDensity}
          data-testid={`Section.${CafsSection.PlantWoodDensity}`}>
          <ErrorBoundary
            fallback={<Alert severity="error">Something went wrong...</Alert>}>
            <WoodDensitySection
              expanded={expanded === CafsSection.PlantWoodDensity}
            />
          </ErrorBoundary>
        </VariableSection>
      )}

      <VariableSection
        name={CafsSectionsMap.get(CafsSection.PlantBiomassAllocation) ?? ""}
        onChange={handleChange(CafsSection.PlantBiomassAllocation)}
        expanded={expanded === CafsSection.PlantBiomassAllocation}
        data-testid={`Section.${CafsSection.PlantBiomassAllocation}`}>
        <ErrorBoundary
          fallback={<Alert severity="error">Something went wrong...</Alert>}>
          <BiomassAllocationSection
            expanded={expanded === CafsSection.PlantBiomassAllocation}
          />
        </ErrorBoundary>
      </VariableSection>

      <VariableSection
        name={CafsSectionsMap.get(CafsSection.PlantRoot) ?? ""}
        onChange={handleChange(CafsSection.PlantRoot)}
        expanded={expanded === CafsSection.PlantRoot}
        data-testid={`Section.${CafsSection.PlantRoot}`}>
        <ErrorBoundary
          fallback={<Alert severity="error">Something went wrong...</Alert>}>
          <RootsSection expanded={expanded === CafsSection.PlantRoot} />
        </ErrorBoundary>
      </VariableSection>

      <VariableSection
        name={CafsSectionsMap.get(CafsSection.PlantConstraint) ?? ""}
        onChange={handleChange(CafsSection.PlantConstraint)}
        expanded={expanded === CafsSection.PlantConstraint}
        data-testid={`Section.${CafsSection.PlantConstraint}`}>
        <ErrorBoundary
          fallback={<Alert severity="error">Something went wrong...</Alert>}>
          <ConstraintsSection
            expanded={expanded === CafsSection.PlantConstraint}
          />
        </ErrorBoundary>
      </VariableSection>

      <VariableSection
        name={CafsSectionsMap.get(CafsSection.PlantGrowthCondition) ?? ""}
        onChange={handleChange(CafsSection.PlantGrowthCondition)}
        expanded={expanded === CafsSection.PlantGrowthCondition}
        data-testid={`Section.${CafsSection.PlantGrowthCondition}`}>
        <ErrorBoundary
          fallback={<Alert severity="error">Something went wrong...</Alert>}>
          <GrowthConditionsSection
            expanded={expanded === CafsSection.PlantGrowthCondition}
          />
        </ErrorBoundary>
      </VariableSection>

      <VariableSection
        name={CafsSectionsMap.get(CafsSection.PlantCost) ?? ""}
        onChange={handleChange(CafsSection.PlantCost)}
        expanded={expanded === CafsSection.PlantCost}
        data-testid={`Section.${CafsSection.PlantCost}`}>
        <ErrorBoundary
          fallback={<Alert severity="error">Something went wrong...</Alert>}>
          <CostsSection expanded={expanded === CafsSection.PlantCost} />
        </ErrorBoundary>
      </VariableSection>

      <VariableSection
        name={CafsSectionsMap.get(CafsSection.PlantLabour) ?? ""}
        onChange={handleChange(CafsSection.PlantLabour)}
        expanded={expanded === CafsSection.PlantLabour}
        data-testid={`Section.${CafsSection.PlantLabour}`}>
        <ErrorBoundary
          fallback={<Alert severity="error">Something went wrong...</Alert>}>
          <LabourSection expanded={expanded === CafsSection.PlantLabour} />
        </ErrorBoundary>
      </VariableSection>

      <VariableSection
        name={CafsSectionsMap.get(CafsSection.PlantBiodiversity) ?? ""}
        onChange={handleChange(CafsSection.PlantBiodiversity)}
        expanded={expanded === CafsSection.PlantBiodiversity}
        data-testid={`Section.${CafsSection.PlantBiodiversity}`}>
        <ErrorBoundary
          fallback={<Alert severity="error">Something went wrong...</Alert>}>
          <BiodiversitySection
            expanded={expanded === CafsSection.PlantBiodiversity}
          />
        </ErrorBoundary>
      </VariableSection>

      <VariableSection
        name={CafsSectionsMap.get(CafsSection.PlantResponseToPressure) ?? ""}
        onChange={handleChange(CafsSection.PlantResponseToPressure)}
        expanded={expanded === CafsSection.PlantResponseToPressure}
        data-testid={`Section.${CafsSection.PlantResponseToPressure}`}>
        <ErrorBoundary
          fallback={<Alert severity="error">Something went wrong...</Alert>}>
          <PressureSection
            expanded={expanded === CafsSection.PlantResponseToPressure}
          />
        </ErrorBoundary>
      </VariableSection>
    </>
  );
}
