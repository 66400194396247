import { pick, reject } from "ramda";

import { CreateApiOperationOptions } from "api-client/types";

export function queryParamsBuilder(
  params: Record<string, unknown>,
  options?: CreateApiOperationOptions,
): URLSearchParams {
  const defaultParams = options?.defaultParams ?? {};
  const allowedParams = options?.allowedParams ?? [];

  const merged = { ...defaultParams, ...params };

  const cleanParams = reject(
    value => value === null || value === undefined,
    merged,
  );

  const filteredParams = allowedParams.length
    ? pick(allowedParams, cleanParams)
    : cleanParams;

  const stringParams = Object.entries(filteredParams).reduce(
    (acc, [key, value]) => {
      if (value !== null && value !== undefined) {
        acc[key] = String(value);
      }
      return acc;
    },
    {} as Record<string, string>,
  );

  return new URLSearchParams(stringParams);
}
