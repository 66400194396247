import { useEffect, useRef } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { TextFieldProps } from "@mui/material";

import { MuiInput } from "../mui/MuiInput";

export type RhfInputProps = TextFieldProps & {
  name: string;
  readonly?: boolean;
  focusOnError?: boolean;
};

export function RhfInput({
  name,
  label,
  rows,
  maxRows,
  multiline,
  focusOnError,
  ...rest
}: RhfInputProps) {
  const { control, getFieldState } = useFormContext();
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (getFieldState(name).invalid && inputRef.current && focusOnError) {
      inputRef?.current?.focus();
    }
  }, [getFieldState(name).invalid]);

  return (
    <Controller
      control={control}
      render={({ field }) => (
        <MuiInput
          {...field}
          label={label}
          placeholder={rest.placeholder}
          variant={"outlined"}
          error={getFieldState(name).invalid}
          ref={inputRef}
          value={
            field.value === undefined || field.value === null ? "" : field.value
          }
          type={rest.type}
          rows={rows}
          maxRows={maxRows}
          multiline={multiline}
          InputProps={rest.InputProps}
          inputProps={rest.inputProps}
          disabled={rest.disabled}
          className={rest.className}
        />
      )}
      name={name}
    />
  );
}
