import React, { useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Button, IconButton, Tooltip } from "@mui/material";

import { Rubber } from "../../../components/icons/Rubber";
import { useExportAllCafs } from "../api/useExportAllCafs";
import { useExportSelectedCafs } from "../api/useExportSelectedCafs";
import { DashboardContext } from "../DashboardContext";

export function CafsExport() {
  const {
    selectedPlants,
    selectedInputs,
    selectedDataset,
    handleDeselectAllCafs,
  } = useContext(DashboardContext);

  const selectedNodes = [...selectedPlants, ...selectedInputs];

  const { exportAllCafs } = useExportAllCafs();
  const { exportSelectedCafs } = useExportSelectedCafs();

  const { formatMessage } = useIntl();

  return selectedDataset ? (
    <div className="flex gap-4 items-center" data-testid="cafs-export">
      <Tooltip
        title={formatMessage({
          id: "TOOLTIP.EXPORT_SELECTED_CAFS",
          defaultMessage: "Export data of selected CAFS of the dataset",
          description: "Tooltip message on export selected CAFS button",
        })}>
        <div>
          <Button
            variant="contained"
            color="primary"
            disabled={!selectedNodes.length}
            onClick={() => exportSelectedCafs()}>
            <FormattedMessage
              id="EXPORT_CAFS.SELECTED"
              defaultMessage="Export selected ({count})"
              values={{ count: selectedNodes.length }}
            />
          </Button>
        </div>
      </Tooltip>
      <Tooltip
        title={formatMessage({
          id: "TOOLTIP.EXPORT_ALL_CAFS",
          defaultMessage: "Export data of all CAFS in the dataset",
          description: "Tooltip message on export all CAFS button",
        })}>
        <div>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => exportAllCafs()}>
            <FormattedMessage
              id="EXPORT_CAFS.ALL"
              defaultMessage="Export all"
              description="Export all cavs buttin"
            />
          </Button>
        </div>
      </Tooltip>
      <Tooltip
        title={formatMessage({
          id: "TOOLTIP.CLEAR_SELECTED_DATASETS",
          defaultMessage: "Clear selected datasets",
          description: "Clear selected datasets tooltip text",
        })}>
        <div>
          <IconButton
            className="hover:text-red-500"
            disabled={!selectedNodes.length}
            onClick={handleDeselectAllCafs}>
            <Rubber />
          </IconButton>
        </div>
      </Tooltip>
    </div>
  ) : null;
}
