import { UseFormReturn } from "react-hook-form";

import { CafsVariableTooltips } from "../../../../api/types/cafs";
import { PlantType } from "../../../../api/types/plants";
import { CafsSection } from "../../const/cafs-section";
import { CafsVariableType } from "../../const/cafs-variable-type";

import { AutocompleteRow } from "./AutocompleteRow";
import { BooleanRow } from "./BooleanRow";
import { CafsVariable } from "./CafsTable";
import { InfoRow } from "./InfoRow";
import { InputRow } from "./InputRow";

export function renderVariableRow<T>(
  variable: CafsVariable<keyof T>,
  data: unknown,
  section: CafsSection,
  tooltips: CafsVariableTooltips<unknown> | undefined,
  plantType: PlantType | undefined,
  isReadMode?: boolean,
  productCode?: string,
  guestName?: string | null,
  methods?: UseFormReturn,
) {
  if (variable.type === CafsVariableType.Remarks) return null;
  if (
    variable.renderCondition &&
    methods &&
    !variable.renderCondition({ methods: methods, cafsType: plantType })
  )
    return <></>;

  switch (variable.type) {
    case CafsVariableType.Input:
      return (
        <InputRow
          key={variable.name as string}
          variableName={variable.name as string}
          variableValue={(data || {})[variable.name as keyof typeof data]}
          sectionName={section}
          tooltip={(tooltips ?? {})[variable.name as keyof typeof data]}
          dataFormat={variable.format}
          data={variable.data}
          disabled={isReadMode}
          type={variable.text ? "text" : "number"}
          productCode={productCode}
          guestName={guestName}
        />
      );
    case CafsVariableType.Select:
      return (
        <AutocompleteRow
          key={variable.name as string}
          variableName={variable.name as string}
          options={variable.options}
          variableValue={(data || {})[variable.name as keyof typeof data]}
          sectionName={section}
          disabled={isReadMode}
          tooltip={(tooltips ?? {})[variable.name as keyof typeof data]}
          productCode={productCode}
          guestName={guestName}
        />
      );
    case CafsVariableType.Calculated:
      return (
        <InputRow
          key={variable.name as string}
          variableName={variable.name as string}
          variableValue={(data || {})[variable.name as keyof typeof data]}
          calculationFn={variable.calculationFn}
          dependOn={variable.dependOn}
          sectionName={section}
          tooltip={(tooltips ?? {})[variable.name as keyof typeof data]}
          disabled={isReadMode}
          data={variable.data}
          productCode={productCode}
          guestName={guestName}
        />
      );
    case CafsVariableType.Boolean:
      return (
        <BooleanRow
          key={variable.name as string}
          variableName={variable.name as string}
          sectionName={section}
          variableValue={(data || {})[variable.name as keyof typeof data]}
          tooltip={(tooltips ?? {})[variable.name as keyof typeof data]}
          disabled={isReadMode}
          productCode={productCode}
          guestName={guestName}
        />
      );
    case CafsVariableType.Info:
      return (
        <InfoRow
          variableName={variable.name as string}
          key={variable.name as string}
          sectionName={section}
          tooltip={(tooltips ?? {})[variable.name as keyof typeof data]}
          variableValue={(data || {})[variable.name as keyof typeof data]}
          productCode={productCode}
          guestName={guestName}
        />
      );
    default:
      return <></>;
  }
}
