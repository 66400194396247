import { PropsWithChildren, SyntheticEvent, useContext } from "react";

import { AccordionDetails } from "@mui/material";
import { twMerge } from "tailwind-merge";

import { PlantResponseToPressure } from "../../../../../api/types/plants";
import { ConfirmationModal } from "../../../../../components/ConfirmationModal";
import { Trash } from "../../../../../components/icons/trash";
import { StyledAccordion } from "../../../../../components/StyledAccordion";
import { StyledAccordionSummary } from "../../../../../components/StyledAccordionSummary";
import { useModal } from "../../../../../hooks/useModal";
import { usePlantPressureDelete } from "../../../api/usePlantPressureDelete";
import { CafsContext } from "../../../CafsContext";

export type RtpItemProps = PropsWithChildren & {
  item: PlantResponseToPressure;
  onChange: (event: SyntheticEvent<Element, Event>, expanded: boolean) => void;
  expanded: boolean;
};

export function RtpItem({ children, item, onChange, expanded }: RtpItemProps) {
  const { isReadMode, cafs } = useContext(CafsContext);

  const { open, handleOpen, handleClose } = useModal();
  const { deleteRtp, isPending } = usePlantPressureDelete({
    onSuccess: handleClose,
  });

  function handleDeleteRtp() {
    deleteRtp(item?.Id!);
    handleClose();
  }

  return (
    <>
      <StyledAccordion
        data-testid={`${item.Id}-${item.Category?.Current}`}
        onChange={onChange}
        expanded={expanded}>
        <StyledAccordionSummary expanded={expanded}>
          <div>{item.Category?.Current ?? item.Category?.Base}</div>
          {renderTrash(item, handleOpen, isReadMode)}
        </StyledAccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </StyledAccordion>

      {/* Delete RTP confirmation dialog */}

      <ConfirmationModal
        onConfirm={handleDeleteRtp}
        onCancel={handleClose}
        header="Delete Plant Response to Pressure"
        disabled={isPending}
        variant="error"
        okText="Delete"
        icon={<Trash />}
        text={
          <p>
            Are you sure you want to delete this
            <span className="font-semibold">
              {item?.Category?.Current}
            </span>{" "}
            response to pressure for{" "}
            <span className="font-semibold text-primary">{cafs?.FullName}</span>{" "}
            plant? If the parent plant guest has a{" "}
            <span className="font-semibold">{item?.Category?.Current}</span>{" "}
            response to pressure, this guest response to pressure will be
            replaced by the{" "}
            <span className="font-semibold">{item?.Category?.Current}</span>{" "}
            plant response to pressure from the parent plant.
          </p>
        }
        open={open}
      />
    </>
  );
}

function renderTrash(
  guest: PlantResponseToPressure,
  deleteFn: (data: PlantResponseToPressure) => void,
  isReadMode: boolean | undefined,
) {
  return guest.CanDelete && !isReadMode ? (
    <Trash
      className={twMerge(
        "text-xl text-darkGray hover:text-primary",
        isReadMode && "pointer-events-none text-grey",
      )}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        deleteFn(guest);
      }}
    />
  ) : null;
}
