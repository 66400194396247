import { createApiOperation } from "../api-client/create-api-operation";

import {
  Dataset,
  DatasetDetails,
  Datasets as DatasetsType,
} from "./types/datasets";

export const Datasets = {
  list: createApiOperation<DatasetsType, "/Api/Datasets", "get">(
    "/Api/Datasets",
    "get",
  ),

  create: createApiOperation<Dataset, "/Api/Datasets", "post">(
    "/Api/Datasets",
    "post",
  ),

  update: createApiOperation<Dataset, "/Api/Datasets/{datasetId}", "put">(
    "/Api/Datasets/{datasetId}",
    "put",
  ),

  details: createApiOperation<
    DatasetDetails,
    "/Api/Datasets/{datasetId}",
    "get"
  >("/Api/Datasets/{datasetId}", "get"),

  assignRegion: createApiOperation<
    never,
    "/Api/Datasets/{datasetId}/Region/{regionId}",
    "patch"
  >("/Api/Datasets/{datasetId}/Region/{regionId}", "patch"),

  unassignRegion: createApiOperation<
    never,
    "/Api/Datasets/{datasetId}/Region/{regionId}",
    "delete"
  >("/Api/Datasets/{datasetId}/Region/{regionId}", "delete"),
};
