import { useMutation } from "@tanstack/react-query";

import { Auth } from "../../api/auth";
import { MutationKey } from "../../consts/mutation-keys";
import { useApiSuccess } from "../../hooks/useApiSuccess";
import { ApiHookConfig } from "../../types/api-hook-config";

export const useLogout = ({ onSuccess }: ApiHookConfig) => {
  const { handleSuccess } = useApiSuccess({ onSuccessFn: onSuccess });
  const mutationFn = () => Auth.logout();

  const {
    mutate: logout,
    isError,
    isPending,
  } = useMutation({
    mutationKey: [MutationKey.Logout],
    mutationFn: mutationFn,
    onSuccess: handleSuccess,
  });

  return { logout, isPending, isError };
};
