import { Link, useMatch } from "react-router-dom";

import { Input } from "../../api/types/inputs";
import { Plant } from "../../api/types/plants";
import { Access } from "../../consts/access";

import { renderBreadcrumb } from "./render-breadcrumb";

export type BreadcrumbProps = {
  data: Plant | Input | undefined;
};

export function Breadcrumb({ data }: BreadcrumbProps) {
  const matchPerennial = useMatch("/dashboard/perennial/:datasetId/:id");
  const matchAnnual = useMatch("/dashboard/annual/:datasetId/:id");

  const basePath = matchPerennial
    ? "/dashboard/perennial"
    : matchAnnual
      ? "/dashboard/annual"
      : "/dashboard/input";

  const ancestors = (data?.Ancestors || []).map(item =>
    item.AccessType !== Access.None ? (
      <Link
        key={`${item.Id}_${item.FullName}`}
        className="hover:text-primary hover:font-bold"
        to={`${basePath}/${data?.Dataset?.Id}/${item.Id}`}>
        {item.FullName}
      </Link>
    ) : (
      <div className="inline-block text-grey">{item.FullName}</div>
    ),
  );

  ancestors.unshift(
    <Link
      key={`dashboard_${data?.Dataset?.Id}_${data?.Dataset?.Name}`}
      className="hover:text-primary hover:font-bold"
      to="/dashboard">
      Dashboard
    </Link>,
    <span key={`dataset_${data?.Dataset?.Id}_${data?.Dataset?.Name}`}>
      {data?.Dataset?.Name}
    </span>,
  );

  ancestors.push(
    <span
      key={`currentItem_${data?.Id}_${data?.FullName}`}
      className="text-primary">
      {data?.FullName}
    </span>,
  );

  return renderBreadcrumb(ancestors);
}
