import { User } from "../../components/icons/User";
import { useAuth } from "../../hooks/useAuth";

export function UserInfo() {
  const { user } = useAuth();
  return (
    <div className="w-full flex flex-row gap-4 items-center">
      <div className="text-darkGray">
        <User className="text-darkGray text-6xl" />
      </div>
      <div>
        <h2 className="text-xl font-bold">
          {user?.FirstName + " " + user?.LastName}
        </h2>
        <span className="text-darkGray">{user?.Role}</span>
      </div>
    </div>
  );
}
