import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

import { NumberCafsValue } from "../../../../../../api/types/cafs";
import { useVegetationLayer } from "../../../../api/useVegetationLayer";

export function VegetationObserver() {
  const { vegetationLayers } = useVegetationLayer();
  const { watch, setValue, formState } = useFormContext();
  const watchedVegetationLayerId = watch("VegetationLayerId.Base");
  const MaxHeightM = watch("MaxHeightM");

  useEffect(() => {
    if (formState.isDirty && Object.keys(formState.touchedFields).length) {
      const vl = vegetationLayers?.find(
        vl => vl.Id === watchedVegetationLayerId,
      );
      setValue(
        "MaxHeightM",
        {
          ...(MaxHeightM as NumberCafsValue)!,
          Base: vl?.MaxHeightM,
        },
        { shouldDirty: false, shouldTouch: false, shouldValidate: false },
      );
    }
  }, [watchedVegetationLayerId]);

  return <></>;
}
