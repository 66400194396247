import * as yup from "yup";
import { number, string } from "yup";

import { PlantResponseToPressure } from "../../../../../api/types/plants";
import { CafsValueRemarksValidation } from "../../../../../consts/validation";
import { CafsForm } from "../../../../../types/cafs-form";

export const createPressureSchema = yup.object<
  Partial<CafsForm<keyof PlantResponseToPressure>>
>({
  TargetSuitability: yup.object({
    Base: string().required(),
  }),
  Category: yup.object({
    Base: string().required(),
  }),
  MaxEffect: yup.object({
    Base: number().required(),
  }),
  GeneralRemarks: CafsValueRemarksValidation(),
});
