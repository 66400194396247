import { ModalSize, MuiModal } from "../../../../components/mui/MuiModal";
import { useGuestCreate } from "../../api/useGuestCreate";

import { GuestCreate } from "./GuestCreate";

export type CreateProductModalProps = {
  open: boolean;
  onClose: () => void;
};

export function CreateGuestModal({ open, onClose }: CreateProductModalProps) {
  const handleClose = () => {
    clearApiErrors();
    onClose();
  };

  const { apiErrors, clearApiErrors, createGuest } = useGuestCreate({
    onSuccess: handleClose,
  });

  return (
    <MuiModal
      open={open}
      onClose={handleClose}
      size={ModalSize.Medium}
      header="Create guest">
      <GuestCreate
        onSubmit={createGuest}
        customErrors={apiErrors}
        onCancel={handleClose}
      />
    </MuiModal>
  );
}
