import React from "react";
import { useIntl } from "react-intl";

import { PageHeader } from "../../components/PageHeader";
import { PageSection } from "../../components/PageSection";

import { TooltipTabs } from "./components/TooltipTabs";

export function TooltipSettingsView() {
  const { formatMessage } = useIntl();
  return (
    <>
      <PageHeader
        text={formatMessage({
          id: "PAGE_TITLE.TITLE",
          description: "Tooltip settings page title",
          defaultMessage: "Tooltip settings",
        })}
      />
      <PageSection>
        <TooltipTabs />
      </PageSection>
    </>
  );
}
