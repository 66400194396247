import React from "react";
import { RouterProvider } from "react-router-dom";

import { AppContextProvider } from "./AppContext";
import { router } from "./router";

function App() {
  return (
    <AppContextProvider>
      <RouterProvider router={router} />
    </AppContextProvider>
  );
}

export default App;
