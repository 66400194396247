import { PlantLifeCycle } from "../../../../../api/types/plants";
import { DataFormat } from "../../../../../consts/data-format";
import { CafsVariableType } from "../../../const/cafs-variable-type";
import { CafsVariablesCollection } from "../../cafs-table/CafsTable";
import { renderForTree } from "../../cafs-table/utils/render-for-tree";

export function useLifecycleVariables(): CafsVariablesCollection<PlantLifeCycle> {
  return [
    {
      name: "LifeCycleRemarks",
      type: CafsVariableType.Remarks,
    },
    {
      name: "NaturalLifeCycleMonths",
      type: CafsVariableType.Input,
      format: DataFormat.Year,
    },
    {
      name: "EconomicLifeCycleMonths",
      type: CafsVariableType.Input,
      format: DataFormat.Year,
    },
    {
      name: "TreeThinningYears",
      type: CafsVariableType.Input,
      text: true,
      renderCondition: renderForTree,
    },
    {
      name: "TreeThinningFractions",
      type: CafsVariableType.Input,
      renderCondition: renderForTree,
      text: true,
    },
  ];
}
