import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Save } from "@mui/icons-material";
import { Button, Tooltip } from "@mui/material";

export type SaveBtnProps = {
  disabled?: boolean;
};

export function SaveBtn({ disabled, ...rest }: SaveBtnProps) {
  const { formatMessage } = useIntl();
  return (
    <Tooltip
      title={formatMessage({
        id: "TOOLTIPS.CAFS_SAVE_BTN",
        defaultMessage: "Save the new base values in the draft CAFS",
        description: "Save button in cafs table tooltip",
      })}>
      <div className="w-fit">
        <Button
          {...rest}
          role="button"
          type="submit"
          disabled={disabled}
          startIcon={<Save />}
          variant="contained"
          color="primary">
          <FormattedMessage
            id="APP.SAVE"
            defaultMessage="SAVE"
            description="General save text"
          />
        </Button>
      </div>
    </Tooltip>
  );
}
