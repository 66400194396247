import { useContext, useMemo } from "react";

import { CafsContext } from "../views/cafs-view/CafsContext";
import { CafsSection } from "../views/cafs-view/const/cafs-section";

export function useAssignedReferences<T>(
  variableName: keyof T | undefined,
  sectionName: CafsSection,
) {
  const { assignedReferences } = useContext(CafsContext);

  const data = useMemo(() => {
    return (assignedReferences?.ReferencesTo || []).filter(
      r => r.FieldName === `${sectionName}.${variableName as string}`,
    );
  }, [variableName, assignedReferences, sectionName]);

  return {
    assignedReferences: data,
  };
}
