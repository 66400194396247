import { useMutation } from "@tanstack/react-query";

import { References } from "../../../api/references";
import { Reference } from "../../../api/types/references";
import { MutationKey } from "../../../consts/mutation-keys";
import { QueryKey } from "../../../consts/query-keys";
import { useApiError } from "../../../hooks/useApiError";
import { useApiSuccess } from "../../../hooks/useApiSuccess";
import { ApiHookConfig } from "../../../types/api-hook-config";

export function useReferenceCreate({ onSuccess }: ApiHookConfig) {
  const { handleSuccess } = useApiSuccess({
    snackbarText: "Reference created.",
    invalidate: [[QueryKey.References], [QueryKey.AllReferencesList]],
    onSuccessFn: onSuccess,
  });

  const { handleError, apiErrors, clearApiErrors } = useApiError();

  const mutationFn = (data: Partial<Reference>) =>
    References.create(undefined, data);

  const { mutate: createReference, isPending } = useMutation({
    mutationKey: [MutationKey.CreateReference],
    mutationFn: mutationFn,
    onSuccess: handleSuccess,
    onError: handleError,
    retry: 0,
  });

  return { createReference, isPending, apiErrors, clearApiErrors };
}
