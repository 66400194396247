import { createApiOperation } from "../api-client/create-api-operation";

import { Token } from "./types/token";

export const Auth = {
  login: createApiOperation<Token, "/Api/Login/Login", "post">(
    "/Api/Login/Login",
    "post",
  ),
  logout: createApiOperation<never, "/Api/Login/Logout", "delete">(
    "/Api/Login/Logout",
    "delete",
  ),
  refreshToken: createApiOperation<Token, "/Api/Login/RefreshToken", "post">(
    "/Api/Login/RefreshToken",
    "post",
  ),
};
