import { StringCafsValue } from "../../../../../../api/types/cafs";
import { InputDefinition } from "../../../../../../api/types/inputs";

export const rusleFactorFn = (
  values: Record<keyof InputDefinition, StringCafsValue>,
): number => {
  const ruslePFactorValue = parseFloat(
    values.RuslePFactor?.Base ?? values.RuslePFactor?.Current ?? "0",
  );
  const rusleCFactorValue = parseFloat(
    values.RusleCFactor?.Base ?? values.RusleCFactor?.Current ?? "0",
  );

  return ruslePFactorValue * rusleCFactorValue;
};
