import { createApiOperation } from "../api-client/create-api-operation";

import { Products as ProductsType } from "./types/products";

export const Products = {
  get: createApiOperation<ProductsType, "/Api/Products", "get">(
    "/Api/Products",
    "get",
  ),

  create: createApiOperation<never, "/Api/Products", "post">(
    "/Api/Products",
    "post",
  ),

  update: createApiOperation<never, "/Api/Products/{productId}", "put">(
    "/Api/Products/{productId}",
    "put",
  ),

  updatePrice: createApiOperation<
    never,
    "/Api/Prices/{regionId}/{productId}",
    "put"
  >("/Api/Prices/{regionId}/{productId}", "put"),
};
