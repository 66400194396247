import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { plainSearchParams } from "utils/plain-search-params";

import { SortingInfo } from "../types/sorting-info";

export type UseSortingProps = SortingInfo & { prefix?: string };
export function useSorting({
  order: defaultOrder,
  orderBy: defaultOrderBy,
  prefix,
}: UseSortingProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  return useMemo(() => {
    const order = getPrefixedParameter(
      prefix ?? null,
      searchParams,
      "order",
      defaultOrder!,
    );
    const orderBy = getPrefixedParameter(
      prefix ?? null,
      searchParams,
      "orderBy",
      defaultOrderBy,
    );

    const handleSortingChange = ({ orderBy: property }: SortingInfo) => {
      const isAsc = prefix
        ? orderBy === `${prefix}_${property}` && order === "Ascending"
        : orderBy === property && order === "Ascending";
      setSearchParams({
        ...plainSearchParams(searchParams),
        ...createPrefixedParameters(
          prefix ?? null,
          isAsc ? "Descending" : "Ascending",
          property,
        ),
      });
    };

    return { sorting: { order, orderBy } as SortingInfo, handleSortingChange };
  }, [searchParams, setSearchParams, defaultOrder, defaultOrderBy, prefix]);
}

export function getPrefixedParameter(
  prefix: string | null,
  searchParams: URLSearchParams,
  parameter: "order" | "orderBy",
  defaultValue: string,
): string {
  if (!prefix) {
    return searchParams.get(parameter) ?? defaultValue;
  }
  return searchParams.get(prefix + "_" + parameter) ?? defaultValue;
}

function createPrefixedParameters(
  prefix: string | null,
  order: string,
  orderBy: string,
): Record<string, string> {
  return prefix
    ? { [`${prefix}_order`]: order, [`${prefix}_orderBy`]: orderBy }
    : { order, orderBy };
}
