import { useQuery } from "@tanstack/react-query";

import { Tooltips } from "../../../api/tooltips";
import { QueryKey } from "../../../consts/query-keys";

export type TooltipItem = {
  Name: string;
  Description: string | null;
  Unit: string | null;
};

export type CategoryMap = {
  [category: string]: CategoryValue;
};

export type CategoryValue = {
  [property: string]: Omit<TooltipItem, "Name">;
};

export function useFetchTooltips() {
  const queryFn = () =>
    Tooltips.list({ query: { all: true } }).then(res =>
      createCategoryMap(res.Tooltips as TooltipItem[]),
    );

  function createCategoryMap(tooltipItems: TooltipItem[]): CategoryMap {
    const categoryMap: CategoryMap = {};

    tooltipItems.forEach((item: TooltipItem) => {
      const [category, property] = item.Name.split(".");
      if (!categoryMap[category]) {
        categoryMap[category] = {};
      }
      categoryMap[category][property] = {
        Description: item.Description,
        Unit: item.Unit,
      };
    });

    return categoryMap;
  }

  const { data, isPending } = useQuery({
    queryKey: [QueryKey.Tooltips],
    queryFn: queryFn,
  });

  return { data, isPending };
}
