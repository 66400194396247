import { createApiOperation } from "../api-client/create-api-operation";

import { Regions as RegionsType } from "./types/regions";

export const Regions = {
  list: createApiOperation<RegionsType, "/Api/Regions", "get">(
    "/Api/Regions",
    "get",
  ),
};
