import { ReactNode } from "react";

import { DataFormat } from "../../consts/data-format";
import { TextWithEllipsis } from "../TextWithEllipsis";

export type FormulaProps = {
  format?: DataFormat;
  value: number | string | boolean | null | undefined;
  fractionDigits?: number;
  charactersLimit?: number;
};

export function Formula({
  format,
  value,
  fractionDigits = 4,
  charactersLimit,
}: FormulaProps): ReactNode {
  if (value === null || value === undefined) {
    return null;
  }

  const [, fractionalPart] = value?.toString().split(".");

  if (format === DataFormat.Percent) {
    return value ? `${value}%` : null;
  }

  if (format === DataFormat.Boolean || typeof value === "boolean") {
    return value ? `Yes` : "No";
  }

  if (typeof value === "string") {
    return charactersLimit ? (
      <TextWithEllipsis text={String(value)} limit={charactersLimit} />
    ) : (
      String(value)
    );
  }

  if (format === DataFormat.Year) {
    return value ? `${(+value / 12).toFixed(2)}` : null;
  }

  if (format === DataFormat.Hour) {
    return value ? `${(+value / 8).toFixed(2)}` : null;
  }

  if (fractionalPart && fractionalPart.length > 4) {
    return parseFloat((+value as number).toFixed(fractionDigits));
  }

  return value;
}
