import * as React from "react";
import { useEffect } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { twMerge } from "tailwind-merge";

import { Edit } from "../../../components/icons/Edit";
import { Save } from "../../../components/icons/Save";
import { Xmark } from "../../../components/icons/Xmark";
import { RhfFormField } from "../../../components/rhf/RhfFormField";
import { RhfInput } from "../../../components/rhf/RhfInput";
import { useApiValidation } from "../../../hooks/useApiValidation";
import { ApiValidationErrors } from "../../../types/api-error";
import { PriceItem } from "../../../types/price-item";
import { PriceValue } from "../../../types/price-value";
import { formatNumber } from "../../../utils/format-number";

import { priceSchema } from "./ValidationSchema";

export type PriceFormProps = {
  item: PriceItem;
  data: PriceValue;
  onEdit: (name: string) => void;
  onCancel: () => void;
  onSubmit: (data: {
    HighPricePKg?: number | undefined | null;
    PriceLowOfHigh?: number | undefined | null;
  }) => void;
  editedItem:
    | { column: string; row: number; type: "world" | "region" }
    | null
    | undefined;
  type: "world" | "region";
  apiErrors: ApiValidationErrors;
};
export function PriceForm({
  onEdit,
  item,
  editedItem,
  onCancel,
  onSubmit,
  type,
  data,
  apiErrors,
}: PriceFormProps) {
  const methods = useForm<FieldValues>({ resolver: yupResolver(priceSchema) });

  useEffect(() => {
    methods.reset(data);
  }, [data]);

  useApiValidation(apiErrors, methods);

  const handleSubmit = (data: {
    HighPricePKg?: number | undefined | null;
    PriceLowOfHigh?: number | undefined | null;
  }) => {
    onSubmit(data);
  };

  const handleEdit = (value: string) => {
    onEdit(value);
    methods.clearErrors();
    methods.reset(data);
  };

  return (
    <FormProvider {...methods}>
      <form className="grid grid-cols-2 ">
        <div className="col-span-1 items-center">
          <div className="flex flex-row items-center justify-end h-full">
            {editedItem?.column === `HighPricePKg` &&
            editedItem.row === item.productId &&
            type === editedItem.type ? (
              <div className="flex flex-nowrap items-center gap-2 w-full pl-8">
                <div
                  className={twMerge(
                    "w-full",
                    !!methods.getFieldState("HighPricePKg").error && "py-4",
                  )}>
                  <RhfFormField name={`HighPricePKg`}>
                    <RhfInput
                      type="number"
                      focusOnError
                      name={`HighPricePKg`}
                      label="HighPricePKg"
                    />
                  </RhfFormField>
                </div>
                <div className="flex flex-row gap-2 flex-initial">
                  <Save
                    className="text-xl text-darkGray hover:text-primary block cursor-pointer"
                    onClick={methods.handleSubmit(handleSubmit)}
                  />
                  <Xmark
                    className="text-xl text-darkGray hover:text-red-500 block cursor-pointer"
                    onClick={() => onCancel()}
                  />
                </div>
              </div>
            ) : (
              <div className="flex flex-row items-center justify-end gap-2">
                <p>
                  {item[type].HighPricePKg
                    ? formatNumber(item[type].HighPricePKg!, {
                        minimumFractionDigits: 4,
                      })
                    : null}
                </p>
                <div className="flex flex-row flex-nowrap">
                  <Edit
                    className="text-darkGray hover:text-primary text-xl cursor-pointer"
                    onClick={() => handleEdit("HighPricePKg")}
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="col-span-1 items-center">
          <div className="flex flex-row items-center justify-end h-full gap-2">
            {editedItem?.column === `PriceLowOfHigh` &&
            editedItem.row === item.productId &&
            type === editedItem.type ? (
              <div className="flex flex-nowrap items-center gap-2 w-full pl-8">
                <div
                  className={twMerge(
                    "w-full",
                    !!methods.getFieldState("PriceLowOfHigh").error && "py-4",
                  )}>
                  <RhfFormField name={`PriceLowOfHigh`}>
                    <RhfInput
                      type="number"
                      focusOnError
                      name={`PriceLowOfHigh`}
                      label="PriceLowOfHigh"
                    />
                  </RhfFormField>
                </div>
                <div className="flex flex-row gap-2 flex-initial item">
                  <Save
                    className="text-xl text-darkGray hover:text-primary cursor-pointer"
                    onClick={methods.handleSubmit(handleSubmit)}
                  />
                  <Xmark
                    className="text-xl text-darkGray hover:text-red-500 cursor-pointer"
                    onClick={() => onCancel()}
                  />
                </div>
              </div>
            ) : (
              <>
                <p>
                  {item[type].PriceLowOfHigh
                    ? formatNumber(item[type].PriceLowOfHigh!, {
                        minimumFractionDigits: 4,
                      })
                    : null}
                </p>
                <div className="flex flex-row flex-nowrap">
                  <Edit
                    className="text-darkGray text-xl hover:text-primary cursor-pointer"
                    onClick={() => handleEdit("PriceLowOfHigh")}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
