import { useEffect } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import { Alert } from "@mui/material";

import { InputLabour } from "../../../../../api/types/inputs";
import { useApiValidation } from "../../../../../hooks/useApiValidation";
import { useFetchData } from "../../../../../hooks/useFetchData";
import { CafsForm } from "../../../../../types/cafs-form";
import { SectionProps } from "../../../../../types/section-props";
import { useInputLabourData } from "../../../api/useInputLabourData";
import { useInputLabourUpdate } from "../../../api/useInputLabourUpdate";
import { CafsSection } from "../../../const/cafs-section";
import { useSectionSaveDisabled } from "../../../hooks/useSectionSaveDisabled";
import { CafsTable } from "../../cafs-table/CafsTable";
import { renderVariableRow } from "../../cafs-table/render-variable-row";
import { mapInitialValues } from "../../cafs-table/utils/map-initial-values";
import { mapSubmitValues } from "../../cafs-table/utils/map-submit-values";
import { VegetationObserver } from "../growth/utils/vegetation-observer";
import { SectionRemarks } from "../SectionRemarks";

import { inputLabourSchema } from "./input-labour-schema";
import { useInputlabourVariables } from "./useInputLabourVariables";

export function InputLabourSection({ expanded }: SectionProps) {
  const variables = useInputlabourVariables();

  const { datasetId, id } = useParams();
  const { shouldFetchData } = useFetchData(expanded);

  const { inputLabourData, isPending } = useInputLabourData(
    datasetId!,
    id!,
    shouldFetchData,
  );
  const { updateLabour, apiErrors } = useInputLabourUpdate({
    onSuccess: () => {},
  });

  const handleLabourUpdate = (data: Partial<CafsForm<InputLabour>>) => {
    updateLabour(mapSubmitValues(data));
  };

  const methods = useForm<FieldValues>({
    resolver: yupResolver(inputLabourSchema),
    mode: "onChange",
  });

  useEffect(() => {
    if (inputLabourData) {
      methods.reset(mapInitialValues(inputLabourData, variables));
    }
  }, [inputLabourData]);

  useApiValidation(apiErrors, methods);

  const { disabled } = useSectionSaveDisabled(methods, isPending);

  return inputLabourData && !isPending ? (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleLabourUpdate)}>
        <SectionRemarks<InputLabour>
          name="LabourRemarks"
          data={inputLabourData}
        />
        <CafsTable<InputLabour>
          name={CafsSection.InputLabour}
          variables={variables}
          render={renderVariableRow}
          data={inputLabourData ?? {}}
          disabled={disabled}
        />
        <VegetationObserver />
      </form>
    </FormProvider>
  ) : (
    <div className="w-full">
      <Alert severity="info">Loading data</Alert>
    </div>
  );
}
