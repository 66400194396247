import React from "react";

import { Alert, Snackbar } from "@mui/material";

import { SnackbarMessage } from "../hooks/useSnacbarManager";

type SnackbarComponentProps = {
  snackbar: SnackbarMessage | null;
  onClose: () => void;
};

export const SnackbarComponent: React.FC<SnackbarComponentProps> = ({
  snackbar,
  onClose,
}) => {
  return (
    <Snackbar
      open={snackbar !== null}
      autoHideDuration={5000}
      onClose={onClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      sx={{
        maxWidth: "97%",
        mr: "0.5%",
      }}>
      {snackbar ? (
        <Alert
          onClose={onClose}
          severity={snackbar.type}
          sx={{ width: "100%" }}>
          {snackbar.message}
        </Alert>
      ) : undefined}
    </Snackbar>
  );
};
