import { useQuery } from "@tanstack/react-query";

import { Plants } from "../../../api/plants";
import { QueryKey } from "../../../consts/query-keys";

export const useRootsData = (
  datasetId: string,
  id: string,
  shouldFetchData: boolean,
) => {
  const { data: rootsData, isPending } = useQuery({
    queryKey: [QueryKey.RootsSection, datasetId, id],
    queryFn: () =>
      Plants.getRoots({
        path: { datasetId: +datasetId!, plantId: +id! },
      }),
    throwOnError: true,
    enabled: shouldFetchData,
  });

  return { rootsData, isPending };
};
