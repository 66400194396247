import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import { useMutation } from "@tanstack/react-query";

import { Plants } from "../../../api/plants";
import { CafsVariableCollection } from "../../../api/types/cafs";
import { PlantResponseToPressure } from "../../../api/types/plants";
import { MutationKey } from "../../../consts/mutation-keys";
import { QueryKey } from "../../../consts/query-keys";
import { useApiError } from "../../../hooks/useApiError";
import { useApiSuccess } from "../../../hooks/useApiSuccess";
import { ApiHookConfig } from "../../../types/api-hook-config";

export const usePlantPressureUpdate = ({ onSuccess }: ApiHookConfig) => {
  const { datasetId, id } = useParams();
  const { formatMessage } = useIntl();

  const { handleError, apiErrors, clearApiErrors } = useApiError();
  const { handleSuccess } = useApiSuccess({
    invalidate: [[QueryKey.PlantRtp, datasetId!, id!], [QueryKey.CafsDetails]],
    snackbarText: formatMessage({
      id: "ACTIONS.PLANT_PRESSURE_UPDATE_SUCCESS",
      defaultMessage: "Response to pressure updated successfully.",
      description: "Response to pressure update success message",
    }),
    onSuccessFn: onSuccess,
  });

  const mutationFn = ({
    data,
    plantResponseToPressureId,
  }: {
    data: CafsVariableCollection<PlantResponseToPressure>;
    plantResponseToPressureId: number | undefined;
  }) =>
    Plants.updateResponsesToPressure(
      {
        path: {
          datasetId: +datasetId!,
          plantId: +id!,
          plantResponseToPressureId: plantResponseToPressureId!,
        },
      },
      data,
    );

  const { mutate: updateRtp, isPending } = useMutation({
    mutationKey: [MutationKey.UpdatePlantRtp],
    mutationFn: mutationFn,
    onSuccess: handleSuccess,
    onError: handleError,
  });

  return { updateRtp, isPending, apiErrors, clearApiErrors };
};
