import React, { forwardRef } from "react";

import {
  Button,
  ButtonOwnProps,
  LinearProgress,
  Typography,
} from "@mui/material";

import { Upload } from "./icons/Upload";

export type FileUploadProps = Pick<ButtonOwnProps, "variant" | "color"> & {
  onUpload: (file: File) => void;
  disabled: boolean;
  progress: number;
  isPending: boolean;
  status: string;
  text: string;
  accept?: ".csv" | ".json";
};

const FileUpload = forwardRef<HTMLInputElement, FileUploadProps>(
  (
    {
      onUpload,
      disabled,
      progress,
      isPending,
      status,
      text,
      variant = "contained",
      color = "primary",
      accept = ".csv",
    },
    ref,
  ) => {
    const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        onUpload(file);
      }
    };

    return (
      <div className="w-full">
        <div className="flex flex-row gap-4 items-center ">
          <Button
            variant={variant}
            disabled={disabled}
            color={color}
            startIcon={<Upload />}
            component="label">
            {text}
            <input
              ref={ref}
              type="file"
              hidden
              accept={accept}
              onChange={handleUpload}
            />
          </Button>
          {isPending && <p>{status}</p>}
        </div>
        {isPending && (
          <div className="mt-4">
            <LinearProgress variant="determinate" value={progress} />
            <Typography variant="body2" color="textSecondary">
              {`Upload progress: ${progress.toFixed(2)}%`}
            </Typography>
          </div>
        )}
      </div>
    );
  },
);

export default FileUpload;
