import { createApiOperation } from "../api-client/create-api-operation";

import {
  Input,
  InputAudits,
  InputCost,
  InputDefinition,
  InputGuest,
  InputGuests,
  InputLabour,
} from "./types/inputs";

export const Inputs = {
  getDetails: createApiOperation<
    Input,
    "/Api/Inputs/{datasetId}/{inputId}",
    "get"
  >("/Api/Inputs/{datasetId}/{inputId}", "get"),

  updateDetails: createApiOperation<
    never,
    "/Api/Inputs/{datasetId}/{inputId}",
    "put"
  >("/Api/Inputs/{datasetId}/{inputId}", "put"),

  create: createApiOperation<
    Input,
    "/Api/Inputs/{datasetId}/{inputId}",
    "post"
  >("/Api/Inputs/{datasetId}/{inputId}", "post"),

  createRoot: createApiOperation<Input, "/Api/Inputs/{datasetId}", "post">(
    "/Api/Inputs/{datasetId}",
    "post",
  ),

  delete: createApiOperation<
    never,
    "/Api/Inputs/{datasetId}/{inputId}",
    "delete"
  >("/Api/Inputs/{datasetId}/{inputId}", "delete"),

  approve: createApiOperation<
    never,
    "/Api/Inputs/{datasetId}/{inputId}/Approve",
    "patch"
  >("/Api/Inputs/{datasetId}/{inputId}/Approve", "patch"),

  reject: createApiOperation<
    never,
    "/Api/Inputs/{datasetId}/{inputId}/Reject",
    "patch"
  >("/Api/Inputs/{datasetId}/{inputId}/Reject", "patch"),

  datasetAssign: createApiOperation<
    never,
    "/Api/Inputs/{datasetId}/{inputId}/Assign",
    "patch"
  >("/Api/Inputs/{datasetId}/{inputId}/Assign", "patch"),

  datasetUnassign: createApiOperation<
    never,
    "/Api/Inputs/{datasetId}/{inputId}/Unassign",
    "patch"
  >("/Api/Inputs/{datasetId}/{inputId}/Unassign", "patch"),

  definition: createApiOperation<
    InputDefinition,
    "/Api/Inputs/{datasetId}/{inputId}/Definition",
    "get"
  >("/Api/Inputs/{datasetId}/{inputId}/Definition", "get"),

  definitionUpdate: createApiOperation<
    never,
    "/Api/Inputs/{datasetId}/{inputId}/Definition",
    "put"
  >("/Api/Inputs/{datasetId}/{inputId}/Definition", "put"),

  cost: createApiOperation<
    InputCost,
    "/Api/Inputs/{datasetId}/{inputId}/Cost",
    "get"
  >("/Api/Inputs/{datasetId}/{inputId}/Cost", "get"),

  costUpdate: createApiOperation<
    never,
    "/Api/Inputs/{datasetId}/{inputId}/Cost",
    "put"
  >("/Api/Inputs/{datasetId}/{inputId}/Cost", "put"),

  labour: createApiOperation<
    InputLabour,
    "/Api/Inputs/{datasetId}/{inputId}/Labour",
    "get"
  >("/Api/Inputs/{datasetId}/{inputId}/Labour", "get"),

  updateLabour: createApiOperation<
    never,
    "/Api/Inputs/{datasetId}/{inputId}/Labour",
    "put"
  >("/Api/Inputs/{datasetId}/{inputId}/Labour", "put"),

  getHistory: createApiOperation<
    InputAudits,
    "/Api/Audits/Input/{inputId}",
    "get"
  >("/Api/Audits/Input/{inputId}", "get"),

  guests: createApiOperation<
    InputGuests,
    "/Api/Inputs/{datasetId}/{inputId}/Guests",
    "get"
  >("/Api/Inputs/{datasetId}/{inputId}/Guests", "get"),

  createGuest: createApiOperation<
    InputGuest,
    "/Api/Inputs/{datasetId}/{inputId}/Guest",
    "post"
  >("/Api/Inputs/{datasetId}/{inputId}/Guest", "post"),

  updateGuest: createApiOperation<
    never,
    "/Api/Inputs/{datasetId}/{inputId}/Guest/{guestId}",
    "put"
  >("/Api/Inputs/{datasetId}/{inputId}/Guest/{guestId}", "put"),

  deleteGuest: createApiOperation<
    never,
    "/Api/Inputs/{datasetId}/{inputId}/Guest/{guestId}",
    "delete"
  >("/Api/Inputs/{datasetId}/{inputId}/Guest/{guestId}", "delete"),

  copy: createApiOperation<
    Input,
    "/Api/Inputs/{datasetId}/{inputId}/Copy",
    "post"
  >("/Api/Inputs/{datasetId}/{inputId}/Copy", "post"),
};
