import { useParams } from "react-router-dom";

import { useQuery } from "@tanstack/react-query";

import { References } from "../../../api/references";
import { QueryKey } from "../../../consts/query-keys";

export const useAllReferencesList = () => {
  const { datasetId, id } = useParams();

  const queryFn = () => References.listAll();

  const {
    data: allReferencesList,
    isPending,
    isFetching,
  } = useQuery({
    queryKey: [QueryKey.AllReferencesList, datasetId, id],
    queryFn: queryFn,
    staleTime: Infinity,
  });

  return { allReferencesList, isPending, isFetching };
};
