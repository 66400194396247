import { ModalSize, MuiModal } from "../../components/mui/MuiModal";

import { useReferenceCreate } from "./api/useAddReference";
import { ReferencesForm } from "./ReferencesForm";

export type CreateProductModalProps = {
  open: boolean;
  onClose: () => void;
};

export function CreateReferenceModal({
  open,
  onClose,
}: CreateProductModalProps) {
  const handleClose = () => {
    clearApiErrors();
    onClose();
  };

  const { apiErrors, clearApiErrors, createReference } = useReferenceCreate({
    onSuccess: handleClose,
  });

  return (
    <MuiModal
      open={open}
      onClose={handleClose}
      size={ModalSize.Big}
      header="Create reference">
      <ReferencesForm onSubmit={createReference} apiErrors={apiErrors} />
    </MuiModal>
  );
}
