import React, { useContext, useTransition } from "react";
import { FormattedMessage } from "react-intl";

import { Alert, CircularProgress } from "@mui/material";
import { SimpleTreeView } from "@mui/x-tree-view";

import { SearchBar } from "../../../components/SearchBar";
import { CafsType } from "../../../consts/cafsType";
import { LocalStorageKey } from "../../../consts/local-storage-key";
import { useDataSetDetails } from "../api/useDatasetDetails";
import { DashboardContext } from "../DashboardContext";
import { expandTreeNodes } from "../utils/expand-tree-nodes";
import { findNodes } from "../utils/find-nodes";

import { CafsExport } from "./CafsExport";
import { CafsTreeNode } from "./CafsTreeNode";
import { FiltersBar } from "./FiltersBar";
import { NoResult } from "./NoResult";

export function CafsTree({ ...props }) {
  const { selectedDataset } = useContext(DashboardContext);
  const { cafsTree = { total: 0, items: [] }, isLoading } =
    useDataSetDetails(selectedDataset);

  const {
    expandedNodes,
    activeFilter,
    handleFilterChange,
    handleTreeExpand,
    handleSearch,
    searchTerm,
  } = useContext(DashboardContext);

  const [isFilterPending, startFilterTransition] = useTransition();

  const handleNodeToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
    event.persist();
    let iconClicked = (event.target as Element).closest(
      ".MuiTreeItem-iconContainer",
    );
    if (iconClicked) {
      handleTreeExpand(nodeIds);
    }
  };

  const handleNodesSearch = (value: string) => {
    if (value.length) {
      handleFilterChange(null);
      startFilterTransition(() => {
        const paths = findNodes(cafsTree.items, value);
        const newExpandedNodes = Array.from(new Set(paths.flat()));
        handleTreeExpand(newExpandedNodes.length ? newExpandedNodes : null);
        handleSearch(value);
        localStorage.setItem(LocalStorageKey.SearchTerm, value);
      });
    } else {
      handleSearch("");
      handleTreeExpand([]);
      localStorage.removeItem(LocalStorageKey.SearchTerm);
      localStorage.removeItem(LocalStorageKey.ExpandedNodes);
    }
  };

  const applyFilter = (type: CafsType, filterId: number) => {
    handleSearch("");
    if (activeFilter && filterId === +activeFilter) {
      startFilterTransition(() => {
        handleTreeExpand([]);
        handleFilterChange(null);
        handleSearch("");
      });
    } else {
      startFilterTransition(() => {
        handleSearch("");
        const paths = expandTreeNodes(cafsTree.items, type);
        const newExpandedNodes = Array.from(new Set(paths.flat()));
        handleTreeExpand(newExpandedNodes.length ? newExpandedNodes : null);
        handleFilterChange(filterId);
      });
    }
  };

  const renderTreeViewOrLoading = () => {
    if (isLoading) {
      return <CircularProgress color="primary" />;
    }

    if (isFilterPending) {
      return <CircularProgress />;
    }

    if (expandedNodes) {
      return (
        <SimpleTreeView
          expandedItems={expandedNodes}
          onExpandedItemsChange={handleNodeToggle}
          disableSelection>
          <CafsTreeNode treeNodes={cafsTree.items} searchTerm={searchTerm} />
        </SimpleTreeView>
      );
    }

    return <NoResult />;
  };

  return (
    <div {...props}>
      <div className="flex flex-row justify-between items-center gap-4 mb-8">
        <h2 className="text-2xl font-bold">
          <FormattedMessage
            id="DASHBOARD.SELECT_CAFS"
            description="CAFS selection section title on dashboard page"
            defaultMessage="CAFS"
          />
          {!isLoading && cafsTree.total && selectedDataset?.Id ? (
            <span className="ml-2 text-primary">({cafsTree.total})</span>
          ) : null}
        </h2>
        <CafsExport />
      </div>

      {!selectedDataset && !isLoading && (
        <Alert severity="info" className="w-full">
          <FormattedMessage
            id="DASHBOARD.NO_DATASET_MSG"
            description="Message displayed instead of cafs tree where there is no dataset selected"
            defaultMessage="Please select dataset to see CAFS."
          />
        </Alert>
      )}

      {selectedDataset && (
        <div className="w-full" data-testid="cafs-tree">
          <div className="mb-8">
            <FiltersBar onApply={applyFilter} className="mb-4" />
            <SearchBar initialValue={searchTerm} onChange={handleNodesSearch} />
          </div>
          <div className="mt-4">{renderTreeViewOrLoading()}</div>
        </div>
      )}
    </div>
  );
}
