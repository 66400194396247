export enum MutationKey {
  "ApproveDraft" = "ApproveDraft",
  "RejectDraft" = "RejectDraft",
  "SaveGrowthSection" = "SaveGrowthSection",
  "SaveLifeCycleSection" = "SaveLifeCycleSection",
  "SaveWoodDensitySection" = "SaveWoodDensitySection",
  "SaveBiomassAllocationSection" = "SaveBiomassAllocationSection",
  "SaveBiodiversity" = "SaveBiodiversity",
  "SaveBiomassProductionSection" = "SaveBiomassProductionSection",
  "SaveGrowthConditionsSection" = "SaveGrowthConditionsSection",
  "SaveRootsSection" = "SaveRootsSection",
  "SaveConstraintsSection" = "SaveConstraintsSection",
  "SaveCostsSection" = "SaveCostsSection",
  "SaveLabourSection" = "SaveLabourSection",
  "CreatePlant" = "CreatePlant",
  "CopyCafs" = "CopyCafs",
  "CreateInput" = "CreateInput",
  "DeletePlant" = "DeletePlant",
  "UploadDataset" = "UploadDataset",
  "UploadJson" = "UploadJson",
  "UpdatePlantDetails" = "UpdatePlantDetails",
  "UpdateInputDetails" = "UpdatePlantDetails",
  "UpdatePlantProduct" = "UpdatePlantProduct",
  "UpdatePlantGuest" = "UpdatePlantGuest",
  "DeletePlantProduct" = "DeletePlantProduct",
  "DeletePlantGuest" = "DeletePlantGuest",
  "CreatePlantProduct" = "CreatePlantProduct",
  "CreateCafsGuest" = "CreateCafsGuest",
  "CreatePlantRtp" = "CreatePlantRtp",
  "UpdatePlantRtp" = "UpdatePlantRtp",
  "UpdateDataset" = "UpdateDataset",
  "DeletePlantRtp" = "DeletePlantRtp",
  "CreateGuestRtp" = "CreateGuestRtp",
  "UpdateGuestRtp" = "UpdateGuestRtp",
  "DeleteGuestRtp" = "DeleteGuestRtp",
  "DatasetAssign" = "DatasetAssign",
  "RegionAssign" = "RegionAssign",
  "RegionUnassign" = "RegionUnassign",
  "InputDatasetAssign" = "InputDatasetAssign",
  "InputDatasetUnassign" = "InputDatasetUnassign",
  "Login" = "Login",
  "Logout" = "Logout",
  "DefinitionUpdate" = "DefinitionUpdate",
  "InputCostUpdate" = "InputCostUddate",
  "InputLabourUpdate" = "InputlabourUddate",
  "DeleteProduct" = "DeleteProduct",
  "CreateReference" = "CreateReference",
  "DeleteReference" = "DelteReference",
  "UpdateReference" = "UpdateReference",
  "CreateProduct" = "CreateProduct",
  "UpdatePrice" = "UpdatePrice",
  "Tooltips" = "tooltips",
  "ExportCafs" = "ExportCafs",
  "FetchFile" = "FetchFile",
  "DownloadJson" = "DownloadJson",
  "ReferencesTo" = "ReferencesTo",
  "AssignReferencesTo" = "AssignReferencesTo",
  "UnassignReferencesTo" = "UnassignReferencesTo",
  "ExportReferencesTo" = "ExportReferencesTo",
}
