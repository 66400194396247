import { Plant } from "../../../../../api/types/plants";

export const trimPlantData = (data: Record<keyof Plant, { Base: string }>) => {
  return {
    EnglishName: {
      Base: data.EnglishName?.Base?.trim(),
    },
    Specification: {
      Base: data.Specification?.Base?.trim(),
    },
    FormerRecName: {
      Base: data.FormerRecName?.Base?.trim(),
    },
    ScientificName: {
      Base: data.ScientificName?.Base?.trim(),
    },
    GeneralRemarks: {
      Base: data.GeneralRemarks?.Base?.trim(),
    },
  };
};
