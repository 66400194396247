import * as yup from "yup";

import { PlantGrowth } from "../../../../../api/types/plants";
import {
  CafsValueNumberValidation,
  CafsValueRemarksValidation,
} from "../../../../../consts/validation";
import { CafsForm } from "../../../../../types/cafs-form";

export const growthConditionsSchema = yup.object<CafsForm<keyof PlantGrowth>>({
  TranspOfPotEvTransp: CafsValueNumberValidation(true),
  MinimumMoistureSuitabilityToSurvive: CafsValueNumberValidation(true),
  NitrogenFix: CafsValueNumberValidation(),
  OptpH: CafsValueNumberValidation(),
  TolpHDeviation: CafsValueNumberValidation(),
  SalinityDamageECeMin: CafsValueNumberValidation(),
  SalinityDamageECeMax: CafsValueNumberValidation(),
  CoverEvapotranspirationReduction: CafsValueNumberValidation(true),
  AgroEcologicalZoneTemperatureId: CafsValueNumberValidation(),
  MinTempToSurviveC: CafsValueNumberValidation(),
  MaxDaysSurvivalTooLowTemp: CafsValueNumberValidation(),
  MaxTempToSurviveC: CafsValueNumberValidation(),
  MaxDaysSurvivalTooHighTemp: CafsValueNumberValidation(),
  MinDailyMaxTempToGrowC: CafsValueNumberValidation(),
  MinDailyMaxTempForOptimalGrowthC: CafsValueNumberValidation(),
  MaxDailyMaxTempForOptimalGrowthC: CafsValueNumberValidation(),
  MaxDailyMaxTempToGrowC: CafsValueNumberValidation(),
  MinDailyRadiationToGrowPerM2: CafsValueNumberValidation(),
  GrowthConditionsRemarks: CafsValueRemarksValidation(),
});
