import { LoadingPlaceholder } from "../../../../../components/LoadingPlaceholder";
import { SectionProps } from "../../../../../types/section-props";
import { usePlantPressureData } from "../../../api/usePlantPressureData";

import { PressuresList } from "./PressuresList";

export function PressureSection({ expanded }: SectionProps) {
  const { plantPressureData, isPending } = usePlantPressureData(expanded);

  return isPending ? (
    <LoadingPlaceholder />
  ) : (
    <PressuresList data={plantPressureData?.PlantResponseToPressures || []} />
  );
}
