import { useIntl } from "react-intl";

import { useMutation } from "@tanstack/react-query";

import { Synchronization } from "../../../api/synchronization";
import { MutationKey } from "../../../consts/mutation-keys";
import { useApiSuccess } from "../../../hooks/useApiSuccess";
import { useFetchError } from "../../../hooks/useFetchError";
import { ApiError } from "../../../types/api-error";

export type UseDatasetImportProps = {
  onProgress: (progress: number) => void;
  onUploadComplete: () => void;
};

export function useJsonUpload({
  onProgress,
  onUploadComplete,
}: UseDatasetImportProps) {
  const { formatMessage } = useIntl();
  const { handleSuccess } = useApiSuccess({
    snackbarText: formatMessage({
      id: "ACTION.JSON_UPLOAD_SUCCESS",
      defaultMessage: "JSON uploaded successfully",
      description: "JSON upload success",
    }),
  });

  const mutationFn = (data: FormData) => {
    return Synchronization.uploadJSON(
      undefined,
      data,
      onProgress,
      onUploadComplete,
    );
  };

  const {
    mutate: upload,
    isPending,
    isSuccess,
    error,
  } = useMutation({
    mutationKey: [MutationKey.UploadJson],
    mutationFn: mutationFn,
    onSuccess: handleSuccess,
  });

  useFetchError(error as unknown as ApiError);

  return { upload, isPending, isSuccess };
}
