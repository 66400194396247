import React from "react";

import { CafsTreeNodeType } from "../../../types/cafs-tree-node-type";

type CafsStatusProps = {
  node: CafsTreeNodeType;
};

export function CafsStatus({ node }: CafsStatusProps) {
  const shouldRender = node.status && node.status !== "Approved";

  return shouldRender ? (
    <div className="mr-1 rounded bg-orange-200 text-orange-600 px-2">
      <small>{node.status}</small>
    </div>
  ) : null;
}
