import React, { useState } from "react";
import { useIntl } from "react-intl";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";

import { Reference } from "../../api/types/references";
import { SearchBar } from "../../components/SearchBar";
import { ROWS_PER_PAGE } from "../../consts/rows-per-page";
import { useModal } from "../../hooks/useModal";
import { PageInfo } from "../../types/page-info";
import { SortingInfo } from "../../types/sorting-info";
import { emptyFn } from "../../utils/empty-fn";

import { DeleteReferenceModal } from "./DeleteReferenceModal";
import { EditReferenceModal } from "./EditReferenceModal";
import { ReferencesTableRow } from "./ReferencesTableRow";
import { TableColumnHeader } from "./TableColumnHeader";
import { useReferencesTableColumns } from "./useReferencesTableColumns";

export type ReferencesTableProps = {
  data: Reference[];
  pageInfo: PageInfo;
  loading: boolean;
  sortingInfo: SortingInfo;
  onSortingChange?: (data: SortingInfo) => void;
  onPaginationChange?: (data: PageInfo) => void;
  onSearchChange?: (value: string) => void;
  searchPhrase?: string;
  total: number | null | undefined;
};

export function ReferencesTableHeader({
  data,
  pageInfo,
  sortingInfo,
  searchPhrase,
  total,
  loading,
  onSortingChange = emptyFn,
  onPaginationChange = emptyFn,
  onSearchChange = emptyFn,
}: ReferencesTableProps) {
  const [selectedItem, setSelectedItem] = useState<
    Reference | null | undefined
  >();

  const { columns } = useReferencesTableColumns();
  const { formatMessage } = useIntl();
  const { open, handleOpen, handleClose } = useModal();
  const {
    open: deleteModalOpen,
    handleOpen: handleDeleteModalOpen,
    handleClose: handleDeleteModalClose,
  } = useModal();

  const handleEdit = (item: Reference) => {
    setSelectedItem(item);
    handleOpen();
  };

  const handleDelete = (item: Reference) => {
    setSelectedItem(item);
    handleDeleteModalOpen();
  };

  return (
    <>
      <div className="w-full mb-6 flex flex-row items-end justify-end">
        <div>
          <small>Total number of references: {total}</small>
        </div>
      </div>
      <div className="mb-8">
        <SearchBar
          initialValue={searchPhrase}
          onChange={value => onSearchChange(value)}
        />
      </div>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer>
          <Table data-testid={`ref}_table`}>
            <TableHead>
              <TableRow sx={{ backgroundColor: "005F3B" }}>
                {columns.map(column => (
                  <TableCell>
                    <TableColumnHeader<Reference>
                      column={column}
                      orderBy={sortingInfo.orderBy}
                      order={sortingInfo.order}
                      onClick={() =>
                        onSortingChange({
                          orderBy: column.key,
                          order: sortingInfo.order,
                        })
                      }
                    />
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {(data || [])?.map(reference => {
                return (
                  <ReferencesTableRow
                    item={reference}
                    onEdit={item => handleEdit(item)}
                    onDelete={item => handleDelete(item)}
                  />
                );
              })}

              {!loading && !data.length && (
                <TableCell colSpan={9}>
                  <div className="text-center">No items</div>
                </TableCell>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={ROWS_PER_PAGE}
            component="div"
            count={pageInfo.total}
            rowsPerPage={pageInfo.pageSize}
            page={pageInfo.page}
            onPageChange={(_, value) =>
              onPaginationChange({ ...pageInfo, page: value })
            }
            onRowsPerPageChange={e =>
              onPaginationChange({
                ...pageInfo,
                page: 0,
                pageSize: +e.target.value,
              })
            }
            labelDisplayedRows={({ from, to, count }) => {
              return formatMessage(
                {
                  id: "APP.PAGINATION_OF_DESKTOP_LABEL",
                  defaultMessage: "{from}-{to} of {count}",
                },
                { from, to, count },
              );
            }}
            labelRowsPerPage={formatMessage({
              id: "APP.PAGINATION.ROWS_PER_PAGE_DESKTOP_LABEL",
              defaultMessage: "Rows per page",
            })}
          />
        </TableContainer>
        <EditReferenceModal
          open={open}
          onClose={handleClose}
          item={selectedItem}
        />

        <DeleteReferenceModal
          open={deleteModalOpen}
          onClose={handleDeleteModalClose}
          reference={selectedItem}
        />
      </Paper>
    </>
  );
}
