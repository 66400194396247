import React from "react";

export type PageHeaderProps = {
  text: string;
  rightPart?: React.ReactNode;
};

export function PageHeader({ text, rightPart }: PageHeaderProps) {
  return rightPart ? (
    <div className="flex flex-row justify-between">
      <h2 className="text-4xl text-primary font-bold">{text}</h2>
      <div>{rightPart}</div>
    </div>
  ) : (
    <h2 className="text-4xl text-primary font-bold">{text}</h2>
  );
}
