export function getParamsByPrefix(
  prefix: string | null,
  data: URLSearchParams,
) {
  const params = Object.fromEntries(data.entries());

  if (!prefix) return params;

  return Object.keys(params)
    .filter(param => param.startsWith(prefix + "_"))
    .reduce(
      (obj, key) => {
        obj[key.split(`${prefix}_`)[1]] = params[key];
        return obj;
      },
      {} as typeof params,
    );
}
