import { useEffect } from "react";
import { UseFormReturn } from "react-hook-form";

import { ApiValidationErrors } from "../types/api-error";

export const useApiValidation = (
  customErrors: ApiValidationErrors | undefined,
  methods: UseFormReturn,
) => {
  useEffect(() => {
    if (customErrors) {
      Object.keys(customErrors).forEach(fieldName => {
        methods.setError(fieldName, {
          type: "manual",
          message: customErrors![fieldName][0],
        });
      });
    } else methods.clearErrors();
  }, [customErrors, methods]);
};
