import React, { useState } from "react";
import { useIntl } from "react-intl";

import { Button } from "@mui/material";

import { MuiInput } from "../../components/mui/MuiInput";
import { PageHeader } from "../../components/PageHeader";
import { PageSection } from "../../components/PageSection";

import { useProductCreate } from "./api/useAddProduct";
import { useFetchProducts } from "./api/useFetchProducts";
import { ProductsTable } from "./components/ProductsTable";

export function ProductsView() {
  const { formatMessage } = useIntl();

  const { products, isPending } = useFetchProducts();
  const [product, setProduct] = useState<string>("");

  const clearProduct = () => {
    setProduct("");
  };

  const { createProduct } = useProductCreate(clearProduct);

  return (
    <>
      <PageHeader
        text={formatMessage({
          id: "PRODUCTS.TITLE",
          defaultMessage: "Products",
          description: "Products page title",
        })}
      />

      <PageSection>
        <p className="mb-2">Create new product</p>
        <div className="w-full flex flex-row gap-4">
          <MuiInput
            placeholder="Product name"
            value={product}
            onChange={e => setProduct(e.target.value)}
          />
          <Button
            type="button"
            color="primary"
            variant="contained"
            disabled={!product?.length || isPending}
            onClick={() => {
              if (product?.length) return createProduct(product);
            }}>
            Create
          </Button>
        </div>
      </PageSection>

      <PageSection title="Products list">
        <ProductsTable data={products?.Products || []} />
      </PageSection>
    </>
  );
}
