import { useMatch, useParams } from "react-router-dom";

import { useQuery } from "@tanstack/react-query";

import { ReferencesTo } from "../../../api/references-to";
import { QueryKey } from "../../../consts/query-keys";

export const useReferencesAssignedList = () => {
  const { datasetId, id } = useParams();
  const matchInput = useMatch("/dashboard/input/:datasetId/:id");

  const queryFn = () =>
    ReferencesTo.list({
      query: matchInput ? { inputId: +id! } : { plantId: +id! },
    });

  const {
    data: assignedReferencesList,
    isPending,
    isFetching,
  } = useQuery({
    queryKey: [QueryKey.AssignedReferencesList, datasetId, id],
    queryFn: queryFn,
  });

  return { assignedReferencesList, isPending, isFetching };
};
