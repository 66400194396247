import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import { useMutation } from "@tanstack/react-query";

import { Datasets } from "../../../api/datasets";
import { PlantGuest } from "../../../api/types/plants";
import { MutationKey } from "../../../consts/mutation-keys";
import { QueryKey } from "../../../consts/query-keys";
import { useApiError } from "../../../hooks/useApiError";
import { useApiSuccess } from "../../../hooks/useApiSuccess";
import { ApiHookConfig } from "../../../types/api-hook-config";
import { CafsForm } from "../../../types/cafs-form";

export function useDatasetCreate({ onSuccess }: ApiHookConfig) {
  const { datasetId, id } = useParams();
  const { formatMessage } = useIntl();

  const { apiErrors, clearApiErrors, handleError } = useApiError();
  const { handleSuccess } = useApiSuccess({
    invalidate: [
      [QueryKey.DataSetsCollection, datasetId!, id!],
      [QueryKey.Regions],
    ],
    snackbarText: formatMessage({
      id: "ACTION.DATASET_CREATE",
      defaultMessage: "Dataset has been created successfully.",
      description: "Dataset create success message",
    }),
    onSuccessFn: onSuccess,
  });

  const mutationFn = (data: Partial<CafsForm<PlantGuest>>) => {
    return Datasets.create(undefined, data);
  };

  const { mutate: createDataset } = useMutation({
    mutationKey: [MutationKey.CreateCafsGuest, datasetId, id],
    mutationFn: mutationFn,
    onSuccess: handleSuccess,
    onError: handleError,
  });

  return { createDataset, apiErrors, clearApiErrors };
}
