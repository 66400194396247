import { RhfFormField } from "../../../../components/rhf/RhfFormField";
import { RhfInput } from "../../../../components/rhf/RhfInput";

export type FormInputRowProps<T extends Record<string, unknown>> = {
  name: keyof T;
  type?: "text" | "number";
  required?: boolean;
  readonly?: boolean;
  focusOnError?: boolean;
};

export function BaseInputRow<T extends Record<string, unknown>>({
  name,
  type = "text",
  readonly,
  focusOnError,
}: FormInputRowProps<T>) {
  return (
    <>
      <div>
        <RhfFormField name={`${name as string}`}>
          <RhfInput
            name={`${name as string}.Base`}
            type={type}
            disabled={readonly}
            placeholder={name as string}
            label={name as string}
            focusOnError={focusOnError}
          />
        </RhfFormField>
      </div>
    </>
  );
}
