import { useMatch } from "react-router-dom";

import { useQuery } from "@tanstack/react-query";

import { Inputs } from "../../../api/inputs";
import { Plants } from "../../../api/plants";
import { QueryKey } from "../../../consts/query-keys";

export function useHistoryData(datasetId: string, cafsId: string) {
  const matchInput = useMatch("/dashboard/input/:datasetId/:plantId");

  const queryFn = () => {
    return matchInput
      ? Inputs.getHistory({ path: { inputId: +cafsId } })
      : Plants.getHistory({
          path: { plantId: +cafsId },
        });
  };

  const { data, isLoading } = useQuery({
    queryKey: matchInput ? [] : [QueryKey.PlantAudit, datasetId!, cafsId!],
    queryFn: queryFn,
    staleTime: Infinity,
  });

  return { data, isLoading };
}
