import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import { useMutation } from "@tanstack/react-query";

import { Plants } from "../../../api/plants";
import { MutationKey } from "../../../consts/mutation-keys";
import { QueryKey } from "../../../consts/query-keys";
import { useApiError } from "../../../hooks/useApiError";
import { useApiSuccess } from "../../../hooks/useApiSuccess";
import { ApiHookConfig } from "../../../types/api-hook-config";

export const useDeletePlantProductForm = ({ onSuccess }: ApiHookConfig) => {
  const { datasetId, id } = useParams();
  const { formatMessage } = useIntl();
  const { handleError } = useApiError();
  const { handleSuccess } = useApiSuccess({
    invalidate: [[QueryKey.PlantProducts], [QueryKey.CafsDetails]],
    snackbarText: formatMessage({
      id: "ACTIONS.PRODUCT_DELETE_SUCCESS",
      defaultMessage: "Product deleted successfully.",
      description: "Product delete success message",
    }),
    onSuccessFn: onSuccess,
  });

  const mutationFn = (plantProductId: number) =>
    Plants.deletePlantProduct({
      path: {
        plantProductId,
        datasetId: +datasetId!,
        plantId: +id!,
      },
    });

  const { mutate: deleteProduct, isPending } = useMutation({
    mutationKey: [MutationKey.DeletePlantProduct, datasetId, id],
    mutationFn: mutationFn,
    onSuccess: handleSuccess,
    onError: handleError,
  });

  return { deleteProduct, isPending };
};
