import React, { PropsWithChildren } from "react";

import { PageSectionHeader } from "./PageSectionHeader";

type PageSectionProps = PropsWithChildren & {
  title?: string;
  description?: string;
};

export function PageSection({
  title,
  description,
  children,
  ...props
}: PageSectionProps) {
  return (
    <div className="mt-12" {...props}>
      {title && <PageSectionHeader title={title} description={description} />}
      <div className="py-2">{children}</div>
    </div>
  );
}
