import { useContext } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";

import { yupResolver } from "@hookform/resolvers/yup";
import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";

import { PlantGuest } from "../../../../api/types/plants";
import { useApiValidation } from "../../../../hooks/useApiValidation";
import { ApiError } from "../../../../types/api-error";
import { CafsForm } from "../../../../types/cafs-form";
import { CafsContext } from "../../CafsContext";
import { CafsBaseTable } from "../cafs-base-table/CafsBaseTable";
import { renderBaseRow } from "../cafs-base-table/render-base-row";
import { SectionRemarks } from "../cafs-sections/SectionRemarks";

import { guestCreateSchema } from "./guest-create-schema";
import { useGuestCreateVariables } from "./useGuestCreateVariables";

export type PlantGuestCreateProps = {
  onSubmit: (data: Partial<CafsForm<PlantGuest>>) => void;
  customErrors: ApiError["Body"]["Errors"];
  onCancel: () => void;
};

export function GuestCreate({
  onSubmit,
  customErrors,
  onCancel,
}: PlantGuestCreateProps) {
  const variables = useGuestCreateVariables();
  const { isReadMode } = useContext(CafsContext);

  const methods = useForm<Partial<CafsForm<PlantGuest>>>({
    resolver: yupResolver(guestCreateSchema),
    shouldFocusError: true,
    mode: "onChange",
  });

  useApiValidation(customErrors, methods);

  return (
    <FormProvider {...methods}>
      <CafsBaseTable variables={variables} render={renderBaseRow} />
      <SectionRemarks<PlantGuest> name="GeneralRemarks" />
      <div className="mt-12 flex flex-row gap-4">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          startIcon={<Add />}
          disabled={!methods.formState.isValid || isReadMode}
          onClick={methods.handleSubmit(onSubmit)}>
          <FormattedMessage
            id="BTN.CREATE_GUEST"
            defaultMessage="Create"
            description="Create guest button"
          />
        </Button>
        <Button
          type="button"
          variant="outlined"
          color="primary"
          onClick={onCancel}>
          <FormattedMessage id="APP.CANCEL" defaultMessage="Cancel" />
        </Button>
      </div>
    </FormProvider>
  );
}
