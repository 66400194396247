import React, { useContext } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";

import { Save } from "@mui/icons-material";
import { Button, Table, TableBody } from "@mui/material";

import { Plant } from "../../../../api/types/plants";
import { useTooltips } from "../../api/useTooltips";
import { CafsContext } from "../../CafsContext";
import { CafsSection } from "../../const/cafs-section";

import { PlantFormRow } from "./PlantFormRow";

type PlantFormProps = {
  data: Plant;
};

export function PlantForm({ data }: PlantFormProps) {
  const { isReadMode } = useContext(CafsContext);
  const {
    formState: { isDirty, isValid },
  } = useFormContext();

  const { tooltips } = useTooltips(
    {
      EnglishName: data.EnglishName,
      Specification: data.Specification,
      ScientificName: data.ScientificName,
      GeneralRemarks: data.GeneralRemarks,
      FormerRecName: data.FormerRecName,
    },
    CafsSection.Plant,
  );

  return (
    <>
      <Table
        sx={{
          minWidth: 650,
          border: "1px solid #CED4DA",
          borderBottom: "none",
        }}
        stickyHeader
        aria-label="plant-details-table">
        <TableBody>
          {/* EnglishName */}

          <PlantFormRow
            data={data}
            name="EnglishName"
            tooltip={tooltips?.EnglishName}
            isReadMode={isReadMode}
          />

          <PlantFormRow
            data={data}
            name="FormerRecName"
            tooltip={tooltips?.FormerRecName}
            isReadMode={isReadMode}
          />

          <PlantFormRow
            data={data}
            name="Specification"
            tooltip={tooltips?.Specification}
            isReadMode={isReadMode}
          />

          <PlantFormRow
            data={data}
            name="ScientificName"
            tooltip={tooltips?.ScientificName}
            isReadMode={isReadMode}
          />

          <PlantFormRow
            data={data}
            name="GeneralRemarks"
            tooltip={tooltips?.GeneralRemarks}
            rows={6}
            isReadMode={isReadMode}
          />
        </TableBody>
      </Table>
      <div className="mt-8">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          startIcon={<Save />}
          disabled={!isDirty || !isValid || isReadMode}>
          <FormattedMessage
            id="APP.SAVE"
            defaultMessage="Save"
            description="General save text"
          />
        </Button>
      </div>
    </>
  );
}
