import { useQuery } from "@tanstack/react-query";

import { Datasets } from "../../../api/datasets";
import { QueryKey } from "../../../consts/query-keys";

export const useDataSets = () => {
  const { data: datasets } = useQuery({
    queryKey: [QueryKey.DataSetsCollection],
    queryFn: () => Datasets.list(),
  });

  return { datasets };
};
