import React, { useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";

import { Autocomplete, Button, TextField } from "@mui/material";

import { Dataset } from "../../../api/types/datasets";
import { Region } from "../../../api/types/regions";
import { Edit } from "../../../components/icons/Edit";
import { Save } from "../../../components/icons/Save";

export type DatasetNameProps = {
  data: Dataset;
  regions: Region[] | null | undefined;
  onEdit: (row: Dataset, column: string) => void;
  onSave: (data: { dataset: Dataset; region: Region | null }) => void;
  onCancel: () => void;
  editMode: boolean | undefined;
};
export function RegionUpdate({
  data,
  onEdit,
  editMode,
  onCancel,
  onSave,
  regions,
}: DatasetNameProps) {
  const [dataToSave, setDataToSave] = useState<{
    dataset: Dataset;
    region: Region | null;
  }>();
  const assignedRegion = useMemo(
    () => (regions || []).find(r => r.Id === data.RegionId),
    [data, regions],
  );

  return editMode ? (
    <div className="flex justify-between gap-2 w-full">
      <div className="flex-1">
        <Autocomplete
          size="small"
          value={dataToSave ? dataToSave?.region : assignedRegion}
          onChange={(e, value) =>
            setDataToSave({ ...dataToSave, dataset: data, region: value })
          }
          options={regions || []}
          getOptionLabel={option => option.Name ?? ""}
          renderInput={params => (
            <TextField {...params} label="Choose a region" />
          )}
        />
      </div>
      <div className="flex flex-row gap-4 items-center justify-end flex-1 w-full">
        <Button
          variant="contained"
          color="primary"
          startIcon={<Save />}
          disabled={!dataToSave}
          onClick={() => onSave(dataToSave!)}
          size="small">
          <FormattedMessage
            id="APP.SAVE"
            defaultMessage="Save"
            description="General save text"
          />
        </Button>
        <Button
          variant="outlined"
          color="warning"
          size="small"
          onClick={onCancel}>
          <FormattedMessage
            id="APP.CANCEL"
            defaultMessage="Cancel"
            description="General cancel text"
          />
        </Button>
      </div>
    </div>
  ) : (
    <div className="flex flex-row flex-nowrap justify-between w-full">
      <p>{assignedRegion?.Name}</p>
      <div className="flex flex-row gap-4 items-end justify-end">
        <Edit
          className="text-xl text-darkGray hover:text-primary cursor-pointer"
          onClick={() => onEdit(data, "region")}
        />
      </div>
    </div>
  );
}
