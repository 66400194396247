import { useQuery } from "@tanstack/react-query";

import { Plants } from "../../../api/plants";
import { QueryKey } from "../../../consts/query-keys";

export const useWoodDensityData = (
  datasetId: string,
  id: string,
  shouldFetchData: boolean,
) => {
  const { data: woodDensityData, isPending } = useQuery({
    queryKey: [QueryKey.WoodDensitySection, datasetId, id],
    queryFn: () =>
      Plants.getWoodDensity({
        path: { datasetId: +datasetId! as number, plantId: +id! as number },
      }).then(response => {
        return response;
      }),
    enabled: shouldFetchData,
    throwOnError: true,
  });

  return { woodDensityData, isPending };
};
