import React from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";

import { CafsErrorBoundary } from "./components/CafsErrorBoundary";
import ProtectedRoute from "./components/ProtectedRoute";
import { RouteError } from "./components/RouteError";
import { Routes } from "./consts/routes";
import { UserRole } from "./consts/user-role";
import { MainTemplate } from "./templates/MainTemplate";
import { CafsView } from "./views/cafs-view/CafsView";
import { DashboardView } from "./views/dashboard-view/DashboardView";
import { DatasetView } from "./views/dataset-view/DatasetView";
import { LoginView } from "./views/login-view/LoginView";
import { NotFoundView } from "./views/not-found-view/NotFoundView";
import { PriceView } from "./views/price-view/PriceView";
import { ProductsView } from "./views/products-view/ProductsView";
import { ReferencesView } from "./views/references-view/ReferencesView";
import { TooltipSettingsView } from "./views/tooltips-settings-view/TooltipSettingsView";

const farmTreeRoles = [
  UserRole.FarmTreeExpert,
  UserRole.FarmTreeDeveloper,
  UserRole.FarmTreeMaster,
];

export const router = createBrowserRouter([
  {
    path: "login",
    element: <LoginView />,
  },
  {
    path: "/",
    element: <ProtectedRoute element={<MainTemplate />} />,
    errorElement: <RouteError />,
    children: [
      {
        path: "",
        element: <Navigate to={Routes.Dashboard} />,
      },
      {
        path: Routes.Dashboard,
        children: [
          {
            path: "",
            element: <ProtectedRoute element={<DashboardView />} />,
          },
          {
            path: Routes.Perennial,
            element: (
              <CafsErrorBoundary>
                <ProtectedRoute element={<CafsView />} />
              </CafsErrorBoundary>
            ),
          },
          {
            path: Routes.Annual,
            element: (
              <CafsErrorBoundary>
                <ProtectedRoute element={<CafsView />} />
              </CafsErrorBoundary>
            ),
          },
          {
            path: Routes.Input,
            element: (
              <CafsErrorBoundary>
                <ProtectedRoute element={<CafsView />} />
              </CafsErrorBoundary>
            ),
          },
        ],
      },
      {
        path: Routes.Price,
        element: (
          <ProtectedRoute
            path={`/${Routes.Price}`}
            element={<PriceView />}
            allowedRoles={farmTreeRoles}
          />
        ),
      },
      {
        path: Routes.Dataset,
        element: (
          <ProtectedRoute
            path={`/${Routes.Dataset}`}
            element={<DatasetView />}
            allowedRoles={farmTreeRoles}
          />
        ),
      },
      {
        path: Routes.Product,
        element: (
          <ProtectedRoute
            path={`${Routes.Product}`}
            element={<ProductsView />}
            allowedRoles={farmTreeRoles}
          />
        ),
      },
      {
        path: Routes.TooltipsSettings,
        element: (
          <ProtectedRoute
            path={`/${Routes.TooltipsSettings}`}
            element={<TooltipSettingsView />}
            allowedRoles={farmTreeRoles}
          />
        ),
      },
      {
        path: Routes.References,
        element: (
          <ProtectedRoute
            path={`/${Routes.References}`}
            element={<ReferencesView />}
            allowedRoles={farmTreeRoles}
          />
        ),
      },
    ],
  },
  {
    path: "*",
    element: <NotFoundView />,
  },
]);
