import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import { useMutation } from "@tanstack/react-query";

import { Plants } from "../../../api/plants";
import { PlantProduct } from "../../../api/types/plants";
import { MutationKey } from "../../../consts/mutation-keys";
import { QueryKey } from "../../../consts/query-keys";
import { useApiError } from "../../../hooks/useApiError";
import { useApiSuccess } from "../../../hooks/useApiSuccess";
import { ApiHookConfig } from "../../../types/api-hook-config";
import { CafsForm } from "../../../types/cafs-form";

export const usePlantProductCreate = ({ onSuccess }: ApiHookConfig) => {
  const { datasetId, id } = useParams();
  const { apiErrors, clearApiErrors, handleError } = useApiError();

  const { formatMessage } = useIntl();

  const { handleSuccess } = useApiSuccess({
    invalidate: [[QueryKey.PlantProducts], [QueryKey.CafsDetails]],
    snackbarText: formatMessage({
      id: "ACTIONS.CREATE_PRODUCT_SUCCESS",
      defaultMessage: "Product created successfully",
      description: "Product create success message",
    }),
    onSuccessFn: onSuccess,
  });

  const mutationFn = (newData: Partial<CafsForm<PlantProduct>>) =>
    Plants.createProducts(
      { path: { datasetId: +datasetId!, plantId: +id! } },
      newData,
    );

  const { mutate: createProduct, isPending } = useMutation({
    mutationKey: [MutationKey.CreatePlantProduct, datasetId, id],
    mutationFn: mutationFn,
    onSuccess: handleSuccess,
    onError: handleError,
  });

  return { createProduct, apiErrors, clearApiErrors, isPending };
};
