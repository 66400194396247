import { useEffect, useState } from "react";

export function useFetchData(expanded: boolean) {
  const [shouldFetchData, setShouldFetch] = useState(false);

  useEffect(() => {
    if (expanded && !shouldFetchData) {
      setShouldFetch(true);
    }
  }, [expanded]);

  return { shouldFetchData };
}
