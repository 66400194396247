import { Menu } from "types/menu";

import { Dashboard } from "../components/icons/Dashboard";
import { Dataset } from "../components/icons/Dataset";
import { Plant } from "../components/icons/Plant";
import { PriceTag } from "../components/icons/PriceTag";
import { QuestionMark } from "../components/icons/QuestionMark";
import { Search } from "../components/icons/Search";

import { Routes } from "./routes";
import { UserRole } from "./user-role";

export const menu: Menu = [
  {
    label: "Dashboard",
    link: `/${Routes.Dashboard}`,
    key: "dashboard",
    messageKey: "dashboard",
    icon: <Dashboard className="text-2xl" />,
    roles: [
      UserRole.ClientEditor,
      UserRole.ClientExpert,
      UserRole.ClientViewer,
      UserRole.FarmTreeMaster,
      UserRole.FarmTreeDeveloper,
      UserRole.FarmTreeExpert,
    ],
  },
  {
    label: "Dataset",
    link: `/${Routes.Dataset}`,
    key: "dataset",
    messageKey: "dataset",
    icon: <Dataset className="text-2xl" />,
    roles: [
      UserRole.FarmTreeMaster,
      UserRole.FarmTreeDeveloper,
      UserRole.FarmTreeExpert,
    ],
  },
  {
    label: "Price",
    link: `/${Routes.Price}`,
    key: "price",
    messageKey: "price",
    icon: <PriceTag className="text-2xl" />,
    roles: [
      UserRole.FarmTreeMaster,
      UserRole.FarmTreeDeveloper,
      UserRole.FarmTreeExpert,
    ],
  },
  {
    label: "Products",
    link: `/${Routes.Product}`,
    key: "products",
    messageKey: "products",
    icon: <Plant className="text-3xl" />,
    roles: [
      UserRole.FarmTreeMaster,
      UserRole.FarmTreeDeveloper,
      UserRole.FarmTreeExpert,
    ],
  },
  {
    label: "Tooltips settings",
    link: `/${Routes.TooltipsSettings}`,
    key: "tooltips_settings",
    messageKey: "tooltips_settings",
    icon: <QuestionMark className="text-2xl" />,
    roles: [
      UserRole.FarmTreeMaster,
      UserRole.FarmTreeDeveloper,
      UserRole.FarmTreeExpert,
    ],
  },
  {
    label: "References",
    link: `/${Routes.References}`,
    key: "references",
    messageKey: "references",
    icon: <Search className="text-2xl" />,
    roles: [
      UserRole.FarmTreeMaster,
      UserRole.FarmTreeDeveloper,
      UserRole.FarmTreeExpert,
    ],
  },
];
