import { CafsValue } from "../api/types/cafs";
import { CafsFormValue } from "../types/cafs-form-value";

export const getCurrentValue = (value: CafsFormValue | CafsValue) => {
  if (typeof value === "object" && value !== null && "Current" in value) {
    return value.Current;
  }
  return "";
};

export const getBaseValue = (value: CafsFormValue | CafsValue) => {
  if (typeof value === "object" && value !== null && "Base" in value) {
    return value.Base;
  }
  return "";
};

export const getCalculatedValue = (value: CafsFormValue | CafsValue) => {
  if (typeof value === "object" && value !== null && "Calculated" in value) {
    return value.Calculated;
  }
  return "";
};

export const getParentValue = (value: CafsFormValue | CafsValue) => {
  if (typeof value === "object" && value !== null && "Parent" in value) {
    return value.Parent;
  }
  return "";
};

export const getVariableValue = (value: CafsFormValue | CafsValue) => {
  if (typeof value === "object") {
    return value;
  }
  return {};
};
