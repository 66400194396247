import React, { useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

import { Product } from "../../../api/types/products";
import { SearchBar } from "../../../components/SearchBar";
import { NoResult } from "../../dashboard-view/components/NoResult";
import { useUpdateProduct } from "../api/useUpdateProduct";

import { ProductTableRow } from "./ProducTableRow";

export type ProductsTableProps = {
  data: Product[];
};

export function ProductsTable({ data }: ProductsTableProps) {
  const [editedItem, setEditedItem] = useState<Product | null>(null);
  const [searchPhase, setSearchPhase] = useState("");

  const closeEditModal = () => {
    setEditedItem(null);
  };

  const products = useMemo(
    () =>
      data.filter(p =>
        p.Name.toLowerCase().includes(searchPhase.toLowerCase()),
      ),
    [data, searchPhase],
  );

  const { updateProduct } = useUpdateProduct(closeEditModal);

  return (
    <div className="p-4 border-[1px] border-solid border-gray-200 rounded mt-4">
      <div className="mt-4 mb-8">
        <SearchBar
          placeholder="Search by product name..."
          searchIcon
          onChange={value => {
            setSearchPhase(value);
          }}
        />
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage
                id="PRODUCTS_TABLE.NAME"
                description="Products table name column"
                defaultMessage="Name"
              />
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products.length ? (
            (products || []).map(item => (
              <ProductTableRow
                item={item}
                editMode={editedItem?.Id === item.Id}
                onEdit={item => setEditedItem(item)}
                onCancel={() => setEditedItem(null)}
                onSave={data =>
                  updateProduct({ productId: editedItem?.Id!, name: data! })
                }
              />
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={2}>
                <NoResult />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
}
