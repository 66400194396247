import { useMemo } from "react";

import { PlantProduct, PlantProducts } from "../../../api/types/plants";
import { ProductCode } from "../../../api/types/products";
import { createOptions } from "../../../utils/create-options";

export function useProductCodesOptions(
  plantProducts: PlantProducts | undefined,
  product?: PlantProduct | undefined,
) {
  const options = useMemo(() => {
    const allProductCodes = plantProducts?.ProductCodes;
    const productCodes = (plantProducts?.PlantProducts || []).map(
      p => p.Code?.Current ?? p.Code?.Base,
    );
    const availableProducts = (allProductCodes || []).filter(
      p => !productCodes.includes(p as ProductCode | undefined),
    );

    return createOptions(
      product
        ? [...availableProducts, product?.Code?.Current! ?? product?.Code?.Base]
        : availableProducts,
    );
  }, [plantProducts]);

  return { options };
}
