import { LinearProgress } from "@mui/material";
import { useIsFetching, useIsMutating } from "@tanstack/react-query";

export function Loader() {
  const isFetching = useIsFetching();
  const isMutating = useIsMutating();
  return isFetching || isMutating ? (
    <div className="w-full pb-2">
      <LinearProgress />
    </div>
  ) : null;
}
