import { useCallback, useState } from "react";

export enum SnackbarType {
  Success = "success",
  Error = "error",
  Info = "info",
  Warning = "warning",
}

export type SnackbarMessage = {
  type: SnackbarType;
  message: string;
};

export const useSnackbarManager = () => {
  const [snackbar, setSnackbar] = useState<SnackbarMessage | null>(null);

  const showSnackbar = useCallback((message: SnackbarMessage) => {
    setSnackbar(message);
  }, []);

  const closeSnackbar = useCallback(() => {
    setSnackbar(null);
  }, []);

  return {
    snackbar,
    showSnackbar,
    closeSnackbar,
  };
};
