import { PropsWithChildren } from "react";
import { FieldErrors, useFormContext } from "react-hook-form";

import { CafsValueError } from "../../types/cafs-value-error";

import { isCustomFieldError } from "./isCustomFieldError";

export type RhfFormFieldProps = PropsWithChildren & {
  name: string;
  customError?: string;
};

export function RhfFormField({ name, children }: RhfFormFieldProps) {
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <div className="w-full">
      {children}
      <small style={{ color: "red" }}>{renderError(errors, name)}</small>
    </div>
  );
}

function renderError(errors: FieldErrors, fieldName: string) {
  return (
    <>
      {isCustomFieldError(errors[fieldName])
        ? (errors[fieldName] as CafsValueError)?.Base?.message
        : errors[fieldName]?.message}
    </>
  );
}
