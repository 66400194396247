import { StringCafsValue } from "../../../../../../api/types/cafs";
import { PlantGrowth } from "../../../../../../api/types/plants";

export const rusleFactorFn = (
  values: Partial<Record<keyof PlantGrowth, StringCafsValue>>,
): number => {
  const rusleCFactorValue = parseFloat(
    !!values.RusleCFactor?.Base?.length
      ? values.RusleCFactor?.Base
      : values.RusleCFactor?.Current ?? "0",
  );
  const ruslePFactorValue = parseFloat(
    !!values.RuslePFactor?.Base?.length
      ? values.RuslePFactor?.Base
      : values.RuslePFactor?.Current ?? "0",
  );

  return rusleCFactorValue * ruslePFactorValue;
};
