import React from "react";
import { useNavigate } from "react-router-dom";

import { useTheme } from "@emotion/react";
import { Button } from "@mui/material";

import { UserRole } from "../../api/types/user";
import { Logout } from "../../components/icons/Logout";
import { LocalStorageKey } from "../../consts/local-storage-key";
import { Routes } from "../../consts/routes";
import { useAuth } from "../../hooks/useAuth";
import { Menu } from "../../types/menu";
import { useLogout } from "../top-nav/useLogout";
import { UserInfo } from "../user-info/UserInfo";

import { renderNavItem } from "./render-nav-item";

export type MobileMenuProps = {
  menu: Menu;
  userRole: UserRole | undefined;
  onNavigate: () => void;
};
export function MobileMenu({ menu, userRole, onNavigate }: MobileMenuProps) {
  const theme = useTheme();
  const { clearAuthData } = useAuth();
  const navigate = useNavigate();

  const { logout } = useLogout({
    onSuccess: () => {
      clearAuthData();
      localStorage.removeItem(LocalStorageKey.SelectedDataset);
      navigate(`/${Routes.Login}`);
    },
  });

  const handleLogout = () => {
    logout();
    onNavigate();
  };

  return (
    <div style={{ paddingTop: "30px" }}>
      <div style={{ maxWidth: "80%", margin: "0px auto" }}>
        <UserInfo />
      </div>
      <div
        style={{
          height: 2,
          borderBottom: `1px ${theme.palette.grey[400]} solid`,
          width: "80%",
          margin: "16px auto",
        }}
      />
      <div style={{ maxWidth: "80%", margin: "0px auto" }}>
        {(menu || []).map(item => renderNavItem(item, userRole, onNavigate))}
      </div>
      <div
        style={{
          width: "80%",
          margin: "auto",
        }}>
        <div className="mt-6 pt-6 w-full">
          <Button
            variant="outlined"
            className="w-full"
            size="large"
            startIcon={<Logout />}
            onClick={handleLogout}>
            Logout
          </Button>
        </div>
        <p className="text-gray-500 text-sm mt-2 pt-2">
          Copyright © FarmTree® B.V.
        </p>
      </div>
    </div>
  );
}
