import { UserRole } from "../../api/types/user";
import { Menu } from "../../types/menu";
import { UserInfo } from "../user-info/UserInfo";

import { renderNavItem } from "./render-nav-item";

export type DesktopMenuProps = {
  menu: Menu;
  userRole: UserRole | undefined;
};

export function DesktopMenu({ menu, userRole }: DesktopMenuProps) {
  return (
    <div className="w-[348px] bg-lightGrey h-[calc(100%-82px)] pt-8 flex flex-col">
      <div className="px-8 mb-8">
        <UserInfo />
      </div>
      <div className="px-8"></div>
      <div className="flex-1 flex flex-col justify-between">
        <div className="mt-4 px-8">
          {(menu || []).map(item => renderNavItem(item, userRole, () => {}))}
        </div>
        <p className="px-8 py-4 text-gray-500 text-sm">
          Copyright © FarmTree® B.V.
        </p>
      </div>
    </div>
  );
}
