import { useState } from "react";

export const useAccordion = () => {
  const [expanded, setExpanded] = useState<number | string | boolean>(false);

  const collapseAll = () => {
    setExpanded(false);
  };

  const handleChange =
    (panel: number | string) =>
    (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return { expanded, handleChange, collapseAll };
};
