import { createApiOperation } from "../api-client/create-api-operation";

import { Prices as PricesType } from "./types/prices";

export const Prices = {
  getRegion: createApiOperation<PricesType, "/Api/Prices/{regionId}", "get">(
    "/Api/Prices/{regionId}",
    "get",
  ),
};
