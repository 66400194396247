import { PropsWithChildren } from "react";

import { ExpandMore } from "@mui/icons-material";
import { AccordionSummary, AccordionSummaryProps } from "@mui/material";
import { styled } from "@mui/system";
import { twMerge } from "tailwind-merge";

type StyledAccordionSummaryProps = PropsWithChildren & {
  expanded: boolean;
} & AccordionSummaryProps;

export const StyledAccordionSummary = styled(
  ({ expanded, children, ...props }: StyledAccordionSummaryProps) => (
    <AccordionSummary expandIcon={<ExpandMore />} {...props}>
      <div
        className={twMerge(
          "w-full flex justify-between items-center",
          expanded && "text-2xl font-semibold mb-4 ",
        )}>
        {children}
      </div>
    </AccordionSummary>
  ),
)({});
