import { GuestResponseToPressure } from "../../../../api/types/guests";
import { CafsVariableType } from "../../const/cafs-variable-type";
import { CafsVariablesCollection } from "../cafs-table/CafsTable";

export type UsePressureVariablesProps = {
  categories: { id: string; label: string }[];
};

export function useGuestPressureVariables({
  categories,
}: UsePressureVariablesProps): CafsVariablesCollection<GuestResponseToPressure> {
  return [
    { name: "Remarks", type: CafsVariableType.Remarks },
    {
      name: "Category",
      type: CafsVariableType.Select,
      options: categories,
    },
    {
      name: "REffect",
      type: CafsVariableType.Input,
    },
  ];
}
