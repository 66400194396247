import React, { HTMLProps, useContext } from "react";

import { FIlterIcon } from "../../../components/icons/FIlter";
import { CafsType } from "../../../consts/cafsType";
import { DashboardContext } from "../DashboardContext";

import { Filter } from "./Filter";

export type FiltersBarProps = HTMLProps<HTMLDivElement> & {
  onApply: (type: CafsType, filterId: number) => void;
};

export function FiltersBar({ onApply, ...rest }: FiltersBarProps) {
  const { activeFilter } = useContext(DashboardContext);

  return (
    <div {...rest}>
      <div className="flex flex-row gap-2 items-center mb-4">
        <FIlterIcon className="text-primary" />
        <span>Filters</span>
      </div>
      <div className="flex gap-2">
        <Filter
          id={0}
          active={String(activeFilter) === "0"}
          text="Expand all perennials"
          onClick={() => onApply(CafsType.Tree, 0)}
        />
        <Filter
          id={1}
          active={String(activeFilter) === "1"}
          text="Expand alll annuals"
          onClick={() => onApply(CafsType.Crop, 1)}
        />
        <Filter
          id={2}
          active={String(activeFilter) === "2"}
          text="Expand all inputs"
          onClick={() => onApply(CafsType.Input, 2)}
        />
      </div>
    </div>
  );
}
