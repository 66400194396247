import TableSortLabel from "@mui/material/TableSortLabel";

import { BaseTableColumn } from "../../types/base-table-column";
import { Order } from "../../types/order";

export type TableColumnHeaderProps<T extends { [key: string]: unknown }> = {
  column: BaseTableColumn<T>;
  orderBy: string;
  order: Order;
  onClick: (value: BaseTableColumn<T>) => void;
};

const orderMapper: Record<Order, string> = {
  Ascending: "asc",
  Descending: "desc",
  Unspecified: "asc",
};

export function TableColumnHeader<T extends { [key: string]: unknown }>({
  column,
  orderBy,
  order,
  onClick,
}: TableColumnHeaderProps<T>) {
  const isActive = orderBy === column.key;
  const direction =
    orderBy === column.key
      ? (orderMapper[order] as "asc" | "desc" | undefined)
      : "asc";

  return column.isSortable ? (
    <TableSortLabel
      sx={{ whiteSpace: "noWrap" }}
      active={isActive}
      direction={direction}
      onClick={() => onClick(column)}>
      {column.label}
    </TableSortLabel>
  ) : (
    <span>{column.label}</span>
  );
}
