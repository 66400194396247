import { components } from "../../../../api-client/api-schema";

export type CafsHistoryInfoRowProps = {
  data: components["schemas"]["AuditItemModelDto"];
};

export function CafsHistoryInfoRow({ data }: CafsHistoryInfoRowProps) {
  return (
    <div className="mb-4 flex flex-row items-center">
      <span className="text-2xl font-bold text-primary">{data.ObjectType}</span>
      {data.State === "Added" ? (
        <div className="rounded  bg-green bg-opacity-30 text-primary ml-2 py-1 px-2 flex items-center justify-center">
          {data.State}
        </div>
      ) : (
        <div className="rounded bg-orange-200 text-orange-600  ml-2 px-2 py-1 flex items-center justify-center">
          {data.State}
        </div>
      )}
    </div>
  );
}
