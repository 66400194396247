import { useIntl } from "react-intl";

import { useMutation } from "@tanstack/react-query";

import { Csv } from "../../../api/csv";
import { MutationKey } from "../../../consts/mutation-keys";
import { useApiSuccess } from "../../../hooks/useApiSuccess";
import { useFetchError } from "../../../hooks/useFetchError";
import { ApiError } from "../../../types/api-error";

export type UseDatasetImportProps = {
  onProgress: (progress: number) => void;
  onUploadComplete: () => void;
};

export function usePriceImport({
  onProgress,
  onUploadComplete,
}: UseDatasetImportProps) {
  const { formatMessage } = useIntl();

  const { handleSuccess } = useApiSuccess({
    snackbarText: formatMessage({
      id: "ACTION.PRICES_IMPORT_SUCCESS",
      defaultMessage: "Prices imported successfully",
      description: "Prices import success",
    }),
  });

  const mutationFn = (data: FormData) => {
    return Csv.uploadPrices(undefined, data, onProgress, onUploadComplete);
  };

  const {
    mutate: upload,
    isPending,
    isSuccess,
    error,
  } = useMutation({
    mutationKey: [MutationKey.UploadDataset],
    mutationFn: mutationFn,
    onSuccess: handleSuccess,
  });

  useFetchError(error as unknown as ApiError);

  return { upload, isPending, isSuccess };
}
