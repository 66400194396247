import * as yup from "yup";

import { InputLabour } from "../../../../../api/types/inputs";
import {
  CafsValueNumberValidation,
  CafsValueRemarksValidation,
} from "../../../../../consts/validation";
import { CafsForm } from "../../../../../types/cafs-form";

export const inputLabourSchema = yup.object<CafsForm<keyof InputLabour>>({
  LabourRemarks: CafsValueRemarksValidation(),
  LabourEstDayPHa: CafsValueNumberValidation(),
  LabourEstFemaleFraction: CafsValueNumberValidation(true),
  LabourMaintDayPHaPY: CafsValueNumberValidation(),
  LabourMaintFemaleFraction: CafsValueNumberValidation(true),
  IrrigationLabourDaysPerMMPerHa: CafsValueNumberValidation(),
});
