import React from "react";

import { Button } from "@mui/material";

import { Download } from "../../../components/icons/Download";
import { useDeletedItems } from "../api/useDeletedItems";

export function DeletedItems() {
  const { fetchDeletedItems, isPending } = useDeletedItems();
  return (
    <Button
      variant="outlined"
      color="error"
      disabled={isPending}
      startIcon={<Download />}
      onClick={() => fetchDeletedItems()}>
      Get deleted items
    </Button>
  );
}
