import { useMutation } from "@tanstack/react-query";

import { Datasets } from "../../../api/datasets";
import { MutationKey } from "../../../consts/mutation-keys";
import { QueryKey } from "../../../consts/query-keys";
import { useApiSuccess } from "../../../hooks/useApiSuccess";
import { ApiHookConfig } from "../../../types/api-hook-config";

export type RegionAssignmentPayload = {
  datasetId: number;
  regionId: number;
};

export const useUnassignRegion = ({ onSuccess }: ApiHookConfig) => {
  const { handleSuccess } = useApiSuccess({
    invalidate: [[QueryKey.DataSetsCollection], [QueryKey.Regions]],
    onSuccessFn: onSuccess,
  });
  const mutationFn = (data: RegionAssignmentPayload) =>
    Datasets.unassignRegion({
      path: { datasetId: data.datasetId, regionId: data.regionId },
    });

  const { mutate: unassignRegion, isPending } = useMutation({
    mutationKey: [MutationKey.RegionUnassign],
    mutationFn: mutationFn,
    onSuccess: handleSuccess,
    throwOnError: true,
  });

  return { unassignRegion, isPending };
};
