import { useMatch, useParams } from "react-router-dom";

import { useQuery } from "@tanstack/react-query";

import { Inputs } from "../../../api/inputs";
import { Plants } from "../../../api/plants";
import { QueryKey } from "../../../consts/query-keys";

export const useInputDetailsData = () => {
  const matchInput = useMatch("/dashboard/input/:datasetId/:id");
  const { datasetId, id } = useParams();

  const queryFn = () => {
    return matchInput
      ? Inputs.getDetails({
          path: { datasetId: +datasetId!, inputId: +id! },
        })
      : Plants.getDetails({
          path: { datasetId: +datasetId!, plantId: +id! },
        });
  };
  return useQuery({
    queryKey: [QueryKey.CafsDetails, datasetId, +id!],
    queryFn: queryFn,
    throwOnError: true,
  });
};
