import { useMemo } from "react";

import { Products } from "../../../api/types/products";
import { SelectOption } from "../../../types/select-option";

export function useProductsOptions(products: Products | undefined): {
  options: SelectOption[] | undefined;
} {
  const options = useMemo(
    () =>
      products?.Products?.map(th => {
        return { label: th.Name, id: th.Id } as SelectOption;
      }),
    [products],
  );
  return { options };
}
