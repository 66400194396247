import { IconProps } from "../../types/icon-props";

export function Dataset(props: IconProps) {
  return (
    <svg
      height="1em"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fill="currentColor"
        d="M7 17H11V13H7V17ZM13 17H17V13H13V17ZM7 11H11V7H7V11ZM13 11H17V7H13V11ZM5 21C4.45 21 3.97933 20.8043 3.588 20.413C3.19667 20.0217 3.00067 19.5507 3 19V5C3 4.45 3.196 3.97933 3.588 3.588C3.98 3.19667 4.45067 3.00067 5 3H19C19.55 3 20.021 3.196 20.413 3.588C20.805 3.98 21.0007 4.45067 21 5V19C21 19.55 20.8043 20.021 20.413 20.413C20.0217 20.805 19.5507 21.0007 19 21H5ZM5 19H19V5H5V19Z"
      />
    </svg>
  );
}
