import { useQuery } from "@tanstack/react-query";

import { Products } from "../../../api/products";
import { QueryKey } from "../../../consts/query-keys";
export function useFetchProducts(shouldFetchData = true) {
  const queryFn = () => Products.get();

  const {
    data: products,
    isPending,
    isFetching,
  } = useQuery({
    queryKey: [QueryKey.Products],
    queryFn: queryFn,
    enabled: shouldFetchData,
  });

  return { products, isPending, isFetching };
}
