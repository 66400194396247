import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import { useMutation } from "@tanstack/react-query";

import { Inputs } from "../../../api/inputs";
import { Plants } from "../../../api/plants";
import { Cafs } from "../../../api/types/cafs";
import { Dataset } from "../../../api/types/datasets";
import { CafsMap } from "../../../consts/cafsMap";
import { CafsType } from "../../../consts/cafsType";
import { MutationKey } from "../../../consts/mutation-keys";
import { useApiSuccess } from "../../../hooks/useApiSuccess";
import { useFetchError } from "../../../hooks/useFetchError";
import { ApiError } from "../../../types/api-error";
import { CafsTreeNodeType } from "../../../types/cafs-tree-node-type";

export const useCafsCreate = (selectedDataSet: Dataset | null) => {
  const navigate = useNavigate();

  const { formatMessage } = useIntl();

  const { handleSuccess: handleApiSuccess } = useApiSuccess({
    snackbarText: formatMessage({
      id: "ACTION.CAFS_CREATE_SUCCESS",
      defaultMessage: "CAFS has been created.",
      description: "Success msg of CAFS create",
    }),
    invalidate: [],
  });

  const mutationFn = (data: CafsTreeNodeType) => {
    return data.type === CafsType.Input
      ? data.isRoot
        ? Inputs.createRoot(
            {
              path: {
                datasetId: +selectedDataSet?.Id!,
              },
            },
            {},
          )
        : Inputs.create(
            {
              path: {
                datasetId: +selectedDataSet?.Id!,
                inputId: +data.id!,
              },
            },
            {},
          )
      : data.isRoot
        ? Plants.createRoot(
            {
              path: {
                datasetId: +selectedDataSet?.Id!,
                plantType: data.type,
              },
            },
            {},
          )
        : Plants.create(
            {
              path: {
                datasetId: +selectedDataSet?.Id!,
                plantId: +data.id,
              },
            },
            {},
          );
  };

  const handleSuccess = (data: Cafs, node: CafsTreeNodeType) => {
    handleApiSuccess();
    navigate(`${CafsMap.get(node.type)}/${selectedDataSet?.Id}/${data.Id}`);
  };

  const { mutate: createCafs, error } = useMutation({
    mutationKey: [MutationKey.CreatePlant, selectedDataSet],
    mutationFn: mutationFn,
    onSuccess: handleSuccess,
  });

  useFetchError(error as unknown as ApiError);

  return { createCafs };
};
