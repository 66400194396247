import React from "react";
import { FormattedMessage } from "react-intl";

import { NotFound } from "../../../components/icons/NotFound";

export function NoResult() {
  return (
    <div className="flex flex-row items-center justify-center gap-2">
      <NotFound className="text-orange-500 text-4xl font-semibold" />
      <p className="text-gray-500">
        <FormattedMessage
          id="CAFS_TREE.NO_SEARCH_RESULT"
          defaultMessage="No matching results found."
          description="Message text on CAFS tree when there is no search results"
        />
      </p>
    </div>
  );
}
