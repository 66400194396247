import { useMatch } from "react-router-dom";

import { useMutation } from "@tanstack/react-query";

import { Inputs } from "../../../api/inputs";
import { Plants } from "../../../api/plants";
import { MutationKey } from "../../../consts/mutation-keys";
import { QueryKey } from "../../../consts/query-keys";
import { useApiError } from "../../../hooks/useApiError";
import { useApiSuccess } from "../../../hooks/useApiSuccess";

export const useAssignToDatasetDetails = (
  datasetId: string,
  plantId: string,
) => {
  const matchInput = useMatch("/dashboard/input/:datasetId/:id");
  const { handleSuccess } = useApiSuccess({
    invalidate: [[QueryKey.CafsDetails]],
    snackbarText: "Dataset assigned",
  });
  const { handleError } = useApiError();

  const mutationFn = (newDataset: number) => {
    return matchInput
      ? Inputs.datasetAssign({
          path: { datasetId: newDataset, inputId: +plantId },
        })
      : Plants.datasetAssign({
          path: { datasetId: newDataset, plantId: +plantId },
        });
  };

  const { mutate: assignDataset } = useMutation({
    mutationKey: [MutationKey.DatasetAssign, datasetId, plantId],
    mutationFn: mutationFn,
    onSuccess: handleSuccess,
    onError: handleError,
  });

  return { assignDataset };
};
