import * as yup from "yup";

import { PlantDetailsForm } from "../../../../types/plant-details-form";

export const plantFormSchema = yup.object<PlantDetailsForm>({
  EnglishName: yup.object().shape({
    Base: yup
      .string()
      .transform(value => (!value?.length ? null : value))
      .required("EnglishName field is required"),
  }),
  Specification: yup.object().shape({ Base: yup.string().nullable() }),
  ScientificName: yup.object().shape({ Base: yup.string().nullable() }),
  GeneralRemarks: yup.object().shape({ Base: yup.string().nullable() }),
});
