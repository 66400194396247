import { defineMessages } from "react-intl";

export const menuMessages = defineMessages({
  dashboard: { id: "MENU_DASHBOARD", defaultMessage: "Dashboard" },
  dataset: { id: "MENU.USERS", defaultMessage: "Dataset" },
  price: { id: "MENU.USERS", defaultMessage: "Price" },
  products: { id: "MENU.USERS", defaultMessage: "Products" },
  tooltips_settings: { id: "MENU.USERS", defaultMessage: "Tooltips settings" },
  references: { id: "MENU.USERS", defaultMessage: "References" },
});
