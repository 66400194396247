import { createTheme } from "@mui/material";
import type {} from "@mui/x-tree-view/themeAugmentation";

const baseTheme = createTheme({
  palette: {
    primary: {
      main: "#04623e",
      contrastText: "#fff",
    },
  },
});
export const theme = createTheme(baseTheme, {
  palette: {
    primary: {
      main: "#04623e",
      contrastText: "#fff",
    },
    secondary: {
      main: "#658b30",
      contrastText: "#fff",
    },
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        root: {
          "& :focus": {
            outline: "none",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          "&::placeholder": {
            fontSize: "12px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            lineHeight: "24px",
            opacity: 0.7,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "12px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          lineHeight: "24px",
          "&.Mui-focused": {
            fontSize: "16px",
          },
          "&.MuiInputLabel-shrink": {
            fontSize: "16px",
          },
        },
      },
    },
    MuiTreeItem: {
      styleOverrides: {
        root: {
          "& > .MuiTreeItem-content": {
            paddingBottom: "6px",
            paddingTop: "6px",
          },
          "& > .MuiTreeItem-content.Mui-selected": {
            backgroundColor: "#eceff1",
            "&:hover": {
              backgroundColor: "#eceff1",
            },
          },
          "& > .MuiTreeItem-content.Mui-focused!important": {
            backgroundColor: "inherit",
          },
          "& > .MuiTreeItem-content:hover": {
            backgroundColor: "#eceff1!important",
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
            {
              display: "none",
            },
          "& input[type=number]": {
            MozAppearance: "textfield",
          },

          "& .MuiInputBase-root": {
            "& .MuiInputBase-input": {
              color: "rgba(0, 0, 0, 0.7)",
            },
          },

          "& .MuiTextField-root": {
            "& .MuiOutlinedInput-root": {
              "& .MuiOutlinedInput-input": {
                color: "inherit",
                backgroundColor: "transparent",
              },
              "& fieldset": {
                borderColor: "transparent !important",
              },
            },
            display: "none!importatnt",
          },

          "& .MuiInputBase-root.MuiInput-root": {
            color: "inherit",
            "&:before": {
              borderBottom: "1px solid transparent !important",
            },
            "&:hover:not(.Mui-disabled):before": {
              borderBottom: "1px solid transparent !important",
            },
            "&:after": {
              borderBottom: "1px solid transparent !important",
            },
          },
          "&.edited": {
            backgroundColor: "#fdf5e6!important",
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: "bold",
          backgroundColor: "#A6B1B7",
          color: "#ffffff!important",
          height: "60px",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:not(.draftRow):hover": {
            backgroundColor: "#F0F2F4!important",
          },
          "&.highlight": {
            backgroundColor: "#F2F6EF!important",
          },
          "&:nth-of-type(odd)": {
            backgroundColor: "#fafafa",
          },
          "&.draftRow": {
            backgroundColor: "rgba(255, 167, 38, 0.1)",
          },
          "&.draftRow:hover": {
            backgroundColor: "rgba(255, 167, 38, 0.2)",
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
          borderColor: "red",
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          "& .MuiGrid-item": {
            margin: 0,
            padding: 0,
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          margin: "0!important",
          padding: "0!important",
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        toolbar: {
          [baseTheme.breakpoints.between("xs", "sm")]: {
            paddingLeft: 0,
            paddingRight: 0,
          },
        },
        select: {
          [baseTheme.breakpoints.between("xs", "sm")]: {
            paddingLeft: 0,
            paddingRight: 0,
          },
        },
        actions: {
          "& .MuiIconButton-root": {
            color: "#fff",
            backgroundColor: baseTheme.palette.primary.main,
            borderRadius: "5px",
            marginLeft: "5px",
          },
          [baseTheme.breakpoints.between("xs", "sm")]: {
            marginLeft: 0,
            marginRight: 0,
            padding: 0,
          },
        },
      },
    },
  },
});
