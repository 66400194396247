import React from "react";
import { FormattedMessage } from "react-intl";

import { GuestResponseToPressure } from "../../../../api/types/guests";
import { ConfirmationModal } from "../../../../components/ConfirmationModal";
import { Trash } from "../../../../components/icons/trash";
import { useGuestPressureDelete } from "../../api/useGuestPressureDelete";

export type DeleteGuestPressureModalProps = {
  open: boolean;
  onClose: () => void;
  guestId: number | undefined;
  pressure: GuestResponseToPressure;
};

export function DeleteGuestPressureModal({
  open,
  onClose,
  guestId,
  pressure,
}: DeleteGuestPressureModalProps) {
  const { deleteRtp, isPending } = useGuestPressureDelete(onClose);

  function handleDeleteRtp() {
    deleteRtp({
      guestId: guestId!,
      guestResponseToPressureId: pressure?.Id!,
    });
  }

  return (
    <ConfirmationModal
      onConfirm={handleDeleteRtp}
      onCancel={onClose}
      okText="Delete"
      icon={<Trash />}
      variant="error"
      disabled={isPending}
      header="Delete Plant Guest Response to Pressure"
      text={
        <FormattedMessage
          id="MODAL.GUEST_DELETE_PRESSURE_MSG"
          defaultMessage="Are you sure you want to delete this <span1>{category}</span1> response to pressure for <span2>{category}</span2> guest? If the parent plant guest has a <span3>{category}</span3> response to pressure, this guest response to pressure will be replaced by the <span4>{category}</span4> guest response to pressure from the parent plant."
          values={{
            category: pressure?.Category?.Current ?? pressure.Category?.Base,
            span1: chunks => <span className="font-semibold">{chunks}</span>,
            span2: chunks => (
              <span className="font-semibold text-primary">{chunks}</span>
            ),
            span3: chunks => <span className="font-semibold">{chunks}</span>,
            span4: chunks => <span className="font-semibold">{chunks}</span>,
          }}
        />
      }
      open={open}
    />
  );
}
