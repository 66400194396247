import { FormattedMessage } from "react-intl";

import { Date } from "../../../components/icons/Date";
import { formatDate } from "../../../utils/format-date";

import { CafsHistory } from "./cafs-history/CafsHistory";

export type UpdateProps = {
  date: string | null | undefined;
};

export function UpdateInfo({ date }: UpdateProps) {
  return (
    <div className="flex flex-col text-darkGray h-[70px]">
      <div className="flex flex-row gap-2 mb-2 items-start flex-1">
        <Date className="text-2xl text-primary" />
        <span className="font-semibold">
          <FormattedMessage
            id="PLANT_VIEW.LAST_UPDATE_LABEL"
            defaultMessage="Last updated on"
            description="Update on label on plant page view"
          />
        </span>
      </div>
      <div className="flex flex-row gap-4 items-center flex-1">
        <div className="text-nowrap">{formatDate(date)}</div>
        <CafsHistory />
      </div>
    </div>
  );
}
