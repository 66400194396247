import { PlantResponseToPressure } from "../../../../../api/types/plants";
import { createOptions } from "../../../../../utils/create-options";
import { CafsVariableType } from "../../../const/cafs-variable-type";
import { CafsVariablesCollection } from "../../cafs-table/CafsTable";

export type UsePressureVariablesProps = {
  categories: readonly string[] | null | undefined;
  targets: readonly string[] | null | undefined;
};

export function usePressureVariables({
  categories,
  targets,
}: UsePressureVariablesProps): CafsVariablesCollection<PlantResponseToPressure> {
  const categoryOptions = createOptions(categories);
  const targetOptions = createOptions(targets);

  return [
    { name: "Remarks", type: CafsVariableType.Remarks },
    {
      name: "Category",
      type: CafsVariableType.Select,
      options: categoryOptions,
    },
    {
      name: "TargetSuitability",
      type: CafsVariableType.Select,
      options: targetOptions,
    },
    {
      name: "MaxEffect",
      type: CafsVariableType.Input,
      options: [],
    },
  ];
}
