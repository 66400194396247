import { useContext, useEffect } from "react";
import { UseFormReturn } from "react-hook-form";

import { PlantProduct } from "../../../../api/types/plants";
import { CafsVariableType } from "../../const/cafs-variable-type";
import { useCategoriesOptions } from "../../hooks/useCategoriesOptions";
import { usePlantPartsOptions } from "../../hooks/usePlantPartsOptions";
import { useProductCodesOptions } from "../../hooks/useProductCodesOptions";
import { useProductsOptions } from "../../hooks/useProductsOptions";
import { ProductsContext } from "../../ProductsContext";
import { CafsVariablesCollection } from "../cafs-table/CafsTable";
import { renderForCrop } from "../cafs-table/utils/render-for-crop";

import { renderForP1 } from "./render-for-p1";

export function useProductCreateVariables(
  methods: UseFormReturn,
): CafsVariablesCollection<PlantProduct> {
  const { plantProducts, allProducts } = useContext(ProductsContext);
  const { options: categories } = useCategoriesOptions(plantProducts);
  const { options: products } = useProductsOptions(allProducts);
  const { options: plantParts } = usePlantPartsOptions(plantProducts);

  const { options: availableCodes } = useProductCodesOptions(plantProducts);

  const code = methods.watch("Code.Base");

  useEffect(() => {
    if (code !== "P1") {
      methods.setValue("YToOptProd.Base", "");
      methods.setValue("YWithOptProd.Base", "");
      methods.setValue("ProdEndCycleFraction.Base", "");
      methods.setValue("MaxFreshProductionAnnual.Base", "");
    }
  }, [code]);

  return [
    {
      type: CafsVariableType.Select,
      name: "Category",
      options: categories,
      required: true,
    },
    {
      type: CafsVariableType.Select,
      name: "PlantPart",
      options: plantParts,
      required: true,
    },
    {
      type: CafsVariableType.Select,
      name: "Code",
      options: availableCodes,
      required: true,
    },
    {
      type: CafsVariableType.Select,
      name: "ProductId",
      options: products,
      required: true,
    },
    {
      type: CafsVariableType.Input,
      name: "DMContentFraction",
    },
    {
      type: CafsVariableType.Input,
      name: "DegradationAnnualFraction",
    },
    {
      type: CafsVariableType.Input,
      name: "HarvestYears",
      text: true,
    },
    {
      type: CafsVariableType.Input,
      name: "HarvestMonths",
      text: true,
    },
    {
      type: CafsVariableType.Input,
      name: "HarvestAgeMonths",
      text: true,
      renderCondition: renderForCrop,
    },
    {
      type: CafsVariableType.Input,
      name: "HarvestFractions",
      text: true,
    },
    {
      type: CafsVariableType.Input,
      name: "BurnAtHarvestFraction",
    },
    {
      type: CafsVariableType.Input,
      name: "RemoveAtHarvestFraction",
    },
    {
      type: CafsVariableType.Input,
      name: "DecompPYearFraction",
    },
    {
      type: CafsVariableType.Input,
      name: "ProductFreshName",
      text: true,
    },
    {
      type: CafsVariableType.Input,
      name: "ProductDryName",
      text: true,
    },
    {
      type: CafsVariableType.Input,
      name: "Y1stProd",
    },
    {
      type: CafsVariableType.Boolean,
      name: "PriceValidForDryProduct",
    },
    {
      type: CafsVariableType.Input,
      name: "YToOptProd",
      renderCondition: renderForP1,
    },
    {
      type: CafsVariableType.Input,
      name: "YWithOptProd",
      renderCondition: renderForP1,
    },
    {
      type: CafsVariableType.Input,
      name: "ProdEndCycleFraction",
      renderCondition: renderForP1,
    },
    {
      type: CafsVariableType.Info,
      name: "MaxFreshProductionAnnual",
      renderCondition: renderForP1,
    },
    {
      type: CafsVariableType.Input,
      name: "HarvestKgFreshPDay",
    },
    {
      type: CafsVariableType.Input,
      name: "NkgPTDM",
    },
    {
      type: CafsVariableType.Input,
      name: "PkgPTDM",
    },
    {
      type: CafsVariableType.Input,
      name: "KkgPTDM",
    },
    {
      type: CafsVariableType.Boolean,
      name: "IsBelowGroundBiomass",
    },
  ];
}
