import { createApiOperation } from "../api-client/create-api-operation";

import {
  Plant,
  PlantAudits,
  PlantBiodiversity,
  PlantBiomassAllocation,
  PlantConstraint,
  PlantCosts,
  PlantGrowth,
  PlantGrowthConditions,
  PlantGuest,
  PlantGuests,
  PlantLabour,
  PlantLifeCycle,
  PlantProduct,
  PlantProducts,
  PlantResponseToPressures,
  PlantRoots,
  PlantWoodDensity,
} from "./types/plants";

export const Plants = {
  approve: createApiOperation<
    never,
    "/Api/Plants/{datasetId}/{plantId}/Approve",
    "patch"
  >("/Api/Plants/{datasetId}/{plantId}/Approve", "patch"),

  reject: createApiOperation<
    never,
    "/Api/Plants/{datasetId}/{plantId}/Reject",
    "patch"
  >("/Api/Plants/{datasetId}/{plantId}/Reject", "patch"),

  updateDetails: createApiOperation<
    never,
    "/Api/Plants/{datasetId}/{plantId}",
    "put"
  >("/Api/Plants/{datasetId}/{plantId}", "put"),

  getGrowth: createApiOperation<
    PlantGrowth,
    "/Api/Plants/{datasetId}/{plantId}/Growth",
    "get"
  >("/Api/Plants/{datasetId}/{plantId}/Growth", "get"),

  updateGrowth: createApiOperation<
    never,
    "/Api/Plants/{datasetId}/{plantId}/Growth",
    "put"
  >("/Api/Plants/{datasetId}/{plantId}/Growth", "put"),

  getLifeCycle: createApiOperation<
    PlantLifeCycle,
    "/Api/Plants/{datasetId}/{plantId}/LifeCycle",
    "get"
  >("/Api/Plants/{datasetId}/{plantId}/LifeCycle", "get"),

  updateLifeCycle: createApiOperation<
    never,
    "/Api/Plants/{datasetId}/{plantId}/LifeCycle",
    "put"
  >("/Api/Plants/{datasetId}/{plantId}/LifeCycle", "put"),

  getWoodDensity: createApiOperation<
    PlantWoodDensity,
    "/Api/Plants/{datasetId}/{plantId}/WoodDensity",
    "get"
  >("/Api/Plants/{datasetId}/{plantId}/WoodDensity", "get"),

  getBiodiversity: createApiOperation<
    PlantBiodiversity,
    "/Api/Plants/{datasetId}/{plantId}/Biodiversity",
    "get"
  >("/Api/Plants/{datasetId}/{plantId}/Biodiversity", "get"),

  updateBiodiversity: createApiOperation<
    never,
    "/Api/Plants/{datasetId}/{plantId}/Biodiversity",
    "put"
  >("/Api/Plants/{datasetId}/{plantId}/Biodiversity", "put"),

  updateWoodDensity: createApiOperation<
    never,
    "/Api/Plants/{datasetId}/{plantId}/WoodDensity",
    "put"
  >("/Api/Plants/{datasetId}/{plantId}/WoodDensity", "put"),

  getBiomassAllocation: createApiOperation<
    PlantBiomassAllocation,
    "/Api/Plants/{datasetId}/{plantId}/BiomassAllocation",
    "get"
  >("/Api/Plants/{datasetId}/{plantId}/BiomassAllocation", "get"),

  updateBiomassAllocation: createApiOperation<
    never,
    "/Api/Plants/{datasetId}/{plantId}/BiomassAllocation",
    "put"
  >("/Api/Plants/{datasetId}/{plantId}/BiomassAllocation", "put"),

  getRoots: createApiOperation<
    PlantRoots,
    "/Api/Plants/{datasetId}/{plantId}/Root",
    "get"
  >("/Api/Plants/{datasetId}/{plantId}/Root", "get"),

  updateRoots: createApiOperation<
    never,
    "/Api/Plants/{datasetId}/{plantId}/Root",
    "put"
  >("/Api/Plants/{datasetId}/{plantId}/Root", "put"),

  getConstraints: createApiOperation<
    PlantConstraint,
    "/Api/Plants/{datasetId}/{plantId}/Constraint",
    "get"
  >("/Api/Plants/{datasetId}/{plantId}/Constraint", "get"),

  updateConstraints: createApiOperation<
    never,
    "/Api/Plants/{datasetId}/{plantId}/Constraint",
    "put"
  >("/Api/Plants/{datasetId}/{plantId}/Constraint", "put"),

  getGrowthConditions: createApiOperation<
    PlantGrowthConditions,
    "/Api/Plants/{datasetId}/{plantId}/GrowthCondition",
    "get"
  >("/Api/Plants/{datasetId}/{plantId}/GrowthCondition", "get"),

  updateGrowthConditions: createApiOperation<
    never,
    "/Api/Plants/{datasetId}/{plantId}/GrowthCondition",
    "put"
  >("/Api/Plants/{datasetId}/{plantId}/GrowthCondition", "put"),

  getCosts: createApiOperation<
    PlantCosts,
    "/Api/Plants/{datasetId}/{plantId}/Cost",
    "get"
  >("/Api/Plants/{datasetId}/{plantId}/Cost", "get"),

  updateCosts: createApiOperation<
    never,
    "/Api/Plants/{datasetId}/{plantId}/Cost",
    "put"
  >("/Api/Plants/{datasetId}/{plantId}/Cost", "put"),

  getLabour: createApiOperation<
    PlantLabour,
    "/Api/Plants/{datasetId}/{plantId}/Labour",
    "get"
  >("/Api/Plants/{datasetId}/{plantId}/Labour", "get"),

  updateLabour: createApiOperation<
    never,
    "/Api/Plants/{datasetId}/{plantId}/Labour",
    "put"
  >("/Api/Plants/{datasetId}/{plantId}/Labour", "put"),

  getDetails: createApiOperation<
    Plant,
    "/Api/Plants/{datasetId}/{plantId}",
    "get"
  >("/Api/Plants/{datasetId}/{plantId}", "get"),

  create: createApiOperation<
    Plant,
    "/Api/Plants/{datasetId}/{plantId}",
    "post"
  >("/Api/Plants/{datasetId}/{plantId}", "post"),

  createRoot: createApiOperation<
    Plant,
    "/Api/Plants/{datasetId}/{plantType}",
    "post"
  >("/Api/Plants/{datasetId}/{plantType}", "post"),

  getProducts: createApiOperation<
    PlantProducts,
    "/Api/Plants/{datasetId}/{plantId}/Products",
    "get"
  >("/Api/Plants/{datasetId}/{plantId}/Products", "get"),

  getGuests: createApiOperation<
    PlantGuests,
    "/Api/Plants/{datasetId}/{plantId}/Guests",
    "get"
  >("/Api/Plants/{datasetId}/{plantId}/Guests", "get"),

  createProducts: createApiOperation<
    PlantProduct,
    "/Api/Plants/{datasetId}/{plantId}/Product",
    "post"
  >("/Api/Plants/{datasetId}/{plantId}/Product", "post"),

  createGuests: createApiOperation<
    PlantGuest,
    "/Api/Plants/{datasetId}/{plantId}/Guest",
    "post"
  >("/Api/Plants/{datasetId}/{plantId}/Guest", "post"),

  updateProduct: createApiOperation<
    never,
    "/Api/Plants/{datasetId}/{plantId}/Product/{plantProductId}",
    "put"
  >("/Api/Plants/{datasetId}/{plantId}/Product/{plantProductId}", "put"),

  deletePlantProduct: createApiOperation<
    never,
    "/Api/Plants/{datasetId}/{plantId}/Product/{plantProductId}",
    "delete"
  >("/Api/Plants/{datasetId}/{plantId}/Product/{plantProductId}", "delete"),

  deletePlantGuest: createApiOperation<
    never,
    "/Api/Plants/{datasetId}/{plantId}/Guest/{guestId}",
    "delete"
  >("/Api/Plants/{datasetId}/{plantId}/Guest/{guestId}", "delete"),

  delete: createApiOperation<
    never,
    "/Api/Plants/{datasetId}/{plantId}",
    "delete"
  >("/Api/Plants/{datasetId}/{plantId}", "delete"),

  updateGuest: createApiOperation<
    never,
    "/Api/Plants/{datasetId}/{plantId}/Guest/{guestId}",
    "put"
  >("/Api/Plants/{datasetId}/{plantId}/Guest/{guestId}", "put"),

  getResponsesToPressure: createApiOperation<
    PlantResponseToPressures,
    "/Api/Plants/{datasetId}/{plantId}/ResponseToPressures",
    "get"
  >("/Api/Plants/{datasetId}/{plantId}/ResponseToPressures", "get"),

  createResponsesToPressure: createApiOperation<
    PlantResponseToPressures,
    "/Api/Plants/{datasetId}/{plantId}/ResponseToPressure",
    "post"
  >("/Api/Plants/{datasetId}/{plantId}/ResponseToPressure", "post"),

  updateResponsesToPressure: createApiOperation<
    never,
    "/Api/Plants/{datasetId}/{plantId}/ResponseToPressure/{plantResponseToPressureId}",
    "put"
  >(
    "/Api/Plants/{datasetId}/{plantId}/ResponseToPressure/{plantResponseToPressureId}",
    "put",
  ),

  deleteResponsesToPressure: createApiOperation<
    never,
    "/Api/Plants/{datasetId}/{plantId}/ResponseToPressure/{plantResponseToPressureId}",
    "delete"
  >(
    "/Api/Plants/{datasetId}/{plantId}/ResponseToPressure/{plantResponseToPressureId}",
    "delete",
  ),

  datasetAssign: createApiOperation<
    never,
    "/Api/Plants/{datasetId}/{plantId}/Assign",
    "patch"
  >("/Api/Plants/{datasetId}/{plantId}/Assign", "patch"),

  datasetUnassign: createApiOperation<
    never,
    "/Api/Plants/{datasetId}/{plantId}/Unassign",
    "patch"
  >("/Api/Plants/{datasetId}/{plantId}/Unassign", "patch"),

  copy: createApiOperation<
    Plant,
    "/Api/Plants/{datasetId}/{plantId}/Copy",
    "post"
  >("/Api/Plants/{datasetId}/{plantId}/Copy", "post"),

  getHistory: createApiOperation<
    PlantAudits,
    "/Api/Audits/Plant/{plantId}",
    "get"
  >("/Api/Audits/Plant/{plantId}", "get"),
};
