import { CafsValueError } from "../../types/cafs-value-error";

//eslint-disable-next-line
export function isCustomFieldError(error: any): error is CafsValueError {
  return (
    error &&
    typeof error.Base === "object" &&
    error.Base !== null &&
    "message" in error.Base
  );
}
