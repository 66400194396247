import { useMutation } from "@tanstack/react-query";

import { References } from "../../../api/references";
import { Reference } from "../../../api/types/references";
import { MutationKey } from "../../../consts/mutation-keys";
import { QueryKey } from "../../../consts/query-keys";
import { useApiError } from "../../../hooks/useApiError";
import { useApiSuccess } from "../../../hooks/useApiSuccess";
import { ApiHookConfig } from "../../../types/api-hook-config";

type UseReferencesDeleteProps = ApiHookConfig & {
  reference: Reference | undefined | null;
};

export function useReferenceDelete({
  onSuccess,
  reference,
}: UseReferencesDeleteProps) {
  const { handleSuccess } = useApiSuccess({
    snackbarText: "Reference deleted.",
    invalidate: [[QueryKey.References], [QueryKey.AllReferencesList]],
    onSuccessFn: onSuccess,
  });

  const { handleError, apiErrors, clearApiErrors } = useApiError();

  const mutationFn = () =>
    References.delete({ path: { referenceId: reference?.Id! } });

  const { mutate: deleteReference, isPending: deleteReferencePending } =
    useMutation({
      mutationKey: [MutationKey.CreateReference],
      mutationFn: mutationFn,
      onSuccess: handleSuccess,
      onError: handleError,
      retry: 0,
    });

  return { deleteReference, deleteReferencePending, apiErrors, clearApiErrors };
}
