import { IconProps } from "../../types/icon-props";

export function Dashboard(props: IconProps) {
  return (
    <svg
      height="1em"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g clipPath="url(#clip0_88_108)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2ZM13 4.062V5C12.9997 5.25488 12.9021 5.50003 12.7272 5.68537C12.5522 5.8707 12.313 5.98223 12.0586 5.99717C11.8042 6.01211 11.5536 5.92933 11.3582 5.76574C11.1627 5.60214 11.0371 5.3701 11.007 5.117L11 5V4.062C9.28177 4.27898 7.68025 5.04734 6.43578 6.25179C5.19131 7.45623 4.37102 9.03178 4.098 10.742L4.062 11H5C5.25488 11.0003 5.50003 11.0979 5.68537 11.2728C5.8707 11.4478 5.98223 11.687 5.99717 11.9414C6.01211 12.1958 5.92933 12.4464 5.76574 12.6418C5.60214 12.8373 5.3701 12.9629 5.117 12.993L5 13H4.062C4.30363 14.9112 5.22674 16.6711 6.66175 17.9564C8.09677 19.2417 9.94734 19.966 11.8735 19.9964C13.7998 20.0268 15.6723 19.3612 17.1471 18.1219C18.622 16.8825 19.6002 15.1527 19.902 13.25L19.938 13H19C18.7451 12.9997 18.5 12.9021 18.3146 12.7272C18.1293 12.5522 18.0178 12.313 18.0028 12.0586C17.9879 11.8042 18.0707 11.5536 18.2343 11.3582C18.3979 11.1627 18.6299 11.0371 18.883 11.007L19 11H19.938C19.7201 9.25614 18.9323 7.63308 17.697 6.383L15.273 11.142L15.118 11.436L14.808 12.046C14.438 12.766 14.036 13.5 13.485 14.051C12.513 15.022 10.897 15.14 9.879 14.121C8.86 13.103 8.978 11.487 9.949 10.515C10.421 10.043 11.027 9.68 11.645 9.353L12.564 8.882L13.413 8.438L17.616 6.303C16.3662 5.06794 14.7435 4.28016 13 4.062ZM13.162 10.838L12.952 10.95L12.736 11.063C12.334 11.272 11.914 11.489 11.564 11.761L11.363 11.931L11.29 12.015C11.097 12.275 11.155 12.569 11.293 12.707C11.431 12.845 11.725 12.903 11.985 12.71L12.071 12.636L12.239 12.436C12.456 12.156 12.639 11.831 12.81 11.506L12.937 11.264C13.012 11.118 13.087 10.974 13.162 10.838Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_88_108">
          <rect width="24" height="24" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
}
