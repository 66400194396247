export const mapErrors = (
  errors: { [s: string]: unknown } | ArrayLike<unknown>,
) => {
  let mappedErrors: { [s: string]: unknown } = {};
  for (let [key, value] of Object.entries(errors)) {
    let newKey = key.replace("$.", "");
    mappedErrors[newKey] = value;
  }
  return mappedErrors;
};
