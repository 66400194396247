import { createApiOperation } from "../api-client/create-api-operation";

export const Csv = {
  uploadDataSet: createApiOperation<never, "/Api/Csv/Dataset", "post">(
    "/Api/Csv/Dataset",
    "post",
  ),
  uploadPrices: createApiOperation<never, "/Api/Csv/Prices", "post">(
    "/Api/Csv/Prices",
    "post",
  ),
};
