import { useIntl } from "react-intl";
import { useMatch, useParams } from "react-router-dom";

import { useMutation } from "@tanstack/react-query";

import { Inputs } from "../../../api/inputs";
import { Plants } from "../../../api/plants";
import { PlantGuest } from "../../../api/types/plants";
import { MutationKey } from "../../../consts/mutation-keys";
import { QueryKey } from "../../../consts/query-keys";
import { useApiError } from "../../../hooks/useApiError";
import { useApiSuccess } from "../../../hooks/useApiSuccess";
import { CafsForm } from "../../../types/cafs-form";

export const useGuestUpdate = (guestId: number) => {
  const { datasetId, id } = useParams();
  const matchInput = useMatch("/dashboard/input/:datasetId/:id");
  const { formatMessage } = useIntl();

  const { handleError, apiErrors } = useApiError();
  const { handleSuccess } = useApiSuccess({
    invalidate: [[QueryKey.PlantGuests], [QueryKey.CafsDetails]],
    snackbarText: formatMessage({
      id: "ACTIONS.GUEST_UPDATE_SUCCESS",
      defaultMessage: "Guest updated successfully.",
      description: "Guest update success message",
    }),
  });

  const mutationFn = (newData: Partial<CafsForm<PlantGuest>>) => {
    return matchInput
      ? Inputs.updateGuest(
          {
            path: {
              guestId: guestId,
              datasetId: +datasetId!,
              inputId: +id!,
            },
          },
          newData,
        )
      : Plants.updateGuest(
          {
            path: {
              guestId: guestId,
              datasetId: +datasetId!,
              plantId: +id!,
            },
          },
          newData,
        );
  };

  const { mutate: updateGuest } = useMutation({
    mutationKey: [MutationKey.UpdatePlantGuest, +datasetId!, +id!],
    mutationFn: mutationFn,
    onError: handleError,
    onSuccess: handleSuccess,
  });

  return { apiErrors, updateGuest };
};
