import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import { useMutation } from "@tanstack/react-query";

import { Plants } from "../../../api/plants";
import { PlantResponseToPressure } from "../../../api/types/plants";
import { MutationKey } from "../../../consts/mutation-keys";
import { QueryKey } from "../../../consts/query-keys";
import { useApiError } from "../../../hooks/useApiError";
import { useApiSuccess } from "../../../hooks/useApiSuccess";
import { ApiHookConfig } from "../../../types/api-hook-config";
import { CafsForm } from "../../../types/cafs-form";

export const usePlantPressureCreate = ({ onSuccess }: ApiHookConfig) => {
  const { datasetId, id } = useParams();
  const { formatMessage } = useIntl();
  const { handleError, apiErrors, clearApiErrors } = useApiError();
  const { handleSuccess } = useApiSuccess({
    onSuccessFn: onSuccess,
    invalidate: [[QueryKey.PlantRtp, datasetId!, id!], [QueryKey.CafsDetails]],
    snackbarText: formatMessage({
      id: "ACTIONS.CREATE_PRESSURE_SUCCESS",
      defaultMessage: "Response to pressure created successfully",
    }),
  });

  const mutationFn = (data: Partial<CafsForm<keyof PlantResponseToPressure>>) =>
    Plants.createResponsesToPressure(
      {
        path: { datasetId: +datasetId!, plantId: +id! },
      },
      data,
    );

  const { mutate: createRtp, isPending } = useMutation({
    mutationKey: [MutationKey.CreatePlantRtp],
    mutationFn: mutationFn,
    onSuccess: handleSuccess,
    onError: handleError,
  });

  return { createRtp, apiErrors, clearApiErrors, isPending };
};
