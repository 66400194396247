import * as yup from "yup";

import {
  CafsValueRemarksValidation,
  emptyToNullTransform,
  FractionValidation,
} from "consts/validation";

import { PlantProduct } from "../../../../api/types/plants";
import { CafsForm } from "../../../../types/cafs-form";

export const createProductSchema = yup.object<
  Partial<CafsForm<keyof PlantProduct>>
>({
  DMContentFraction: yup.object({ Base: FractionValidation().default(null) }),
  NPKAllocFraction: yup.object({ Base: FractionValidation().default(null) }),
  DegradationAnnualFraction: yup.object({
    Base: FractionValidation().default(null),
  }),
  HarvestInterimOfStVolFraction: yup.object({
    Base: FractionValidation().default(null),
  }),
  BurnAtHarvestFraction: yup.object({
    Base: FractionValidation().default(null),
  }),
  RemoveAtHarvestFraction: yup.object({
    Base: FractionValidation().default(null),
  }),
  HarvestFullRhythmMonths: yup.object({
    Base: yup.number().nullable().default(null).transform(emptyToNullTransform),
  }),
  HarvestInterimRhythmMonths: yup.object({
    Base: yup.number().nullable().transform(emptyToNullTransform).default(null),
  }),
  DecompPYearFraction: yup.object({ Base: FractionValidation().default(null) }),
  HarvestYears: yup.object({
    Base: yup
      .string()
      .nullable()
      .matches(/^[0-9;/-]*$/, {
        message:
          "HarvestYears may only contain digits (0 to 9), semi-colons, dashes, and forward slashes",
        excludeEmptyString: true,
      })
      .transform((value, originalValue) =>
        !value || Number.isNaN(value) ? null : originalValue,
      ),
  }),
  HarvestAgeMonths: yup.object({
    Base: yup
      .string()
      .nullable()
      .matches(/^[0-9;/-]*$/, {
        message:
          "HarvestAgeMonths may only contain digits (0 to 9), semi-colons, dashes, and forward slashes",
        excludeEmptyString: true,
      })
      .transform((value, originalValue) =>
        !value || Number.isNaN(value) ? null : originalValue,
      ),
  }),
  HarvestMonths: yup.object({
    Base: yup
      .string()
      .nullable()
      .matches(/^[0-9;/-]*$/, {
        message:
          "HarvestMonths may only contain digits (0 to 9), semi-colons, dashes, and forward slashes",
        excludeEmptyString: true,
      })
      .transform((value, originalValue) =>
        !value || Number.isNaN(value) ? null : originalValue,
      ),
  }),
  HarvestFractions: yup.object({
    Base: yup
      .string()
      .nullable()
      .matches(/^[0-9.;]*$/, {
        message:
          "HarvestFractions may only contain digits (0 to 9), semi-colons and dots",
        excludeEmptyString: true,
      })
      .transform((value, originalValue) =>
        !value || Number.isNaN(value) ? null : originalValue,
      ),
  }),
  Y1stProd: yup.object({
    Base: yup.number().nullable().transform(emptyToNullTransform).default(null),
  }),
  HarvestKgFreshPDay: yup.object({
    Base: yup.number().nullable().transform(emptyToNullTransform).default(null),
  }),
  PriceLowOfHighFraction: yup.object({
    Base: FractionValidation().default(null),
  }),
  YWithOptProd: yup.object({
    Base: yup.number().nullable().transform(emptyToNullTransform).default(null),
  }),
  ProdEndCycleFraction: yup.object({
    Base: FractionValidation().default(null),
  }),
  MaxFreshProductionAnnual: yup.object({
    Base: yup.number().nullable().transform(emptyToNullTransform).default(null),
  }),
  YToOptProd: yup.object({
    Base: yup.number().nullable().transform(emptyToNullTransform).default(null),
  }),
  GeneralRemarks: CafsValueRemarksValidation(),
  Code: yup.object({
    Base: yup
      .string()
      .nullable()
      .transform(emptyToNullTransform)
      .default(null)
      .required(),
  }),
  ProductId: yup.object({
    Base: yup
      .number()
      .nullable()
      .transform(emptyToNullTransform)
      .default(null)
      .required(),
  }),
  Category: yup.object({
    Base: yup
      .string()
      .nullable()
      .transform(emptyToNullTransform)
      .default(null)
      .required(),
  }),
  PlantPart: yup.object({
    Base: yup
      .string()
      .nullable()
      .transform(emptyToNullTransform)
      .default(null)
      .required(),
  }),
  ProductDryName: yup.object({
    Base: yup.string().nullable().transform(emptyToNullTransform).default(null),
  }),
  ProductFreshName: yup.object({
    Base: yup.string().nullable().transform(emptyToNullTransform).default(null),
  }),
});
