import React from "react";

import { ConfirmationModal } from "../../../components/ConfirmationModal";
import { Trash } from "../../../components/icons/trash";
import { CafsTreeNodeType } from "../../../types/cafs-tree-node-type";
import { useCafsDelete } from "../api/useCafsDelete";

export type DeleteCafsModalProps = {
  open: boolean;
  onClose: () => void;
  cafs: CafsTreeNodeType | undefined;
};

export function DeleteCafsModal({ open, onClose, cafs }: DeleteCafsModalProps) {
  const { deleteCafs, deleteCafsPending } = useCafsDelete({
    cafs: cafs,
    onSuccess: onClose,
  });

  return (
    <ConfirmationModal
      onConfirm={() => deleteCafs({ cafsId: cafs?.id!, type: cafs?.type })}
      onCancel={onClose}
      header="CAFS Delete"
      variant="error"
      okText="delete"
      icon={<Trash />}
      disabled={deleteCafsPending}
      text={
        <span className="text-shark">
          Are you sure you want to{" "}
          <span className="font-semibold text-red-500">delete</span>{" "}
          <span className="font-semibold">{cafs?.name}</span>?
        </span>
      }
      open={open}
    />
  );
}
