import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { Tooltip } from "@mui/material";

import { Edit } from "../../../components/icons/Edit";
import { Search } from "../../../components/icons/Search";
import { Access } from "../../../consts/access";
import { CafsMap } from "../../../consts/cafsMap";
import { CafsTreeNodeType } from "../../../types/cafs-tree-node-type";
import { DashboardContext } from "../DashboardContext";

type CafsEditProps = {
  node: CafsTreeNodeType;
};

export function CafsEdit({ node }: CafsEditProps) {
  const shouldRender = node.access !== Access.None && !node.isRoot;
  const { selectedDataset } = useContext(DashboardContext);
  const navigate = useNavigate();

  if (!shouldRender) {
    return null;
  }

  const handleCafsEdit = (node: CafsTreeNodeType) => {
    navigate(`${CafsMap.get(node.type)}/${selectedDataset?.Id}/${node.id}`);
  };

  switch (node.access) {
    case Access.Full:
      return (
        <Tooltip title="Edit in a temporary copy in EditDraft mode">
          <div>
            <Edit
              className="text-primary hover:text-orange-400 text-xl"
              onClick={() => handleCafsEdit(node)}
            />
          </div>
        </Tooltip>
      );
    case Access.Read:
      return (
        <Tooltip title="Read access only">
          <div>
            <Search
              className="text-primary hover:text-orange-400 mt-[1px] text-xl"
              onClick={() => handleCafsEdit(node)}
            />
          </div>
        </Tooltip>
      );
    default:
      return null;
  }
}
