import { FormattedMessage } from "react-intl";
import { NavLink } from "react-router-dom";

export function NotFoundView() {
  return (
    <div className="w-[100vw] h-[100vh] flex justify-center items-center">
      <div className="flex flex-col items-center justify-center">
        <p className="text-primary text-8xl font-bold">
          <FormattedMessage id="NOT_FOUND.NUMBER" defaultMessage="404" />
        </p>
        <p className="text-primary text-4xl">
          <FormattedMessage
            id="NOT_FOUND.TITLE"
            defaultMessage="Page not found"
          />
        </p>
        <div className="mt-2 hover:text-secondary">
          <NavLink to="/dashboard">
            <FormattedMessage
              id="NOT_FOUND.SUBTITLE"
              defaultMessage="go to dashboard"
            />
          </NavLink>
        </div>
      </div>
    </div>
  );
}
