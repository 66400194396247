import * as yup from "yup";

import { PlantGuest } from "../../../../api/types/plants";
import {
  CafsValueNumberValidation,
  CafsValueRemarksValidation,
  CafsValueStringValidation,
} from "../../../../consts/validation";
import { CafsForm } from "../../../../types/cafs-form";

export const guestEditSchema = yup.object<CafsForm<keyof PlantGuest>>({
  Name: CafsValueStringValidation(),
  Category: CafsValueStringValidation(),
  PressureMaxScale: CafsValueNumberValidation(),
  RpMonth: CafsValueNumberValidation(),
  InitialMonth: CafsValueNumberValidation(),
  DurationMonths: CafsValueStringValidation(),
  RhythmYears: CafsValueNumberValidation(),
  ContrToBiodiversity: CafsValueNumberValidation(),
  OptSOMtpHa: CafsValueNumberValidation(),
  SOMSensitivity: CafsValueNumberValidation(),
  GeneralRemarks: CafsValueRemarksValidation(),
});
