import { useParams } from "react-router-dom";

import { useQuery } from "@tanstack/react-query";

import { Inputs } from "../../../api/inputs";
import { QueryKey } from "../../../consts/query-keys";

export const useInputCostData = (shouldFetchData: boolean) => {
  const { datasetId, id } = useParams();

  const { data: costsData, isPending } = useQuery({
    queryKey: [QueryKey.InputCostSection, datasetId, id],
    queryFn: () =>
      Inputs.cost({
        path: { datasetId: +datasetId! as number, inputId: +id! as number },
      }).then(response => {
        return response;
      }),
    enabled: shouldFetchData,
    throwOnError: true,
  });

  return { costsData, isPending };
};
