import { IconProps } from "../../types/icon-props";

export function PriceTag(props: IconProps) {
  return (
    <svg
      height="1em"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fill="currentColor"
        d="M12.8701 22C12.3872 21.9995 11.9238 21.8091 11.5801 21.47L5.17005 15.05C5.00557 14.8865 4.90305 14.6708 4.88005 14.44L4.00005 5.09001C3.98658 4.94354 4.00559 4.7959 4.05571 4.65762C4.10584 4.51934 4.18585 4.39382 4.29005 4.29001C4.39387 4.18581 4.51939 4.10579 4.65767 4.05567C4.79595 4.00554 4.94358 3.98654 5.09005 4.00001L14.4401 4.88001C14.6709 4.903 14.8865 5.00552 15.0501 5.17001L21.4701 11.58C21.8102 11.9212 22.0012 12.3833 22.0012 12.865C22.0012 13.3468 21.8102 13.8088 21.4701 14.15L14.1501 21.47C13.8099 21.8085 13.3499 21.999 12.8701 22ZM6.87005 13.89L12.8701 19.89L19.9201 12.84L13.9201 6.84001L6.11005 6.11001L6.87005 13.89Z"
      />
      <path
        fill="currentColor"
        d="M10.5 12C11.3284 12 12 11.3284 12 10.5C12 9.67157 11.3284 9 10.5 9C9.67157 9 9 9.67157 9 10.5C9 11.3284 9.67157 12 10.5 12Z"
      />
    </svg>
  );
}
