import { PropsWithChildren, SyntheticEvent, useContext } from "react";
import { FormattedMessage } from "react-intl";
import { useMatch } from "react-router-dom";

import { AccordionDetails } from "@mui/material";
import { twMerge } from "tailwind-merge";

import { PlantGuest } from "../../../../api/types/plants";
import { ConfirmationModal } from "../../../../components/ConfirmationModal";
import { Trash } from "../../../../components/icons/trash";
import { LoadingPlaceholder } from "../../../../components/LoadingPlaceholder";
import { StyledAccordion } from "../../../../components/StyledAccordion";
import { StyledAccordionSummary } from "../../../../components/StyledAccordionSummary";
import { useModal } from "../../../../hooks/useModal";
import { useGuestDelete } from "../../api/useGuestDelete";
import { useGuestPressureData } from "../../api/useGuestPressureData";
import { CafsContext } from "../../CafsContext";
import { GuestPressures } from "../cafs-guest-pressures/GuestPressures";

export type PlantGuestItemProps = PropsWithChildren & {
  guest: PlantGuest;
  onChange: (event: SyntheticEvent<Element, Event>, expanded: boolean) => void;
  expanded: boolean;
};

export function GuestsListItem({
  children,
  guest,
  onChange,
  expanded,
}: PlantGuestItemProps) {
  const { isReadMode, cafs } = useContext(CafsContext);

  const matchInput = useMatch("/dashboard/input/:datasetId/:id");

  const { data, isPending } = useGuestPressureData(guest?.Id, expanded);
  const { open, handleOpen, handleClose } = useModal();

  const { deletePlantGuest } = useGuestDelete({
    onSuccess: handleClose,
  });

  return (
    <>
      <StyledAccordion onChange={onChange} expanded={expanded}>
        <StyledAccordionSummary expanded={expanded}>
          <div>{guest.Name?.Current ?? guest.Name?.Base}</div>
          {renderTrash(guest, handleOpen, isReadMode)}
        </StyledAccordionSummary>
        <AccordionDetails>
          {children}
          {matchInput ? null : (
            <div className="mt-8">
              {isPending ? (
                <LoadingPlaceholder />
              ) : (
                <GuestPressures data={data} guest={guest} />
              )}
            </div>
          )}
        </AccordionDetails>
      </StyledAccordion>

      {/* Delete product confirmation dialog */}

      <ConfirmationModal
        onConfirm={() => deletePlantGuest(guest!.Id!)}
        onCancel={handleClose}
        variant="error"
        header="Delete plant geust"
        icon={<Trash />}
        okText="Delete"
        text={
          <p className="text-shark whitespace-break-spaces">
            <FormattedMessage
              id="deleteGuestConfirmation"
              defaultMessage="Are you sure you want to delete this {category} guest for {plantName}? If the parent plant has a {category} guest, this guest will be replaced by the {category} guest from the parent plant."
              values={{
                category: (
                  <span className="font-semibold">
                    {guest?.Category?.Current}
                  </span>
                ),
                plantName: (
                  <span className="font-semibold text-primary">
                    {cafs?.FullName}
                  </span>
                ),
              }}
            />
          </p>
        }
        open={open}
      />
    </>
  );
}

function renderTrash(
  guest: PlantGuest,
  deleteFn: (data: PlantGuest) => void,
  isReadMode: boolean | undefined,
) {
  return guest.CanDelete && !isReadMode ? (
    <Trash
      className={twMerge(
        "text-xl text-darkGray hover:text-primary",
        isReadMode && "pointer-events-none text-grey",
      )}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        deleteFn(guest);
      }}
    />
  ) : null;
}
