import { StringCafsValue } from "../../../../../../api/types/cafs";
import { PlantGrowth } from "../../../../../../api/types/plants";

export const heightMaxGrowPMonthFn = (
  values: Partial<Record<keyof PlantGrowth, StringCafsValue>>,
): number => {
  const maxHeightMValue = parseFloat(
    !!values.MaxHeightM?.Base?.length
      ? values.MaxHeightM?.Base
      : values.MaxHeightM?.Current ?? "1",
  );
  const minYearsToMaxHeightValue = parseFloat(
    !!values.MinYearsToMaxHeight?.Base?.length
      ? values.MinYearsToMaxHeight?.Base
      : values.MinYearsToMaxHeight?.Current ?? "1",
  );

  const yearsToMonths = (minYearsToMaxHeightValue || 1) * 12;
  return maxHeightMValue / yearsToMonths;
};
