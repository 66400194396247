import { Status } from "../../../consts/status";

export type StatusChipProps = {
  status: string | null | undefined;
};

export function StatusChip({ status }: StatusChipProps) {
  switch (status) {
    case Status.Approved:
      return (
        <div className="rounded bg-green bg-opacity-30 text-primary py-1 px-2 flex items-center justify-center">
          {status}
        </div>
      );
    case Status.DraftEdit:
      return (
        <div className="rounded bg-orange-200 text-orange-600 py-1 px-2 flex items-center justify-center">
          {status}
        </div>
      );
    case Status.DraftNew:
      return (
        <div className="rounded bg-orange-200 text-orange-600 py-1 px-2 flex items-center justify-center">
          {status}
        </div>
      );
    default:
      return <div>{status}</div>;
  }
}
