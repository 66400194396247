import { Token } from "../api/types/token";
import { LocalStorageKey } from "../consts/local-storage-key";
import { removeLocalStorageAuthData } from "../utils/remove-local-storage-auth-data";
import { saveLocalStorageAuthData } from "../utils/save-local-storage-auth-data";

import { requestInitBuilder } from "./utils/request-init-builder";
import { uploadDataFile } from "./utils/upload-data-file";
import { HttpMethod } from "./types";

let refreshPromise: Promise<Token> | null = null;

export type FileResponse = {
  blob: BlobPart;
  filename: string;
};

export async function httpCall(
  method: HttpMethod,
  url: string,
  data?: unknown,
  file?: boolean,
  onUploadProgress?: (progress: number) => void,
  onUploadComplete?: () => void,
): Promise<unknown> {
  const requestInit: RequestInit = requestInitBuilder(method, data);
  const excludedEndpoints = ["/Login", "/Logout"];
  const shouldCheckTokenExpiry = !excludedEndpoints.some(endpoint => {
    return url.includes(endpoint);
  });

  if (shouldCheckTokenExpiry && checkTokenExpiry()) {
    if (!refreshPromise) {
      refreshPromise = refreshToken()
        .catch(error => {
          removeLocalStorageAuthData();
          window.location.href = "/login";
          return Promise.reject(error);
        })
        .finally(() => {
          refreshPromise = null;
        });
    }
    return refreshPromise.then(async data => {
      const newRequestInit = {
        ...requestInit,
        headers: {
          ...requestInit.headers,
          Authorization: `Bearer ${data.Token}`,
        },
      };

      if (onUploadProgress && onUploadComplete) {
        return uploadDataFile(
          url,
          newRequestInit,
          onUploadProgress,
          onUploadComplete,
        );
      }

      return file
        ? await fetchDataFile(url, newRequestInit)
        : await fetchData(url, newRequestInit);
    });
  }

  if (onUploadProgress && onUploadComplete) {
    return uploadDataFile(url, requestInit, onUploadProgress, onUploadComplete);
  }

  return file
    ? await fetchDataFile(url, requestInit)
    : await fetchData(url, requestInit);
}

const fetchData = async (
  url: string,
  requestInit: RequestInit,
): Promise<unknown> => {
  return await fetch(url, requestInit)
    .then(response => {
      if (response.ok) return response.text();
      return response.json().then(text => {
        return Promise.reject({ Status: response.status, Body: text });
      });
    })
    .then(resTextBody => {
      try {
        return JSON.parse(resTextBody);
      } catch {
        return null;
      }
    });
};

const fetchDataFile = async (
  url: string,
  requestInit: RequestInit,
): Promise<FileResponse> => {
  const response = await fetch(url, requestInit);
  if (!response.ok) {
    const errorBody = await response.json();
    return Promise.reject({ Status: response.status, Body: errorBody });
  }

  const contentDisposition = response.headers.get("Content-Disposition");
  const match =
    contentDisposition && contentDisposition.match(/filename="?(.+)"?/);
  const filename = match ? match[1].replace(/['"]/g, "") : "download";

  const blob = await response.blob();

  return { blob, filename };
};

export default fetchDataFile;

const checkTokenExpiry = () => {
  const expiryTime = localStorage.getItem(LocalStorageKey.TokenValidTo) ?? "";
  const currentTime = new Date();
  const expiryDate = new Date(expiryTime);

  if (!expiryTime) {
    return false;
  }

  return currentTime.getTime() + 60 * 1000 >= expiryDate.getTime();
};

export const refreshToken = async (): Promise<Token> => {
  const refreshToken = localStorage.getItem(LocalStorageKey.RefreshToken);
  const email = JSON.parse(
    localStorage.getItem(LocalStorageKey.User) ?? "null",
  )?.Email;
  const domain = `${process.env.REACT_APP_API_URL}/Api/Login/RefreshToken`;

  try {
    const res = await fetch(domain, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ Email: email, RefreshToken: refreshToken }),
    });

    if (!res.ok) {
      return Promise.reject(res);
    }

    const data = await res.json();
    saveLocalStorageAuthData(data);
    return data;
  } catch (error) {
    return await Promise.reject(error);
  }
};
