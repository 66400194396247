import { ReactNode } from "react";

import { IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Modal, { ModalProps } from "@mui/material/Modal";
import { twMerge } from "tailwind-merge";

import { Xmark } from "../icons/Xmark";

export enum ModalSize {
  Small = "Small",
  Medium = "Medium",
  Big = "Big",
}

const ModalSizeMap = new Map([
  [ModalSize.Small, "480px"],
  [ModalSize.Medium, "600px"],
  [ModalSize.Big, "800px"],
]);

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  maxHeight: "80%",
  p: 1,
  pb: 2,
};

export type MuiModalProps = ModalProps & {
  open: boolean;
  onClose: () => void;
  header: ReactNode;
  size?: ModalSize;
  variant?: "primary" | "error" | "warning";
};

export function MuiModal({
  open,
  onClose,
  header,
  variant,
  size = ModalSize.Small,
  ...rest
}: MuiModalProps) {
  return (
    <Modal open={open} disableAutoFocus onClose={onClose} {...rest}>
      <Box
        sx={{
          ...style,
          width: ModalSizeMap.get(size),
          display: "flex",
          flexDirection: "column",
          borderRadius: "10px",
        }}>
        <div className="flex flex-row justify-between items-center pl-4">
          <h6
            id="modal-modal-title"
            className={twMerge(
              "text-primary text-2xl font-semibold pt-4 pb-4",
              variant === "error" && "text-shark",
            )}>
            {header}
          </h6>
          <IconButton disableRipple onClick={onClose} aria-label="Close From">
            <Xmark className="text-grey hover:text-darkGray" />
          </IconButton>
        </div>
        <div style={{ overflowY: "auto", flexGrow: 1 }} className="px-4 py-4">
          {rest.children}
        </div>
      </Box>
    </Modal>
  );
}
