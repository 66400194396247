import React, { createContext, ReactNode, useCallback, useState } from "react";
import { IntlProvider } from "react-intl";

import DE_MESSAGES from "./locale/de.json";
import NL_MESSAGES from "./locale/nl.json";

export const AppIntlContext = createContext<{
  switchLanguage: (locale: string) => void;
}>({
  switchLanguage: () => null,
});

type Props = {
  children?: ReactNode;
};

const messages: {
  [locale: string]: { [key: string]: string };
} = {
  de: DE_MESSAGES,
  nl: NL_MESSAGES,
};

const defaultLocale: keyof typeof messages = "en";

export function IntlContextWrapper({ children }: Props) {
  const userLanguage = "en";

  const [locale, setLocale] = useState(
    userLanguage,

    // **** If needed use set language based on user browser language ***//

    // findBestLanguageMatch(
    //   messages,
    //   navigator.language,
    //   userLanguage,
    //   defaultLocale,
    // ),
  );

  const switchLanguage = useCallback((locale: string) => {
    setLocale(locale);
  }, []);

  return (
    <AppIntlContext.Provider
      value={{
        switchLanguage: switchLanguage,
      }}>
      <IntlProvider
        locale={locale as string}
        defaultLocale={defaultLocale as string}
        messages={messages[locale as string]}>
        {children}
      </IntlProvider>
    </AppIntlContext.Provider>
  );
}

// function findBestLanguageMatch(
//   translations: typeof messages,
//   navigatorLanguage: string,
//   userLanguage: string,
//   defaultLanguage: keyof typeof messages,
// ) {
//   const availableLanguages = Object.keys(messages);
//   const languageRegex = new RegExp(
//     `^${navigatorLanguage.replace("-", "(-|$)")}`,
//   );
//   let bestMatch = null;
//
//   if (availableLanguages.includes(userLanguage)) return userLanguage;
//
//   for (const lang of availableLanguages) {
//     if (
//       languageRegex.test(lang) &&
//       (!bestMatch || lang.length > bestMatch.length)
//     ) {
//       bestMatch = lang;
//     }
//   }
//
//   return bestMatch || defaultLanguage;
// }
