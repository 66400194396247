import { useQuery } from "@tanstack/react-query";

import { Presets } from "../../../api/presets";
import { QueryKey } from "../../../consts/query-keys";

export function useTreeShapesData() {
  const { data: treeShapes } = useQuery({
    queryKey: [QueryKey.TreeShapes],
    queryFn: () => Presets.treeShape(),
    staleTime: Infinity,
  });

  return { treeShapes };
}
