import { useQuery } from "@tanstack/react-query";

import { Datasets } from "../../../api/datasets";
import { Dataset } from "../../../api/types/datasets";
import { QueryKey } from "../../../consts/query-keys";
import { CafsTreeData } from "../../../types/cafs-tree-node-type";
import { mapApiToCafsTree } from "../utils/map-api-to-tree-node";

export const useDataSetDetails = (selectedDataSet: Dataset | null) => {
  const queryFn = () =>
    Datasets.details({
      path: { datasetId: +selectedDataSet?.Id! },
    }).then(data => {
      return mapApiToCafsTree(data) as CafsTreeData;
    });

  const { data: cafsTree, isLoading } = useQuery<CafsTreeData>({
    queryKey: [QueryKey.DataSetDetails, selectedDataSet?.Id],
    queryFn: queryFn,
    enabled: !!selectedDataSet?.Id,
  });

  return { cafsTree: cafsTree!, isLoading };
};
