import { useMemo } from "react";

import { useQuery } from "@tanstack/react-query";

import { Tooltips } from "../../../api/tooltips";
import { CafsVariableCollection } from "../../../api/types/cafs";
import { QueryKey } from "../../../consts/query-keys";
import { mapTooltips } from "../../../utils/map-tooltips";
import { CafsSection } from "../const/cafs-section";

export function useTooltips<T>(
  sectionData: CafsVariableCollection<T> | undefined,
  sectionName: CafsSection,
) {
  const queryFn = () => Tooltips.list();

  const { data, isPending } = useQuery({
    queryKey: [QueryKey.PlantTooltips],
    queryFn: queryFn,
    staleTime: Infinity,
  });

  const tooltips = useMemo(() => {
    return mapTooltips(sectionData, data, sectionName);
  }, [sectionData, data, sectionName]);

  return {
    tooltips,
    isPending,
  };
}
