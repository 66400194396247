import { useEffect } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import { Alert } from "@mui/material";

import { PlantRoot } from "../../../../../api/types/plants";
import { useApiValidation } from "../../../../../hooks/useApiValidation";
import { useFetchData } from "../../../../../hooks/useFetchData";
import { SectionProps } from "../../../../../types/section-props";
import { useRootsData } from "../../../api/useRootsData";
import { useRootsUpdate } from "../../../api/useRootsUpdate";
import { CafsSection } from "../../../const/cafs-section";
import { useSectionSaveDisabled } from "../../../hooks/useSectionSaveDisabled";
import { CafsTable } from "../../cafs-table/CafsTable";
import { renderVariableRow } from "../../cafs-table/render-variable-row";
import { mapInitialValues } from "../../cafs-table/utils/map-initial-values";
import { mapSubmitValues } from "../../cafs-table/utils/map-submit-values";
import { SectionRemarks } from "../SectionRemarks";

import { rootsSchema } from "./roots-schema";
import { useRootsVariables } from "./useRootsVariables";

export function RootsSection({ expanded }: SectionProps) {
  const variables = useRootsVariables();

  const { datasetId, id } = useParams();
  const { shouldFetchData } = useFetchData(expanded);

  const { rootsData, isPending } = useRootsData(
    datasetId!,
    id!,
    shouldFetchData,
  );
  const { updateRoots, apiErrors } = useRootsUpdate({
    onSuccess: () => {},
  });

  const methods = useForm<FieldValues>({
    resolver: yupResolver(rootsSchema),
    mode: "onChange",
  });

  useEffect(() => {
    if (rootsData) {
      methods.reset(mapInitialValues(rootsData, variables));
    }
  }, [rootsData]);

  const handleSubmit = (data: FieldValues) => {
    updateRoots(mapSubmitValues(data));
  };

  const { disabled } = useSectionSaveDisabled(methods, isPending);

  useApiValidation(apiErrors, methods);

  return rootsData && !isPending ? (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <SectionRemarks<PlantRoot> name="RootsRemarks" data={rootsData} />
        <CafsTable
          name={CafsSection.PlantRoot}
          variables={variables}
          render={renderVariableRow}
          data={rootsData ?? {}}
          disabled={disabled}
        />
      </form>
    </FormProvider>
  ) : (
    <div className="w-full">
      <Alert severity="info">Loading data</Alert>
    </div>
  );
}
