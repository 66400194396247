import { IconProps } from "../../types/icon-props";

export function Status(props: IconProps) {
  return (
    <svg
      viewBox="0 0 24 24"
      height="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fill="currentColor"
        d="M11.9999 2C12.5559 2 13.0999 2.046 13.6319 2.132L11.7599 4.004C10.1992 4.05083 8.68644 4.55331 7.40793 5.44948C6.12942 6.34566 5.14109 7.59637 4.56477 9.04743C3.98845 10.4985 3.84934 12.0865 4.16458 13.6156C4.47982 15.1448 5.23563 16.5483 6.33885 17.6531C7.44207 18.758 8.84446 19.5158 10.3732 19.8333C11.9019 20.1507 13.4901 20.0139 14.942 19.4397C16.3939 18.8655 17.646 17.879 18.544 16.6018C19.4421 15.3246 19.9468 13.8125 19.9959 12.252L21.8699 10.38C22.2015 12.4 21.9052 14.4732 21.021 16.3194C20.1368 18.1657 18.7072 19.6961 16.9255 20.704C15.1437 21.7118 13.0956 22.1486 11.0576 21.9553C9.01971 21.762 7.09021 20.9479 5.52973 19.623C3.96925 18.2981 2.85297 16.5262 2.33166 14.5466C1.81034 12.5671 1.90912 10.4752 2.61463 8.55353C3.32015 6.63188 4.59842 4.97304 6.27679 3.80106C7.95517 2.62909 9.95279 2.00043 11.9999 2ZM20.8819 3.124C20.5264 2.76848 20.1044 2.48646 19.6399 2.29405C19.1754 2.10164 18.6776 2.00261 18.1749 2.00261C17.6721 2.00261 17.1743 2.10164 16.7098 2.29405C16.2454 2.48646 15.8233 2.76848 15.4679 3.124L10.2999 8.292C10.191 8.40111 10.1089 8.5339 10.0599 8.68L8.09186 14.52C8.02654 14.714 8.01665 14.9224 8.06331 15.1217C8.10997 15.3211 8.21133 15.5034 8.35598 15.6483C8.50063 15.7931 8.68284 15.8947 8.88209 15.9417C9.08135 15.9886 9.28976 15.979 9.48386 15.914L15.3239 13.95C15.4707 13.9012 15.6042 13.8191 15.7139 13.71L20.8819 8.54C21.2374 8.18453 21.5194 7.76251 21.7118 7.29804C21.9042 6.83357 22.0033 6.33575 22.0033 5.833C22.0033 5.33025 21.9042 4.83243 21.7118 4.36796C21.5194 3.90349 21.2374 3.47947 20.8819 3.124ZM16.8819 4.54C17.2248 4.19708 17.6899 4.00442 18.1749 4.00442C18.6598 4.00442 19.1249 4.19708 19.4679 4.54C19.8108 4.88292 20.0034 5.34803 20.0034 5.833C20.0034 6.31797 19.8108 6.78307 19.4679 7.126L14.4639 12.126L10.5659 13.438L11.8779 9.542L16.8819 4.54Z"
      />
    </svg>
  );
}
