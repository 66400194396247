import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";

import { RhfFormField } from "../../../components/rhf/RhfFormField";
import { RhfInput } from "../../../components/rhf/RhfInput";

export function DatasetForm() {
  return (
    <div className="flex flex-col items-start justify-start gap-2">
      <RhfFormField name="Name">
        <RhfInput name="Name" label="Name" placeholder="Name"></RhfInput>
      </RhfFormField>

      <div className="mt-4">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          startIcon={<Add />}>
          Create
        </Button>
      </div>
    </div>
  );
}
