import { useContext } from "react";

import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";

import { GuestResponseToPressure } from "../../../../api/types/guests";
import { useAccordion } from "../../../../hooks/useAccordion";
import { useModal } from "../../../../hooks/useModal";
import { CafsContext } from "../../CafsContext";

import { CreateGuestPressureModal } from "./CreateGuestPressureModal";
import { GuestPressureItem } from "./GuestPressureItem";
import { GuestPressuresEdit } from "./GuestPressuresEdit";

export type GuestRtpListProps = {
  data: GuestResponseToPressure[];
  guestId: number | undefined;
  categories: { id: string; label: string }[];
};
export function GuestPressuresList({
  data,
  categories,
  guestId,
}: GuestRtpListProps) {
  const { expanded, handleChange: toggleAccordion } = useAccordion();
  const { isReadMode } = useContext(CafsContext);

  const { open, handleOpen, handleClose } = useModal();

  function openCreateRtpModal() {
    handleOpen();
  }

  return (
    <>
      {data.map(rtp => (
        <GuestPressureItem
          item={rtp}
          key={rtp.Id}
          onChange={toggleAccordion(rtp.Id!)}
          guestId={guestId}
          expanded={expanded === rtp.Id}>
          <GuestPressuresEdit
            guestId={guestId}
            pressureId={rtp.Id}
            data={rtp}
            categories={categories}
            onCancel={() => {}}
          />
        </GuestPressureItem>
      ))}
      <div className="mt-6">
        <Button
          variant="outlined"
          color="primary"
          disabled={isReadMode}
          startIcon={<Add />}
          onClick={() => openCreateRtpModal()}>
          Add new
        </Button>
      </div>

      <CreateGuestPressureModal
        open={open}
        onClose={handleClose}
        categories={categories}
        guestId={guestId}
      />
    </>
  );
}
