import { PropsWithChildren, ReactNode } from "react";
import { ErrorBoundary } from "react-error-boundary";

import { Alert } from "@mui/material";

export type CafsErrorBoundaryProps = PropsWithChildren;
export function CafsErrorBoundary({
  children,
}: CafsErrorBoundaryProps): ReactNode {
  return (
    <ErrorBoundary
      fallback={
        <Alert severity="error">
          Something went wrong when fetching CAFS data.{" "}
          <span className="font-semibold">
            Please check if CAFS and dataset with given id's exist.
          </span>
        </Alert>
      }>
      {children}
    </ErrorBoundary>
  );
}
