import { useState } from "react";
import { FormattedMessage } from "react-intl";

import { Table, TableBody, TableCell, TableHead } from "@mui/material";

import { CategoryValue, TooltipItem } from "../api/useFetchTooltips";
import { useUpdateTooltips } from "../api/useUpdateTooltip";

import { VariablesTableRow } from "./VariablesTableRow";

type VariablesTableProps = {
  data: CategoryValue;
  category: string;
  editMode?: boolean;
};

export function VariablesTable({ data, category }: VariablesTableProps) {
  const [editedRow, setEditedRow] = useState<string | null>();

  const closeEditMode = () => {
    setEditedRow(null);
  };

  const { updateTooltip, isPending } = useUpdateTooltips({
    onSuccess: closeEditMode,
  });

  const handleUpdate = (data: TooltipItem) => {
    updateTooltip({ ...data, Name: `${category}.${data.Name}` });
  };

  return (
    <Table>
      <TableHead>
        <TableCell>
          <FormattedMessage
            id="VARIABLES_TABLE.FIELD_NAME"
            description=""
            defaultMessage="Field name"
          />
        </TableCell>
        <TableCell>
          <FormattedMessage
            id="VARIABLES_TABLE.TOOLTIP_DESCRIPTION"
            description=""
            defaultMessage="Tooltip description"
          />
        </TableCell>
        <TableCell>
          <FormattedMessage
            id="VARIABLES_TABLE.UNIT"
            description=""
            defaultMessage="Unit"
          />
        </TableCell>
        <TableCell></TableCell>
      </TableHead>
      <TableBody>
        {Object.keys(data || {}).map(variable => (
          <VariablesTableRow
            key={variable}
            name={variable}
            data={data[variable]}
            onEdit={value => setEditedRow(value)}
            onCancel={() => setEditedRow(null)}
            disabled={isPending}
            onSave={handleUpdate}
            editMode={editedRow === variable}
          />
        ))}
      </TableBody>
    </Table>
  );
}
