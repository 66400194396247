import { IconProps } from "../../types/icon-props";

export function Logout(props: IconProps) {
  return (
    <svg
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fill="currentColor"
        d="M20.3438 17.1563H18.6961C18.5836 17.1563 18.4782 17.2055 18.4078 17.2922C18.2438 17.4914 18.068 17.6836 17.8828 17.8664C17.1256 18.6245 16.2286 19.2286 15.2414 19.6453C14.2188 20.0773 13.1196 20.2989 12.0094 20.2969C10.8868 20.2969 9.79925 20.0766 8.77738 19.6453C7.79026 19.2286 6.89325 18.6245 6.13597 17.8664C5.37733 17.1109 4.77242 16.2155 4.35472 15.2297C3.92113 14.2078 3.70316 13.1227 3.70316 12C3.70316 10.8773 3.92347 9.79219 4.35472 8.77032C4.77191 7.7836 5.37191 6.89532 6.13597 6.1336C6.90003 5.37188 7.78831 4.77188 8.77738 4.35469C9.79925 3.92344 10.8868 3.70313 12.0094 3.70313C13.1321 3.70313 14.2196 3.9211 15.2414 4.35469C16.2305 4.77188 17.1188 5.37188 17.8828 6.1336C18.068 6.31876 18.2414 6.51094 18.4078 6.70782C18.4782 6.79454 18.586 6.84375 18.6961 6.84375H20.3438C20.4914 6.84375 20.5828 6.67969 20.5008 6.55547C18.7032 3.76172 15.5578 1.9125 11.9836 1.92188C6.368 1.93594 1.86566 6.49454 1.92191 12.1031C1.97816 17.6227 6.47347 22.0781 12.0094 22.0781C15.5743 22.0781 18.7055 20.2313 20.5008 17.4445C20.5805 17.3203 20.4914 17.1563 20.3438 17.1563ZM22.4274 11.8523L19.1016 9.22735C18.9774 9.12891 18.7969 9.21797 18.7969 9.37501V11.1563H11.4375C11.3344 11.1563 11.25 11.2406 11.25 11.3438V12.6563C11.25 12.7594 11.3344 12.8438 11.4375 12.8438H18.7969V14.625C18.7969 14.782 18.9797 14.8711 19.1016 14.7727L22.4274 12.1477C22.4498 12.1301 22.4679 12.1077 22.4804 12.0821C22.4928 12.0565 22.4993 12.0285 22.4993 12C22.4993 11.9715 22.4928 11.9435 22.4804 11.9179C22.4679 11.8923 22.4498 11.8699 22.4274 11.8523Z"
      />
    </svg>
  );
}
