import { useCallback } from "react";

export function useHighlight(dependencies: string[], section: string) {
  const addHighlight = useCallback(() => {
    (dependencies || []).forEach(field => {
      const element = document.getElementById(`${section}_${field}`);
      element?.classList.add("highlight");
    });
  }, [dependencies, section]);

  const removeHighlight = useCallback(() => {
    (dependencies || []).forEach(field => {
      const element = document.getElementById(`${section}_${field}`);
      element?.classList.remove("highlight");
    });
  }, [dependencies, section]);

  return { addHighlight, removeHighlight };
}
