import { PlantBiodiversity } from "../../../../../api/types/plants";
import { CafsVariableType } from "../../../const/cafs-variable-type";
import { CafsVariablesCollection } from "../../cafs-table/CafsTable";

export function useBiodiversityVariables(): CafsVariablesCollection<PlantBiodiversity> {
  return [
    {
      name: "BiodiversityRemarks",
      type: CafsVariableType.Remarks,
    },
    {
      name: "ShannonBiodiverstitySpeciesEquivalent",
      type: CafsVariableType.Input,
    },
    {
      name: "IsIndigenous",
      type: CafsVariableType.Boolean,
    },
  ];
}
