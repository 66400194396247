import React, { useContext } from "react";

import { Tooltip } from "@mui/material";

import { SquarePlus } from "../../../components/icons/SquarePlus";
import { CafsTreeNodeType } from "../../../types/cafs-tree-node-type";
import { useCafsCreate } from "../api/useCafsCreate";
import { DashboardContext } from "../DashboardContext";

export type CafsCreateProps = {
  node: CafsTreeNodeType;
};

export function CafsCreate({ node }: CafsCreateProps) {
  const shouldRender =
    (node.isRoot && node.canAddDescendant) ||
    (node.canAddDescendant && !node.isRoot);

  const { selectedDataset } = useContext(DashboardContext);

  const { createCafs } = useCafsCreate(selectedDataset);

  const handleCafsCreate = (node: CafsTreeNodeType) => {
    createCafs(node);
  };

  return shouldRender ? (
    <Tooltip title="Create new child and edit in EditNew mode">
      <div>
        <SquarePlus
          className="text-primary block hover:text-orange-400 mt-[1px] text-xl"
          onClick={() => handleCafsCreate(node)}
        />
      </div>
    </Tooltip>
  ) : null;
}
