import { Alert } from "@mui/material";

export type LoadingPlaceholderProps = {
  text?: string;
};

export function LoadingPlaceholder({
  text = "Loading data...",
}: LoadingPlaceholderProps) {
  return (
    <div className="w-full">
      <Alert severity="info">{text}</Alert>
    </div>
  );
}
