import { ReactNode, useContext, useMemo } from "react";
import * as React from "react";
import { useFormContext } from "react-hook-form";

import { Help } from "@mui/icons-material";
import { IconButton, TableCell, TableRow, Tooltip } from "@mui/material";
import clsx from "clsx";
import DOMPurify from "dompurify";

import { CafsValue } from "../../../../api/types/cafs";
import { TooltipItem } from "../../../../api/types/tooltips";
import { RHFAutocompleteField } from "../../../../components/rhf/RhfAutocomplete";
import { RhfFormField } from "../../../../components/rhf/RhfFormField";
import { Status } from "../../../../consts/status";
import { SelectOption } from "../../../../types/select-option";
import {
  getBaseValue,
  getCurrentValue,
  getParentValue,
} from "../../../../utils/variable-helper";
import { CafsContext } from "../../CafsContext";
import { CafsSection } from "../../const/cafs-section";
import { ReferenceAssign } from "../ReferenceAssign";

export type SelectRowProps<T> = {
  variableName: T;
  variableValue: CafsValue | undefined;
  options: SelectOption[] | undefined;
  productCode?: string;
  guestName?: string | null | undefined;
  tooltip?: TooltipItem;
  sectionName: CafsSection;
  disabled?: boolean;
  focusOnError?: boolean;
  hidden?: ["Parent" | "New Base" | "Calculated" | "Current" | ""];
};

export function AutocompleteRow<T>({
  variableName,
  options,
  variableValue,
  sectionName,
  disabled,
  productCode,
  guestName,
  tooltip,
  focusOnError,
  hidden = [""],
}: SelectRowProps<T>) {
  const { cafs } = useContext(CafsContext);
  const { control, getFieldState } = useFormContext();

  const sanitizedDescription = DOMPurify.sanitize(tooltip?.Description ?? "");

  const renderCalculated = !hidden.includes("Calculated");

  const selected = useMemo(() => {
    return (options || []).find(o => o.id === getCurrentValue(variableValue));
  }, [options, variableValue]);

  const parent = useMemo(() => {
    return (options || []).find(o => o.id === getParentValue(variableValue));
  }, [options, variableValue]);

  const isDirty = getFieldState(`${variableName}.Base`).isDirty;

  const className = clsx({
    draftRow:
      isDirty ||
      (getBaseValue(variableValue) !== null &&
        getBaseValue(variableValue) !== undefined &&
        cafs?.Status !== Status.DraftNew),
  });

  const displayTooltip = tooltip && tooltip.Description;

  const displayEditLabel =
    getBaseValue(variableValue) !== null &&
    getBaseValue(variableValue) !== undefined &&
    cafs?.Status === Status.DraftEdit;

  return (
    <TableRow
      sx={{ height: "85px" }}
      id={`${sectionName}_${variableName}`}
      data-testid={`VariableRow.${variableName}`}
      className={className}>
      <TableCell>
        <div className="flex flex-col">
          <p className="font-mono">{variableName as ReactNode}</p>
          {displayEditLabel && (
            <small className="text-orange-400 italic">Edited</small>
          )}
        </div>
      </TableCell>
      <TableCell />
      <TableCell>
        {displayTooltip && (
          <Tooltip
            title={
              <span
                dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
              />
            }
            data-testid={`${variableName}.tooltip`}>
            <IconButton className="hover:text-primary">
              <Help />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
      <TableCell>{tooltip?.Unit}</TableCell>
      <TableCell>{parent?.label ?? getParentValue(variableValue)}</TableCell>
      <TableCell>
        <RhfFormField name={`${variableName}`}>
          <RHFAutocompleteField
            name={`${variableName}.Base`}
            control={control}
            disabled={disabled}
            focusOnError={focusOnError}
            placeholder={variableName as string}
            options={options || []}
            inputProps={{ "data-testid": `${variableName}.base` }}
          />
        </RhfFormField>
      </TableCell>
      {renderCalculated && <TableCell />}
      <TableCell>
        <div className="text-right">
          {selected?.label ?? getCurrentValue(variableValue)}
        </div>
      </TableCell>
      <TableCell>
        <ReferenceAssign
          fieldName={variableName as string}
          sectionName={sectionName}
          productCode={productCode}
          guestName={guestName}
        />
      </TableCell>
    </TableRow>
  );
}
