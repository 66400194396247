import { useIntl } from "react-intl";
import { useMatch, useParams } from "react-router-dom";

import { useMutation } from "@tanstack/react-query";

import { ReferencesTo } from "../../../api/references-to";
import { MutationKey } from "../../../consts/mutation-keys";
import { QueryKey } from "../../../consts/query-keys";
import { useApiSuccess } from "../../../hooks/useApiSuccess";
import { CafsSection } from "../const/cafs-section";

type mutationFnProps = {
  name: string;
  referenceId: number;
};

export const useReferencesAssign = (
  sectionName: CafsSection,
  productCode?: string,
  guestName?: string | null | undefined,
) => {
  const { id } = useParams();
  const { formatMessage } = useIntl();
  const matchInput = useMatch("/dashboard/input/:datasetId/:id");

  const { handleSuccess } = useApiSuccess({
    invalidate: [[QueryKey.AssignedReferencesList]],
    snackbarText: formatMessage({
      id: "ACTIONS.REFERENCE_ASSIGN",
      defaultMessage: "Reference assigned successfully.",
      description: "Variable reference assign success message",
    }),
  });

  const mutationFn = ({ name, referenceId }: mutationFnProps) => {
    const data = matchInput
      ? {
          InputId: +id!,
          ReferenceId: referenceId,
          FieldName: `${sectionName}.${name}`,
        }
      : productCode
        ? {
            PlantId: +id!,
            ReferenceId: referenceId,
            FieldName: `${sectionName}.${name}`,
            PlantProductCode: productCode,
          }
        : guestName
          ? {
              PlantId: +id!,
              ReferenceId: referenceId,
              FieldName: `${sectionName}.${name}`,
              GuestName: guestName,
            }
          : {
              PlantId: +id!,
              ReferenceId: referenceId,
              FieldName: `${sectionName}.${name}`,
            };

    return ReferencesTo.assign(undefined, data);
  };

  const { mutate: assignReference, isPending } = useMutation({
    mutationKey: [MutationKey.AssignReferencesTo],
    mutationFn: mutationFn,
    onSuccess: handleSuccess,
  });

  return { assignReference, isPending };
};
