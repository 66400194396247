import { twMerge } from "tailwind-merge";

import { Region } from "../../../api/types/regions";
import { ApiValidationErrors } from "../../../types/api-error";
import { PriceItem } from "../../../types/price-item";
import { NoResult } from "../../dashboard-view/components/NoResult";
import { PriceUpdatePayload } from "../api/useUpdatePrice";

import { PricesTableRow } from "./PricesTableRow";
import { PriceCell } from "./ProductsWithPrices";

export type PricesTableProps = {
  items: PriceItem[];
  editedCell: PriceCell;
  selectedRegion: Region | undefined | null;
  onWorldSave: (data: Partial<PriceUpdatePayload>) => void;
  onRegionSave: (data: Partial<PriceUpdatePayload>) => void;
  onCellEdit: (data: PriceCell) => void;
  apiErrors: ApiValidationErrors;
};

export function PricesTable({
  items,
  selectedRegion,
  editedCell,
  onWorldSave,
  onRegionSave,
  onCellEdit,
  apiErrors,
}: PricesTableProps) {
  return (
    <>
      <div>
        <div>
          <div
            className={twMerge(
              "grid grid-cols-3 py-4 px-4 bg-[#637383] text-white font-semibold",
              selectedRegion && "grid-cols-5",
            )}>
            <div className="col-span-1"></div>
            <div className="col-span-1 text-right">World</div>
            <div className="col-span-1"></div>
            {selectedRegion && (
              <>
                <div className="col-span-1 text-right">
                  {selectedRegion.Name}
                </div>
                <div className="col-span-1 text-left"></div>
              </>
            )}
          </div>
          <div
            className={twMerge(
              "grid grid-cols-3 bg-[#A6B1B7] text-white gap-0 py-4  px-4",
              selectedRegion && "grid-cols-5",
            )}>
            <div>Name</div>
            <div className="text-right">HighPricePKg (€)</div>
            <div className="text-right">PriceLowOfHigh</div>
            {!!selectedRegion && (
              <>
                <div className="text-right">HighPricePKg (€)</div>
                <div className="text-right">PriceLowOfHigh</div>
              </>
            )}
          </div>
        </div>
        <div>
          {items.length ? (
            (items || []).map(item => (
              <PricesTableRow
                item={item}
                onCellEdit={onCellEdit}
                onRegionSave={onRegionSave}
                onWorldSave={onWorldSave}
                editedCell={editedCell}
                selectedRegion={selectedRegion}
                apiErrors={apiErrors}
              />
            ))
          ) : (
            <div className="w-full pt-4">
              <NoResult />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
