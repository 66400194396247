import { PlantWoodDensity } from "../../../../../api/types/plants";
import { SelectOption } from "../../../../../types/select-option";
import { useTreeShapesData } from "../../../api/useTreeShapesData";
import { CafsVariableType } from "../../../const/cafs-variable-type";
import { CafsVariablesCollection } from "../../cafs-table/CafsTable";
import { renderForTree } from "../../cafs-table/utils/render-for-tree";

import { boleFractionOfTotalHeightMaxFn } from "./functions/boleFractionOfTotalHeightMax";

export function useWoodDensityVariables(): CafsVariablesCollection<PlantWoodDensity> {
  const { treeShapes } = useTreeShapesData();
  return [
    {
      name: "WoodDensityRemarks",
      type: CafsVariableType.Remarks,
    },
    {
      name: "DensitySapWoodKgPM3",
      type: CafsVariableType.Input,
      renderCondition: renderForTree,
    },
    {
      name: "DensityHeartWoodKgPM3",
      type: CafsVariableType.Input,
      renderCondition: renderForTree,
    },
    {
      name: "YearHeartWoodStart",
      type: CafsVariableType.Input,
      renderCondition: renderForTree,
    },
    {
      name: "YearHeartWoodMax",
      type: CafsVariableType.Input,
      renderCondition: renderForTree,
    },
    {
      name: "HeartwoodMaxVolFraction",
      type: CafsVariableType.Input,
      renderCondition: renderForTree,
    },
    {
      name: "TreeShapeId",
      type: CafsVariableType.Select,
      renderCondition: renderForTree,
      options: treeShapes?.map(ts => {
        return { label: ts.Name, id: ts.Id } as SelectOption;
      }),
    },
    {
      name: "BoleFractionOfTotalHeightMax",
      type: CafsVariableType.Calculated,
      renderCondition: renderForTree,
      calculationFn: boleFractionOfTotalHeightMaxFn,
      data: treeShapes,
      dependOn: ["TreeShapeId"],
    },
  ];
}
