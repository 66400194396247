import { useContext } from "react";

import { useQueryClient } from "@tanstack/react-query";

import { AppContext, SnackbarType } from "../AppContext";

export type UseApiSuccessProps = {
  invalidate?: string[][];
  onSuccessFn?: (data?: unknown) => void;
  snackbarText?: string;
};
export const useApiSuccess = ({
  onSuccessFn = () => {},
  invalidate,
  snackbarText,
}: UseApiSuccessProps) => {
  const { showSnackbar } = useContext(AppContext);
  const queryClient = useQueryClient();
  const handleSuccess = (data?: unknown) => {
    (invalidate || []).forEach(queryKey => {
      queryClient.invalidateQueries({
        queryKey: queryKey,
      });
    });
    if (snackbarText) {
      showSnackbar({
        type: SnackbarType.Success,
        message: snackbarText,
      });
    }
    onSuccessFn(data);
  };

  return { handleSuccess };
};
