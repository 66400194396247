import { useQuery } from "@tanstack/react-query";

import { Inputs } from "../../../api/inputs";
import { QueryKey } from "../../../consts/query-keys";

export const useInputLabourData = (
  datasetId: string,
  id: string,
  shouldFetchData: boolean,
) => {
  const { data: inputLabourData, isPending } = useQuery({
    queryKey: [QueryKey.InputLabourSection, datasetId, id],
    queryFn: () =>
      Inputs.labour({
        path: { datasetId: +datasetId! as number, inputId: +id! as number },
      }).then(response => {
        return response;
      }),
    enabled: shouldFetchData,
    throwOnError: true,
  });

  return { inputLabourData, isPending };
};
