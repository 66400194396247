import { createApiOperation } from "../api-client/create-api-operation";

import {
  AgroEcologicalZoneTemperature,
  RootType,
  TreeHabit,
  TreeShape,
  VegetationLayer,
} from "./types/presets";

export const Presets = {
  treeHabit: createApiOperation<TreeHabit[], "/Api/Presets/TreeHabits", "get">(
    "/Api/Presets/TreeHabits",
    "get",
  ),

  treeShape: createApiOperation<TreeShape[], "/Api/Presets/TreeShapes", "get">(
    "/Api/Presets/TreeShapes",
    "get",
  ),

  vegetationLayer: createApiOperation<
    VegetationLayer[],
    "/Api/Presets/VegetationLayers",
    "get"
  >("/Api/Presets/VegetationLayers", "get"),

  rootTypes: createApiOperation<RootType[], "/Api/Presets/RootTypes", "get">(
    "/Api/Presets/RootTypes",
    "get",
  ),

  AgroEcologicalZoneTemperatures: createApiOperation<
    AgroEcologicalZoneTemperature[],
    "/Api/Presets/AgroEcologicalZoneTemperatures",
    "get"
  >("/Api/Presets/AgroEcologicalZoneTemperatures", "get"),
};
