import React, { useState } from "react";

import { Tooltip } from "@mui/material";

import { Trash } from "../../../components/icons/trash";
import { Access } from "../../../consts/access";
import { useModal } from "../../../hooks/useModal";
import { CafsTreeNodeType } from "../../../types/cafs-tree-node-type";

import { DeleteCafsModal } from "./DeleteCafsModal";

type DeleteIconProps = {
  node: CafsTreeNodeType;
};

export function CafsDelete({ node }: DeleteIconProps) {
  const [cafsToDelete, setCafsToDelete] = useState<CafsTreeNodeType>();
  const { open, handleOpen, handleClose } = useModal();

  const shouldRender =
    !node.isRoot &&
    node.access === Access.Full &&
    !node.childNodes?.length &&
    !node.hasAnyDatasets;

  const handleCafsDelete = (node: CafsTreeNodeType) => {
    setCafsToDelete(node);
    handleOpen();
  };

  return shouldRender ? (
    <>
      <Tooltip title="Delete (if the CAFS has no child and is not in a dataset)">
        <div>
          <Trash
            className="text-primary hover:text-orange-400 text-xl"
            onClick={() => handleCafsDelete(node)}
          />
        </div>
      </Tooltip>
      <DeleteCafsModal open={open} onClose={handleClose} cafs={cafsToDelete} />
    </>
  ) : null;
}
