export enum CafsSection {
  PlantGrowth = "PlantGrowth",
  PlantLifeCycle = "PlantLifeCycle",
  PlantWoodDensity = "PlantWoodDensity",
  PlantBiomassAllocation = "PlantBiomassAllocation",
  PlantRoot = "PlantRoot",
  PlantConstraint = "PlantConstraint",
  PlantGrowthCondition = "PlantGrowthCondition",
  PlantCost = "PlantCost",
  PlantLabour = "PlantLabour",
  PlantDefinition = "PlantDefinition",
  PlantBiodiversity = "PlantBiodiversity",
  PlantResponseToPressure = "PlantResponseToPressure",
  PlantProduct = "PlantProduct",
  Guest = "Guest",
  Plant = "Plant",
  Input = "Input",
  InputDefinition = "InputDefinition",
  InputCost = "InputCost",
  InputLabour = "InputLabour",
  Test = "Test",
  TesLifecycle = "TestLifecycle",
}
