import { FormattedMessage } from "react-intl";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import clsx from "clsx";

import { components } from "../../../../api-client/api-schema";
import { Formula } from "../../../../components/formula/Formula";

export type HistoryTableProps = {
  data: components["schemas"]["AuditItemModelDto"];
  searchTerm: string;
};

export function CafsHistoryTable({ data, searchTerm }: HistoryTableProps) {
  return (
    <Table stickyHeader aria-label="simple base-table">
      <TableHead>
        <TableRow>
          <TableCell>
            <FormattedMessage
              id="HISTORY_TABLE.NAME"
              description=""
              defaultMessage="Name"
            />
          </TableCell>
          <TableCell>
            <FormattedMessage
              id="HISTORY_TABLE.BEFORE"
              description=""
              defaultMessage="Before"
            />
          </TableCell>
          <TableCell>
            <FormattedMessage
              id="HISTORY_TABLE.AFTER"
              description=""
              defaultMessage="After"
            />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.entries(data.Difference || {}).map(([key, values]) => (
          <TableRow
            key={key}
            className={clsx({
              highlight:
                searchTerm.length &&
                key.toLowerCase().includes(searchTerm.toLowerCase()),
            })}>
            <TableCell>{key}</TableCell>
            <TableCell>
              <Formula value={(values as Array<string>)[0]} />
            </TableCell>
            <TableCell>
              <Formula
                value={(values as Array<string>)[1]}
                charactersLimit={10}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
