import React from "react";

type TextWithEllipsisProps = {
  text: string;
  limit: number;
};

export const TextWithEllipsis: React.FC<TextWithEllipsisProps> = ({
  text,
  limit,
}) => {
  const displayText =
    text.length > limit ? `${text.substring(0, limit)}...` : text;

  return (
    <div className="relative group">
      <span className={text.length > limit ? "cursor-pointer" : ""}>
        {displayText}
      </span>
      {text.length > limit && (
        <div className="absolute hidden group-hover:block bg-gray-700 text-white text-xs rounded p-2 shadow-md z-50">
          {text}
        </div>
      )}
    </div>
  );
};
