import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import { NumberCafsValue } from "../../../api/types/cafs";
import { DataFormat } from "../../../consts/data-format";
import { convertDailyToHourly } from "../../../utils/convert-daily-to-hourly";
import { convertHourlyToDaily } from "../../../utils/convert-hourly-to-daily";
import { convertMonthsToYears } from "../../../utils/month-to-years";
import { yearsToMonths } from "../../../utils/years-to-months";

export function useRowFormat(name: string, rowFormat: DataFormat | undefined) {
  const { watch, setValue, formState } =
    useFormContext<Record<string, unknown>>();

  const [format, setFormat] = useState<DataFormat | undefined>(rowFormat);

  const fieldValue = watch(`${name}`) as NumberCafsValue;
  const baseValue = watch(`${name}.Base`);
  const yearFormat = watch(`${name}.${DataFormat.Year}`);
  const hourFormat = watch(`${name}.${DataFormat.Hour}`);

  useEffect(() => {
    if (rowFormat === DataFormat.Percent) {
      return;
    }

    if (
      rowFormat === DataFormat.Year &&
      formState.isDirty &&
      Object.keys(formState.touchedFields).length
    ) {
      setValue(
        `${name}`,
        {
          ...fieldValue,
          Base: yearFormat
            ? convertMonthsToYears(baseValue)
            : yearsToMonths(baseValue),
        },
        { shouldTouch: false, shouldDirty: false },
      );
    }
    setFormat(yearFormat ? DataFormat.Year : DataFormat.Month);
  }, [yearFormat]);

  useEffect(() => {
    if (rowFormat === DataFormat.Percent) {
      return;
    }

    if (
      rowFormat === DataFormat.Hour &&
      formState.isDirty &&
      Object.keys(formState.touchedFields).length
    ) {
      setValue(
        `${name}`,
        {
          ...fieldValue,
          Base: hourFormat
            ? convertDailyToHourly(baseValue)
            : convertHourlyToDaily(baseValue),
        },
        { shouldTouch: false, shouldDirty: false },
      );
    }
    setFormat(hourFormat ? DataFormat.Hour : DataFormat.Day);
  }, [hourFormat]);

  return { format };
}
