import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import { useMutation } from "@tanstack/react-query";

import { Plants } from "../../../api/plants";
import { PlantLifeCycle } from "../../../api/types/plants";
import { MutationKey } from "../../../consts/mutation-keys";
import { QueryKey } from "../../../consts/query-keys";
import { useApiError } from "../../../hooks/useApiError";
import { useApiSuccess } from "../../../hooks/useApiSuccess";
import { ApiHookConfig } from "../../../types/api-hook-config";
import { CafsForm } from "../../../types/cafs-form";

export const useLifecycleUpdate = ({ onSuccess }: ApiHookConfig) => {
  const { datasetId, id } = useParams();
  const { formatMessage } = useIntl();
  const { handleSuccess } = useApiSuccess({
    invalidate: [
      [QueryKey.LifeCycleSection],
      [QueryKey.CafsDetails],
      [QueryKey.PlantAudit],
    ],
    snackbarText: formatMessage({
      id: "ACTIONS.LIFECYCLE_UPDATE_SUCCESS",
      defaultMessage: "Plant life cycle updated successfully.",
      description: "Plant life cycle update success message",
    }),
    onSuccessFn: onSuccess,
  });

  const { handleError, apiErrors } = useApiError();

  const mutationFn = (data: Partial<CafsForm<PlantLifeCycle>>) =>
    Plants.updateLifeCycle(
      { path: { datasetId: +datasetId!, plantId: +id! } },
      data,
    );

  const { mutate: updateLifeCycle } = useMutation({
    mutationKey: [MutationKey.SaveLifeCycleSection, datasetId, id],
    mutationFn: mutationFn,
    onSuccess: handleSuccess,
    onError: handleError,
  });

  return { updateLifeCycle, apiErrors };
};
