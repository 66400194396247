import { useNavigate } from "react-router-dom";

import { IconButton, useMediaQuery, useTheme } from "@mui/material";

import Logo from "assets/logo.svg";

import { Bars } from "../../components/icons/Bars";
import { Logout } from "../../components/icons/Logout";
import { Xmark } from "../../components/icons/Xmark";
import { LangBtn } from "../../components/LangBtn";
import { LocalStorageKey } from "../../consts/local-storage-key";
import { Routes } from "../../consts/routes";
import { useAuth } from "../../hooks/useAuth";

import { Loader } from "./Loader";
import { useLogout } from "./useLogout";

export type TopNavProps = {
  open: boolean;
  onToggle: (open: boolean) => void;
};

export function TopNav({ open, onToggle }: TopNavProps) {
  const theme = useTheme();
  const { clearAuthData } = useAuth();
  const navigate = useNavigate();

  const { logout } = useLogout({
    onSuccess: () => {
      clearAuthData();
      localStorage.removeItem(LocalStorageKey.SelectedDataset);
      navigate(`/${Routes.Login}`);
    },
  });

  const matches = useMediaQuery(theme.breakpoints.up("lg"));

  function handleLogout() {
    logout();
  }

  return (
    <div className="fixed w-full shadow-md h-[82px] z-50 top-0 left-0 bg-white">
      <div className="flex h-full relative">
        <div className="w-full flex justify-center items-center">
          <img src={Logo} alt="logo" className="w-[160px]" />
        </div>
        <div className="flex flex-row items-center absolute top-1/2 transform -translate-y-1/2 right-8 gap-2 cursor-pointer">
          <div className="hidden">
            <LangBtn />
          </div>
          {matches && (
            <div
              className="flex flex-row gap-2 ml-4 text-primary hover:text-orange-500"
              onClick={handleLogout}>
              <Logout className="text-2xl 0" />
              <p> logout</p>
            </div>
          )}
          {!matches && (
            <IconButton
              sx={{ padding: 0, ml: 2 }}
              size="large"
              color="primary"
              onClick={() => onToggle(!open)}>
              {open ? <Xmark /> : <Bars className="text-2xl" />}
            </IconButton>
          )}
        </div>
      </div>
      <Loader />
    </div>
  );
}
