import { PropsWithChildren, SyntheticEvent, useContext } from "react";

import { AccordionDetails } from "@mui/material";

import { GuestResponseToPressure } from "../../../../api/types/guests";
import { StyledAccordion } from "../../../../components/StyledAccordion";
import { StyledAccordionSummary } from "../../../../components/StyledAccordionSummary";
import { useModal } from "../../../../hooks/useModal";
import { CafsContext } from "../../CafsContext";

import { DeleteGuestPressureModal } from "./DeleteGuestPressureModal";
import { renderGuestTrash } from "./render-guest-trash";

export type GuestRtpItemProps = PropsWithChildren & {
  item: GuestResponseToPressure;
  onChange: (event: SyntheticEvent<Element, Event>, expanded: boolean) => void;
  guestId: number | undefined;
  expanded: boolean;
};

export function GuestPressureItem({
  children,
  item,
  onChange,
  expanded,
  guestId,
}: GuestRtpItemProps) {
  const { isReadMode } = useContext(CafsContext);
  const { open, handleOpen, handleClose } = useModal();

  return (
    <>
      <StyledAccordion onChange={onChange} expanded={expanded}>
        <StyledAccordionSummary expanded={expanded}>
          <div>{item.Category?.Current ?? item.Category?.Base}</div>
          {renderGuestTrash(item, handleOpen, isReadMode)}
        </StyledAccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </StyledAccordion>

      <DeleteGuestPressureModal
        open={open}
        onClose={handleClose}
        guestId={guestId}
        pressure={item}
      />
    </>
  );
}
