import { VariableRenderProps } from "../../../../../types/variable-render-props";

export function renderForIrrigation({ methods }: VariableRenderProps): boolean {
  if (methods) {
    const baseType = methods.watch("Type.Base");
    const currentType = methods.watch("Type.Current");
    return (
      baseType === "IrrigationSprinkler" ||
      baseType === "IrrigationDrip" ||
      ((currentType === "IrrigationSprinkler" ||
        currentType === "IrrigationDrip") &&
        !baseType)
    );
  }
  return true;
}
