import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import { useMutation } from "@tanstack/react-query";

import { Plants } from "../../../api/plants";
import { PlantBiomassAllocation } from "../../../api/types/plants";
import { MutationKey } from "../../../consts/mutation-keys";
import { QueryKey } from "../../../consts/query-keys";
import { useApiError } from "../../../hooks/useApiError";
import { useApiSuccess } from "../../../hooks/useApiSuccess";
import { ApiHookConfig } from "../../../types/api-hook-config";
import { CafsForm } from "../../../types/cafs-form";

export const useBiomassAllocationUpdate = ({ onSuccess }: ApiHookConfig) => {
  const { datasetId, id } = useParams();
  const { formatMessage } = useIntl();
  const { handleSuccess } = useApiSuccess({
    invalidate: [
      [QueryKey.BiomassAllocationSection],
      [QueryKey.CafsDetails],
      [QueryKey.PlantAudit],
    ],
    snackbarText: formatMessage({
      id: "ACTIONS.BIOMASS_ALLOCATION_UPDATE",
      defaultMessage: "Plant biomass allocation updated successfully.",
      description: "Plant biomass allocation update success message",
    }),
    onSuccessFn: onSuccess,
  });

  const { handleError, apiErrors } = useApiError();

  const mutationFn = (data: Partial<CafsForm<PlantBiomassAllocation>>) =>
    Plants.updateBiomassAllocation(
      {
        path: { datasetId: +datasetId! as number, plantId: +id! as number },
      },
      data,
    );

  const { mutate: updateBiomassAllocation, isPending } = useMutation({
    mutationKey: [MutationKey.SaveBiomassAllocationSection, datasetId, id],
    mutationFn: mutationFn,
    onSuccess: handleSuccess,
    onError: handleError,
  });

  return { updateBiomassAllocation, apiErrors, isPending };
};
