import React from "react";
import { useMatch } from "react-router-dom";

import { twMerge } from "tailwind-merge";

import { Plant } from "../../../api/types/plants";
import { Seedling } from "../../../components/icons/Seedling";
import { Towel } from "../../../components/icons/Towel";
import { Tree } from "../../../components/icons/Tree";
import { Breadcrumb } from "../../../features/breadcrumb/Breadcrumb";

export type PlantTitleProps = {
  data: Plant;
};

export function CafsTitle({ data }: PlantTitleProps) {
  const matchInput = useMatch("/dashboard/input/:datasetId/:id");
  const matchTree = useMatch("/dashboard/perennial/:datasetId/:id");
  const matchCrop = useMatch("/dashboard/annual/:datasetId/:id");
  return (
    <div>
      <Breadcrumb data={data} />
      {data.FullName?.length ? (
        <div className="flex flex-row gap-4 items-center mt-6">
          <div>
            {matchTree && <Tree className={twMerge("text-primary text-4xl")} />}
            {matchCrop && (
              <Seedling className={twMerge("text-primary text-4xl")} />
            )}
            {matchInput && (
              <Towel className={twMerge("text-primary text-4xl")} />
            )}
          </div>
          <h2 className="text-4xl text-primary font-bold">{data?.FullName}</h2>
        </div>
      ) : null}
    </div>
  );
}
