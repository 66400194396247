import { CafsItem } from "../../../api/types/cafs";
import { DatasetDetails } from "../../../api/types/datasets";
import { Access } from "../../../consts/access";
import { CafsMap } from "../../../consts/cafsMap";
import { CafsType } from "../../../consts/cafsType";
import {
  CafsTreeData,
  CafsTreeNodeType,
} from "../../../types/cafs-tree-node-type";

function createNodeId(name: CafsType, id: number | undefined) {
  return `${CafsMap.get(name)}_${id}`;
}

function mapChildToCafsTreeNode(
  node: CafsItem,
  type: CafsType,
  parentPath: string = "",
): CafsTreeNodeType {
  const currentPath = `${parentPath}/${node.FullName ?? "unknown"}`;

  return {
    id: node?.Id ?? 0,
    nodeId: createNodeId(type, node.Id),
    name: node.FullName ?? "",
    scientificName: node.ScientificName,
    type: type,
    tier: node.Tier,
    status: node.Status,
    access: node.AccessType,
    canCopy: node.CanCopy,
    canAddDescendant: node.CanAddDescendant,
    isAssigned: node.IsAssigned,
    hasAnyDatasets: node.HasAnyDatasets,
    parent: parentPath,
    childNodes: node.Descendants
      ? node.Descendants.map(childNode =>
          mapChildToCafsTreeNode(childNode, type, `${currentPath}`),
        )
      : null,
  };
}

export function mapApiToCafsTree(data: DatasetDetails): CafsTreeData {
  return {
    items: [
      {
        id: "Perennials",
        nodeId: "Perennials",
        name: "Perennials",
        scientificName: "",
        type: CafsType.Tree,
        canCopy: false,
        tier: 0,
        isRoot: true,
        parent: null,
        access: Access.Full,
        hasAnyDatasets: false,
        canAddDescendant: data.CanAddDescendant,
        childNodes: data.Perennials
          ? data.Perennials.map(item =>
              mapChildToCafsTreeNode(item, CafsType.Tree),
            )
          : null,
      },
      {
        id: "Annuals",
        nodeId: "Annuals",
        name: "Annuals",
        scientificName: "",
        isRoot: true,
        type: CafsType.Crop,
        canCopy: false,
        parent: null,
        tier: 0,
        access: Access.Full,
        hasAnyDatasets: false,
        canAddDescendant: data.CanAddDescendant,
        childNodes: data.Annuals
          ? data.Annuals.map(item =>
              mapChildToCafsTreeNode(item, CafsType.Crop),
            )
          : null,
      },
      {
        id: "Inputs",
        nodeId: "Inputs",
        name: "Inputs",
        scientificName: "",
        isRoot: true,
        parent: null,
        type: CafsType.Input,
        canCopy: false,
        hasAnyDatasets: false,
        tier: 0,
        access: Access.Full,
        canAddDescendant: data.CanAddDescendant,
        childNodes: data.Inputs
          ? data.Inputs.map(item =>
              mapChildToCafsTreeNode(item, CafsType.Input),
            )
          : null,
      },
    ],
    total: data.CafsCount,
  } as CafsTreeData;
}
