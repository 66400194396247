import { PlantAudit } from "../../../../../api/types/plants";

export const filterHistory = (audit: PlantAudit, searchTerm: string) => {
  const termLower = searchTerm.toLowerCase();
  const hasMatchingHistoryItem = audit.AuditItems?.some(historyItem => {
    const differenceKeys = Object.keys(historyItem.Difference || {});
    return differenceKeys.some(key => key.toLowerCase().includes(termLower));
  });

  return (
    hasMatchingHistoryItem ||
    audit.UpdatedBy?.toLowerCase().includes(termLower) ||
    audit.UpdatedAt?.toLowerCase().includes(termLower)
  );
};
