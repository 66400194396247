import { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";

import { Add } from "@mui/icons-material";
import { Alert, Button } from "@mui/material";

import { LoadingPlaceholder } from "../../../../components/LoadingPlaceholder";
import { useAccordion } from "../../../../hooks/useAccordion";
import { useModal } from "../../../../hooks/useModal";
import { useGuestsData } from "../../api/useGuestsData";
import { CafsContext } from "../../CafsContext";

import { CreateGuestModal } from "./CreateGuestModal";
import { GuestEdit } from "./GuestEdit";
import { GuestsListItem } from "./GuestsListItem";

export function GuestsList() {
  const { datasetId, id } = useParams();
  const { isReadMode } = useContext(CafsContext);
  const { guests, isPending } = useGuestsData(datasetId, id);
  const { expanded, handleChange: toggleAccordion } = useAccordion();

  const { open, handleOpen, handleClose } = useModal();

  return (
    <>
      {isPending && <LoadingPlaceholder />}
      {!guests?.Guests?.length && !isPending && (
        <Alert severity="info">There is no guests for this plant.</Alert>
      )}
      {guests?.Guests?.map((guest, index) => (
        <GuestsListItem
          key={guest.Id}
          onChange={toggleAccordion(guest.Id!)}
          expanded={expanded === guest.Id!}
          guest={guest}>
          <GuestEdit guest={guest} guestId={guest.Id} />
        </GuestsListItem>
      ))}
      <div className="mt-6 border-t-[1px] border-grey pt-6">
        <Button
          type="button"
          variant="outlined"
          color="primary"
          disabled={isReadMode}
          startIcon={<Add />}
          onClick={handleOpen}>
          <FormattedMessage
            id="BTN.ADD_NEW_PRODUCT"
            defaultMessage="Add new guest"
            description="Add new guest btn"
          />
        </Button>
      </div>

      <CreateGuestModal open={open} onClose={handleClose} />
    </>
  );
}
