import { UseFormReturn } from "react-hook-form";

import { PlantType } from "../../../../api/types/plants";
import { CafsVariableType } from "../../const/cafs-variable-type";
import { CafsVariable } from "../cafs-table/CafsTable";

import { BaseAutocompleteRow } from "./BaseAutocompleteRow";
import { BaseInputRow } from "./BaseInputRow";
import { BaseSwitchRow } from "./BaseSwitchRow";
import { BaseTextAreaRow } from "./BaseTextAreaRow";

export function renderBaseRow<T>(
  variable: CafsVariable<keyof T>,
  plantType: PlantType | undefined,
  methods: UseFormReturn,
) {
  if (
    variable.renderCondition &&
    methods &&
    !variable.renderCondition({ methods: methods, cafsType: plantType })
  )
    return <></>;

  switch (variable.type) {
    case CafsVariableType.Input:
      return (
        <div key={variable.name as string} className="mb-6">
          <BaseInputRow
            key={variable.name as string}
            name={variable.name as string}
            type={variable.text ? "text" : "number"}
            required={variable.required}
          />
        </div>
      );
    case CafsVariableType.Select:
      return (
        <div key={variable.name as string} className="mb-6">
          <BaseAutocompleteRow
            key={variable.name as string}
            name={variable.name as string}
            options={variable.options ?? []}
            required={variable.required}
          />
        </div>
      );
    case CafsVariableType.Boolean:
      return (
        <div key={variable.name as string} className="mb-6">
          <BaseSwitchRow
            key={variable.name as string}
            name={variable.name as string}
          />
        </div>
      );
    case CafsVariableType.Text:
      return (
        <div key={variable.name as string} className="mb-6">
          <BaseTextAreaRow
            key={variable.name as string}
            name={variable.name as string}
          />
        </div>
      );
    default:
      return null;
  }
}
