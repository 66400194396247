import React, { createContext, PropsWithChildren, useState } from "react";

import { Dataset } from "../../api/types/datasets";
import { CafsType } from "../../consts/cafsType";
import { LocalStorageKey } from "../../consts/local-storage-key";
import { CafsTreeNodeType } from "../../types/cafs-tree-node-type";
import { emptyFn } from "../../utils/empty-fn";

type NodeHandler = (node: CafsTreeNodeType) => void;

export type DashboardContextProps = {
  handleCafsSelect: NodeHandler;
  handleDeselectAllCafs: () => void;
  handleTreeExpand: (nodes: string[] | null) => void;
  handleFilterChange: (id: number | null) => void;
  handleDatasetChange: (data: Dataset | null) => void;
  handleSearch: (searchTerm: string) => void;
  selectedPlants: CafsTreeNodeType[];
  selectedInputs: CafsTreeNodeType[];
  expandedNodes: string[] | null;
  activeFilter: string | null;
  selectedDataset: Dataset | null;
  searchTerm: string;
};

export const DashboardContext = createContext<DashboardContextProps>({
  handleCafsSelect: emptyFn,
  handleSearch: emptyFn,
  handleDeselectAllCafs: emptyFn,
  handleTreeExpand: emptyFn,
  handleFilterChange: emptyFn,
  handleDatasetChange: emptyFn,
  selectedPlants: [],
  selectedInputs: [],
  expandedNodes: [],
  activeFilter: null,
  searchTerm: "",
  selectedDataset: null,
});

export type DashboardCtxProviderProps = PropsWithChildren;

export function DashboardContextProvider({
  children,
}: DashboardCtxProviderProps) {
  const [selectedDataset, setSelectedDataset] = useState<Dataset | null>(
    JSON.parse(localStorage.getItem(LocalStorageKey.SelectedDataset) ?? "null"),
  );
  const [activeFilter, setActiveFiltes] = useState<string | null>(
    JSON.parse(localStorage.getItem(LocalStorageKey.ActiveFilter) ?? "null"),
  );
  const [selectedPlants, setSelectedPlants] = useState<CafsTreeNodeType[]>(
    JSON.parse(localStorage.getItem(LocalStorageKey.SelectedPlants) || "[]"),
  );
  const [selectedInputs, setSelectedInputs] = useState<CafsTreeNodeType[]>(
    JSON.parse(localStorage.getItem(LocalStorageKey.SelectedInputs) || "[]"),
  );
  const [expandedNodes, setExpandedNodes] = useState<string[] | null>(() =>
    JSON.parse(localStorage.getItem(LocalStorageKey.ExpandedNodes) || "[]"),
  );
  const [searchTerm, setSearchTerm] = useState<string>(
    localStorage.getItem(LocalStorageKey.SearchTerm) || "",
  );

  const handleCafsSelect = (node: CafsTreeNodeType) => {
    if (node.type === CafsType.Tree || node.type === CafsType.Crop) {
      setSelectedPlants(prevSelectedPlants => {
        const nodeIndex = prevSelectedPlants.findIndex(
          selectedNode => selectedNode.id === node.id,
        );
        let newSelectedPlants;
        if (nodeIndex > -1) {
          newSelectedPlants = prevSelectedPlants.filter(
            (_, index) => index !== nodeIndex,
          );
        } else {
          newSelectedPlants = [...prevSelectedPlants, node];
        }
        localStorage.setItem(
          LocalStorageKey.SelectedPlants,
          JSON.stringify(newSelectedPlants),
        );
        return newSelectedPlants;
      });
    } else if (node.type === CafsType.Input) {
      setSelectedInputs(prevSelectedInputs => {
        const nodeIndex = prevSelectedInputs.findIndex(
          selectedNode => selectedNode.id === node.id,
        );
        let newSelectedInputs;
        if (nodeIndex > -1) {
          newSelectedInputs = prevSelectedInputs.filter(
            (_, index) => index !== nodeIndex,
          );
        } else {
          newSelectedInputs = [...prevSelectedInputs, node];
        }
        localStorage.setItem(
          LocalStorageKey.SelectedInputs,
          JSON.stringify(newSelectedInputs),
        );
        return newSelectedInputs;
      });
    }
  };

  const handleDeselectAllCafs = () => {
    setSelectedInputs([]);
    setSelectedPlants([]);
    localStorage.removeItem(LocalStorageKey.SelectedInputs);
    localStorage.removeItem(LocalStorageKey.SelectedPlants);
  };

  const handleTreeExpand = (data: string[] | null) => {
    setExpandedNodes(data);
    localStorage.setItem(LocalStorageKey.ExpandedNodes, JSON.stringify(data));
  };

  const handleDatasetChange = (value: Dataset | null) => {
    setSelectedInputs([]);
    setSelectedPlants([]);

    if (value) {
      setSelectedDataset(value);
      localStorage.setItem(
        LocalStorageKey.SelectedDataset,
        JSON.stringify(value),
      );
      return;
    }
    setSelectedDataset(null);
  };

  const handleSearch = (data: string) => {
    if (data.length) {
      setSearchTerm(data);
      return;
    }
    setSearchTerm("");
    localStorage.removeItem(LocalStorageKey.SearchTerm);
  };

  const handleFilterChange = (filterId: number | null) => {
    setActiveFiltes(String(filterId));
    localStorage.setItem(LocalStorageKey.ActiveFilter, String(filterId));
  };

  return (
    <DashboardContext.Provider
      value={{
        selectedPlants,
        selectedInputs,
        expandedNodes,
        searchTerm,
        activeFilter,
        selectedDataset,
        handleTreeExpand,
        handleFilterChange,
        handleSearch,
        handleCafsSelect,
        handleDeselectAllCafs,
        handleDatasetChange,
      }}>
      {children}
    </DashboardContext.Provider>
  );
}
