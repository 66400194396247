import { useParams } from "react-router-dom";

import { useQuery } from "@tanstack/react-query";

import { Guests } from "../../../api/guests";
import { QueryKey } from "../../../consts/query-keys";

export const useGuestPressureData = (
  guestId: number | undefined,
  enabled: boolean,
) => {
  const { datasetId, id } = useParams();
  const queryFn = () =>
    Guests.getResponsesToPressure({
      path: { datasetId: +datasetId!, plantId: +id!, guestId: +guestId! },
    });

  const { data, isPending } = useQuery({
    queryKey: [QueryKey.GuestRtp, datasetId, id, guestId],
    queryFn: queryFn,
    staleTime: Infinity,
    enabled: enabled,
  });

  return { data, isPending };
};
