import { Accordion, AccordionProps } from "@mui/material";
import { styled } from "@mui/system";

type StyledAccordionProps = {
  expanded: boolean;
} & AccordionProps;

export const StyledAccordion = styled((props: StyledAccordionProps) => (
  <Accordion {...props} />
))(({ theme, expanded }) => ({
  borderTop: expanded ? `3px solid ${theme.palette.primary.main}` : "none",
  "&:not(.Mui-expanded):hover": {
    backgroundColor: theme.palette.action?.hover,
  },
}));
