import dayjs from "dayjs";
import { twMerge } from "tailwind-merge";

import { components } from "../../../../api-client/api-schema";
import { Date } from "../../../../components/icons/Date";
import { User } from "../../../../components/icons/User";

export type HistoryAccordionTitleProps = {
  data: components["schemas"]["AuditModelDto"];
  expanded: boolean;
};

export function CafsHistoryTitle({
  data,
  expanded,
}: HistoryAccordionTitleProps) {
  return (
    <div className={twMerge("flex flex-row justify-between w-full")}>
      <div className="flex flex-row gap-3 items-center">
        <div className={twMerge("text-darkGray")}>
          <User className="text-darkGray text-xl" />
        </div>
        <span className={twMerge("text-shark font-semibold")}>
          {data.UpdatedBy}
        </span>
      </div>
      <div className="rounded bg-opacity-10 text-info-main px-2 py-1 flex flex-row gap-2 items-center justify-center">
        <div className="text-info-main">
          <Date className="text-xl text-darkGray" />
        </div>
        <div className="text-darkGray">
          {dayjs(data.UpdatedAt).format("DD.MM.YYYY HH:mm:ss")}
        </div>
      </div>
    </div>
  );
}
