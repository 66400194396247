import { GuestResponseToPressure } from "../../../../api/types/guests";
import { ModalSize, MuiModal } from "../../../../components/mui/MuiModal";
import { CafsForm } from "../../../../types/cafs-form";
import { useGuestPressureCreate } from "../../api/useGuestPressureCreate";

import { GuestPressureCreate } from "./GuestPressureCreate";

export type CreateProductModalProps = {
  open: boolean;
  onClose: () => void;
  categories: { id: string; label: string }[];
  guestId: number | undefined;
};

export function CreateGuestPressureModal({
  open,
  onClose,
  categories,
  guestId,
}: CreateProductModalProps) {
  const handleClose = () => {
    clearApiErrors();
    onClose();
  };

  const { createRtp, apiErrors, clearApiErrors, isPending } =
    useGuestPressureCreate({
      onSuccess: handleClose,
    });

  function handleCreateRtp(
    data: Partial<CafsForm<keyof GuestResponseToPressure>>,
  ) {
    createRtp({ data, guestId: guestId });
  }

  return (
    <MuiModal
      open={open}
      onClose={handleClose}
      size={ModalSize.Medium}
      header="Create response to pressure">
      <GuestPressureCreate
        categories={categories}
        onSubmit={handleCreateRtp}
        onCancel={handleClose}
        customErrors={apiErrors}
        disabled={isPending}
      />
    </MuiModal>
  );
}
