import { createContext, PropsWithChildren, ReactNode, useState } from "react";

import { User } from "./api/types/user";
import { SnackbarComponent } from "./components/SnackbarComponent";
import { LocalStorageKey } from "./consts/local-storage-key";
import { useSnackbarManager } from "./hooks/useSnacbarManager";

export enum SnackbarType {
  "Success" = "success",
  "Error" = "error",
}

export type SnackbarProps = {
  type: SnackbarType;
  message: string;
};

export const AppContext = createContext<AppContextProps>({
  user: {},
  showSnackbar: (data: SnackbarProps) => {},
  setUserData: (data: User | undefined) => {},
});

export type AppContextProps = {
  user: User | undefined;
  showSnackbar: (data: SnackbarProps) => void;
  setUserData: (data: User | undefined) => void;
};

export const AppContextProvider = ({
  children,
}: PropsWithChildren): ReactNode => {
  const [user, setUser] = useState<User | undefined>(
    JSON.parse(localStorage.getItem(LocalStorageKey.User) ?? "null") as User,
  );

  const setUserData = (data: User | undefined) => {
    setUser(data);
  };

  const { snackbar, showSnackbar, closeSnackbar } = useSnackbarManager();

  return (
    <AppContext.Provider
      value={{
        user: user,
        showSnackbar: showSnackbar,
        setUserData: setUserData,
      }}>
      {children}
      <SnackbarComponent snackbar={snackbar} onClose={closeSnackbar} />
    </AppContext.Provider>
  );
};
