import { ErrorBoundary } from "react-error-boundary";

import { Alert } from "@mui/material";

import { useAccordion } from "../../../../hooks/useAccordion";
import { CafsSection } from "../../const/cafs-section";
import { CafsSectionsMap } from "../../const/cafs-sections-map";
import { InputCosts } from "../cafs-sections/Input-costs/InputCostSection";
import { InputDefinitionSection } from "../cafs-sections/Input-definition/InputDefinitionSection";
import { InputLabourSection } from "../cafs-sections/input-labour/InputLabourSection";
import { VariableSection } from "../VariableSection";

export function InputVariables() {
  const { expanded, handleChange } = useAccordion();
  return (
    <>
      <VariableSection
        name={CafsSectionsMap.get(CafsSection.PlantDefinition) ?? ""}
        onChange={handleChange(CafsSection.PlantDefinition)}
        expanded={expanded === CafsSection.PlantDefinition}
        data-testid={`Section.${CafsSection.PlantDefinition}`}>
        <ErrorBoundary
          fallback={<Alert severity="error">Something went wrong...</Alert>}>
          <InputDefinitionSection
            expanded={expanded === CafsSection.PlantDefinition}
          />
        </ErrorBoundary>
      </VariableSection>

      <VariableSection
        name={CafsSectionsMap.get(CafsSection.PlantCost) ?? ""}
        onChange={handleChange(CafsSection.PlantCost)}
        expanded={expanded === CafsSection.PlantCost}
        data-testid={`Section.${CafsSection.PlantCost}`}>
        <ErrorBoundary
          fallback={<Alert severity="error">Something went wrong...</Alert>}>
          <InputCosts expanded={expanded === CafsSection.PlantCost} />
        </ErrorBoundary>
      </VariableSection>

      <VariableSection
        name={CafsSectionsMap.get(CafsSection.PlantLabour) ?? ""}
        onChange={handleChange(CafsSection.PlantLabour)}
        expanded={expanded === CafsSection.PlantLabour}
        data-testid={`Section.${CafsSection.PlantLabour}`}>
        <ErrorBoundary
          fallback={<Alert severity="error">Something went wrong...</Alert>}>
          <InputLabourSection expanded={expanded === CafsSection.PlantLabour} />
        </ErrorBoundary>
      </VariableSection>
    </>
  );
}
