import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import { useMutation } from "@tanstack/react-query";

import { Guests } from "../../../api/guests";
import { MutationKey } from "../../../consts/mutation-keys";
import { QueryKey } from "../../../consts/query-keys";
import { useApiError } from "../../../hooks/useApiError";
import { useApiSuccess } from "../../../hooks/useApiSuccess";

export type DeleteGuestRtpMutation = {
  guestId: number;
  guestResponseToPressureId: number;
};

export const useGuestPressureDelete = (onSuccess: () => void) => {
  const { datasetId, id } = useParams();
  const { formatMessage } = useIntl();
  const { handleError } = useApiError();
  const { handleSuccess } = useApiSuccess({
    onSuccessFn: onSuccess,
    invalidate: [[QueryKey.GuestRtp, datasetId!, id!], [QueryKey.CafsDetails]],
    snackbarText: formatMessage({
      id: "ACTIONS.GUEST_PRESSURE_DELETE_SUCCESS",
      defaultMessage: "Guest response to pressure deleted successfully.",
      description: "Guest pressure delete success message",
    }),
  });

  const mutationFn = ({
    guestId,
    guestResponseToPressureId,
  }: DeleteGuestRtpMutation) =>
    Guests.deleteResponsesToPressure({
      path: {
        datasetId: +datasetId!,
        plantId: +id!,
        guestId,
        guestResponseToPressureId,
      },
    });

  const { mutate: deleteRtp, isPending } = useMutation({
    mutationKey: [MutationKey.DeletePlantRtp],
    mutationFn: mutationFn,
    onSuccess: handleSuccess,
    onError: handleError,
  });

  return { deleteRtp, isPending };
};
