import { httpCall } from "../http-client";
import { HttpMethod } from "../http-client/types";

import { apiUrl } from "./utils/api-url";
import { queryParamsBuilder } from "./utils/query-params-builder";
import { resourcePathBuilder } from "./utils/resource-path-builder";
import { paths } from "./api-schema";
import { CreateApiOperationOptions, Path } from "./types";

export function createApiOperation<R, P extends Path, M extends keyof paths[P]>(
  path: P,
  httpMethod: M,
  options?: CreateApiOperationOptions,
) {
  type ParamsType = paths[P][M] extends {
    parameters: unknown;
  }
    ? paths[P][M]["parameters"]
    : never;

  return async (
    params?: ParamsType,
    data?: unknown,
    onProgress?: (progress: number) => void,
    onUploadComplete?: () => void,
  ): Promise<R> => {
    const urlPath = resourcePathBuilder(path, (params as Pathable)?.path);
    const urlSearchParams = queryParamsBuilder(
      (params as Querable)?.query ?? {},
      options?.defaultParams,
    );

    return httpCall(
      httpMethod as HttpMethod,
      apiUrl(urlPath, urlSearchParams),
      data,
      options?.file,
      onProgress,
      onUploadComplete,
    ) as Promise<R>;
  };
}

type Pathable = { path?: Record<string, unknown> };
type Querable = { query?: Record<string, unknown> };
