import * as yup from "yup";

import { PlantLifeCycle } from "../../../../../api/types/plants";
import {
  CafsValueNumberValidation,
  CafsValueRemarksValidation,
} from "../../../../../consts/validation";
import { CafsForm } from "../../../../../types/cafs-form";

export const lifecycleSchema = yup.object<CafsForm<keyof PlantLifeCycle>>({
  LifeCycleRemarks: CafsValueRemarksValidation(),
  NaturalLifeCycleMonths: CafsValueNumberValidation(),
  EconomicLifeCycleMonths: CafsValueNumberValidation(),
  TreeThinningYears: yup.object({
    Base: yup
      .string()
      .nullable()
      .matches(/^[0-9;/-]*$/, {
        message:
          "TreeThinningYears may only contain digits (1 to 9), semi-colons, dashes, and forward slashes",
        excludeEmptyString: true,
      })
      .transform((value, originalValue) =>
        !value || Number.isNaN(value) ? null : originalValue,
      ),
  }),
  TreeThinningFractions: yup.object({
    Base: yup
      .string()
      .nullable()
      .matches(/^[0-9;.]*$/, {
        message:
          "TreeThinningFractions may only contain digits (1 to 9), semi-colons, and dots",
        excludeEmptyString: true,
      })
      .transform((value, originalValue) =>
        !value || Number.isNaN(value) ? null : originalValue,
      ),
  }),
});
