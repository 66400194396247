import { StringCafsValue } from "../../../../../../api/types/cafs";
import { PlantGrowth } from "../../../../../../api/types/plants";
import { TreeHabit } from "../../../../../../api/types/presets";

export const offSeasonCanopyCoverReductionFn = (
  values: Partial<Record<keyof PlantGrowth, StringCafsValue>>,
  data: TreeHabit[],
): number | undefined => {
  const treeHabitValue = parseInt(values.TreeHabitId?.Base ?? "0", 10);
  const tree = data.find(d => d.Id === treeHabitValue);
  return tree?.OffSeasonCanopyCoverReduction;
};
