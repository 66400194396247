import { useContext } from "react";
import { useIntl } from "react-intl";

import { useMutation } from "@tanstack/react-query";

import { Inputs } from "../../../api/inputs";
import { Plants } from "../../../api/plants";
import { CafsType } from "../../../consts/cafsType";
import { MutationKey } from "../../../consts/mutation-keys";
import { QueryKey } from "../../../consts/query-keys";
import { useApiError } from "../../../hooks/useApiError";
import { useApiSuccess } from "../../../hooks/useApiSuccess";
import { useFetchError } from "../../../hooks/useFetchError";
import { ApiError } from "../../../types/api-error";
import { ApiHookConfig } from "../../../types/api-hook-config";
import { CafsTreeNodeType } from "../../../types/cafs-tree-node-type";
import { DashboardContext } from "../DashboardContext";

type UseCafsDeleteProps = ApiHookConfig & {
  cafs: CafsTreeNodeType | undefined;
};

export const useCafsDelete = ({ onSuccess, cafs }: UseCafsDeleteProps) => {
  const { selectedDataset } = useContext(DashboardContext);
  const { formatMessage } = useIntl();

  const { handleSuccess } = useApiSuccess({
    snackbarText: formatMessage(
      {
        id: "ACTION.CAFS_DELETE_SUCCESS",
        defaultMessage: "Item {parent}/{name} has been deleted.",
        description: "Success message for item deletion",
      },
      {
        parent: cafs?.parent,
        name: cafs?.name,
      },
    ),
    invalidate: [[QueryKey.DataSetDetails]],
    onSuccessFn: onSuccess,
  });

  const { handleError } = useApiError();

  const mutationFn = ({
    cafsId,
    type,
  }: {
    cafsId: string | number;
    type: CafsType | undefined;
  }) => {
    return type === CafsType.Input
      ? Inputs.delete({
          path: {
            datasetId: +selectedDataset?.Id!,
            inputId: +cafsId!,
          },
        })
      : Plants.delete(
          {
            path: {
              datasetId: +selectedDataset?.Id!,
              plantId: +cafsId!,
            },
          },
          {},
        );
  };

  const {
    mutate: deleteCafs,
    isPending: deleteCafsPending,
    error,
  } = useMutation({
    mutationKey: [MutationKey.DeletePlant, +selectedDataset!],
    mutationFn: mutationFn,
    onSuccess: handleSuccess,
    onError: handleError,
  });

  useFetchError(error as unknown as ApiError);

  return { deleteCafs, deleteCafsPending };
};
