import { PlantGrowthConditions } from "../../../../../api/types/plants";
import { DataFormat } from "../../../../../consts/data-format";
import { SelectOption } from "../../../../../types/select-option";
import { useTemperaturesData } from "../../../api/useTemperaturesData";
import { CafsVariableType } from "../../../const/cafs-variable-type";
import { CafsVariablesCollection } from "../../cafs-table/CafsTable";

export function useGrowthConditionsVariables(): CafsVariablesCollection<PlantGrowthConditions> {
  const { agroEcologicalZoneTemperatures } = useTemperaturesData();
  return [
    {
      name: "GrowthConditionsRemarks",
      type: CafsVariableType.Remarks,
    },
    {
      name: "TranspOfPotEvTransp",
      type: CafsVariableType.Input,
    },
    {
      name: "MinimumMoistureSuitabilityToSurvive",
      type: CafsVariableType.Input,
      format: DataFormat.Percent,
    },
    {
      name: "CoverEvapotranspirationReduction",
      type: CafsVariableType.Input,
    },
    {
      name: "NitrogenFix",
      type: CafsVariableType.Input,
    },
    {
      name: "OptpH",
      type: CafsVariableType.Input,
    },
    {
      name: "TolpHDeviation",
      type: CafsVariableType.Input,
    },
    {
      name: "SalinityDamageECeMin",
      type: CafsVariableType.Input,
    },
    {
      name: "SalinityDamageECeMax",
      type: CafsVariableType.Input,
    },
    {
      name: "AgroEcologicalZoneTemperatureId",
      type: CafsVariableType.Select,
      options: agroEcologicalZoneTemperatures?.map(az => {
        return { label: az.Name, id: az.Id } as SelectOption;
      }),
    },
    {
      name: "MinTempToSurviveC",
      type: CafsVariableType.Input,
    },
    {
      name: "MaxDaysSurvivalTooLowTemp",
      type: CafsVariableType.Input,
    },
    {
      name: "MaxTempToSurviveC",
      type: CafsVariableType.Input,
    },
    {
      name: "MaxDaysSurvivalTooHighTemp",
      type: CafsVariableType.Input,
    },
    {
      name: "MinDailyMaxTempToGrowC",
      type: CafsVariableType.Input,
    },
    {
      name: "MaxDailyMaxTempToGrowC",
      type: CafsVariableType.Input,
    },
    {
      name: "MinDailyMaxTempForOptimalGrowthC",
      type: CafsVariableType.Input,
    },
    {
      name: "MaxDailyMaxTempForOptimalGrowthC",
      type: CafsVariableType.Input,
    },
    {
      name: "MinDailyRadiationToGrowPerM2",
      type: CafsVariableType.Input,
    },
  ];
}
