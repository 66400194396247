import * as yup from "yup";

import { PlantWoodDensity } from "../../../../../api/types/plants";
import {
  CafsValueNumberValidation,
  CafsValueRemarksValidation,
} from "../../../../../consts/validation";
import { CafsForm } from "../../../../../types/cafs-form";

export const wooddensitySchema = yup.object<CafsForm<keyof PlantWoodDensity>>({
  WoodDensityRemarks: CafsValueRemarksValidation(),
  DensitySapWoodKgPM3: CafsValueNumberValidation(),
  DensityHeartWoodKgPM3: CafsValueNumberValidation(),
  YearHeartWoodStart: CafsValueNumberValidation(),
  YearHeartWoodMax: CafsValueNumberValidation(),
  HeartwoodMaxVolFraction: CafsValueNumberValidation(true),
  TreeShapeId: CafsValueNumberValidation(),
  BoleFractionOfTotalHeightMax: CafsValueNumberValidation(true),
});
