export function apiUrl(path: string, urlSearchParams: URLSearchParams) {
  const domain = process.env.REACT_APP_API_URL;
  const query = addQuestionMark(urlSearchParams.toString());

  return `${domain}${path}${query}`;
}

function addQuestionMark(str: string): string {
  if (str.length) {
    return `?${str}`;
  }
  return str;
}
