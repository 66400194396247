import { useEffect } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { Alert } from "@mui/material";

import { PlantBiomassAllocation } from "../../../../../api/types/plants";
import { useApiValidation } from "../../../../../hooks/useApiValidation";
import { useFetchData } from "../../../../../hooks/useFetchData";
import { SectionProps } from "../../../../../types/section-props";
import { useBiomassAllocationData } from "../../../api/useBiomassAllocationData";
import { useBiomassAllocationUpdate } from "../../../api/useBiomassAllocationUpdate";
import { CafsSection } from "../../../const/cafs-section";
import { useSectionSaveDisabled } from "../../../hooks/useSectionSaveDisabled";
import { CafsTable } from "../../cafs-table/CafsTable";
import { renderVariableRow } from "../../cafs-table/render-variable-row";
import { mapInitialValues } from "../../cafs-table/utils/map-initial-values";
import { mapSubmitValues } from "../../cafs-table/utils/map-submit-values";
import { SectionRemarks } from "../SectionRemarks";

import { biomassAllocationSchema } from "./biomass-schema";
import { useBiomassVariables } from "./useBiomassVariables";

export function BiomassAllocationSection({ expanded }: SectionProps) {
  const variables = useBiomassVariables();

  const { shouldFetchData } = useFetchData(expanded);
  const { biomassAllocationData, isPending } =
    useBiomassAllocationData(shouldFetchData);

  const { updateBiomassAllocation, apiErrors } = useBiomassAllocationUpdate({
    onSuccess: () => {},
  });

  const methods = useForm<FieldValues>({
    resolver: yupResolver(biomassAllocationSchema),
    mode: "onChange",
  });

  useEffect(() => {
    if (biomassAllocationData) {
      methods.reset(mapInitialValues(biomassAllocationData, variables));
    }
  }, [biomassAllocationData]);

  const handleSubmit = (data: FieldValues) => {
    updateBiomassAllocation(mapSubmitValues(data));
  };

  useApiValidation(apiErrors, methods);

  const { disabled } = useSectionSaveDisabled(methods, isPending);

  return biomassAllocationData && !isPending ? (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <SectionRemarks<PlantBiomassAllocation>
          name="BiomassRemarks"
          data={biomassAllocationData}
        />
        <CafsTable<PlantBiomassAllocation>
          name={CafsSection.PlantBiomassAllocation}
          variables={variables}
          render={renderVariableRow}
          data={biomassAllocationData ?? {}}
          disabled={disabled}
        />
      </form>
    </FormProvider>
  ) : (
    <div className="w-full">
      <Alert severity="info">Loading data</Alert>
    </div>
  );
}
