import { useEffect } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { Alert } from "@mui/material";

import { PlantConstraint } from "../../../../../api/types/plants";
import { useApiValidation } from "../../../../../hooks/useApiValidation";
import { useFetchData } from "../../../../../hooks/useFetchData";
import { SectionProps } from "../../../../../types/section-props";
import { useConstraintsData } from "../../../api/useConstraintsData";
import { useConstraintsUpdate } from "../../../api/useConstraintsUpdate";
import { CafsSection } from "../../../const/cafs-section";
import { useSectionSaveDisabled } from "../../../hooks/useSectionSaveDisabled";
import { CafsTable } from "../../cafs-table/CafsTable";
import { renderVariableRow } from "../../cafs-table/render-variable-row";
import { mapInitialValues } from "../../cafs-table/utils/map-initial-values";
import { mapSubmitValues } from "../../cafs-table/utils/map-submit-values";
import { SectionRemarks } from "../SectionRemarks";

import { constraintsSchema } from "./constraints-schema";
import { useConstrainstVariables } from "./useConstraintsVariables";

export function ConstraintsSection({ expanded }: SectionProps) {
  const variables = useConstrainstVariables();

  const { shouldFetchData } = useFetchData(expanded);

  const { constraintsData, isPending } = useConstraintsData(shouldFetchData);

  const { updateConstraints, apiErrors } = useConstraintsUpdate({
    onSuccess: () => {},
  });

  const methods = useForm<FieldValues>({
    resolver: yupResolver(constraintsSchema),
    mode: "onChange",
  });

  useEffect(() => {
    if (constraintsData) {
      methods.reset(mapInitialValues(constraintsData, variables));
    }
  }, [constraintsData]);

  const handleSubmit = (data: FieldValues) => {
    updateConstraints(mapSubmitValues(data));
  };

  useApiValidation(apiErrors, methods);

  const { disabled } = useSectionSaveDisabled(methods, isPending);

  return constraintsData && !isPending ? (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <SectionRemarks<PlantConstraint>
          name="ConstraintsRemarks"
          data={constraintsData}
        />
        <CafsTable<PlantConstraint>
          name={CafsSection.PlantConstraint}
          variables={variables}
          render={renderVariableRow}
          data={constraintsData ?? {}}
          disabled={disabled}
        />
      </form>
    </FormProvider>
  ) : (
    <div className="w-full">
      <Alert severity="info">Loading data</Alert>
    </div>
  );
}
