import { CafsVariableCollection } from "../../../../../api/types/cafs";
import { DataFormat } from "../../../../../consts/data-format";
import { CafsFormValue } from "../../../../../types/cafs-form-value";
import { convertHourlyToDaily } from "../../../../../utils/convert-hourly-to-daily";
import { getBaseValue } from "../../../../../utils/variable-helper";
import { yearsToMonths } from "../../../../../utils/years-to-months";

export const mapSubmitValues = <T>(
  data: CafsVariableCollection<T>,
): CafsVariableCollection<T> => {
  const mappedValues: Record<string, unknown> = {};

  Object.keys(data).forEach(key => {
    const variableKey = key as keyof typeof data;
    const value = data[variableKey] as CafsFormValue;

    if (value && typeof value === "object" && getBaseValue(value) !== null) {
      if (DataFormat.Year in value && value[DataFormat.Year]) {
        mappedValues[variableKey as keyof typeof mappedValues] = {
          ...value,
          Base: getBaseValue(value)
            ? yearsToMonths(getBaseValue(value))
            : getBaseValue(value),
        };
      } else if (DataFormat.Hour in value && value[DataFormat.Hour]) {
        mappedValues[variableKey as keyof typeof mappedValues] = {
          ...(value as Object),
          Base: getBaseValue(value)
            ? convertHourlyToDaily(getBaseValue(value))
            : getBaseValue(value),
        };
      } else {
        mappedValues[variableKey as keyof typeof mappedValues] = value;
      }
    } else mappedValues[variableKey as keyof typeof mappedValues] = value;
  });

  return mappedValues as CafsVariableCollection<T>;
};
