import { useIntl } from "react-intl";
import { useMatch, useNavigate, useParams } from "react-router-dom";

import { useMutation } from "@tanstack/react-query";

import { Inputs } from "../../../api/inputs";
import { Plants } from "../../../api/plants";
import { Cafs } from "../../../api/types/cafs";
import { MutationKey } from "../../../consts/mutation-keys";
import { Status } from "../../../consts/status";
import { useApiError } from "../../../hooks/useApiError";
import { useApiSuccess } from "../../../hooks/useApiSuccess";
import { ApiHookConfig } from "../../../types/api-hook-config";
import { CafsViewQueryKeys } from "../const/cafs-view-query-keys";

type UseRejectDraftProps = ApiHookConfig & {
  cafs: Cafs | undefined;
};

export function useDraftReject({ cafs, onSuccess }: UseRejectDraftProps) {
  const { datasetId, id } = useParams();
  const matchInput = useMatch("/dashboard/input/:datasetId/:id");
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const navigateToDashboard = () => {
    if (cafs?.Status === Status.DraftNew) {
      navigate("/dashboard");
    }
  };

  const { handleError } = useApiError();
  const { handleSuccess } = useApiSuccess({
    snackbarText: formatMessage({
      id: "ACTIONS.DRAFT_REJECT_SUCCESS",
      defaultMessage: "Draft rejected",
      description: "Reject draft success message",
    }),
    invalidate: CafsViewQueryKeys,
    onSuccessFn: () => {
      navigateToDashboard();
      onSuccess();
    },
  });

  const mutationFn = () => {
    return matchInput
      ? Inputs.reject({ path: { datasetId: +datasetId!, inputId: +id! } })
      : Plants.reject({ path: { datasetId: +datasetId!, plantId: +id! } });
  };

  const { mutate: rejectDraft, isPending } = useMutation({
    mutationFn: mutationFn,
    onSuccess: handleSuccess,
    onError: handleError,
    mutationKey: [MutationKey.RejectDraft, datasetId!, id!],
  });

  return { rejectDraft, isPending };
}
