import { ApiError } from "../types/api-error";

export const mapApiErrorToMessage = (apiError: ApiError): string => {
  const { Errors, GenericErrors } = apiError.Body;

  // If Errors is not empty, concatenate all error messages into a single string
  if (Errors && Object.keys(Errors).length > 0) {
    return Object.values(Errors).flat().join(", ");
  }

  // If Errors is empty, concatenate all generic error messages into a single string
  if (GenericErrors && GenericErrors.length > 0) {
    return GenericErrors.join(", ");
  }

  // If there are no errors, return an empty string
  return "";
};
