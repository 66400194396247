import React, { useContext } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { FormattedMessage, useIntl } from "react-intl";

import { Alert } from "@mui/material";

import { MuiTabPanel } from "../../../components/mui/MuiTabPanel";
import { StyledTabItem } from "../../../components/StyledTabItem";
import { StyledTabs } from "../../../components/StyledTabs";
import { useAuth } from "../../../hooks/useAuth";
import { useTabs } from "../../../hooks/useTabs";
import { CafsContext } from "../CafsContext";

import { GuestsList } from "./cafs-guests/GuestsList";
import { InputVariables } from "./cafs-variables/InputVariables";
import { ReferencesExport } from "./ReferencesExport";

export function InputTabs({ ...props }) {
  const { cafs } = useContext(CafsContext);
  const { tabIndex, handleTabChange } = useTabs();
  const { isFarmTreeRole } = useAuth();

  const { formatMessage } = useIntl();

  return (
    !!cafs?.EnglishName?.Current?.length && (
      <div {...props}>
        <div className="border-[1px] border-gray w-full">
          <StyledTabs
            value={tabIndex}
            onChange={handleTabChange}
            aria-label="input tabs">
            <StyledTabItem
              selected={tabIndex === 0}
              label={formatMessage({
                id: "CAFS.INPUT_TAB_HEADER",
                defaultMessage: "Input",
                description: "Input tab header on cafs view",
              })}
              index={0}
            />
            <StyledTabItem
              selected={tabIndex === 1}
              label={formatMessage({
                id: "CAFS.GUESTS_TAB_HEADER",
                defaultMessage: "Guests",
                description: "Guests tab header on cafs view",
              })}
              index={1}
            />
          </StyledTabs>
        </div>

        <MuiTabPanel index={0} value={tabIndex}>
          <ErrorBoundary
            fallback={
              <Alert severity="error">
                <FormattedMessage
                  id="ERROR.FETCHING_CAFS_DATA"
                  defaultMessage="Something went wrong when fetching cafs data..."
                  description="Error message when fetching cafs data problem"
                />
              </Alert>
            }>
            <InputVariables />
          </ErrorBoundary>
        </MuiTabPanel>
        <MuiTabPanel index={1} value={tabIndex}>
          <ErrorBoundary
            fallback={
              <Alert severity="error">
                <FormattedMessage
                  id="ERROR.FETCHING_GUESTS_DATA"
                  defaultMessage="Something went wrong when fetching guests data..."
                  description="Error message when fetching guests data problem"
                />
              </Alert>
            }>
            <GuestsList />
          </ErrorBoundary>
        </MuiTabPanel>
        {isFarmTreeRole && <ReferencesExport />}
      </div>
    )
  );
}
