import React from "react";
import { Navigate, RouteProps } from "react-router-dom";

import { UserRole } from "../consts/user-role";
import { useAuth } from "../hooks/useAuth";

type ProtectedRouteProps = {
  allowedRoles?: UserRole[];
} & RouteProps;
const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  allowedRoles = [
    UserRole.FarmTreeDeveloper,
    UserRole.FarmTreeMaster,
    UserRole.FarmTreeExpert,
    UserRole.ClientExpert,
    UserRole.ClientViewer,
    UserRole.ClientEditor,
  ],
  ...props
}: ProtectedRouteProps) => {
  const { isAuthenticated, user } = useAuth();
  const hasPermission =
    isAuthenticated &&
    user &&
    user?.Role &&
    allowedRoles.includes(user.Role as UserRole);
  return hasPermission ? props.element : <Navigate to="/login" />;
};

export default ProtectedRoute;
