import { Prices } from "../../../api/types/prices";
import { Product } from "../../../api/types/products";

export function mapProducts(
  products: Product[] | undefined | null,
  defaultPrices: Prices | undefined | null,
  regionPrices: Prices | undefined | null,
) {
  return (products || []).map(p => {
    return {
      name: p.Name,
      productId: p.Id,
      world: {
        HighPricePKg: (defaultPrices?.Prices || []).find(
          item => item.ProductId === p.Id,
        )?.HighPricePKg,
        PriceLowOfHigh: (defaultPrices?.Prices || []).find(
          item => item.ProductId === p.Id,
        )?.PriceLowOfHigh,
      },
      region: {
        HighPricePKg: (regionPrices?.Prices || []).find(
          item => item.ProductId === p.Id,
        )?.HighPricePKg,
        PriceLowOfHigh: (regionPrices?.Prices || []).find(
          item => item.ProductId === p.Id,
        )?.PriceLowOfHigh,
      },
    };
  });
}
