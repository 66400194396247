import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import { useMutation } from "@tanstack/react-query";

import { Plants } from "../../../api/plants";
import { PlantProduct } from "../../../api/types/plants";
import { MutationKey } from "../../../consts/mutation-keys";
import { QueryKey } from "../../../consts/query-keys";
import { useApiError } from "../../../hooks/useApiError";
import { useApiSuccess } from "../../../hooks/useApiSuccess";
import { ApiHookConfig } from "../../../types/api-hook-config";
import { CafsForm } from "../../../types/cafs-form";

export const useUpdatePlantProductForm = ({
  onSuccess,
  productId,
}: ApiHookConfig & { productId: number | undefined }) => {
  const { datasetId, id } = useParams();
  const { formatMessage } = useIntl();

  const { handleError, apiErrors, clearApiErrors } = useApiError();
  const { handleSuccess } = useApiSuccess({
    invalidate: [[QueryKey.PlantProducts], [QueryKey.CafsDetails]],
    snackbarText: formatMessage({
      id: "ACTIONS.PRODUCT_UPDATE_SUCCESS",
      defaultMessage: "Product updated successfully.",
      description: "Product update success message",
    }),
    onSuccessFn: () => {
      clearApiErrors();
      onSuccess();
    },
  });

  const mutationFn = (newData: Partial<CafsForm<PlantProduct>>) =>
    Plants.updateProduct(
      {
        path: {
          plantProductId: +productId!,
          datasetId: +datasetId!,
          plantId: +id!,
        },
      },
      newData,
    );

  const { mutate: updateProduct } = useMutation({
    mutationKey: [MutationKey.UpdatePlantProduct, +datasetId!, +id!],
    mutationFn: mutationFn,
    onError: handleError,
    onSuccess: handleSuccess,
  });

  return { updateProduct, apiErrors, clearApiErrors };
};
