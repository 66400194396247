import { CafsTreeNodeType } from "../../../types/cafs-tree-node-type";

export function treeNodeStyles(node: CafsTreeNodeType, searchTerm: string) {
  const lowerSearchTerm = searchTerm.toLowerCase();
  const matchesSearchTerm = (name: string | null | undefined) =>
    name?.toLowerCase().includes(lowerSearchTerm);

  return {
    ":selected": { color: "red" },
    "& > .MuiTreeItem-content": {
      backgroundColor:
        (matchesSearchTerm(node?.name) ||
          matchesSearchTerm(node?.scientificName)) &&
        searchTerm.length
          ? "#e8f5e9"
          : "inherit",
      "&:hover": {
        backgroundColor:
          (matchesSearchTerm(node?.name) ||
            matchesSearchTerm(node?.scientificName)) &&
          searchTerm.length
            ? "#e8f5e9"
            : "inherit",
      },
    },
  };
}
