import React, { useState } from "react";
import { Outlet } from "react-router-dom";

import { useMediaQuery, useTheme } from "@mui/material";
import { twMerge } from "tailwind-merge";

import { menu } from "../consts/menu";
import { SideNav } from "../features/side-nav/SideNav";
import { TopNav } from "../features/top-nav/TopNav";

export function MainTemplate() {
  const [sideNavOpen, setSideNavOpen] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <div className="flex flex-col h-screen pt-[82px] overflow-y-hidden">
      <TopNav open={sideNavOpen} onToggle={setSideNavOpen} />
      <div className="flex flex-row overflow-y-auto">
        <SideNav
          open={sideNavOpen}
          menu={menu}
          onNavigate={() => setSideNavOpen(false)}
        />
        <div
          className={twMerge(
            "px-8 pt-8 pb-16 w-full overflow-y-auto",
            matches ? "ml-[348px]" : "",
          )}>
          <Outlet />
        </div>
      </div>
    </div>
  );
}
