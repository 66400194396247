import { useParams } from "react-router-dom";

import { useQuery } from "@tanstack/react-query";

import { Plants } from "../../../api/plants";
import { PlantProducts } from "../../../api/types/plants";
import { QueryKey } from "../../../consts/query-keys";
import { useFetchError } from "../../../hooks/useFetchError";
import { ApiError } from "../../../types/api-error";

export const usePlantProductsData = () => {
  const { datasetId, id } = useParams();

  const queryFn = () =>
    Plants.getProducts({
      path: { datasetId: +datasetId!, plantId: +id! },
    });

  const {
    data: plantProducts,
    isPending,
    isFetching,
    error,
  } = useQuery<PlantProducts, ApiError>({
    queryKey: [QueryKey.PlantProducts, datasetId, id],
    queryFn: queryFn,
  });

  useFetchError(error);

  return { plantProducts, isPending, isFetching };
};
