import { useQuery } from "@tanstack/react-query";

import { Regions } from "../../../api/regions";
import { QueryKey } from "../../../consts/query-keys";

export const useFetchRegions = () => {
  const { data: regions } = useQuery({
    queryKey: [QueryKey.Regions],
    queryFn: ({ queryKey }) => Regions.list(),
  });

  return { regions };
};
