import React, { useEffect, useMemo, useState } from "react";

import { Region } from "../../../api/types/regions";
import { LoadingPlaceholder } from "../../../components/LoadingPlaceholder";
import { SearchBar } from "../../../components/SearchBar";
import { PriceItem } from "../../../types/price-item";
import { useFetchProducts } from "../../products-view/api/useFetchProducts";
import { useFetchRegionPrices } from "../api/useFetchRegionPrices";
import { useFetchRegions } from "../api/useFetchRegions";
import { PriceUpdatePayload, useUpdatePrice } from "../api/useUpdatePrice";
import { mapProducts } from "../utils/map-products";

import { PricesTable } from "./PricesTable";

export type PriceCellType = "world" | "region";
export type PriceCell =
  | {
      column: string;
      row: number;
      type: PriceCellType;
    }
  | null
  | undefined;

export type ProductsWithPricesProps = {
  selectedRegion: Region | undefined | null;
};
export function ProductsWithPrices({
  selectedRegion,
}: ProductsWithPricesProps) {
  const [editedItem, setEditedItem] = useState<{
    column: string;
    row: number;
    type: "world" | "region";
  } | null>();

  const clearEditedItem = () => {
    setEditedItem(null);
  };

  const { regions } = useFetchRegions();
  const { products } = useFetchProducts();

  const defaultRegion = (regions?.Regions || []).find(r => r.IsDefault);
  const [searchPhase, setSearchPhase] = useState("");

  const { updatePrice, apiErrors, clearApiErrors } =
    useUpdatePrice(clearEditedItem);

  const { regionPrices } = useFetchRegionPrices(selectedRegion?.Id);

  const { regionPrices: defaultPrices, isPending: defaultPricesLoading } =
    useFetchRegionPrices(defaultRegion?.Id);

  useEffect(() => {
    clearApiErrors();
  }, [editedItem?.column, editedItem?.row, editedItem?.type]);

  const handleWorldSave = (data: Partial<PriceUpdatePayload>) => {
    updatePrice({ ...data, regionId: defaultRegion?.Id } as PriceUpdatePayload);
  };

  const handleRegionSave = (data: Partial<PriceUpdatePayload>) => {
    updatePrice({
      ...data,
      regionId: selectedRegion?.Id,
    } as PriceUpdatePayload);
  };

  const items: PriceItem[] = useMemo(
    () =>
      mapProducts(products?.Products, defaultPrices, regionPrices).filter(p =>
        p.name.toLowerCase().includes(searchPhase.toLowerCase()),
      ),
    [
      defaultPrices,
      regionPrices,
      products?.Products,
      selectedRegion,
      searchPhase,
    ],
  );

  return defaultPricesLoading ? (
    <LoadingPlaceholder />
  ) : (
    <div className="p-4 border-[1px] border-solid border-gray-200 rounded mt-4">
      <div className="mb-8 mt-4">
        <SearchBar
          placeholder="Search by product name"
          searchIcon
          onChange={value => {
            setSearchPhase(value);
          }}
        />
      </div>

      <PricesTable
        items={items}
        selectedRegion={selectedRegion}
        editedCell={editedItem}
        onWorldSave={handleWorldSave}
        onRegionSave={handleRegionSave}
        onCellEdit={data => setEditedItem(data)}
        apiErrors={apiErrors}
      />
    </div>
  );
}
